import React ,{useState , useEffect} from "react";
import {CaseListService} from "../../services/CaseService";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Dropdown from 'react-bootstrap/Dropdown';
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DetailTopbar from "../../components/common/DetailTopbar";
import {useParams, Link} from "react-router-dom";
import {AssignmentByCaseModel} from "../../models/Assignment";
import DatePicker from "react-datepicker";
import Loader from "../../components/common/Loader";
import {inputNumber, showErrorInfo} from "../../components/common/Helpers";
import CaseMainInfo from "../../components/common/CaseMainInfo";
import $ from 'jquery';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface URLParam {
    id : string;
}

interface IState{
    loading :boolean;
    cases_arr : [];
    assignments : AssignmentByCaseModel;
    user_arr : [];
    expect_contract_type : [];
    errorMessage : string;
}

interface IProps{}
let AssignmentByCase:React.FC<IProps> = () => {
    let {encode} = useParams<URLParam | any>();
    let [state, setState] = useState<IState>({
        loading : false,
        cases_arr :  [],
        assignments : {
            case_id: 0,
            create_type: "",
            person_id : 0,
            proposal_price : 0,
            proposal_date : "",
            proposal_user_id : "",
            interview_date : "",
            interview_user_id : "",
            interview_time : "",
            interview_place : "",
            interview_remark : "",
            buyer_contract: "準委任",
            seller_contract: "準委任",
            adoption_flg: 0,
            work_start_date: ""
        },
        user_arr : [],
        expect_contract_type : [],
        errorMessage : "",
    });

    useEffect(() => {
        setState({...state, loading: true});
        if(encode){
            CaseListService.show(encode).then((response)=>{
                setState({
                    ...state,
                    loading : false,
                    cases_arr : response.data.case,
                    user_arr : response.data.users, 
                    assignments : {
                        case_id: response.data.case.case_id,
                        create_type: "case_person",
                        person_id : 0,
                        proposal_price : 0,
                        proposal_date : "",
                        proposal_user_id : "",
                        interview_date : "",
                        interview_user_id : "",
                        interview_time : "",
                        interview_place : "",
                        interview_remark : "",
                        buyer_contract: "準委任",
                        seller_contract: "準委任",
                        adoption_flg: 0,
                        work_start_date: ""
                    },
                    expect_contract_type: response.data.select_options.expect_contract_type
                })
                // setData(response.data.assignments);
            }).catch((error)=>{
                setState({
                    ...state,
                    loading : false,
                    errorMessage : error.message
                })
            });
        }
    }, [encode]);

  let {loading, cases_arr, assignments, user_arr, expect_contract_type, errorMessage} = state;

  let [data , setData] = useState([]);
  const [selectedInterviewDate, setSelectedInterviewDate] = useState(null);
  const [selectedProposalDate, setSelectedProposalDate] = useState(null);
  const [selectedWorkStartDate, setSelectedWorkStartDate] = useState(null);
  const [person_list, setPersonList] = useState([]);
  const [person_field, setPersonFieldList] = useState([]);
  const [person_list_display, setPersonListDisplay] = useState("none");
  const [person_search_result , setSearchResult] = useState("");
  const [assign_person_id, setAssignPersonID] = useState("");


  let [work_start_date_disabled, setWorkStartDateDisabled] = useState(true);

  const handleDateChange = function(date:any, column_name:any){
        let selected_data:any = "";
        if(date !== null){
            selected_data = date.getFullYear() +"-"+ (date.getMonth() + 1) +"-"+ (date.getDate());
        }

 
        switch (column_name) {
            case "interview_date":
                setSelectedInterviewDate(date);
                setState({
                    ...state,
                    assignments : {
                        ...state.assignments,
                        interview_date : selected_data // input name 'username' set value 'event target value'
                    }
                });
                break;
                case "proposal_date":
                    setSelectedProposalDate(date);
                    setState({
                        ...state,
                        assignments : {
                            ...state.assignments,
                            proposal_date : selected_data // input name 'username' set value 'event target value'
                        }
                    });
                break;
                case "work_start_date":
                    setSelectedWorkStartDate(date);
                    setState({
                        ...state,
                        assignments : {
                            ...state.assignments,
                            work_start_date : selected_data // input name 'username' set value 'event target value'
                        }
                    });
                break;    
            default:
                break;
        }
    
    }

  
    const personSearch = function(){
        setAssignPersonID("");
        $("input[name=person_id]").next().remove();
        CaseListService.searchPerson("InitialName,full_name,kana_name,alphabet_name",state.assignments.person_id,"persons").then((response) => {
            setPersonList(response.data.data);
            setPersonFieldList(response.data.info);
            setPersonListDisplay("block");
        //    window.location.href = "/cases";
        })
    }


    let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        if(event.target.name == "person_id"){
            setSearchResult("");
        }
        setState({
            assignments : {
                ...state.assignments,
                [event.target.name] : event.target.value  // input name set value 'event target value'
            },
            loading : false,
            cases_arr : cases_arr,
            user_arr : user_arr,
            expect_contract_type : expect_contract_type, 
            errorMessage : "",
        });        
    };

    let assignPersonId = (e: React.ChangeEvent<HTMLSelectElement>): void => { // change event type declare in React
        var index = e.target.selectedIndex;
        setSearchResult(e.target[index].innerHTML);
        setAssignPersonID(e.target.value);
        setState({
            assignments : {
                ...state.assignments,
                person_id : parseInt(e.target.value),
            },
            loading : false,
            cases_arr : cases_arr,
            user_arr : user_arr,
            expect_contract_type : expect_contract_type, 
            errorMessage : "",
        }); 
        setPersonListDisplay("none");       
    };

    let store = (event : React.FormEvent<HTMLFormElement>):void => { // click event type declare in React
        event.preventDefault();
        CaseListService.assignmentByCase(state.assignments).then((response) => {
            window.location.href = '/cases/'+cases_arr['encode']; 
        }).catch((error) => {
            if(error.response.data){
                showErrorInfo(error.response.data);
            }

        })
    };

    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setState({
            assignments : {
                ...state.assignments,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            },
            loading : false,
            cases_arr : cases_arr,
            user_arr : user_arr,
            expect_contract_type : expect_contract_type, 
            errorMessage : "",
        });
     };
     const handleAdoption = function(e:any){
        let isChecked = e.target.checked;
        let value = e.target.value;
        if(isChecked === true){
            setState({
                assignments : {
                    ...state.assignments,
                    adoption_flg: e.target.value  // input name 'username' set value 'event target value'
                },
                loading : false,
                cases_arr : cases_arr,
                user_arr : user_arr,
                expect_contract_type : expect_contract_type, 
                errorMessage : "",
            });

            if(value == "1"){
                setWorkStartDateDisabled(false);
            }else{
                setWorkStartDateDisabled(true); 
            }
           
        }
    }

    const handleKeyPress = (event:React.KeyboardEvent)  => {
        if (event.key === "Backspace") {
            setSearchResult("");
        }
      };
   
    return(
        <React.Fragment>
          <DetailTopbar />
        <Container>
            <div className="book-mark-container detail">
                <Row>
                    <Col xxl={4} xl={4} md={4} sm={6} xs={8}>
                        <h3 className='header-left-design'>割リ当て登録 (案件ID : {cases_arr['case_id']})</h3>
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={6} xs={6} className="xs-hidden">
                        <div className="mt-3 text-center">
                            {( loading   == true) ? <Loader /> :<> </>}
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={6} xs={4} className="mt_2">
                        <Link to={`/cases/${cases_arr['encode']}`} className="header-back-btn-white w_100">キャンセル</Link>
                    </Col>
                </Row>
                <Row>
                    <Col xxl={4} xl={4} md={4} sm={6} xs={12}  className="pc-hidden">
                        <div className="mt-3 text-center">
                            {( loading   == true) ? <Loader /> :<> </>}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xxl={6} xl={6} md={6} sm={6} xs={12} className="mb-3">
                        <CaseMainInfo cases_arr={cases_arr} />
                    </Col>
                    <Col xxl={6} xl={6} md={6} sm={6} xs={12}>
                        <div className="card">
                            <div className="card-body">
                            <h4>割り当て登録</h4>
                                <form className="form assignment_add" onSubmit={store}>
                                    <input type="hidden" name="create_type" value="case_person" />
                                    <input type="hidden" name="case_id" value="{cases_arr['case_id']}" />
                                    <Row className="mt-5">
                                        <label htmlFor="person_id">技術者</label>
                                        <Col xxl={10} xl={10} md={10} sm={10} xs={8}  className="input-box ">
                                            <input type="text"  className="form-control" onChange={updateInput} name="person_id" 
                                             value={(state.assignments.person_id != 0)?state.assignments.person_id:""} onKeyDown={handleKeyPress} />
                                        </Col>
                                        <Col xxl={2} xl={2} md={2} sm={2} xs={4} >
                                            <button className="btn_standard btn_search w-100" onClick={personSearch} type="button">検索</button>
                                        </Col>
                                        <span>{person_search_result}</span>
                                    </Row>
                                    <Row className="mt-3" style={{display: person_list_display }}>
                                        <Col xxl={12} xl={12} md={12} sm={12} xs={12}  className="input-box ">
                                            <select name="partner_id" className="form-control" value={assign_person_id} onChange={assignPersonId}>
                                                <option value=""></option>
                                            {person_list.length > 0 && person_list.map((key,val) => {
                                                return(<option value={key['person_id']}>イニシャル：{key["InitialName"]}、
                                               英語氏名：{key["full_name"]}、 漢字氏名：{key["alphabet_name"]}</option>);
                                                })
                                            }
                                            </select>
                                            {
                                              person_list.length == 0 ?
                                               <span>検索結果はありません</span>
                                               : null
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xxl={12} xl={12} md={12} sm={12} xs={12}  className="input-box ">
                                            <label htmlFor="proposal_price">希望単価</label>
                                            <input type="number" className="form-control" onKeyPress={inputNumber} onChange={updateInput} name="proposal_price"  value={(state.assignments.proposal_price != 0)?state.assignments.proposal_price:""}/>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <label htmlFor="proposal_date ">提案日＆提案者</label>
                                        <Col xxl={6} xl={6} md={6} sm={6} xs={6}  className="input-box ">
                                            <DatePicker 
                                                dateFormat="yyyy/MM/dd"
                                                locale="ja"
                                                selected={selectedProposalDate} 
                                                onChange={date =>
                                                    handleDateChange(date, "proposal_date")
                                                }
                                                autoComplete = "off"
                                                isClearable
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                />
                                        </Col>
                                        <Col xxl={6} xl={6} md={6} sm={6} xs={6}  className="input-box ">
                                        <select name="proposal_user_id" id="type" onChange={onSelect} value={state.assignments.proposal_user_id} className="form-control w-100">
                                            <option value="">選択なし</option>
                                            {user_arr.map(user => {
                                                            return(<option value={user["user_id"]}> {user["name_jp"]}</option>)
                                                        })
                                                }
                                            </select>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <label htmlFor="interview_date">面談日＆面談者</label>
                                        <Col xxl={6} xl={6} md={6} sm={6} xs={6}  className="input-box ">
                                            <DatePicker 
                                                dateFormat="yyyy/MM/dd"
                                                locale="ja"
                                                selected={selectedInterviewDate} 
                                                onChange={date =>
                                                    handleDateChange(date, "interview_date")
                                                }
                                                autoComplete = "off"
                                                isClearable
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                />
                                        </Col>
                                        <Col xxl={6} xl={6} md={6} sm={6} xs={6}  className="input-box ">
                                        <select name="interview_user_id"  id="type" onChange={onSelect} value={state.assignments.interview_user_id} className="form-control w-100">
                                            <option value="">選択なし</option>
                                            {user_arr.map(user => {
                                                            return(<option value={user["user_id"]}> {user["name_jp"]}</option>)
                                                        })
                                                }
                                            </select>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xxl={12} xl={12} md={12} sm={12} xs={12}  className="input-box ">
                                            <label htmlFor="interview_time">面談時刻</label>
                                            <input type="time" className="form-control" onChange={updateInput} name="interview_time"  value={state.assignments.interview_time}/>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xxl={12} xl={12} md={12} sm={12} xs={12}  className="input-box ">
                                            <label htmlFor="interview_place">面談場所</label>
                                            <input type="text" className="form-control" onChange={updateInput} name="interview_place"  value={state.assignments.interview_place}/>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xxl={12} xl={12} md={12} sm={12} xs={12}  className="input-box ">
                                            <label htmlFor="interview_remark">面談評価</label>
                                            <input type="text" className="form-control" onChange={updateInput} name="interview_remark"  value={state.assignments.interview_remark}/>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3 input-box ">
                                        <Col xxl={12} xl={12} md={12} sm={12} xs={12}  className="input-box ">
                                            <label htmlFor="buyer_contract">受注契約</label>
                                            <select name="buyer_contract" id="type" onChange={onSelect} value={state.assignments.buyer_contract} className="form-control w-100">
                                            {expect_contract_type.map(contract_type => {
                                                            return(<option value={contract_type}> {contract_type}</option>)
                                                        })
                                                }
                                            </select>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xxl={12} xl={12} md={12} sm={12} xs={12}  className="input-box ">
                                            <label htmlFor="seller_contract">発注契約</label>
                                            <select name="seller_contract" id="type" onChange={onSelect} value={state.assignments.seller_contract} className="form-control w-100">
                                            {expect_contract_type.map(contract_type => {
                                                            return(<option value={contract_type}> {contract_type}</option>)
                                                        })
                                                }
                                            </select>                        
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xxl={12} xl={12} md={12} sm={12} xs={12}  className="result input-box ">
                                            <label htmlFor="seller_contract">最終結果</label>
                                            <span className=""><input type="radio" className="p-2 " value="0" name="adoption_flg"  checked ={state.assignments.adoption_flg ==0 ? true: false}  onChange={e => handleAdoption(e)} style={{position:"relative",top:"3px"}}/> 検討中</span>
                                            <span className=""><input type="radio"  className="p-2" value="1" name="adoption_flg" checked ={state.assignments.adoption_flg ==1 ? true: false}  onChange={e => handleAdoption(e)} style={{position:"relative",top:"3px"}}/> 採用</span>
                                            <span className=""><input type="radio"  className="p-2"  value="2" name="adoption_flg" checked ={state.assignments.adoption_flg ==2 ? true: false}  onChange={e => handleAdoption(e)} style={{position:"relative",top:"3px"}} /> 不採用</span>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <label htmlFor="work_start_date">稼働開始日</label>
                                        <Col xxl={4} xl={4} md={4} sm={4} xs={12}  className="input-box ">
                                            <DatePicker 
                                                dateFormat="yyyy/MM/dd"
                                                locale="ja"
                                                selected={selectedWorkStartDate} 
                                                onChange={date =>
                                                    handleDateChange(date, "work_start_date")
                                                }
                                                name="work_start_date"
                                                disabled = {work_start_date_disabled}
                                                autoComplete = "off"
                                                isClearable
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                />
                                            {/* <input type="text" ui-type="date" onChange={updateInput} name="work_start_date"  value={state.assignments.work_start_date} className="form-control"/> */}
                                        </Col>
                                    </Row>
                                    <Row className="mt-5">
                                        <div className="text-center">
                                            <button type="submit" className="button submit-btn">登録</button>
                                        </div>
                                    </Row>
                                    </form>
                            </div>
                        </div>
                    </Col>
                  
			    </Row>
            
                </div>
            </Container>	
            
       
        </React.Fragment>
    );
};
export default AssignmentByCase;