import React, {useEffect, useState} from 'react';
import {ICase} from "../../models/ICase";
import {AssignmentService} from "../../services/AssignmentService";
import AuthService from "../../services/AuthService";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Dropdown from 'react-bootstrap/Dropdown';
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import {Link} from "react-router-dom";
import $ from 'jquery';
import { CSVLink, CSVDownload } from "react-csv";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {AssignmentUpdate} from "../../models/Assignment";
import DatePicker from "react-datepicker";
import {showErrorInfo, PCModalStyle, MobileModalStyle} from "../../components/common/Helpers";
import Loader from "../../components/common/Loader";
import {useLocation} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageTitle from "../../components/common/PageTitle";

interface IState{
    assignment : [],
    errorMessage : string;  
    perPage : number;
    page : number;
    column: string;
    order: string;
    totalRows : number;
    hrList : [];
    assignStatusList : [];
    proposeStatusList : [];
    paymentSiteList : [];
    assignmentUpdateList : AssignmentUpdate;
    loading : boolean;
}
interface IProps{}
let AssignmentCaseList:React.FC<IProps> = () => {
   
    const row : string[] = [];
    const [checkedRows, setCheckedRows] = useState(row);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false); 
    let [selectedProposalDate, setSelectedProposalDate] = useState(null);
    let [selectedAssignEndDate, setSelectedAssignEndDate] = useState(null);
    let [selectedWorkEndDatePlan, setSelectedWorkEndDatePlan] = useState(null);
    let [selectedWorkStartDate, setSelectedWorkStartDate] = useState(null);
    let [selectedInterviewDate, setSelectedInterviewDate] = useState(null);
    const [showPrevColor, setShowPrevColor] = useState("grey");
    const [showNextColor, setShowNextColor] = useState("grey");
    const [width, setWidth] = useState(719);
    const [height, setHeight] = useState(770);
    const [show_case_name, setShowCaseName] = useState("");
    const [show_person_nm, setShowPersonName] = useState("");

    const handleDateChange = function(date:any, column_name:any){
        let selected_data:any = "";
        if(date !== null){
            selected_data = date.getFullYear() +"/"+ (date.getMonth() + 1).toString().padStart(2, "0")+"/"+(date.getDate());
        }   
        switch (column_name) {
            case "proposal_date":
                setSelectedProposalDate(date);
                setState({
                    ...state,
                    assignmentUpdateList : {
                        ...state.assignmentUpdateList,
                       proposal_date : selected_data // input name 'username' set value 'event target value'
                    }
                });
                break;
            case "interview_date":
                setSelectedInterviewDate(date);
                setState({
                    ...state,
                    assignmentUpdateList : {
                        ...state.assignmentUpdateList,
                        interview_date : selected_data // input name 'username' set value 'event target value'
                    }
                });
                break;
            case "work_start_date":
                setSelectedWorkStartDate(date);
                setState({
                    ...state,
                    assignmentUpdateList : {
                        ...state.assignmentUpdateList,
                        work_start_date : selected_data // input name 'username' set value 'event target value'
                    }
                });
                break;
            case "work_end_date_plan":
                setSelectedWorkEndDatePlan(date);
                setState({
                    ...state,
                    assignmentUpdateList : {
                        ...state.assignmentUpdateList,
                        work_end_date_plan : selected_data // input name 'username' set value 'event target value'
                    }
                });
                break;
            case "assign_end_date":
                setSelectedAssignEndDate(date);
                setState({
                    ...state,
                    assignmentUpdateList : {
                        ...state.assignmentUpdateList,
                        assign_end_date : selected_data // input name 'username' set value 'event target value'
                    }
                });
                break;
            default:
                break;
        }
       
    }
    let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setState({
            ...state,
            assignmentUpdateList : {
                ...state.assignmentUpdateList,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        });   
    };

    let updateTextArea = (event:React.ChangeEvent<HTMLTextAreaElement>):void => {
        setState({
            ...state,
            assignmentUpdateList : {
                ...state.assignmentUpdateList,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        });
    }
    const onSelectAssignment = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setState({
            ...state,
            assignmentUpdateList : {
                ...state.assignmentUpdateList,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            }
        });
     };

    const updateAssign = (assign:any) => {
        AssignmentService.showAssignment(assign).then((data)=>{
             setState({
                    ...state,
                    assignmentUpdateList : {
                        assignment_id: data.data.assignment_id,
                        update_type: "normal",
                        proposal_date : data.data.proposal_date,
                        interview_place : data.data.interview_place,
                        interview_date : data.data.interview_date,
                        interview_time : data.data.interview_time,
                        adoption_flg: data.data.adoption_flg,
                        work_start_date: data.data.work_start_date,
                        work_place : data.data.work_place,
                        work_end_date_plan: data.data.work_end_date_plan,
                        contract_price_type : data.data.contract_price_type,
                        proposal_price : data.data.proposal_price,
                        settlement : data.data.settlement,
                        settlement_to : data.data.settlement_to,
                        settlement_biko : data.data.settlement_biko,
                        payment_site: data.data.payment_site,
                        person_cost : data.data.person_cost,
                        o_adj_hours_low : data.data.o_adj_hours_low,
                        o_adj_hours_high : data.data.o_adj_hours_high,
                        o_adj_hours_biko : data.data.o_adj_hours_biko,
                        o_payment_site : data.data.o_payment_site,
                        assign_end_date: data.data.assign_end_date,
                        flb_shared_cmt: data.data.flb_shared_cmt // input name 'username' set value 'event target value'
                    }
                });
                setShowCaseName(data.data.case_name);
                setShowPersonName(data.data.person_name);
                setOpen(true);
        }).catch((error) => {
           console.log(error);
        })
    
    }

    const updateCaseAssign = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
      
            
        AssignmentService.assignmentUpdate(state.assignmentUpdateList).then((response) => {
            getData(assignment_status,propose_status,flb_hr_id,perPage,page,search_keywords, sort, column, order);
            handleClose();
        //    window.location.href = "/assignment/list/case";
        }).catch((error) => {
            if(error.response.data){
                showErrorInfo(error.response.data);
            }

        })
        
    };
  
    const handleAdoption = function(e:any){
        let isChecked = e.target.checked;
        let value = e.target.value;
        if(isChecked === true){
            setState({
                ...state,
                assignmentUpdateList : {
                    ...state.assignmentUpdateList,
                    [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
                }
            });          
        }
    }

    const defaultcolumns = [
            {
                name: "assignment_id",
                label: "編集",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        // return(<Link to={`/cases/${tableMeta.rowData[1]}`} >{value}</Link>);
                            return(<button type="button" className="btn_standard btn-sm" onClick={() => {
                                updateAssign(`${tableMeta.rowData[0]}`);
                                }}>編集
                            </button>);
                        },
                    },
               
            },
            {
                name: "assignment_id",
                label: "承認提出",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: {maxWidth: "100px" }}),
                    customBodyRender: (value:string, tableMeta:any, updateValue:any) => {
                        if(tableMeta.rowData[17] == "1" && tableMeta.rowData[27] != null && tableMeta.rowData[18] != null)
                        {
                            if(tableMeta.rowData[23] == null || tableMeta.rowData[23] == ""){
                                return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                    assignAdmit(`${tableMeta.rowData[1]}`);
                                    }}>承認提出
                                </button>);
                            }else{
                                return(<span>承認済み</span>);
                            }
                        } else{
                            return(<span></span>);
                        }
                       
                        },
                    },
               
            },
            {
                name: "assignment_id",
                label: "割り当てID",
            },
           {
            name: "case_id",
            label: "案件ID",
           },
           {
            name: "case-encode",
            label: "Encode",
            "options":{
                "display":false,
             }
           },
           {
            name: "case-case_name",
            label: "案件名",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "250px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link  to="" onClick={()=>caseDetail(tableMeta.rowData[4])}>
                        <div className='longText' title={value} style={{color:"blue"}}>
                            {value}
                        </div>
                    </Link>);
                    },
                },
           },
           {
            name: "case-saler-name_jp",
            label: "営業担当",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "130px", maxWidth:"150px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                 },
           },
           {
            name: "person-encode",
            label: "Encode",
            "options":{
                "display":false,
             }
           },
           {
            name: "person-full_name",
            label: "要員名",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "150px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to="" onClick={()=>personDetail(tableMeta.rowData[7])}>
                        <div className='longText' title={value} style={{color:"blue"}}>
                            {value}
                        </div>
                        </Link>);
                    },
                },
           },
           {
            name: "case-hr-name_jp",
            label: "HR担当者",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px" ,maxWidth: "130px" }}),
            }
           },
           {
            name: "assigner-name_jp",
            label: "割り当て担当",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "130px" }}),
            }
           },
           {
            name: "assign_start_date",
            label: "割り当て開始日",
            options: {
                filter: true,
                setCellProps: () => ({ style: {maxWidth: "100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                    return(value);
                    },
                },
           },
           {
            name: "proposal_date",
            label: "提案日",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "120px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                }
           },
        },
           {
            name: "interview_time",
            label: "面談時刻",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "130px",textAlign:"center"}})
            }
           },
           {
            name: "interview_place",
            label: "面談場所",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "130px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                }
           },
           },
           {
            name: "interview_date",
            label: "面談日",
            class: "longText",
            options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "120px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                    // return(value);
                    },
                },
           },
           {
            name: "interview_remark",
            label: "面談評価",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px",  maxWidth: "130px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                }
           }
        },
           {
            name: "adoption_flg",
            label: "最終結果",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "130px",maxWidth: "150px", textAlign:"center"}}),
            }
           },
        //    {
        //     name: "work_start_date",
        //     label: "稼働開始日",
        //     options: {
        //         filter: true,
        //         setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
        //         customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
        //             value = (value != null)?value.substring(0, value.indexOf(" ")):value;
        //             return(value);
        //             },
        //         },
        //    },
        //    {
        //     name: "work_place",
        //     label: "業務場所",
        //     class: "longText",
        //     options: {
        //         filter: true, 
        //         setCellProps: () => ({ style: { maxWidth: "100px"}}),
        //         customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
        //             return(
        //                 <div className='longText' title={value}>
        //                     {value}
        //                 </div>
        //             );
        //         }
        //     }
        //    },
        //    {
        //     name: "work_end_date_plan",
        //     label: "稼働終了予定日",
        //     class: "longText",
        //     options: {
        //         filter: true,
        //         customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
        //             return(
        //                 <div className='longText' title={value} style={{maxWidth: "100px"}}>
        //                     {value}
        //                 </div>
        //             );
        //             // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
        //             // return(value);
        //             },
        //         },
        //    },
        //   {
        //     name: "contract_price_type",
        //     label: "稼働単金種別",
        //     options: {
        //         filter: true, 
        //         setCellProps: () => ({ style: { minWidth: "120px", maxWidth: "150px"}}),
        //     }
        //    },
           {
            name: "proposal_price",
            label: "希望単価",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(value > 99999){
                        value = Math.ceil(value/10000)+"万";
                    }else{
                        value = (value)?value: "";
                    }
                   return (value);
                    },
                },
           },
           {
            name: "settlement",
            label: "精算From",
           },
           {
            name: "settlement_to",
            label: "精算To",
           },
           {
            name: "settlement_biko",
            label: "精算備考",
           },
           {
            name: "payment_site",
            label: "支払サイト",
           },
           {
            name: "approval_date",
            label: "承認日",
            class: "LongText",
            options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                    // return(value);
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                },
                
           },
           {
            name: "assign_end_date",
            label: "割り当て終了日",
            class: "LongText",
            options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                    // return(value);
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                },
           },
           {
            name: "flb_shared_cmt",
            label: "社内共有メモ",
            class: "LongText",
            options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "150px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                    // return(value);
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                },
           },
           {
            name: "assignment_id",
            label: "割り当てID",
            "options":{
              
                "display":false,
             }
           },
            {
            name: "work_start_date",
            label: "稼働開始日",
            options: {
                "display":false
            }
           },
        ];
        const allcolumns = [
            {
                name: "case_id",
                label: "編集",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        // return(<Link to={`/cases/${tableMeta.rowData[1]}`} >{value}</Link>);
                            return(<button type="button" className="btn_standard btn-sm" onClick={() => {
                                updateAssign(`${tableMeta.rowData[0]}`);
                                }}>編集
                            </button>);
                        },
                    },
               
            },
            {
                name: "assignment_id",
                label: "承認提出",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                    customBodyRender: (value:string, tableMeta:any, updateValue:any) => {
                        if(tableMeta.rowData[15] == "1" && tableMeta.rowData[26] != null && tableMeta.rowData[20] != null)
                        {
                            if(tableMeta.rowData[23] == null || tableMeta.rowData[23] == ""){
                                return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                    assignAdmit(`${tableMeta.rowData[1]}`);
                                    }}>承認提出
                                </button>);
                            }else{
                                return(<span>承認済み</span>);
                            }
                        } else{
                            return(<span></span>);
                        }
                       
                        },
                    },
               
            },
            {
                name: "assignment_id",
                label: "割り当てID",
            },
            {
             name: "case_id",
             label: "案件ID",
            },
            {
             name: "case-encode",
             label: "Encode",
             "options":{
                 "display":false,
              }
            },
            {
                name: "case-case_name",
                label: "案件名",
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "300px"}}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<Link to="" onClick={()=>caseDetail(tableMeta.rowData[4])}>
                            <div className='longText' title={value} style={{color:"blue"}}>
                                {value}
                            </div>
                        </Link>);
                        },
                    },
               },
            {
             name: "case-saler-name_jp",
             label: "営業担当",
            },
            {
             name: "person-encode",
             label: "Encode",
             "options":{
                 "display":false,
              }
            },
            {
             name: "person-full_name",
             label: "要員名",
             class: "longText",
             options: {
                 filter: true, 
                 setCellProps: () => ({ style: { maxWidth: "150px" }}),
                 customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                     return(<Link to="" onClick={()=>personDetail(tableMeta.rowData[6])}>
                        <div className='longText' title={value} style={{color:"blue"}}>
                            {value}
                        </div>
                     </Link>);
                     },
                 },
            },
            {
             name: "case-hr-name_jp",
             label: "HR担当者",
            },
            {
                name: "case-customer-name_jp",
                label: "取引先",
            },
            {
                name: "case-contact_name",
                label: "取引先担当者",
            },
            {
             name: "assigner-name_jp",
             label: "割り当て担当",
             options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
            }
            },
            {
             name: "interview_date",
             label: "面談日",
             options: {
                 filter: true,
                 setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                 customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                     value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                     return(value);
                     },
                 },
            },
            {
                name: "interview_remark",
                label: "面談評価",
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px"}}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                {value}
                            </div>
                        );
                    }
               }
            },
            {
             name: "adoption_flg",
             label: "最終結果",
            },
            {
             name: "work_start_date",
             label: "稼働開始日",
             options: {
                 filter: true,
                 setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                 customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                     value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                     return(value);
                     },
                 },
            },
            {
                name: "work_place",
                label: "業務場所",
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px"}}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                {value}
                            </div>
                        );
                    }
                }
            },
            {
             name: "work_end_date_plan",
             label: "稼働終了予定日",
             options: {
                 filter: true,
                 setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                 customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                     value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                     return(value);
                     },
                 },
            },
           {
             name: "contract_price_type",
             label: "稼働単金種別",
            },
            {
                name: "proposal_price",
                label: "希望単価",
                options: {
                    filter: true,
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        if(value > 99999){
                            value = Math.ceil(value/10000)+"万";
                        }else{
                            value = (value)?value: "";
                        }
                       return (value);
                        },
                    },
            },
            {
             name: "settlement",
             label: "精算",
            },
            {
             name: "payment_site",
             label: "支払サイト",
            },
            {
                name: "approval_date",
                label: "承認日",
                class: "LongText",
                options: {
                    filter: true,
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                        // return(value);
                        return(
                            <div className='longText' title={value}>
                                {value}
                            </div>
                        );
                        },
                    },
                    
            },
            {
                name: "assign_end_date",
                label: "割り当て終了日",
                class: "LongText",
                options: {
                    filter: true,
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                        // return(value);
                        return(
                            <div className='longText' title={value}>
                                {value}
                            </div>
                        );
                        },
                    },
            },
            {
                name: "flb_shared_cmt",
                label: "社内共有メモ",
                class: "LongText",
                options: {
                    filter: true,
                    setCellProps: () => ({ style: { maxWidth: "150px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                        // return(value);
                        return(
                            <div className='longText' title={value}>
                                {value}
                            </div>
                        );
                        },
                    },
            },
            {
             name: "assignment_id",
             label: "割り当てID",
             "options":{
               
                 "display":false,
              }
            },
         ];

        const addCustomerColumns = [
            {
                name: "case_id",
                label: "編集",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        // return(<Link to={`/cases/${tableMeta.rowData[1]}`} >{value}</Link>);
                            return(<button type="button" className="btn_standard btn-sm" onClick={() => {
                                updateAssign(`${tableMeta.rowData[0]}`);
                                }}>編集
                            </button>);
                        },
                    },
               
            },
            {
                name: "assignment_id",
                label: "承認提出",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                    customBodyRender: (value:string, tableMeta:any, updateValue:any) => {
                        if(tableMeta.rowData[19] == "1" && tableMeta.rowData[24] != null && tableMeta.rowData[25] != null)
                        {
                            if(tableMeta.rowData[20] == null || tableMeta.rowData[20] == ""){
                                return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                    assignAdmit(`${tableMeta.rowData[1]}`);
                                    }}>承認提出
                                </button>);
                            }else{
                                return(<span>承認済み</span>);
                            }
                        } else{
                            return(<span></span>);
                        }
                       
                        },
                    },
               
            },
            {
                name: "assignment_id",
                label: "割り当てID",
            },
            {
             name: "case_id",
             label: "案件ID",
            },
            {
                name: "case-encode",
                label: "Encode",
                "options":{
                  
                    "display":false,
                 }
               },
               {
                name: "case-case_name",
                label: "案件名",
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "300px"}}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<Link to="" onClick={()=>caseDetail(tableMeta.rowData[4])}>
                            <div className='longText' title={value} style={{color:"blue"}}>
                                {value}
                            </div>
                        </Link>);
                        },
                    },
               },
               {
                name: "case-saler-name_jp",
                label: "営業担当",
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { minWidth: "130px", maxWidth:"150px"}}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                {value}
                            </div>
                        );
                        },
                     },
               },
            {
                name: "person-encode",
                label: "Encode",
                "options":{
                    "display":false,
                 }
            },
            {
            name: "person-full_name",
            label: "要員名",
            class:"longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "150px" , maxWidth: "170px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to="" onClick={()=>personDetail(tableMeta.rowData[7])}>
                        <div className='longText' title={value} style={{color:"blue"}}>
                            {value}
                        </div>
                    </Link>);
                    },
                },
            },
            {
             name: "case-hr-name_jp",
             label: "HR担当者",
            },
            {
                name: "case-customer-name_jp",
                label: "取引先",
            },
            {
                name: "case-contact_name",
                label: "取引先担当者",
            },
            {
             name: "assigner-name_jp",
             label: "割り当て担当",
             options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
            }
            },
            {
                name: "assign_start_date",
                label: "割り当て開始日",
                options: {
                    filter: true,
                    setCellProps: () => ({ style: {maxWidth: "100px"}}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                        return(value);
                        },
                    },
            },
            {
                name: "proposal_date",
                label: "提案日",
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "140px"}}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                {value}
                            </div>
                        );
                    }
               },
            },
            {
             name: "interview_time",
             label: "面談時刻",
             options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "50px",textAlign:"center"}})
            }
            },
            {
             name: "interview_place",
             label: "面談場所",
             class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                }
            }
            },
            {
             name: "interview_date",
             label: "面談日",
             options: {
                 filter: true,
                 setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                 customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                     value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                     return(value);
                     },
                 },
            },
            {
                name: "interview_remark",
                label: "面談評価",
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px"}}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                {value}
                            </div>
                        );
                    }
               }
            },
            {
             name: "adoption_flg",
             label: "最終結果",
            },
            // {
            //  name: "work_start_date",
            //  label: "稼働開始日",
            //  options: {
            //      filter: true,
            //      setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
            //      customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
            //          value = (value != null)?value.substring(0, value.indexOf(" ")):value;
            //          return(value);
            //          },
            //      },
            // },
            // {
            //     name: "work_place",
            //     label: "業務場所",
            //     class: "longText",
            //     options: {
            //         filter: true, 
            //         setCellProps: () => ({ style: { maxWidth: "100px"}}),
            //         customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
            //             return(
            //                 <div className='longText' title={value}>
            //                     {value}
            //                 </div>
            //             );
            //         }
            //     }
            // },
        //     {
        //      name: "work_end_date_plan",
        //      label: "稼働終了予定日",
        //      options: {
        //          filter: true,
        //          setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
        //          customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
        //              value = (value != null)?value.substring(0, value.indexOf(" ")):value;
        //              return(value);
        //              },
        //          },
        //     },
        //    {
        //      name: "contract_price_type",
        //      label: "稼働単金種別",
        //     },
        //     {
        //         name: "proposal_price",
        //         label: "希望単価",
        //         options: {
        //             filter: true,
        //             customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
        //                 if(value > 99999){
        //                     value = Math.ceil(value/10000)+"万";
        //                 }else{
        //                     value = (value)?value: "";
        //                 }
        //                return (value);
        //                 },
        //             },
        //        },
            // {
            //  name: "settlement",
            //  label: "精算",
            // },
            // {
            //  name: "payment_site",
            //  label: "支払サイト",
            // },
            {
                name: "approval_date",
                label: "承認日",
                class: "LongText",
                options: {
                    filter: true,
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                        // return(value);
                        return(
                            <div className='longText' title={value}>
                                {value}
                            </div>
                        );
                        },
                    },
                    
            },
            {
                name: "assign_end_date",
                label: "割り当て終了日",
                class: "LongText",
                options: {
                    filter: true,
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                        // return(value);
                        return(
                            <div className='longText' title={value}>
                                {value}
                            </div>
                        );
                        },
                    },
               },
               {
                name: "flb_shared_cmt",
                label: "社内共有メモ",
                class: "LongText",
                options: {
                    filter: true,
                    setCellProps: () => ({ style: { maxWidth: "150px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                        // return(value);
                        return(
                            <div className='longText' title={value}>
                                {value}
                            </div>
                        );
                        },
                    },
               },
            {
                name: "assignment_id",
                label: "割り当てID",
                "options":{
                  
                    "display":false,
                 }
            },
             {
             name: "work_start_date",
             label: "稼働開始日",
             options: {
                "display":false,
                 },
            },
                {
            name: "proposal_price",
            label: "希望単価",
            options: {
                "display":false,
            }
            },
         ];

    const addWorkProposalcolumns = [
            {
                name: "case_id",
                label: "編集",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        // return(<Link to={`/cases/${tableMeta.rowData[1]}`} >{value}</Link>);
                            return(<button type="button" className="btn_standard btn-sm" onClick={() => {
                                updateAssign(`${tableMeta.rowData[0]}`);
                                }}>編集
                            </button>);
                        },
                    },
               
            },
            {
                name: "assignment_id",
                label: "承認提出",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "150px" }}),
                    customBodyRender: (value:string, tableMeta:any, updateValue:any) => {
                        if(tableMeta.rowData[13] == "1" && tableMeta.rowData[14] != null && tableMeta.rowData[18] != null)
                        {
                            if(tableMeta.rowData[21] == null || tableMeta.rowData[21] == ""){
                                return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                    assignAdmit(`${tableMeta.rowData[1]}`);
                                    }}>承認提出
                                </button>);
                            }else{
                                return(<span>承認済み</span>);
                            }
                        } else{
                            return(<span></span>);
                        }
                       
                        },
                    },
               
            },
            {
                name: "assignment_id",
                label: "割り当てID",
            },
            {
             name: "case_id",
             label: "案件ID",
            },
            {
                name: "case-encode",
                label: "Encode",
                "options":{
                  
                    "display":false,
                 }
               },
               {
                name: "case-case_name",
                label: "案件名",
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "300px"}}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<Link to="" onClick={()=>caseDetail(tableMeta.rowData[4])}>
                            <div className='longText' title={value} style={{color:"blue"}}>
                                {value}
                            </div>
                        </Link>);
                        },
                    },
               },
               {
                name: "case-saler-name_jp",
                label: "営業担当",
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { minWidth: "130px", maxWidth:"150px"}}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                {value}
                            </div>
                        );
                        },
                     },
               },
            {
            name: "person-encode",
            label: "Encode",
            "options":{
                "display":false,
                }
            },
            {
            name: "person-full_name",
            label: "要員名",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "150px",maxWidth: "170px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to="" onClick={()=>personDetail(tableMeta.rowData[7])}>
                        <div className='longText' title={value} style={{color:"blue"}}>
                            {value}
                        </div>
                        </Link>);
                    },
                },
            },
            {
             name: "case-hr-name_jp",
             label: "HR担当者",
            },
            {
             name: "assigner-name_jp",
             label: "割り当て担当",
             options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
            }
            },
            // {
            //     name: "assign_start_date",
            //     label: "割り当て開始日",
            //     options: {
            //         filter: true,
            //         setCellProps: () => ({ style: {maxWidth: "100px"}}),
            //         customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
            //             // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
            //             return(value);
            //             },
            //         },
            // },
            {
             name: "interview_date",
             label: "面談日",
             options: {
                 filter: true,
                 setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                 customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                     value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                     return(value);
                     },
                 },
            },
            {
                name: "interview_remark",
                label: "面談評価",
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { minWidth: "100px",maxWidth: "130px"}}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                {value}
                            </div>
                        );
                    }
               }
            },
            {
             name: "adoption_flg",
             label: "最終結果",
             options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px",maxWidth: "130px" }}),
            }
            },
            {
             name: "work_start_date",
             label: "稼働開始日",
             options: {
                 filter: true,
                 setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                 customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                     value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                     return(value);
                     },
                 },
            },
            {
                name: "work_place",
                label: "勤務場所",
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px"}}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                {value}
                            </div>
                        );
                    }
               }
            },
            {
             name: "work_end_date_plan",
             label: "稼働終了予定日",
             options: {
                 filter: true,
                 setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                 customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                     value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                     return(value);
                     },
                 },
            },
           {
             name: "contract_price_type",
             label: "稼働単金種別",
            },
            {
                name: "proposal_price",
                label: "希望単価",
                options: {
                    filter: true,
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        if(value > 99999){
                            value = Math.ceil(value/10000)+"万";
                        }else{
                            value = (value)?value: "";
                        }
                       return (value);
                        },
                    },
               },
            {
             name: "settlement",
             label: "精算From",
            },
            {
             name: "payment_site",
             label: "支払サイト",
            },
            {
                name: "approval_date",
                label: "承認日",
                class: "LongText",
                options: {
                    filter: true,
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                        // return(value);
                        return(
                            <div className='longText' title={value}>
                                {value}
                            </div>
                        );
                        },
                    },
                    
            },
            {
                name: "assign_end_date",
                label: "割り当て終了日",
                class: "LongText",
                options: {
                    filter: true,
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                        // return(value);
                        return(
                            <div className='longText' title={value}>
                                {value}
                            </div>
                        );
                        },
                    },
            },
            {
                name: "flb_shared_cmt",
                label: "社内共有メモ",
                class: "LongText",
                options: {
                    filter: true,
                    setCellProps: () => ({ style: { maxWidth: "150px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                        // return(value);
                        return(
                            <div className='longText' title={value}>
                                {value}
                            </div>
                        );
                        },
                    },
               },
            {
                name: "assignment_id",
                label: "割り当てID",
                "options":{
                  
                    "display":false,
                 }
               },
         ];
 

   

      

    const getMuiTheme = () =>
    createTheme({
        components: {
        MUIDataTable: {
            styleOverrides:{
                responsiveScroll: {
                    maxHeight: 'none',
                  },
            }
        },
        MuiPaper: {
            styleOverrides:{
                root: {
                    width: "100%",
                    marginTop: "5%",
                    // // marginLeft: "5%",
                    // // marginBottom: "20%"
                }
            }
        },  
        MUIDataTableHeadCell: {
                styleOverrides:{
                  root: {
                      backgroundColor: "rgb(193,193,193)",
                      padding: '0px'
                  }
                }
        },
        // MuiTableCell: {
        //     head: {
        //         backgroundColor: "red !important"
        //     }
        // }

        MuiTableCell: {
            styleOverrides:{
                head: {
                        color : 'white',
                        backgroundColor: "red !important"
                }
            }
        },
          MUIDataTableBodyCell: {
            styleOverrides:{
              root: {
                  backgroundColor: "#f1f1f1",
                  width: "100%",
                  padding: '0px'
              }
            }
          }
        }
      })

    let [state , setState] = useState<IState>({
        assignment : [],
        errorMessage : "",
        page : 0,
        perPage : 10,
        column : "",
        order : "",
        totalRows : 0,
        hrList : [],
        assignStatusList : [],
        proposeStatusList : [],
        paymentSiteList : [],
        assignmentUpdateList : {
            assignment_id: "",
            update_type: "",
            proposal_date : "",
            interview_place : "",
            interview_date : "",
            interview_time : "",
            adoption_flg: 0,
            work_start_date: "",
            work_place : "",
            work_end_date_plan: "",
            contract_price_type : 0,
            proposal_price : null,
            settlement : null,
            settlement_to : null,
            settlement_biko : "",
            payment_site: 20,
            person_cost : null,
            o_adj_hours_low : null,
            o_adj_hours_high : null,
            o_adj_hours_biko : "",
            o_payment_site : 20,
            assign_end_date: "",
            flb_shared_cmt: ""
        },
        loading: false
    });

    const [flb_hr_id, setFlbHr] = useState("0");  
    const [search_keywords, setSearch] = useState("");
    const result : string[] = [];
    const [columnVisible, setColumnVisible] = React.useState(defaultcolumns);
    const [sort, setSort] = useState("0");
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const login_user =   JSON.parse(localStorage.getItem("user") || '{}');
    const [checked, isChecked] = useState(false);
    const [assignment_status, setAssignmentStatus] = useState("unfinished");  
    const [propose_status, setProposeStatus] = useState("all");  
    const [customerchecked, setCustomerChecked] = useState<boolean>(false);
    const [workproposalchecked, setWorkProposalChecked] = useState<boolean>(false);

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height: 700,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    const getData = async (assignment_status:string, propose_status:string,flb_hr_id:any,perPage:number,page:number,search_keywords:any, sort:any, column:string, order:string) => {
        setState({...state,loading :true,})
        AssignmentService.getAllAssignmentCaseList(assignment_status,propose_status,flb_hr_id,perPage,page,search_keywords, sort, column, order).then((response)=>{
            setState({
                ...state,
                assignment:response.data.data,
                page : response.data.current_page,
                perPage : response.data.per_page,
                column : response.data.column,
                order : response.data.order,
                totalRows : response.data.total,
                hrList: response.data.flb_hr_id,
                assignStatusList : response.data.assign_status,
                proposeStatusList : response.data.propose_status,
                paymentSiteList : response.data.payment_site,
                loading:false,
            })
            if(Math.floor(response.data.total/response.data.per_page) >0){
                setShowNextColor("blue");
            }else{
                setShowNextColor("grey");
            }
            if(0 == response.data.current_page){
                setShowPrevColor("grey");
            }
            if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
                setShowNextColor("grey");
            }
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }

            setState({
                ...state,
                errorMessage:error.message,
                loading:false
            })
        })
    }
    let {assignment, perPage, page, column, order, totalRows,hrList,assignStatusList, proposeStatusList, paymentSiteList, assignmentUpdateList,loading , errorMessage} = state;

    // const selectAll = (e:any) => {
    //     if(checked == false){
    //         isChecked(true);
    //         let ids : any[] = [];
    //         cases.forEach((element, index) => {
    //             ids.push(element["case_id"]);
    //             setCheckedRows(ids);
    //           });
    //         $('input[type=checkbox][name="list_check[]').prop('checked', true).trigger('change');
    //     }else{
    //         isChecked(false);
    //         setCheckedRows([]);
    //         $('input[type=checkbox][name="list_check[]').prop('checked', false).trigger('change');
    //     }

    //     // .addClass("list_check_all");
    //     console.log(checkedRows);
    //   };

    useEffect(()=>{
        setState({...state,
            loading: true
        });
        getData(assignment_status,propose_status,flb_hr_id,perPage,page,search_keywords, sort, column, order);
    }, [perPage]);
    let changePage = (page:any) => {
        setCurrentPage(page);
        getData(assignment_status,propose_status,flb_hr_id,perPage,page+1,search_keywords, sort, column, order);
    };
    let changeRowsPerPage = (perPage:any) => {
        getData(assignment_status,propose_status,flb_hr_id,perPage,page,search_keywords, sort, column, order);
    };
   
    // const handleCheckbox = function(e:any){
    //     let isChecked = e.target.checked;
    //     let value = e.target.value;
    //     console.log(isChecked);

    //     if(isChecked === true){
    //         checkedRows.push(value);
    //         setCheckedRows(checkedRows);
    //         // getData(filter_list,sale,hr,case_status, state.perPage,customer,e.target.value,state.page, search,skill_list,sort);
    //     }
    //     else{            
    //         const rows = checkedRows.filter(function(row){
    //             return row !== value; 
    //          });
    //          setCheckedRows(rows);
    //         //  getData(filter_list,sale,hr,case_status, state.perPage,customer,e.target.value,state.page, search,skill,sort);
    //     }
    // }

    const handleChange  = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.target.name) {
            case "customer":
                if(e.target.checked == true){
                    setCustomerChecked(e.target.checked);
                    if(workproposalchecked){
                        setColumnVisible(allcolumns); 
                    }else{
                        setColumnVisible(addCustomerColumns);
                    }
                }else
                {
                    setCustomerChecked(e.target.checked);
                    if(workproposalchecked){
                        setColumnVisible(addWorkProposalcolumns); 
                    }else{
                        setColumnVisible(defaultcolumns);
                    }
                }
                break;
            case "work-proposal":
                if(e.target.checked == true){
                    setWorkProposalChecked(e.target.checked);
                    if(customerchecked){
                        setColumnVisible(allcolumns); 
                    }else{
                        setColumnVisible(addWorkProposalcolumns);
                    }
                }else
                {
                    setWorkProposalChecked(e.target.checked);
                    if(customerchecked){
                        setColumnVisible(addCustomerColumns);
                    }else{
                        setColumnVisible(defaultcolumns);
                    }
                }
                break;
            default:
                break;
        }
        
      };

    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        switch (e.target.name) {
            case "flb_hr_id":
                setFlbHr(e.target.value);
                getData(assignment_status,propose_status,e.target.value,perPage,page,search_keywords, sort, column, order);
                break;
            case "assignment_status":
                setAssignmentStatus(e.target.value);
                getData(e.target.value,propose_status,flb_hr_id,perPage,page,search_keywords, sort, column, order);
                break;
            case "proposal_status":
                setProposeStatus(e.target.value);
                getData(assignment_status,e.target.value,flb_hr_id,perPage,page,search_keywords, sort, column, order);
                break;
            case "sort":
                setSort(e.target.value);
                getData(assignment_status,propose_status,flb_hr_id,perPage,page,search_keywords, e.target.value, column, order);
                break;
            case "list_length":
                changeRowsPerPage(e.target.value);
                setNoOfRows(parseInt(e.target.value));
                setState({
                    ...state,
                    perPage : parseInt(e.target.value)

                })

                break;
            default:
                break;
        }
     };

     let handleDelete = (rowData:any, rowState:any) => {
        let rows : any[] = [];
        rowData.data.forEach((element:any, index:any) => {
            rows.push(element.dataIndex);
        })
        let ids : any[] = [];
        assignment.forEach((element, index) => {
          if (rows.includes(index)) {
            ids.push(element["assignment_id"]);
          }
        });
        AssignmentService.assignmentDelete(ids).then((response:any)=>{
            if(response.data.success == false){
                alert(response.data.info.message);
                window.location.reload();
            }
        }).catch((error:any) => {
            setState({
                ...state,
                errorMessage:error.message
            })
        })
    }

    let handleSort = (column:any, order:any) => {
        let temp = {};
        temp["column"] = column;
        temp["order"] = order;
        let sort_column = column;
        switch (sort_column) {
            case "case-case_name":
                sort_column = "case_id";
                break;
            case "case-saler-name_jp":
                sort_column = "case_id";
                break;
            case "case-saler-name_jp":
                sort_column = "case_id";
                break;
            case "person-full_name":
                sort_column = "person_id";
                break;
            case "case-hr-name_jp":
                sort_column = "case_id";
                break;
            case "case-customer-company":
                sort_column = "case_id";
                break;
            case "case-contact_name":
                sort_column = "case_id";
                break;
            case "assigner-name_jp":
                sort_column = "assign_user_id";
                break;
            default:
                sort_column = column;
                break;
        }
        getData(assignment_status,propose_status,flb_hr_id,perPage,page,search_keywords, sort, sort_column, order);       
      };

  
   
    const [no_of_rows, setNoOfRows] = useState(10);
    const [current_page, setCurrentPage] = useState(0);

    const options = {
        filterType: 'checkbox' as any,
        tableBodyHeight : "360px",
        tableBodyMaxHeight : "360px",
        responsive: "standard" as any,
        selectableRows: "multiple" as any,
        // selectableRowsHeader: true as any, 
        rowsPerPage: perPage,
        page : page,
        rowsPerPageOptions:[10,20,50,100,200],
        // selectableRowsOnClick: true  as any,
        count: totalRows,
        serverSide: true,
        filter : false,
        print: false,
        viewColumns : false,
        sort: true,
        search : false,
        download :false,
        // resizableColumns: true,
        // onDownload: (buildHead:any, buildBody:any, columns:any, rows:any) => {
        //     console.log(rows); //  <-------- can't get only selected rows
        //   },
        customToolbarSelect: (selectedRows:any, displayData:any, setSelectedRows:any) => {         
            const handleClick = () => {
                
                //console.log(selectedRows.data);
                
            };
                let rows : any[] = [];
                selectedRows.data.forEach((element:any, index:any) => {
                    rows.push(element.dataIndex);
                })
                let csvData : any[] = [];
                // csvData.push(["案件ID","案件名", "開始年月日","期間", "人数", "案件概要", "要求スキル", "作業場所", "HR担当者", "案件依頼日", "更新日"]);
                assignment.forEach((element, index) => {
                        if (rows.includes(index)) {
                            csvData.push(element);
                        }
                });
                const getFileName = () => {
                    let now = new Date().toLocaleDateString();
                    let year = now.slice(0,4);
                    let month = '0' + now.slice(5,6);
                    let day = now.slice(7);
                    let date = year+month+day;
                    return "案件割り当て一覧" +date;
                }
            let headers = [
                { label: "案件ID", key: "case_id" },
                { label: "案件名", key: "case-case_name" },
                { label: "営業担当者", key: "case-saler-name_jp" },
                { label: "要員名", key: "person-full_name" },
                { label: "HR担当者", key: "case-hr-name_jp" },
                { label: "取引先", key: "case-customer-company" },
                { label: "取引先担当者", key: "case-contact_name" },
                { label: "割り当て担当", key: "assigner-name_jp"},
                { label: "割り当て開始日", key: "assign_start_date" },   
                { label: "提案日", key: "proposal_date" },   
                { label: "面談時刻", key: "interview_time" }, 
                { label: "面談場所", key: "interview_place" },
                { label: "面談日", key: "interview_date" },
                { label: "面談評価", key: "interview_remark"},
                { label: "最終結果", key: "adoption_flg" },   
                { label: "稼働開始日", key: "work_start_date" },   
                { label: "業務場所", key: "work_place" }, 
                { label: "稼働終了予定日", key: "work_end_date_plan" },
                { label: "稼働単金種別", key: "contract_price_type" },
                { label: "希望単価", key: "proposal_price"},
                { label: "精算From", key: "settlement" },   
                { label: "支払サイ", key: "payment_site" },   
                { label: "承認日", key: "approval_date" },  
                { label: "割り当て終了日", key: "assign_end_date" },   
                { label: "社内共有メモ", key: "flb_shared_cmt" },   
              ];
            var now = new Date();
            return (
                <CSVLink  className="btn_standard text-decoration-none" filename={getFileName()} data={csvData}  headers={headers}>ダウンロード</CSVLink>
            );
          },
        textLabels: {
            body: {
              noMatch: "データはありません。",
              toolTip: "Sort"
            },
            pagination: {
              next: "Next Page",
              previous: "Previous Page",
              rowsPerPage: "件表示", 
              displayRows: "すべての件数：",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "行 を選択しました。",
                delete: "削除",
                deleteAria: "選択した行を削除しました。",
              },
          },
        rowsSelected: selectedRows,
        onRowSelectionChange: (rowsSelectedData:any, allRows:any, rowsSelected:any) => {
            setSelectedRows(rowsSelected);
        },
        onChangePage (currentPage:any) {
            changePage(currentPage);
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage (numberOfRows:any) {
            changeRowsPerPage(numberOfRows);
            setNoOfRows(numberOfRows);
        },
       
        onCellClick : (colData:any, cellMeta:any) =>{
            // goToDetail(colData, cellMeta);

        },
        onRowsDelete(rowData :any, rowState:any) {
            handleDelete(rowData, rowState);
        },
        onColumnSortChange: (changedColumn:any, direction:any) => {
            let order = 'desc';
            if (direction === 'asc') {
              order = 'asc';
            }
            handleSort(changedColumn, order);
          },
    };
 
    
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {

        switch (e.target.name) {
            case "search":
                    setSearch(e.target.value);
                    getData(assignment_status,propose_status,flb_hr_id,perPage,page,e.target.value, sort, column, order);
                break;
            default:
                break;
        }
        
    };  

    const deleteAssign = () => {
        if(selectedRows.length == 0){
            alert("削除したい案件割り当てをチェックしてください。");
            return false;
        }
        let ans = window.confirm("削除を実施します、大丈夫ですか？");
        if(ans){
            let ids : any[] = [];
            assignment.forEach((element, index) => {            
                if (selectedRows.length > 0 && selectedRows.includes(index)) {
                    ids.push(element["assignment_id"]);
                }
            });
    
            let data = {};
            data["ids"] = ids;
            AssignmentService.assignmentDeleteList(data).then((data)=>{
                window.location.reload();
            }).catch((error) => {
                console.log(error);
            });
        }
       
    }
    const assignAdmit = (assignment_id:any) => {
        AssignmentService.assignmentAdmit(assignment_id).then((data)=>{
            window.location.reload();
        }).catch((error) => {
            console.log(error);
        });
    }

    let nextPage = () => {
        
        // if(Math.floor(totalRows/perPage) >0){
        //     setShowNextColor("blue");
        // }

        if(Math.floor(totalRows/perPage) >0){
            changePage(current_page+1);
            setCurrentPage(current_page+1);
            setState({
                ...state,
                page : current_page+1
            })
            setShowNextColor("blue");
        }
        
        if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
            setShowNextColor("grey");
        }   
        if(0 < current_page+1){
            setShowPrevColor("blue");
        }     

    }

    let previousPage = () => {
        if(current_page > 0){
            setCurrentPage(current_page-1);
            changePage(current_page - 1);
            setState({
                ...state,
                page : current_page-1
            });
            setShowPrevColor("blue");
        }
        

        if(0 == current_page-1){
            setShowPrevColor("grey");
        } 

        if(Math.floor(totalRows/perPage) >0){
            setShowNextColor("blue");
        } 
        
    }
   
    let location = useLocation();
    const personDetail = function (encode:any) {
        localStorage.setItem('previousRoute', location.pathname);
        localStorage.setItem('rootRoute', location.pathname);
        window.open(`/persons/${encode}`, '_blank');
    }
    const caseDetail = function (encode:any) {
        localStorage.setItem('rootRoute', location.pathname);
        localStorage.setItem('previousRoute', location.pathname);
        window.open(`/cases/${encode}`, '_blank');
    }

    const edit_modal_box = (
        <div>
              <div className="row">
                    <div className="col-12">
                         <button type="button" className="modal_close" onClick={handleClose}>X</button>
                    </div>            
                </div>
                <form className="form create-form" onSubmit={updateCaseAssign}>
                    <input type="hidden" name="assignment_id" value={assignmentUpdateList["assignment_id"]} />
                    <input type="hidden" name="update_type" value={assignmentUpdateList["update_type"]} />
                    <Row>
                        <Col xxl={3} xl={3} md={3} sm={3} xs={4}>
                            <label htmlFor="exampleInputEmail1">割り当てID:</label> 
                            <span style={{fontSize:"15px"}}>{assignmentUpdateList["assignment_id"]}</span>
                        </Col>
                        <Col xxl={3} xl={3} md={3} sm={3} xs={8}>
                            <label htmlFor="exampleInputEmail1">要員名:</label> 
                            <span style={{fontSize:"15px"}}>{show_person_nm}</span>
                        </Col>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={12}>
                            <label htmlFor="exampleInputEmail1">案件名:</label> 
                            <span style={{fontSize:"15px"}}>{show_case_name}</span>
                        </Col>
                    </Row>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">提案日:</label> 
                                <DatePicker 
                                        value={(assignmentUpdateList["proposal_date"] != null)?assignmentUpdateList["proposal_date"]:""}
                                        dateFormat="yyyy/MM/dd"
                                        locale="ja"
                                        selected={selectedProposalDate} 
                                        onChange={date =>
                                            handleDateChange(date, "proposal_date")
                                        }
                                        name="proposal_date"
                                        isClearable
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                />
                                <span id='star' className='error'></span>
                            </div>       
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">面談場所:</label> 
                                <input type="text" className="form-control" name="interview_place" value={(assignmentUpdateList['interview_place'] != null)?assignmentUpdateList['interview_place']:""}   onChange={updateInput} />
                                <span id='star' className='error'></span>
                            </div>          
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">面談日:</label> 
                                <DatePicker 
                                        value={(assignmentUpdateList['interview_date'] != null)?assignmentUpdateList['interview_date']:""}
                                        dateFormat="yyyy/MM/dd"
                                        locale="ja"
                                        selected={selectedInterviewDate} 
                                        onChange={date =>
                                            handleDateChange(date, "interview_date")
                                        }
                                        name="interview_date"
                                        isClearable
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                />    
                                <span id='star' className='error'></span>        
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">面談時刻:</label> 
                                <input type="time" className="form-control" name="interview_time"  value={(assignmentUpdateList['interview_time'] != null)?assignmentUpdateList['interview_time'] : ""}  onChange={updateInput} />
                                <span id='star' className='error'></span>
                            </div>           
                        </div>
                        
                    {
                        (() => {
                            if(login_user.role !=3 && login_user.role != 4) {
                            return(
                                <div className="col-12">
                                    <div className="form-group result">
                                        <label htmlFor="seller_contract">最終結果</label>
                                        <span className=""><input type="radio" className="p-2 " value="0"   checked ={assignmentUpdateList['adoption_flg']==0? true: false}  name="adoption_flg" onChange={e => handleAdoption(e)} style={{position:"relative",top:"2px"}} /> 検討中</span>
                                        <span className=""><input type="radio"  className="p-2" value="1" checked ={assignmentUpdateList['adoption_flg']==1? true: false} name="adoption_flg" onChange={e => handleAdoption(e)} style={{position:"relative",top:"2px"}} /> 採用</span>
                                        <span className=""><input type="radio"  className="p-2"  value="2" checked ={assignmentUpdateList['adoption_flg']==2? true: false} name="adoption_flg" onChange={e => handleAdoption(e)} style={{position:"relative",top:"2px"}}  /> 不採用</span>
                                    </div>
                                </div>
                            )
                            }
                        })()
                        }
                        {
                            (() => {
                                if(login_user.role !=3 && login_user.role != 4) {
                                return(
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">稼働開始日:</label> 
                                            <DatePicker 
                                                    value={(assignmentUpdateList["work_start_date"] != null)?assignmentUpdateList["work_start_date"]:""}
                                                    dateFormat="yyyy/MM/dd"
                                                    locale="ja"
                                                    selected={selectedWorkStartDate} 
                                                    onChange={date =>
                                                        handleDateChange(date, "work_start_date")
                                                    }
                                                    name="work_start_date"
                                                    isClearable
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    onKeyDown={(e) => {
                                                        e.preventDefault();
                                                    }}
                                            />     
                                            <span id='star' className='error'></span>
                                        </div>  
                                    </div>
                                )
                                }
                            })()
                        }
                        
                        <div className="col-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">稼働終了予定日:</label> 
                            <DatePicker 
                                    value={(assignmentUpdateList["work_end_date_plan"] != null)?assignmentUpdateList["work_end_date_plan"]:""}
                                    dateFormat="yyyy/MM/dd"
                                    locale="ja"
                                    selected={selectedWorkEndDatePlan} 
                                    onChange={date =>
                                        handleDateChange(date, "work_end_date_plan")
                                    }
                                    name="work_end_date_plan"
                                    isClearable
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                            />    
                            <span id='star' className='error'></span>        
                            </div>     
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">勤務場所:</label> 
                                <input type="text" className="form-control" name="work_place"  value={(assignmentUpdateList['work_place'] != null)?assignmentUpdateList['work_place']:""}   onChange={updateInput} />
                                <span id='star' className='error'></span>
                            </div>        
                        </div>
                        {
                            (() => {
                                if(login_user.role !=3 && login_user.role != 4) {
                                return(
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="contract_price_type">稼働単金種別</label>
                                            <select name="contract_price_type" id="type" onChange={onSelectAssignment} value={(assignmentUpdateList["contract_price_type"] != null)?assignmentUpdateList["contract_price_type"]:""} style={{width:'100%'}}>
                                                <option value="0">月額単価</option>
                                                <option value="1">時間単価</option>
                                            </select> 
                                            <span id='star' className='error'></span>                       
                                        </div>         
                                    </div> 
                                )
                                }
                            })()
                        }
                        {
                            (() => {
                                if(login_user.role !=3 && login_user.role != 4) {
                                return(
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">希望単価:</label> 
                                            <input type="number" className="form-control" name="proposal_price"   value={(assignmentUpdateList['proposal_price'] != null)?assignmentUpdateList['proposal_price']:''}  onChange={updateInput} />
                                        </div>        
                                    </div>
                                )
                                }
                            })()
                        }
                        {/* <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">希望単価:</label> 
                                <input type="number" className="form-control" name="proposal_price"  value={assignmentUpdateList['proposal_price']}  onChange={updateInput} />
                            </div>        
                        </div> */}
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">乙方精算From:</label> 
                                <input type="text" className="form-control" name="settlement" maxLength={6} value={(assignmentUpdateList['settlement'] != null)?assignmentUpdateList['settlement']:''} onChange={updateInput} />
                            </div>             
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">精算To:</label> 
                                <input type="text" className="form-control" name="settlement_to" maxLength={6} value={(assignmentUpdateList['settlement_to'] != null)?assignmentUpdateList['settlement_to']:''} onChange={updateInput} />
                                <span id='star' className='error'></span>
                            </div>             
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">精算備考:</label> 
                                <input type="text" className="form-control" name="settlement_biko"  value={(assignmentUpdateList['settlement_biko'] != null)?assignmentUpdateList['settlement_biko']:''} onChange={updateInput} />
                                <span id='star' className='error'></span>
                            </div>             
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">支払サイト:</label> 
                                <select name="payment_site" onChange={onSelectAssignment} value={(assignmentUpdateList["payment_site"] != null)?assignmentUpdateList["payment_site"]:''}  style={{width:'100%'}}>
                                    {paymentSiteList.map(payment_site => {
                                            return(<option value={payment_site}> {payment_site}</option>)
                                        })
                                    }
                                </select>
                                <span id='star' className='error'></span>  
                            </div>         
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">割り当て終了日:</label> 
                                <DatePicker 
                                        value={(assignmentUpdateList["assign_end_date"] != null)?assignmentUpdateList["assign_end_date"]:""}                                        dateFormat="yyyy/MM/dd"
                                        locale="ja"
                                        selected={selectedAssignEndDate} 
                                        onChange={date =>
                                            handleDateChange(date, "assign_end_date")
                                        }
                                        name="assign_end_date"
                                        isClearable
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                />        
                                <span id='star' className='error'></span>    
                            </div>        
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label>社内共有メモ</label>
                                <textarea className="form-control" name = "flb_shared_cmt" id= "flb_shared_cmt" aria-label="With textarea" 
                                value={(assignmentUpdateList["flb_shared_cmt"] != null)?assignmentUpdateList["flb_shared_cmt"] : ''}  onChange={updateTextArea}></textarea>
                                <span id='star' className='error'></span>
                            </div>           
                        </div>
                        <div className="form-group mt-5 text-center">
                            <button type="submit" className="button submit-btn btn_standard">更新</button>
                        </div>
                    </div>
                </form>
        </div>
    )
    return(
        <React.Fragment>
            <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                    ml: sizeConfigs.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color
                }}
                >
                 <Toolbar>
                <Typography variant="h6">
                    <header className="top-baner">
                        <Row>
                            <Col xxl={10} xl={10} md={10} sm={10} xs={10}>
                                <div id="search-autocomplete" className="form-outline">
                                    <input type="text" name="search" id="search" placeholder = "検索(案件ID、案件名、要員ID、要員名を検索)"
                                        className="form-control"  onChange={onChange}/>
                                </div>
                            </Col>
                            <Col xxl={2} xl={2} md={2} sm={2} xs={2} className="mt-4">
                                <DropDownList />
                            </Col>
                        </Row>
                    </header>
                    </Typography>
                </Toolbar>
                </AppBar>
                <Container>
                        <PageTitle loading={loading} title="案件割り当て一覧" />
                        <Row className="p-2">
                            <Col xxl={4} xl={4} md={4} sm={4} xs={12} className="check-list">
                                <input className="" type="checkbox" id="customer_chk" name="customer" style={{display:([3,4].includes(login_user.role))?'none':''}} checked={customerchecked} onChange={handleChange} /> 
                                <label htmlFor="customer_chk"  style={{display:([3,4].includes(login_user.role))?'none':''}} >取引先表示</label>
                                <input className="ml_20" type="checkbox" value="" id="work_proposal_chk" name ="work-proposal" checked={workproposalchecked} onChange={handleChange} style={{position:"relative",bottom:"1px"}}/> 
                                <label htmlFor="work_proposal_chk">入場情報表示・提案情報非表示</label>
                                <button type="button" className="btn_danger ml_20 btn-sm" onClick={deleteAssign}>削除
                            </button>
                            </Col>
                            <Col xxl={8} xl={8} md={8} sm={8} xs={12} className="filter_select">
                                <div className="float-right">
                                    <label htmlFor="sort" className="control-label">割り当て状況</label>
                                        <select className="filter" name="assignment_status" aria-label=".form-select-sm example" onChange={onSelect} value={assignment_status}>
                                        {assignStatusList.map(assignment_status => {
                                                return(<option value={assignment_status["0"]}> {assignment_status["1"]}</option>)
                                            })
                                        }
                                        </select> 
                                        <label htmlFor="sort" className="control-label">提案状況</label>
                                        <select className="filter" name="proposal_status" aria-label=".form-select-sm example" onChange={onSelect} value={propose_status}>
                                        {proposeStatusList.map(proposal_status => {
                                                return(<option value={proposal_status["0"]}> {proposal_status["1"]}</option>)
                                            })
                                        }
                                        </select>
                                        <label htmlFor="sort" className="control-label">HR担当者</label>
                                        <select className="filter" name="flb_hr_id" aria-label=".form-select-sm example" onChange={onSelect} value={flb_hr_id}>
                                        {hrList.map(h_id => {
                                                return(<option value={h_id["0"]}> {h_id["1"]}</option>)
                                            })
                                        }
                                        </select>
                                </div>
                            </Col>
                        </Row>
                        <Row className="p-2 xs-hidden">
                            <Col xxl={3}  xl={3} md={6} sm={6}>
                                <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                                <select name="sort" id="sort" className="filter" onChange={onSelect}>
                                    <option value="0" selected>新しい順</option>
                                    <option value="1">古い順</option>
                                </select>
                            </Col>
                            <Col xxl={9}  xl={9} md={6} sm={6} className="filter_select">
                                <div className="float-right">
                                   
                                        <select name="list_length" id="list_length" value={no_of_rows} className="filter list_length" onChange={onSelect}>
                                            <option value="10">10</option>
                                            <option value="20" >20</option>
                                            <option value="50" >50</option>
                                            <option value="100" >100</option>
                                            <option value="200" >200</option>
                                        </select>
                                        <label htmlFor="sort" className="control-label">件表示</label>                                   
                                        {/* <label htmlFor="sort" className="control-label ml_20 " style={{position:"relative",right:"8px"}}>すべての件数：{totalRows}件</label> */}
                                        <button type="button" className="btn-sm previous-page-icon" style={{border:"0px"}} onClick={() => {
                                                        previousPage();
                                                        }}>
                                                    <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                                    </button>
                                                    <button type="button" className=" btn-sm next-page-icon" style={{border:"0px"}} onClick={() => {
                                                        nextPage();
                                                        }}>
                                                        <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="p-2 pc-hidden">
                                 <Col xs={12}>
                                    <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                                    <select name="sort" id="sort" className="filter" onChange={onSelect}>
                                        <option value="0" selected>新しい順</option>
                                        <option value="1">古い順</option>
                                    </select>
                                    <select name="list_length" id="list_length" value={no_of_rows} className="filter list_length" onChange={onSelect}>
                                        <option value="10">10</option>
                                        <option value="20" >20</option>
                                        <option value="50" >50</option>
                                        <option value="100" >100</option>
                                        <option value="200" >200</option>
                                    </select>
                                    <label htmlFor="sort" className="control-label">件表示</label>                                   
                                    <button type="button" className="btn-sm previous-page-icon" style={{border:"0px"}} onClick={() => {
                                                    previousPage();
                                                    }}>
                                                <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                                </button>
                                                <button type="button" className=" btn-sm next-page-icon" style={{border:"0px"}} onClick={() => {
                                                    nextPage();
                                                    }}>
                                                    <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                                    </button>
                                </Col>
                            </Row>
                                
                                <div className='datatable assign-case-freeze-header assign-case-freeze-cell assign-case-frozen-columns xs-hidden'>
                                    <MUIDataTable
                                        title={''}
                                        data={assignment}
                                        columns={columnVisible}
                                        options = {options}
                                    />
                                </div>
                                <div className='datatable mobile pc-hidden'>
                                    <MUIDataTable
                                        title={''}
                                        data={assignment}
                                        columns={columnVisible}
                                        options = {options}
                                    />
                                </div>
                </Container>

                <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'none', sm: 'block' },
            }}
        >                                
            <Box sx={PCModalStyle}>
              {edit_modal_box}
            </Box>
        </Modal>


        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'block', sm: 'none' },
            }}
        >                                
            <Box sx={MobileModalStyle}>
              {edit_modal_box}
            </Box>
        </Modal>


          
        </React.Fragment>
    );
};
export default AssignmentCaseList;