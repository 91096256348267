import React ,{useState , useEffect} from "react";
import {ICase} from "../../models/ICase";
import {CaseListService} from "../../services/CaseService";
import {Link, useLocation} from "react-router-dom";
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import {Button} from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Visibility from "@mui/icons-material/Visibility";
import LoginForm from "../../components/LoginForm";
import Loader from "../../components/common/Loader";


interface IState{
    loading :boolean;
    cases : ICase[];
    errorMessage : string;
    total : number;
    flb_sales_arr : [];
    flb_hrs_arr : [];

}
interface IProps{}

let Cases:React.FC<IProps> = () =>{
    let [state, setState] = useState<IState>({
        loading : false,
        cases : [] as ICase[],
        errorMessage : "",
        total : 0,
        flb_sales_arr : [],
        flb_hrs_arr : []
    });
    const result : string[] = [];
    const [filter_list, setFilterList] = useState(result); 
    const [sale, setFlbSale] = useState("全て");
    const [hr, setFlbHr] = useState("全て");  
    const [status, setStatus] = useState("processing");
    const skill : string[] = [];
    const [skill_list, setSkillList] = useState(skill);  
    const [search, setSearch] = useState("");
    const [searchskill, setSearchSkill] = useState("");
    const [active, setActive] = useState("processing"); 
    const [sort, setSort] = useState("0");
    const [show_processing_status, showProcessingStatus] = useState<boolean>(false);
    const [processing_active, setProcessingActive] =   useState("");

    const login_user =   JSON.parse(localStorage.getItem("user") || '{}');
  
    let status_list: any[] = [];
    if(login_user.role != 7){
        status_list = [['all', '全て'] , ['not_start','未着手'], ['processing',' 募集開始'],['not_assigning','割り当てなし'],['assigning', '割り当て中'],['not_proposaling','未提案'],
        ['proposaling','提案中'],['interview','面談設定中'],['wait_result','結果待ち'],['end','募集終了'],['working','稼働中'],['finished','稼働終了']];
    }else{
        status_list = [['not_start','未着手'], ['processing',' 募集開始'],['end','募集終了']];
    }
    
    let status_list1: any[] = [['now_available','即日開始'], ['next_month_available',' 翌月開始'],['next_two_month_available','翌々月開始']];

    if (login_user.role !=7 && login_user.role != 8){
        status_list1.push(['release','公開']);
        status_list1.push(['not_release','未公開']);
        // status_list1 = [['release','公開'],['not_release','未公開']];
    }
    let status_list2: any[] = [];
    if( [1,2,3,4,9].includes(login_user.role)){
        status_list2 = [['koukata_public','甲方登録公開'],['koukata_not_public','甲方登録非公開']];
   }

//    let processing_status_list: any[] = [['now_available','即日開始'], ['next_month_available',' 翌月開始'],['next_two_month_available','翌々月開始']];
  
    // const status_list1 = [['release','公開'],['not_release','未公開'],['koukata_public','甲方登録公開'],['koukata_not_public','甲方登録非公開']];
    
      useEffect(()=>{
        setState({...state, loading: true});
        showProcessingStatus(true);
        localStorage.removeItem("person_status");
        localStorage.removeItem("person_list_status");
        localStorage.removeItem("case_list_status");
        if(localStorage.getItem("case_status") != null && localStorage.getItem("case_search") != null){
            bindStatusData();
            setSearch(localStorage.getItem("case_search")!);  
            getData(filter_list,sale,hr,localStorage.getItem("case_status")!,skill_list,localStorage.getItem("case_search"),searchskill,sort);
        }else if(localStorage.getItem("case_status") != null){
            bindStatusData();
            getData(filter_list,sale,hr,localStorage.getItem("case_status")!,skill_list,search,searchskill,sort);
        }else if(localStorage.getItem("case_search") != null){
            setSearch(localStorage.getItem("case_search")!);  
            getData(filter_list,sale,hr,status,skill_list,localStorage.getItem("case_search"),searchskill,sort);      
        }else{
            getData(filter_list,sale,hr,status,skill_list,search,searchskill,sort);
        }

    }, []);


    const bindStatusData = function () {
        setActive(localStorage.getItem("case_status")!);
        setStatus(localStorage.getItem("case_status")!);
        // if(localStorage.getItem("case_status")! == "now_available" || 
        //    localStorage.getItem("case_status")! == "next_month_available" || 
        //    localStorage.getItem("case_status")! == "next_two_month_available"){
        //     setProcessingActive(localStorage.getItem("case_status")!);
        //     showProcessingStatus(true);
        //     setActive("processing");
        // }else if(localStorage.getItem("case_status")! == "processing"){
        //     showProcessingStatus(true);
        //     setProcessingActive("");
        // }else{
            setProcessingActive(localStorage.getItem("case_status")!);
            showProcessingStatus(false);
        //}
    }

    const getData = async (filter_list:any,sale:any,hr:any,status:string,skill_list:any,search_keywords:any,search_skill:any,sort:any) => {
        setState({...state,loading :true,})
        CaseListService.getAllCases(filter_list,sale,hr,status,skill_list,search_keywords,search_skill,sort).then((response)=>{

            setState({
                ...state,
                loading:false,
                cases:response.data.data,
                total : response.data.total,
                flb_sales_arr : response.data.flb_sales_arr,
                flb_hrs_arr : response.data.flb_hr_arr,
            })
        }).catch((error) => {
            console.log(error);
            if(error.response.status == 403){
                alert(error.response.data.message);
               }
            setState({
                ...state,
                loading:false,
                errorMessage:error.message
            })
        })
    }

    let {loading, cases, total,flb_sales_arr,flb_hrs_arr, errorMessage} = state;
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => { 
        switch (e.target.name) {
            case "search":
                    setSearch(e.target.value);
                    localStorage.setItem("case_search", e.target.value);
                    getData(filter_list,sale,hr,status,skill_list,e.target.value,searchskill,sort);
                break;
            case "search_skill":
                    setSearchSkill(e.target.value);
                    getData(filter_list,sale,hr,status,skill_list,search,e.target.value,sort);
                break;
            default:
                break;
        }
        
      };

      const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        switch (e.target.name) {
            case "flb_sales_id":
                getData(filter_list,e.target.value,hr,status,skill_list,search,searchskill,sort);
                break;
            case "flb_hr_id":
                setFlbHr(e.target.value);
                getData(filter_list,sale,e.target.value,status,skill_list,search,searchskill,sort);
                break;
            case "sort":
                setSort(e.target.value);
                getData(filter_list,sale,hr,status,skill_list,search,searchskill,e.target.value);
                break;
            default:
                break;
        }
     };

     const handleStatus = function(status:any){
        setActive(status);
        setStatus(status);
        localStorage.setItem("case_status", status);
        const filter_status = filter_list.filter((filter) => {
            return filter == "status";
        });
        if(filter_status.length == 0){
            filter_list.push("status");
        }
        // if(status == "now_available" || status == "next_month_available" || status == "next_two_month_available"){
        //     setProcessingActive(status);
        //     showProcessingStatus(true);
        //     setActive("processing");
          
        // }else if(status == "processing"){
        //     showProcessingStatus(true);
        //     setProcessingActive("");
        // }else{

            setProcessingActive(status);
            showProcessingStatus(false);
        //}

        
        getData(filter_list,sale,hr,status,skill_list,search,searchskill,sort);
    }

    const handleSkill = function(e:any){
        let isChecked = e.target.checked;
        let value = e.target.value;
        const filter_skill = filter_list.filter((filter) => {
            return filter == "skill_list";
        });
        if(filter_skill.length == 0){
            filter_list.push("skill_list");
        }
        if(isChecked === true){
            skill_list.push(value);
            setSkillList(skill_list);
            getData(filter_list,sale,hr,status,skill_list,search,searchskill,sort);
        }
        else{            
            // setSkillList(skill_list.filter((skill) => {
            //     return skill !== value;
            // }));
            const skill = skill_list.filter(function(skill){
                return skill != value; 
             });
             setSkillList(skill);
            getData(filter_list,sale,hr,status,skill,search,searchskill,sort);
        }
    }
    let location = useLocation();
    const caseDetail = function (ass_case:any, encode:any) {
        if(ass_case != null){
            localStorage.setItem('rootRoute', location.pathname);
            localStorage.setItem('previousRoute', location.pathname);
            window.location.href = `/cases/${encode}`
        }
    }

    const goToAssignment = function (encode:any) {
        localStorage.setItem('previousRoute', location.pathname);
        localStorage.setItem('rootRoute', location.pathname);
        window.location.href = `/cases/assignment/${encode}`;
    }

    let imageUrl = "";

    return(
        <>
       <React.Fragment>
           <AppBar
            position="fixed"
            sx={{
                width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                ml: sizeConfigs.sidebar.width,
                boxShadow: "unset",
                backgroundColor: colorConfigs.topbar.bg,
                color: colorConfigs.topbar.color
            }}
            
            >
            <Toolbar>
                <Typography variant="h6">
                <header className="top-baner">
                    <Row>
                        <Col xxl={10} xl={10} md={10} sm={10} xs={10} className="float-left">
                            <div id="search-autocomplete" className="form-outline">
                                <input type="text" name="search" id="search" value={search} placeholder = {(login_user.role==1 || login_user.role==2 || login_user.role==9)?"検索(案件ID、案件名、スキル、一行アピール、職種、募集エリア、案件概要、作業場所、取引先名を検索)":"検索(案件ID、案件名、スキル、一行アピール、職種、募集エリア、案件概要、作業場所を検索)"}
                                    className="form-control w-100"  onChange={onChange}/>
                            </div>
                        </Col>
                        <Col xxl={2} xl={2} md={2} sm={2} xs={2} className="mt-4">
                            <DropDownList />
                        </Col>
                    </Row>
                </header>
                </Typography>
            </Toolbar>
            </AppBar>
         
            <Container>
                <Row>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={4}>
                        <h3 className='header-left-design'>案件情報 
                        </h3>
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={4}>
                        <div className="mt-3 text-center">
                            {( loading   == true) ? <Loader /> :<> </>}
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={4} className="float-end">
                        <div className="mt-4">
                        {
                        (() => {
                            if(login_user.role ==1 || login_user.role == 2 || login_user.role == 8 ||login_user.role == 9) {
                            return(<Link to={`/cases/create`} className="btn_add">
                                案件追加
                            </Link>)
                            }
                        })()
                        }
                        </div>
                    </Col>
                </Row> 
               
                <Row>
                    <Col xxl={10} xl={10} md={10} sm={12} xs={12}>
                        <ul className="status-menu filter responsive-list" data-filter="status">
                            {status_list.map(status => (
                                <li  className={"list-item "+((status[0] == active)?active:"")}>
                                    <a onClick={() => handleStatus(status[0])}>
                                        {status[1]}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                        <ul className="status-menu filter responsive-list" data-filter="status">
                            {status_list1 != null && status_list1.map(status => (
                                <li className={"list-item "+((status[0] == active)?active:"")}>
                                    <a onClick={() => handleStatus(status[0])}>
                                        {status[1]}
                                    </a>
                                </li>
                            ))}
                             {status_list2 != null && status_list2.map(status => (
                                <li className={"list-item "+((status[0] == active)?active:"")}>
                                    <a onClick={() => handleStatus(status[0])}>
                                        {status[1]}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </Col>
                </Row>
                <Row className="p-2 g-0">
                    <Col xxl={10} xl={12} md={12} sm={12} xs={12}>
                        <div className="check-list filter" data-filter="skill_list" id="case-filter-by-skill" style={{paddingTop:'15px'}}>
                            <input type="checkbox" id="java" name="java" value="java" onChange={e => handleSkill(e)} />
                            <label htmlFor="java"> JAVA</label><span className="space"></span>
                            <input type="checkbox" id="c" name="c" value="c" onChange={e => handleSkill(e)} />
                            <label htmlFor="c"> C/C++</label><span className="space"></span>
                            <input type="checkbox" id="asp" name="asp" value="asp" onChange={e => handleSkill(e)} />
                            <label htmlFor="asp"> ASP.NET/C#</label><span className="space"></span>
                            <input type="checkbox" id="vb" name="vb" value="vb" onChange={e => handleSkill(e)}/>
                            <label htmlFor="vb"> VB/VB.NET</label><span className="space"></span>
                            <input type="checkbox" id="php" name="php" value="php" onChange={e => handleSkill(e)}/>
                            <label htmlFor="php"> PHP</label><span className="space"></span>
                            <input type="checkbox" id="ruby_python" name="ruby" value="ruby_python" onChange={e => handleSkill(e)}/>
                            <label htmlFor="ruby_python"> Ruby/Python</label><span className="space"></span>
                            <input type="checkbox" id="sap" name="sap" value="sap"  onChange={e => handleSkill(e)}/>
                            <label htmlFor="sap"> SAP</label><span className="space"></span>
                            <input type="checkbox" id="plsql" name="plsql" value="plsql" onChange={e => handleSkill(e)} />
                            <label htmlFor="plsql"> PLSQL(PL/SQL)</label><span className="space"></span>
                            <input type="checkbox" id="handoutai" name="handoutai" value="handoutai" onChange={e => handleSkill(e)} />
                            <label htmlFor="handoutai"> 半導体</label><span className="space"></span>
                            <input type="checkbox" id="other" name="other" value="other" onChange={e => handleSkill(e)} />
                            <label htmlFor="other"> その他</label>
                        </div>
                    </Col>
                    <Col xxl={2} xl={12} md={12} sm={12} xs={12}>
                        <input type="text" placeholder="and スキルを検索" name="search_skill" id="search_skill" className="form_control ml-2" onChange={onChange} style={{borderRadius:"15px !important", width:"100%",height:"30px"}}/>
                    </Col>
                   
                </Row>
                <Row className="p-2">
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6} className="xs-hidden">
                        <span className="search_result_num"> 検索件数 : {total}件</span>
                        <select name="sort" id="sort" className="filter" onChange={onSelect}>
                            <option value="0" selected>新しい順</option>
                            <option value="1">古い順</option>
                        </select>
                    </Col>
                    <Col xxl={8} xl={8} md={8} sm={8} xs={12} className="filter_select">
                        <div className="float-right">
                        {
                            (() => {
                                if(login_user.role != 7) {
                                    return(<label htmlFor="sort" className="control-label">営業担当者</label>)
                                    }
                                })()
                        }
                        {
                            (() => {
                                if(login_user.role != 7) {
                                    return(<select className="filter" name="flb_sales_id" aria-label=".form-select-sm example" onChange={onSelect}>
                                    <option value="全て">全て</option>
                                    {flb_sales_arr != null && flb_sales_arr.map(s_id => {
                                            return(<option value={s_id["user_id"]}> {s_id["name_jp"]}</option>)
                                        })
                                    }
                                    </select> )
                                    }
                                })()
                        }   
                            {
                            (() => {
                                if(login_user.role != 8) {
                                    return(<label htmlFor="sort" className="control-label">HR担当者</label>)
                                    }
                                })()
                            }
                            {
                            (() => {
                                if(login_user.role != 8) {
                                    return(<select className="filter" name="flb_hr_id" aria-label=".form-select-sm example" onChange={onSelect}>
                                    <option value="全て">全て</option>
                                {flb_hrs_arr != null && flb_hrs_arr.map(h_id => {
                                        return(<option value={h_id["user_id"]}> {h_id["name_jp"]}</option>)
                                    })
                                }
                                </select>)
                                    }
                                })()
                            } 
                        </div>
                    </Col>
                </Row>
                <Row className="p-2">
                    <Col xs={12} className="pc-hidden">
                        <span className="search_result_num"> 検索件数 : {total}件</span>
                        <select name="sort" id="sort" className="filter" onChange={onSelect}>
                            <option value="0" selected>新しい順</option>
                            <option value="1">古い順</option>
                        </select>
                    </Col>
                </Row>
                <Row className="cases">
                {
                    cases.length > 0 && cases.map(cs => {
                        if(cs.priority == 1){
                            imageUrl = '/images/priority1.jpg';
                        }else if(cs.priority ==2){
                            imageUrl = '/images/priority2.jpg';
                        }else if(cs.priority ==3){
                            imageUrl = '/images/priority3.png';
                        }else if(cs.priority ==4){
                            imageUrl = '/images/priority4.jpg';
                        }
                        return(
                                <Col className="mb-3" md={6} sm={12} xs={12}>
                                    <div className="card">
                                        <div className="card-body p-3">
                                            <Row>

                                                <Col xxl={9} xl={9} md={8} sm={8} xs={7}>
                                                    <Link to="" onClick={()=>{caseDetail(cs, cs.encode)}}>
                                                    
                                                    <Row>
                                                        <Col xxl={12} xl={12} md={12} sm={12}>
                                                            <p className="h4 ml_1">{cs.case_name.length > 20 ? cs.case_name.substring(0, 20)+"...": cs.case_name} {"(ID:" + cs.case_id+")"}</p>
                                                        </Col>
                                                    </Row>
                                                    
                                                    <Row>
                                                        <Col xxl={9} xl={9} md={9} sm={9} xs={9}>
                                                            <ul>
                                                                <li> <span className="fw-bold">職種 :</span> {cs.category} </li>
                                                                <li><span className="fw-bold">開始 :</span> {cs.start_date_plan}</li>   
                                                                <li> <span className="fw-bold">作業場所 :</span> {cs.work_place.length > 15 ? cs.work_place.substring(0, 15)+"...": cs.work_place} </li>
                                                                <li> <span className="fw-bold">要求内容 :</span>{(cs.requisition != null && cs.requisition.length > 80) ? cs.requisition.substring(0, 80)+"...": cs.requisition}</li>
                                                            
                                                            </ul>
                                                        </Col>
                                                        <Col xxl={3} xl={3} md={3} sm={3} xs={3}>
                                                            {(cs.priority == 0)?"":<img src={imageUrl}  className="priority_img" style={{border:(cs.priority == 4)? "1px solid grey":''}} alt="Priority" />}
                                                        </Col>
                                                    </Row>
                                                    
                                                        
                                                        
                                                    </Link>
                                                </Col>
                                                <Col xxl={3} xl={3} md={4} sm={4} xs={5}>
                                                {
                                                    (() => {
                                                        if(login_user.role != 7) {
                                                        return(<Link  to="" onClick={()=>goToAssignment(cs.encode)}  className="text-decoration-none text-success fw-bold toriate-btn blue assign_btn btn_standard">
                                                        割当追加
                                                        </Link>)
                                                        }
                                                    })()
                                                }
                                                    <Link to="" onClick={()=>{caseDetail(cs, cs.encode)}}>
                                                        <span className="mr_1 mt-2  mb-1 status_btn float-end">{cs.case_status}</span><br />
                                                        <span className="btn btn-xs assign_count m-3 mt-1 float-end">{cs.count}</span>
                                                    </Link>
                                                </Col>
                                            </Row>
                                            <Link to="" onClick={()=>{caseDetail(cs, cs.encode)}}>
                                                <Row className="m-0 ">
                                                    <Col md={12} sm={12}>
                                                        {  cs.required_skill !== null && Object.keys(cs.required_skill).map(key => {
                                                                    if (cs.required_skill[key] !== null && (cs.required_skill[key] != "other" && cs.required_skill[key] != "Other")){
                                                                        if(cs.required_skill[key] === "C" && cs.required_skill[key].length == 1){
                                                                            cs.required_skill[key] = cs.required_skill[key].replace("C", "C/C++");
                                                                        }else if(cs.required_skill[key] === "handoutai"){
                                                                            cs.required_skill[key] = "半導体";
                                                                        }
                                                                        return(<span className="btn m-1 btn-skill">{cs.required_skill[key]} </span>)
                                                                    }
                                                                })
                                                        }
                                                    </Col>
                                                </Row>
                                            </Link>
                                        </div>
                                    </div>
                                    
                                </Col>
                        ) 
                    })
                }
                </Row>
            </Container>
         
        </React.Fragment>
        </>
    )
}

export default Cases;