import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
// const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;
const token = localStorage.getItem("token"); // retrieve stored user information from local storage
const header =  {
    headers :  {
        'Authorization': `Bearer ${token}` ,
        // "server_url" : `${SERVER_URL}`,
        "client-domain" :  `${CLIENT_DOMAIN}`,
       } 
};
class GroupRoomService {
    list( perPage:number,page:number,sort:any, column:string, order:string){
        let dataURL:string = API_URL + `/grouprooms/info1?list_length=${perPage}&page=${page}&sort=${sort}&column=${column}&order=${order}`;
        console.log(dataURL);
        return axios.get(dataURL, header);
    }

    edit(id:any){
        let dataURL:string = API_URL + "/grouprooms/"+id+"/edit";
        return axios.get(dataURL, header);
    }

    update(data:any,id:any) {
        let dataURL:string = API_URL + "/grouprooms/update1/"+id;
        console.log(dataURL);
        return axios.put(dataURL,data,header);
    }
    store(data:any) {
        let dataURL:string = API_URL + "/grouprooms/store1";
        console.log(dataURL);
        return axios.post(dataURL,data,header);
    }
    delete(data:any) {
        let dataURL:string = API_URL + "/grouprooms/list/destroy1";
        return axios.post(dataURL,data, header);
    }
}
export default new GroupRoomService();