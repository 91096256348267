import React ,{useState , useEffect} from "react";
import {ICase} from "../../models/ICase";
import {Link} from "react-router-dom";
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import HomeService from "../../services/HomeService";
import LineChart from "../../components/common/LineChart";
import {Data} from "../../components/common/Data";
import {CommentModel} from "../../models/CommentModel";
import {showErrorInfo} from "../../components/common/Helpers";
import ResourceService from "../../services/ResourceService";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import $ from 'jquery';
import { format } from 'date-fns';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ResourceImg from '../../components/common/ResourceImg';
import WorkService from "../../services/WorkService";
import Loader from "../../components/common/Loader";



interface IState{
    errorMessage : string;
    comment_model : CommentModel;
    loading :boolean;
}
interface IProps{}

let Home:React.FC<IProps> = () =>{
    const [caseCount, setCaseCount] = useState("");
    const [dealedPersonCount, setDealedPersonCount] = useState(""); 
    const [recruitingCaseCount, setRecruitingCaseCount] = useState("");
    const [proposablePersonCount, setProposablePersonCount] = useState("");
    const [workExitPersonCount, setWorkExitPersonCount] = useState("");
    var now = new Date();
    const [monthflg, setMonthflg] = useState((now.getMonth() + 1).toString());
    const [yearflg, setYearFlg] = useState("1");
    const [senderList , setSenderList] = useState([]);
    const [logList , setLogList] = useState([]);
    const [show_title_text , setShowTitleText] = useState("none");
    const [editFormDisplay , setEditFormDisplay] = useState("none");
    const [replyFormDisplay , setReplyFormDisplay] = useState("none");
    // const [to , setReplyFormDisplay] = useState("none");
    const [twoReplyFormDisplay , setTwoReplyFormDisplay] = useState("none");
    const [chatFormDisplay , setChatFormDisplay] = useState("none");
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false); 
    let [show_attach_file, setShowAttachFile] = useState("none");
    let [photo_name, setPhotoName] = useState("");
    let [drop_file, setDropFile] = useState("block");
    let [preDisplay, setPreDisplay] = useState("block");
    let [imgUrl,setImgUrl] = useState("");
    let [show_edit_attach_file,setShowEditAttachFile] = useState("none");
    const [deleteIcon, setDeleteIcon] = React.useState(false);
    const [commentDatas, setCommentDatas] = useState([]);
    const [likes, setLikes] = useState([]);
    const [reply_one, setReplyOne] = useState([]);
    const [isHovered, setIsHovered] = useState(false);
    const [isListHovered, setIsListHovered] = useState(false);
    const [editingId, setEditingId] = useState("null")
    const [editComment, setEditComment] = useState("null")
    const [comment, setComment] = useState('');
    const [replyComment, setReplyComment] = useState('');
    const [twoReplyComment, setTwoReplyComment] = useState('');
    // const[text,setText] = useState("いいね！");
    const login_user =   JSON.parse(localStorage.getItem("user") || '{}');
    // const[likeColor,setLikeColor] = useState("");
    const [isMinimized, setIsMinimized] = useState(false);
    const [receiver_id, setReceiverId] = useState("");
    const [nameJp, setNameJp] = useState("");
    const [photo, setPhoto] = useState("");

    const [commentId, setCommentId] = useState("null");
    const [creatorId, setCreatorId] = useState("null");
    const [likeCount, setLikeCount] = useState("");
    const [inputChatMessageValue, setInputChatMessageValue] = useState('');
    let [replyChatBoxDisplay, setReplyChatBoxDisplay] = useState("none");
    const [showCommentTextBox , setShowCommentTextBox] = useState("none");
    const [showReplyCommentTextBox , setShowReplyCommentTextBox] = useState("none");
    const [showCommentAll , setShowCommentAll] = useState("inline-block");
    const [id, setId] = useState('');
    const [mainId, setMainId] = useState('');
    const [isVisible, setIsVisible] = useState(true);
    // const [replyTxt, setReplyTxt] = useState('');
    // const [isChatCommentBox, setIsChatCommentBox] = useState(false);
    const [chartData, setChartData] = useState({
        labels: Data.map((data) => data.year), 
        datasets: [
          {
            label: "Users Gained ",
            data: Data.map((data) => data.userGain)
          }
        ]
      });

    let [state , setState] = useState<IState>({
        errorMessage : "",
        comment_model : {
            id: "",
            title : "",
            comment : "",
            comment_id1 : "",
            comment_id2 : "",
            file : "",
            file_type : ""
        },
        loading : false,
    })
    let txt:any = "いいね！";
    let likeColor:any = "";
    // let likeCount:any;

      const getData = async () => {
        setState({...state,loading :true,});
        HomeService.getHomeData().then((response)=>{

            Object.entries(response.data.comments).forEach(([key, value]: [string, any]) => {
                if(value.likes != null){
                    setLikes(value.likes);

                }
            })
            // let flonet_data = response.data.chart_data.chartYflonet;
            // let floboard_data = response.data.chart_data.chartYfloboard;
            // let xAxis = response.data.chart_data.chartXaxis;

            // // setMonthflg(response.data.chart_data.monthFlg);
            // // setYearFlg(response.data.chart_data.yearFlg);

            // var flonet_chart_data:any[] = [];
			// for(var i in flonet_data){
            //     flonet_chart_data.push(flonet_data[i]);
            // }

			// var floboard_chart_data:any[] = [];
			// for(var j in floboard_data){
            //     floboard_chart_data.push(floboard_data[j]);
            // }

			// var x_axis:any[] = [];
			// for(var k in xAxis)
			// x_axis.push(xAxis[k]);

            // setChartData({
            //     labels: x_axis,
            //     datasets: [
            //       {
            //         label: '稼働人数:Flonet',
            //         data:   flonet_chart_data,
            //       },
            //       {
            //         label: '稼働人数:FloBoard',
            //         data:   floboard_chart_data,
            //       },
            //     ],
            //   });
            setSenderList(response.data.sender);
            setLogList(response.data.logs);
            setCaseCount(response.data.create_case_count);
            setDealedPersonCount(response.data.dealed_person_count);
            setRecruitingCaseCount(response.data.recruiting_case_count);
            setProposablePersonCount(response.data.proposable_person_count);
            setWorkExitPersonCount(response.data.work_exit_person_count);
            setCommentDatas(response.data.comments);
                
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
            setState({
                ...state,
                loading:false,
                errorMessage:error.message
            })
        })
        // const [isActive, setIsActive] = useState(false);
        // const toggleTextBox = () => {

        //     setIsActive(!isActive);
        //   };
     }


     const getChartData = async (monthflg:any, yearflg:any) => {
        let data = {};
        data["yearflg"] = yearflg;
        data["monthflg"] = monthflg;

        WorkService.getChartData(data).then((response)=>{
            console.log(response);
            let flonet_data = response.data.chart_data.chartYflonet;
            let floboard_data = response.data.chart_data.chartYfloboard;
            let xAxis = response.data.chart_data.chartXaxis;


            setMonthflg(response.data.chart_data.monthFlg);
            setYearFlg(response.data.chart_data.yearFlg);

            var flonet_chart_data:any[] = [];
			for(var i in flonet_data){
                flonet_chart_data.push(flonet_data[i]);
            }

			var floboard_chart_data:any[] = [];
			for(var j in floboard_data){
                floboard_chart_data.push(floboard_data[j]);
            }

			var x_axis:any[] = [];
			for(var k in xAxis)
			x_axis.push(xAxis[k]);

            setChartData({
                labels: x_axis,
                datasets: [
                  {
                    label: '売上:Flonet',
                    data:   flonet_chart_data,
                  },
                  {
                    label: '売上:FloBoard',
                    data:   floboard_chart_data,
                  },
                ],
              });
        }).catch((error) => {
            console.log(error);
           
        });
    }

     useEffect(()=>{
        const fetchData = async () => {
            setState({
                ...state,
                loading :true,
            });
            try {
                setState({
                    ...state,
                    loading :true,
                })
                await  getData();
                await getChartData(monthflg, yearflg);
            } catch (error) {
              console.log(error);
            } finally {
                setState({
                    ...state,
                    loading :false,
                })
            }
          };
      
       
            fetchData();
       
       
    }, []);

    const ShowTitle = function () {
        if(show_title_text == "none"){
            setShowTitleText("inline-block");
        }else{
            setShowTitleText("none");
        }
    }

    const ShowCommentBox = function (e:any) {
        e.preventDefault();
        const commentId = e.target.getAttribute('data-comment');

        if (showCommentTextBox == "none"){
            setShowCommentTextBox("inline-block");
            setId(commentId);
            comment_model['comment_id1'] = commentId; //Main Comment ID
        }else{
            setShowCommentTextBox("none");
            // setIsChatCommentBox(!isChatCommentBox);
        }
    }

    const ShowReplyBox = function (e:any) {
        e.preventDefault();
        const commentId = e.target.getAttribute('data-id');
        const mainId = e.target.getAttribute('data-comment');
        if (showReplyCommentTextBox == "none"){
            setShowReplyCommentTextBox("inline-block");
            setId(commentId);
            setMainId(mainId);
            comment_model['comment_id1'] = mainId;
            comment_model['comment_id2'] = commentId;
         }else{
            setShowReplyCommentTextBox("none");
        }
    }

    const ShowAllComments = function (e:any) {
        const commentId = e.target.getAttribute('data-comment');
        if (showCommentAll == "inline-block"){
            setShowCommentAll("none");
            setId(commentId);
        }else{
            setShowCommentAll("inline-block");
        }
    }

    const replyChatBox =  function(id:any,comment_id:any,userName:any, photo:any) {
        getChatBox(id,comment_id,userName, photo);
    }

    const storeLike =  function(comment_id:any) {
        let data = {};
        data["comment_id"] = comment_id;
        setState({...state,loading :true,});
        HomeService.storeLike(data).then((response)=>{
            if(response.data.success == true){
                getData();
            }
            setState({...state,loading :false,});
        }).catch((error) => {
            setState({...state,loading :false,});
            if(error.response.data){
                showErrorInfo(error.response.data);
            }
        })
    }

    let updateInput = (e:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setState({
            ...state,
            comment_model : {
                ...state.comment_model,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            }
        });      
    };

    let updateTextArea = (e:React.ChangeEvent<HTMLTextAreaElement>):void => {
        setState({
            ...state,
            comment_model : {
                ...state.comment_model,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            }
        });
    }


    let clickAttachBtn = function (e:any) {
        e.preventDefault();
        setOpen(true);
    }

    const upload = function (e:any) {
        e.preventDefault();
      }

    const cancelEditForm = function (e:any) {
        e.preventDefault();
        // getData(monthflg,yearflg);
        setEditFormDisplay("none");
        setTwoReplyFormDisplay("none");
        setPreDisplay("block");
      }

    //   const handleClear = () => {
    //     setInputValue(''); // Clear the input value by setting state to an empty string
    //   };
    
      const clearReplyCommentBox = function (e:any) {
        e.preventDefault();
        const chatInput = $('.reply_one_textarea');
        chatInput.val('');
        setShowReplyCommentTextBox("none");
        // getData(monthflg,yearflg);
        // window.location.reload();
      }
    
    const clearIcon = function(e:any){
        e.preventDefault();
        setShowAttachFile("none");
        setShowEditAttachFile("none");
        setImgUrl("");
    }
    
    const closeChat = function(e:any){
        e.preventDefault();
        setChatFormDisplay("none");
        setReplyChatBoxDisplay("none");
        getData();
        setState({...state,loading :false,});
        // window.location.reload();
    }

    const toggleMinimize = () => {
        setIsMinimized(!isMinimized);
    };

    const fileExplorer = function (e:any) {
        e.preventDefault();
        $('#selectfile').click();
    }
    const changeFile = function(e:any) {
      let file_name = e.target.files[0].name;
      file_upload(e.target.files[0],file_name);
      e.target.value = null;
    };

    const file_upload = function(file_obj:any, name:any) {
        ResourceService.apiUpload(file_obj, 'attachment/', name).then((res:any) => {

        if(editingId === "null"){
            setShowAttachFile("block");
            setImgUrl(ResourceService.url(`attachment/${name}`));
            setDeleteIcon(true);
            comment_model['file'] = name;
            setOpen(false);
        }else{
            setShowEditAttachFile("block");
            // setShowAttachFile("none");
            setImgUrl(ResourceService.url(`attachment/${name}`));
            setDeleteIcon(true);
            comment_model['file'] = name;
            setOpen(false);
        }
        // // setDropProfile("none");
        // setPhotoName(name);
        
        // $(".img-content img").attr("src" , ResourceService.url(`profiles/${name}`))
        comment_model['file'] = name;
        setOpen(false);
      });
    }

    const removeFile = function (id:any, file_name:any) {
        let data = {};
        data["id"] = id;
        data['file'] = file_name;
        HomeService.removeFile(data).then((response) => {
        //    window.location.reload();
           getData();
           setState({
                ...state,
                loading :false,
            })
        }).catch((error) => {
            console.log(error);
          
        })
    }
        
    const store = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
        setState({...state,loading :true,});
            HomeService.storeComment(state.comment_model).then((response) => {
                if(response.data.success == true){
                    getData();
                    comment_model['comment'] = "";
                    setShowCommentTextBox("none");
                    setShowReplyCommentTextBox("none");
                    setShowAttachFile("none");
                    // window.location.reload();
                }
                setState({...state,loading :false,});
            }).catch((error) => {
                setState({...state,loading :false,});
                if(error.response.data){
                    showErrorInfo(error.response.data);
                }
            })
    };

    const storeChatMessage = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
            let datas = {};
            datas["receiver_id"] = receiver_id;
            datas["message"] = inputChatMessageValue;
            if (datas["message"] == null || datas["message"] == ""){
                alert("メッセージを入力してください。");
            }else{
                setState({...state,loading :true,});
                HomeService.storeChatMessage(datas).then((response) => {
                    // const chatMsg = $('.chat-messages');
                    const chatInput = $('.chat-input');
                    let data = {"receiver_id": receiver_id};
                    // chatMsg.text("");
                    chatInput.val("");
                    HomeService.getChatMessage(data).then((response) =>{
                        const chatMsg = $('.chat-messages');
                        chatMsg.text("");
                        const res =  response.data.info[0];
                        Object.entries(res).forEach(([key, value]: [string, any]) => {
                             let message = value.message;
                                if(message == null){
                                    message = "";
                                }
                                if (value.sender_id == login_user.user_id){
                                    chatMsg.append('<div class="message-box-holder"><div class="message-box">'+message+'</div></div>');
                                }
                                if(value.receiver_id == login_user.user_id){
                                    let name_jp;
                                        if(value.sender.name_jp != "undefined"){
                                            name_jp = value.sender.name_jp;
                                        }else{
                                            name_jp = "Unknown";
                                        }
                                            chatMsg.append('<div class="message-box-holder"><div class="message-sender">'+name_jp+'</div><div class="message-box message-partner">'+message+'</div></div>');  
                                }
                        });
                        setState({...state,loading :false,});
                    })
                }).catch((error) => {
                    setState({...state,loading :false,});
                   console.log(error);
                })
            }
    };

    // const update = () => {
    //     const updatedMessages = commentDatas.map(message =>
    //       message["id"] === editingId ? { ...comment_model, comment: editedMessage } : message['comment']
    //     );
    //     setMessages(updatedMessages);
    //     setEditMessageId(null); // Clear edit mode
    //     setEditedMessage(''); // Clear edited message
    //   };

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>):void => {
        // Update the state with the new input value
        setInputChatMessageValue(e.target.value);
    };

    const update = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
        let datas = {};
        datas["id"] = editingId;
        datas["comment"] = editComment;

        // datas["comment"] = comment;
        datas["file"] = comment_model["file"];

        console.log(datas);
        setState({...state,loading :true,});
        HomeService.updateComment(datas).then((response) => {
                setEditFormDisplay("none");
                setPreDisplay("block");
                setEditingId(editingId);
                getData();
                setState({...state,loading :false,});
                // window.location.reload();
                // if(response.data.success == true){
                //     window.location.reload();
                // }
            })
            .catch((error) => {
                console.log(error);
                if(error.response.data){
                    showErrorInfo(error.response.data);
                }
            })
    };

    const updateReply = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
        let datas = {};
        datas["id"] = editingId;
        datas["comment"] = replyComment;
        setState({...state,loading :true,});
            HomeService.updateComment(datas).then((response) => {
                setReplyFormDisplay("none");
                getData();
                setState({...state,loading :false,});
                // window.location.reload();
                // if(response.data.success == true){
                //     window.location.reload();
                // }
            })
            .catch((error) => {
                console.log(error);
                if(error.response.data){
                    showErrorInfo(error.response.data);
                }
                setState({...state,loading :false,});
            })
    };

    const updateTwoReply = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
        let datas = {};
        datas["id"] = editingId;
        datas["comment"] = twoReplyComment;
        setState({...state,loading :true,});
            HomeService.updateComment(datas).then((response) => {
                setTwoReplyFormDisplay("none");
                getData();
                setState({...state,loading :false,});
               
            })
            .catch((error) => {
                console.log(error);
                if(error.response.data){
                    showErrorInfo(error.response.data);
                }
                setState({...state,loading :false,});
            })
    };

    const updateTextChange = (e:any) => {
        setEditComment(e.target.value);
        // setComment(e.target.value);
      };

    const updateReplyTextChange = (e:any) => {
        setReplyComment(e.target.value);
      };

    const updateTwoReplyTextChange = (e:any) => {
        setTwoReplyComment(e.target.value);
      };
    
    const showEdit = function(id:any,comment:any, file:any){
        setEditFormDisplay("block");
        setShowEditAttachFile("none");

        if(id){
            setPreDisplay("none");
            setEditingId(id);
            setEditComment(comment);
        }else{
            setPreDisplay("block");
            setEditingId("null");
        }
        
    }

    const showReplyEdit = function(id:any){
        setReplyFormDisplay("block");
        if(id){
            setIsVisible(!isVisible);
            // setPreDisplay("none");
            setEditingId(id);
            // setEditComment(comment);
        }else{
            // setPreDisplay("block");
            setEditingId("null");
        }
        
    }

    const showTwoReplyEdit = function(id:any){
        setTwoReplyFormDisplay("block");
        if(id){
            setIsVisible(!isVisible);
            // setPreDisplay("none");
            setEditingId(id);
            // setEditComment(comment);
        }else{
            // setPreDisplay("block");
            setEditingId("null");
        }
        
    }

    
    const deleteComment = function(id:any){
        setState({...state,loading :true,});
        HomeService.deleteComment(id).then((response) => {
            getData();
            setState({...state,loading :false,});
        //    window.location.reload();
        })
        .catch((error) => {
            console.log(error);
            if(error.response.data){
                showErrorInfo(error.response.data);
            }
            setState({...state,loading :false,});
        })
        
    }

    const handleIconHover  = (e:any) => {
        const commentId = e.target.getAttribute('data-comment');
        setId(commentId);
        e.preventDefault();
        setIsHovered(true);
        // setDisplayDesign("block");
      };
    
      const handleIconLeave  = (e:any) => {
        e.preventDefault();
         if (!isListHovered) {
            setIsHovered(false);
          }
        // setIsHovered(false);
        // setDisplayDesign("none");
      };

      const handleListHover = () => {
        // const commentId = e.target.getAttribute('data-comment');
        // if(id==)
        setIsListHovered(true);
      };

      const handleListLeave = () => {
        setIsListHovered(false);
        setIsHovered(false);
      };
      
      
      const getChatBox = function(id:any,comment_id:any,userName:any, photo:any){
        if(login_user.user_id == id){
            alert("自分のアカウントをチャット出来ません。もう一度確認してください。");
        }
        else{
            let data = {};
            data["receiver_id"] = id;
            HomeService.getChatMessage(data).then((response) =>{
                setNameJp(userName);
                setPhoto(photo);
                if(id !== comment_id){
                    setChatFormDisplay("block");
                }else{
                    setReplyChatBoxDisplay("block");
                }
                setCommentId(comment_id);
                setReceiverId(id);
                const chatMsg = $('.chat-messages');
                chatMsg.text("");
                if (response.data.info && response.data.info.length > 0) {
                    const res =  response.data.info[0];
                    Object.entries(res).forEach(([key, value]: [string, any]) => {
                     
                     let message = value.message;
                    //  setReceiverId(key);
                     if(message == null){
                        message = "";
                     }
                     if (value.sender_id == login_user.user_id){
                        chatMsg.append('<div class="message-box-holder"><div class="message-box">'+message+'</div></div>');
                    }
                    if(value.receiver_id == login_user.user_id){
                        let name_jp;
                        if(value.sender.name_jp != "undefined"){
                            name_jp = value.sender.name_jp;
                        }else{
                            name_jp = "Unknown";
                        }
                            chatMsg.append('<div class="message-box-holder"><div class="message-sender">'+name_jp+'</div><div class="message-box message-partner">'+message+'</div></div>');  
                      }
                    });
                }
            })
        }
            
        
        // HomeService.deleteComment(id).then((response) => {
        //     window.location.reload();
        // })
        // .catch((error) => {
        //     if(error.response.data){
        //         showErrorInfo(error.response.data);
        //     }
        // })
        
    }
    let {loading, comment_model} = state;

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height: 320,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    const MobileModalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: `calc(100% - 10%)`,
        height:  `calc(100% - 50%)`,
        resize: 'both',
        overflow: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };



  let sender_list = Object.entries(senderList);
//   let replyOne = Object.entries(reply_one);
    
  
  let commentReplyList = Object.entries(commentDatas);
  let replies_2:any = "";


  const FileUploadModal = (
        <div>
          <div className="row">
                    <div className="col-6">
                    </div>
                    <div className="col-6">
                         <button type="button" className="modal_close" onClick={handleClose}>X</button>
                    </div>            
                </div>
                <form className="form">
                     <div className="row">
                        <div className="col-12 input-box">
                            <div id="drop_file_zone" className="drop_file" style={{display: drop_file }} data-id="1" onDrop={upload}>
                                <div id="drag_upload_file">
                                    <p>ファイルをドラッグしてください。</p>
                                    <p>または</p>
                                    <p><input type="button" value="ファイルを選択してください。" onClick={fileExplorer} /></p>
                                    <input type="file" name="file" id="selectfile"  onChange={changeFile} />
                                </div>
                            </div>
                        </div>
                     </div>
                </form>
        </div>
  )

    return(
        <>
       <React.Fragment>

           <AppBar
            position="fixed"
            sx={{
                width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                ml: sizeConfigs.sidebar.width,
                boxShadow: "unset",
                backgroundColor: colorConfigs.topbar.bg,
                color: colorConfigs.topbar.color
            }}
            >
            <Toolbar>
                <Typography variant="h6">
                <header className="top-baner">
                <Row className="row text-body">
                {/* <div className="row text-body" style={{maxHeight:"100vh",overflow:"auto"}}> */}
                        <Col xxl={10} xl={10} md={10} sm={10} xs={10}>
                        </Col>
                        <Col xxl={2} xl={2} md={2} sm={2} xs={2} className="mt-4">
                            <DropDownList />
                        </Col>
                </Row>
                </header>
                </Typography>
                </Toolbar>
            </AppBar>
            <Container>
                <Row>
                <div className="mt-3 text-center">
                    {( loading   == true) ? <Loader /> :<> </>}
                </div>
                </Row>
                <Row>
                            <div className="dashboard-header">
                                <h3>今月のサマリー</h3>
                            </div>
                            <div className="col-md-2 mb-2">
                                <div className="card">
                                    <div className="card-body count-card">
                                        <div className="col-12 pt-2 count-col">
                                            <a href="/cases" className="card-link">{caseCount}</a>
                                        </div>
                                        <div className="col-12 count-name">
                                            新規募集案件
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 mb-2">
                                <div className="card">
                                    <div className="card-body count-card">
                                        <div className="col-12 pt-2 count-col">
                                        <a href="/persons" className="card-link">{dealedPersonCount}</a>
                                        </div>
                                        <div className="col-12 count-name">
                                            新規決定人数
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 mb-2 ">
                                <div className="card">
                                    <div className="card-body count-card">
                                        <div className="col-12 pt-2 count-col">
                                            <a href="/cases/list" className="card-link">{recruitingCaseCount}</a>
                                        </div>
                                        <div className="col-12 count-name">
                                            募集中案件
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 mb-2">
                                <div className="card">
                                    <div className="card-body count-card">
                                        <div className="col-12 pt-2 count-col">
                                            <a href="/persons" className="card-link"> {proposablePersonCount} </a>
                                        </div>
                                    
                                        <div className="col-12 count-name">
                                            営業可能要員
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 mb-2">
                                <div className="card">
                                    <div className="card-body count-card">
                                        <div className="col-12 pt-2 count-col">
                                            <a href="/management/works/list" className="card-link">{workExitPersonCount}</a>
                                        </div>
                                    
                                        <div className="col-12 count-name">
                                            今月退場人数
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row dashboard-summary-block task">
                                <div className="summary-block col-md-6 mb-2">
                                    <div className="row todolist">
                                        <div className="col-md-12">
                                           <form className="chat-box-form" onSubmit={store}>
                                                <div className="chat-insert-profile" >
                                                    <input type="text" className="show-text" name="title" placeholder="Add your title" onChange={updateInput} style={{display: show_title_text }} value={comment_model['title']} />
                                                    <div className="add-text" id="add-text" onClick={ShowTitle}></div>
                                                </div>
                                                <textarea className="chat-text" name="comment" id="chat text" value={comment_model['comment']}  onChange={updateTextArea} placeholder="What is your comments"></textarea>
                                                <hr style={{margin: "unset"}}/>
                                                <div className="img-content show_upload_file" style={{display:show_attach_file}}>
                                                    <img src={imgUrl} className= "image" alt="" style={{width: "10%"}}/>
                                                    {(deleteIcon)?<a href="#" onClick={clearIcon} style={{position:"relative",left:"-87px",bottom:"100px"}}><i className="fa fa-times-circle"></i></a>:""}
                                                </div>
                                                <input type="hidden" name="file" value={comment_model['file']} className="uploaded_file"/>
                                                <input type="hidden" name="file_type" value={comment_model['file_type']} className="uploaded_file_type"></input>
                                                <div className="chat-footer comment-box-footer">
                                                    <button className="attach-btn" onClick={clickAttachBtn}>
                                                        <i className="fas fa-paperclip" style={{fontSize:"1.5em"}}></i>
                                                    </button>
                                                    <input type="submit" className="button-send" id="send" value="共有"/>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="row chat-menu">
                                        <div className="col-md-12">
                                            <ul>
                                                <li><a href=""></a></li>
                                                <li><a href=""></a> </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {commentDatas.map(item =>(
                                         <div className="row chat-box">
                                         <div className="col-md-12" id={item["id"]} style={{display:"block"}}>
                                             <div className="chat-head">
                                                <div className="chat-image">
                                                 <ResourceImg remoteSrc={(item["user"] !== undefined && item["user"] != null)?((item["user"]["photo"] != null)?`profiles/${item["user"]["photo"]}`:""):""} src={'images/businessman.jpg'}  alt="icons"  /> 
                                                </div>
                                                 <div className="chat-profile">
                                                     <p>{(item["user"] !== undefined && item["user"] != null)?((item["user"]["name_jp"] != null)?item["user"]["name_jp"]:"Unknown"):"Unknown"}</p>
                                                     <span className="chat_title">{item["title"]}</span>
                                                 </div>
                                                 <div className="add-text" id="title-menu" data-comment ={item["id"]} onMouseEnter={handleIconHover} onMouseLeave={handleIconLeave}>
                                                        <span className="date" data-comment ={item["id"]}>{format(new Date(item["create_date"]), 'yyyy-MM-dd HH:mm:ss')}</span>
                                                    {id == item["id"] && ((isHovered)? <ul className="show-title-menu" data-comment ={item["id"]} onMouseEnter={handleListHover} onMouseLeave={handleListLeave} style = {{cursor:"pointer"}}>
                                                         <li>
                                                             {/* <form action="{{route('api.comments.destroy', ['comment' => $comment['id']])}}" method="POST"> */}
                                                             <div className="delete" id={item["id"]} onClick={() => deleteComment(item["id"])}>
                                                                 <i className="fas fa-trash" style={{fontSize:"small"}}><span className="edit" style={{paddingLeft:"10px",bottom:"0px"}}>削除</span></i>
                                                                 {/* <input type="submit" value="削除" className="comment-delete-btn" style={{paddingLeft:"10px",fontSize:"13px",background:"white"}}/> */}
                                                             </div>
                                                         </li>
                                                         <li>
                                                             <div className="edit" id={item["id"]} style={{marginTop:"-15px"}} onClick={() => showEdit(item["id"],item["comment"], item["file"])}>
                                                                 <i className="fas fa-pen" style={{fontSize:"small"}}></i><span className="edit" style={{paddingLeft:"10px",bottom:"0px"}}>編集</span>
                                                             </div>
                                                         </li>
                                                        </ul>:"")}
                                                 </div>
                                             </div>
                                             <div className="chat-body">
                                                {(editingId === item["id"])?
                                                <pre className="message-content edit-content comment_pre" id={editingId} style={{display:preDisplay}}>{item["comment"]} </pre>
                                                :<pre className="message-content edit-content comment_pre">{item["comment"]} </pre>}
                                                 <br/>
                                                 <div className="show_uploaded_file">
                                                   
                                                         {item["file"]?
                                                           <a className="text-align-unset" href={ResourceService.download(`attachment/${item["file"]}`)} style={{color:"black!important"}}>
                                                            <span className="display-block" style={{width:"800px"}}>
                                                            <ResourceImg remoteSrc={`attachment/${item["file"]}`} src={''} className="attachment-img" alt="icons" /> 
                                                            <a href='#' style={{position:"absolute",float:"left",marginTop:"-5px",marginLeft:"-1em"}} onClick={() => removeFile(item['id'], item["file"])}><i className='fa fa-close remove'></i></a>
                                                            </span>
                                                          </a>
                                                          :<></>}
                                                        
                                                 </div>
                                                 {(editingId === item["id"])?
                                                    <div id={editingId} className="chat-comment-edit-box" style={{display:editFormDisplay}}>
                                                    <form className="chat-box-form-update" onSubmit={update}>
                                                        <input type="hidden" name="id" value={editingId} className="comment_id"/>
                                                        
                                                        <textarea name="editComment" id={editingId} className="edit-chat-text" defaultValue={item["comment"]} value={editComment} onChange={updateTextChange} placeholder="What is your reply">{}</textarea>
                                                        {/* <textarea name="comment" id={editingId} className="edit-chat-text" defaultValue={item["comment"]} value={comment} onChange={updateTextChange} placeholder="What is your reply">{item["comment"]}</textarea> */}
                                                        <div className="img-content edit_show_upload_file" style={{display:show_edit_attach_file}}>
                                                            <img src={imgUrl} className= "image" alt="" style={{width: "10%"}}/>
                                                            {(deleteIcon)?<a href="#" onClick={clearIcon} style={{position:"relative",left:"-87px",bottom:"100px"}}><i className="fa fa-times-circle"></i></a>:""}
                                                        </div>
                                                        <input type="hidden" name="id" value={item["id"]} className="edit_id"/>
                                                        <input type="hidden" name="file" value={comment_model["file"]} className="edit_uploaded_file"/>
                                                        <input type="hidden" name="file_type" value={comment_model["file_type"]} className="edit_uploaded_file_type"/>
                                                        <div className="button-container" style={{paddingTop:"10px",paddingBottom:"10px"}}>
                                                            <button className="edit-attach-btn" onClick={clickAttachBtn}>
                                                                <i className="fas fa-paperclip" style={{fontSize:"1.5em"}}></i>
                                                            </button>
                                                            <button id="comment_edit_submit" type="submit" className="button update_btn">更新</button>
                                                            <button id="comment_edit_cancel" type="reset" className="button cancel_btn" onClick={cancelEditForm}>取消</button>
                                                        </div>
                                                    </form>
                                                </div> 
                                                 :
                                                 ""
                                                 }
                                             </div>
                                             <div className="chat-footer">
                                                 <div style={{width:"100%",fontSize:"12px"}}>
                                                    <div className="chat-action" style={{cursor:"pointer"}}>

                                                    {
                                                    (() => {
                                                        if (item["likes"] &&  Object.entries(item["likes"]).length > 0) {
                                                            // Loop through likes to determine likeColor and txt
                                                            Object.entries(item["likes"]).forEach((likes) => {                                                    
                                                                // Ensure likes[1] is not null or undefined before accessing its properties
                                                                const likeEntry = likes[1];
                                                                if (  typeof likeEntry === "object" && likeEntry !== undefined  && likeEntry !== null && likeEntry["comment_id"] && likeEntry["comment_id"] == item["id"] && likeEntry["user_id"] == login_user.user_id) {
                                                                    likeColor = "green";
                                                                }
                                                    
                                                                if ( typeof likeEntry === "object" && likeEntry !== undefined  && likeEntry !== null && likeEntry["comment_id"] && likeEntry["comment_id"] == item["id"] && item["creator_id"] != login_user.user_id) {
                                                                    txt = "いいね！済";
                                                                } else {
                                                                    txt = item["like_count"];
                                                                }
                                                            });

                                                            return(
                                                                <div className="chat-like"  id = {item["id"]} style={{color:likeColor}} data-comment={item["id"]} onClick={() => storeLike(item["id"])}>{txt}</div> 
                                                            )
                                                        }else{
                                                            return(
                                                                <div className="chat-like"  id = {item["id"]} style={{color:likeColor}} data-comment={item["id"]} onClick={() => storeLike(item["id"])}>いいね！</div>
                                                            )
                                                            }
                                                        })()
                                                    }
                                                    {/* {
                                                        
                                                        // Ensure this is inside a valid React component or return statement
                                                        item["likes"] && typeof item["likes"] === 'object' && Object.entries(item["likes"]).length > 0 ? (
                                                            Object.entries(item["likes"]).map((likes, index) => {
                                                                console.log(likes[1]);

                                                             

                                                                if (likes[1] != undefined && likes[1]["comment_id"] && likes[1]["comment_id"] === item["id"] && likes[1]["user_id"] === login_user.user_id) {
                                                                    likeColor = "green";
                                                                }

                                                                if (likes[1] != undefined && likes[1]["comment_id"] && likes[1]["comment_id"] === item["id"] && item["creator_id"] !== login_user.user_id) {
                                                                    txt = "いいね！済";
                                                                } else {
                                                                    txt = item["like_count"];
                                                                }

                                                                // return (
                                                                //     <div className="chat-like"  id = {item["id"]} style={{color:likeColor}} data-comment={item["id"]} onClick={() => storeLike(item["id"])}>{txt}</div>
                                                                // );
                                                            })
                                                        ) : (
                                                            txt = "いいね！";
                                                            // <div className="chat-like"  id = {item["id"]} style={{color:likeColor}} data-comment={item["id"]} onClick={() => storeLike(item["id"])}>{txt}</div>
                                                        )
                                                    } */}
                                                       
                                                       
                                                       {/* <div className="chat-like"  id = {item["id"]} style={{color:likeColor}} data-comment={item["id"]} onClick={() => storeLike(item["id"])}>{txt}</div> */}
                                                            <div className="comment" id={`comment_des-${item["id"]}`} data-comment={item["id"]} onClick={ShowCommentBox}>コメント</div>
                                                            <div className="list" id={`something-${item["id"]}`} data-comment={item["id"]} onClick={ShowAllComments}>全て表示する</div>
                                                        </div>
                                                     <div className="chat-activity">
                                                         <div className="activity chat-message" onClick={() => getChatBox(item["creator_id"],item["id"],((item["user"] !== undefined && item["user"] != null)?((item["user"]["name_jp"] != null)?item["user"]["name_jp"]:"Unknown"):"Unknown") , ((item["user"] !== undefined && item["user"] != null)?((item["user"]["photo"] != null)?item["user"]["photo"]:null):null))}
                                                          id={item["creator_id"]} data-comment={item["creator_id"]} data-type="0" data-receiver_id={item["creator_id"]} style={{float:"right",cursor:"pointer"}}>チャット<span className="count" style={{background:"unset"}}></span>
                                                         </div>
                                                     </div>
                                                 </div>
                                                     <br/>
                                                     <hr/>
                                                  
                                                        {
                                                            commentReplyList  && commentReplyList.map((comment,index) => {
                                                              
                                                                if(comment[1]["reply_one"] != undefined && Object.keys(comment[1]["reply_one"]!).length >0){
                                                                return(
                                                                <React.Fragment>
                                                                <div id={`comment-${item["id"]}`} className="chat-comment" style={{width : "100%",display:(id==item["id"])?showCommentAll:"inline-block"}}>
                                                                <div className={`comment-${item["id"]}`} style={{width:"100%"}}>
                                                                        {comment[1] != undefined && Object.entries(comment[1]["reply_one"]).map((comment:any, index) => {
                                                                         
                                                                            if(comment[1]["comment_id1"] ==  item["id"] && comment[1]["comment_id1"]){
                                                                                return(
                                                                                <React.Fragment>
                                                                                    <div className="chat-profile-group">
                                                                                            <div className="chat-image cmt">
                                                                                                <ResourceImg remoteSrc={(comment[1]["user"] !== undefined && comment[1]["user"] != null)?((comment[1]["user"]["photo"] !== undefined && comment[1]["user"]["photo"] != null)?`profiles/${comment[1]["user"]["photo"]}`:""):""} src={'images/businessman.jpg'}  alt="icons"  /> 
                                                                                            </div>
                                                                                            <div className="chat-profile">
                                                                                                <p className="profile_name">{(comment[1]["user"] !== undefined  && comment[1]["user"] != null)?((comment[1]["user"]["name_jp"] !== undefined)?comment[1]["user"]["name_jp"]:"Unknown"):"Unknown"}</p>
                                                                                                <span className="comment-edit1-text">{comment[1]["comment"]}</span>
                                                                                            </div>
                                                                                        <div className="reply_one_edit">
                                                                                            {comment[1]["creator_id"] == login_user.user_id && (
                                                                                                <React.Fragment>
                                                                                                    <div className="add-text" id="reply_one_menu" data-comment={comment[1]["id"]} onMouseEnter={handleIconHover} onMouseLeave={handleIconLeave}>
                                                                                                    {id == comment[1]["id"] && isHovered &&(
                                                                                                        <ul className="show-reply_one-menu" data-comment ={comment[1]["id"]} onMouseEnter={handleListHover} onMouseLeave={handleListLeave} style={{cursor:"pointer"}}>
                                                                                                            <li>
                                                                                                            <div className="delete" id={comment[1]["id"]} onClick={() => deleteComment(comment[1]["id"])}>
                                                                                                                <i className="fas fa-trash" style={{fontSize:"small"}}><span className="edit" style={{paddingLeft:"10px",bottom:"0px"}}>削除</span></i>
                                                                                                            </div>
                                                                                                             
                                                                                                            </li>
                                                                                                            <li> 
                                                                                                                <div className="edit" id={`comment-edit1-${comment[1]["comment_id1"]}`} data-comment={comment[1]["comment_id1"]} onClick={() => showReplyEdit(comment[1]["id"])}>
                                                                                                                    <i className="fas fa-pen"></i><span style={{paddingLeft:"10px"}}>編集</span>
                                                                                                                </div>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    )}
                                                                                                    </div>
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                            {comment[1]["comment_id1"] ==  item["id"] && (
                                                                                                    <div id={`comment_reply-${comment[1]["id"]}`} data-id={comment[1]["id"]} data-reply={item["id"]} data-comment={item["id"]} data-reply_one={comment[1] ["id"]} onClick={ShowReplyBox} style={{fontSize:"12px",cursor:"pointer"}}>返信
                                                                                                    </div>
                                                                                            )}
                                                                                        </div>
                                                                                        { editingId ==  comment[1]["id"] && (
                                                                                        <div id="comment_edit1_box" className="chat-comment-edit-box" style={{margin:"0px",width: "100%",padding:"0px 10px",borderTop:"0px",display:replyFormDisplay}}>
                                                                                            <form className="chat-box-form-reply-update" onSubmit={updateReply}>
                                                                                                <input type="hidden" name="id" value={comment[1]["id"]} className="comment_id"/>
                                                                                                <textarea name="comment" id="" className ="reply_one_textarea chat-text " placeholder="What is your reply" defaultValue={comment[1]["comment"]} value={replyComment} onChange={updateReplyTextChange}>{}</textarea>
                                                                                                <div className="button-container" style={{paddingTop:"10px",paddingBottom:"10px"}}>
                                                                                                    <button id="comment_edit_submit1" type="submit" className="button update_btn">更新</button>
                                                                                                    <button id="comment_edit_cancel" type="reset" className="button cancel_btn" onClick={cancelEditForm}>取消</button>
                                                                                                </div>
                                                                                            </form>
                                                                                        </div> 
                                                                                        )}
                                                                                    </div>
                                                                                    <br></br>
                                                                                    {id == comment[1]["id"] && mainId == item["id"] &&(
                                                                                        <React.Fragment>
                                                                                            <div id={`chat-reply-${comment[1]["id"]}`} className ="chat-reply" style={{width:"100%",display:showReplyCommentTextBox}}>
                                                                                                <form className="reply-comment" onSubmit={store}>
                                                                                                    <input type="hidden" name="comment_id1" className="reply" value={comment_model["comment_id1"]} />
                                                                                                    <input type="hidden" name="comment_id2" className="reply_one" value ={comment_model["comment_id2"]}/>
                                                                                                    <textarea name="comment" id="" className="reply_one_textarea" value={comment_model['comment']}  onChange={updateTextArea} placeholder="What is your reply">{}</textarea>
                                                                                                    <div className="chat-footer chatbox_footer button-container">
                                                                                                            <input type="submit" className="button update_btn" id="reply" value=" 投稿"/>
                                                                                                            <button className="button cancel_btn" id={`cancel-${comment[1]["id"]}`} onClick={clearReplyCommentBox} >取消</button>
                                                                                                    </div>
                                                                                                </form>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                    {
                                                                                        comment[1]["replies_2"] &&(
                                                                                            Object.entries(comment[1]["replies_2"]).map((item:any, index) => (
                                                                                                <React.Fragment>
                                                                                                    <hr style={{marginTop:"50px"}}></hr>
                                                                                                    <div className="chat-image cmt" style={{marginLeft:"20px"}}>
                                                                                                        {/* <img src='".$photo."'/> */}
                                                                                                        <ResourceImg remoteSrc={(item[1]["user"] !== undefined && item[1]["user"] != null && item[1]["user"]["photo"] != null)?`profiles/${item[1]["user"]["photo"]}`: ''} src={'images/businessman.jpg'}  alt="icons"  />           
                                                                                                    </div>
                                                                                                    <div className="chat-profile">
                                                                                                        <p className="profile_name">{(item[1]["user"] !== undefined && item[1]["user"] != null)?item[1]["user"]["name_jp"]:"Unknown"}</p>
                                                                                                        <span className="comment-edit2-text">{item[1]["comment"]}</span>
                                                                                                    </div>
                                                                                                    <br></br>
                                                                                                    {item[1]["creator_id"] == login_user.user_id && (
                                                                                                        <React.Fragment>
                                                                                                            <div className="add-text" id="reply_two-menu" data-comment ={item[1]["id"]} onMouseEnter={handleIconHover} onMouseLeave={handleIconLeave}>
                                                                                                            {id == item[1]["id"] && isHovered &&(<ul className="reply_two-menu" onMouseEnter={handleListHover} onMouseLeave={handleListLeave}>
                                                                                                                    <li>
                                                                                                                            <div className="delete" id={item[1]["id"]} onClick={() => deleteComment(item[1]["id"])}>
                                                                                                                            <i className="fas fa-trash" style={{fontSize:"small"}}><span className="delete" style={{paddingLeft:"10px",bottom:"0px"}}>削除</span></i>
                                                                                                                            </div>
                                                                                                                    </li>
                                                                                                                    <li> 
                                                                                                                        <div id={`comment-edit2-${item[1]["id"]}`} data-comment={item[1]["id"]} onClick={() => showTwoReplyEdit(item[1]["id"])}><i className="fas fa-pen"></i> 編集</div>
                                                                                                                    </li>
                                                                                                                </ul>
                                                                                                            )}
                                                                                                            </div>
                                                                                                            <br></br>
                                                                                                            { editingId ==  item[1]["id"] && (<div id={`comment_edit2_box-${item[1]["id"]}`} className="chat-comment-edit-box" style={{marginLeft:"20px",width: "95%",display:twoReplyFormDisplay,borderTop:"0px"}}>
                                                                                                                <form className="chat-box-two-form-reply-update" onSubmit={updateTwoReply}>
                                                                                                                    <input type="hidden" name="id" value={item[1]["id"]} className="comment_id"/>
                                                                                                                    <textarea name="comment" id="" className="reply_one_textarea chat-text" defaultValue={item[1]["comment"]}  onChange={updateTwoReplyTextChange} placeholder="What is your reply"></textarea>
                                                                                                                    <div className="button-container" style={{paddingTop:"10px",paddingBottom:"10px"}}>
                                                                                                                        <button id="comment_edit_submit2" type="submit" className="button update_btn">更新</button>
                                                                                                                        <button id="comment_edit_cancel" type="reset" className="button cancel_btn" onClick={cancelEditForm}>取消</button>
                                                                                                                    </div>
                                                                                                                </form>
                                                                                                            </div>
                                                                                                        )}
                                                                                                         </React.Fragment>
                                                                                                    )}
                                                                                                </React.Fragment>
                                                                                            ))  
                                                                                    )}
                                                                                    <hr style={{marginTop:"50px"}}></hr>
                                                                                </React.Fragment>
                                                                                )
                                                                            } 
                                                                            })}
                                                                    </div>
                                                                    { comment[1] != undefined &&  comment[1]["reply_one"] != undefined && comment[1]["reply_one"][0] != undefined && comment[1]["reply_one"][0]["comment_id1"] == item["id"] && (
                                                                        id == item["id"] && (
                                                                        <div className={`comment_box-${item["id"]} chat-comment-box`} style= {{width: "100%",display:showCommentTextBox}}>
                                                                            <form className="comment_form" onSubmit={store}>
                                                                                <input type="hidden" name="comment_id1" className="comment_box_reply_one" value={comment_model['comment_id']}/>
                                                                                    <textarea name="comment" id="" className="chat-text" value={comment_model['comment']}  onChange={updateTextArea} placeholder="What is your reply"></textarea>
                                                                                    <div className="chat-footer button-container comment_box_footer">
                                                                                        <input type="submit" className="button update_btn" id="reply" value="投稿"/>
                                                                                        <button className="button cancel_btn" id="cancel-48"> 取消 </button>
                                                                                    </div> 
                                                                            </form>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                </React.Fragment>
                                                                )}else{
                                                                    // { id == item["id"]  && (
                                                                        if(comment[1]["id"] ==  item["id"] &&  comment_model['comment_id1'] == item["id"]){
                                                                            return(
                                                                            <React.Fragment>
                                                                            <div className={`comment_box-${item["id"]} chat-comment-box`} style= {{width: "100%",display:showCommentTextBox}}>
                                                                                <form className="comment_form" onSubmit={store}>
                                                                                    <input type="hidden" name="comment_id1" className="comment_box_reply_one" value={comment_model['comment_id1']}/>
                                                                                        <textarea name="comment" id="" className="chat-text" value={comment_model['comment']}  onChange={updateTextArea} placeholder="What is your reply"></textarea>
                                                                                        <div className="chat-footer button-container comment_box_footer">
                                                                                            <input type="submit" className="button update_btn" id="reply" value="投稿"/>
                                                                                            <button className="button cancel_btn" id="cancel-48"> 取消 </button>
                                                                                        </div> 
                                                                                </form>
                                                                            </div>
                                                                            </React.Fragment>
                                                                    )}
                                                                    // )}
                                                                }
                                                            })
                                                        }
                                             </div>
                                         </div>
                                        </div>
                                    ))}
                                    <div className="chatbox-holder" style={{zIndex:"1",display:chatFormDisplay}}>
                                        <div className={`chatbox ${isMinimized ? 'chatbox-min' : 'chatbox'}`}>
                                            <div className="chatbox-top">
                                                <div className="chatbox-avatar">
                                                <a target="_blank" href="#">
                                                <ResourceImg remoteSrc={(photo !== undefined && photo != null && photo!= '')?`profiles/${photo}`: ''} src={'images/businessman.jpg'}  alt="icons"  /> 
                                                </a> 
                                                </div>
                                                <div className="chat-partner-name">
                                                    <a target="_blank" href="#" style={{color:"black !important"}}>{nameJp}</a>
                                                </div>
                                                <div className="chatbox-icons">
                                                    <a href="#" onClick={toggleMinimize}><i className="fa fa-minus"></i></a>
                                                    <a href="#" onClick={closeChat}><i className="fa fa-close close"></i></a>       
                                                </div>      
                                            </div>
                                            <div className="chat-messages">
                                            </div>
                                            <div className="chat-input-holder">
                                                <form method="POST" onSubmit={storeChatMessage}>
                                                    <input type="hidden" name="receiver_id" id="receiver_id"/>
                                                    <input type="text" className="chat-input" name="message" placeholder="Type your message" value={inputChatMessageValue} onChange={handleChange} style={{color:"black"}}/>
                                                    <input type="submit" id="chat_btn" className="message-send" value="共有"/>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="summary-block graph col-md-6 mb-2">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h3>稼働人数 </h3>
                                            <LineChart chartData={chartData} />
                                        </div>
                                        <div className="col-md-12 sender">
                                            <h3>チャット </h3>
                                            {
                                            sender_list  && sender_list.map(sender => {
                                                
                                                // {(sender[1][2]!=null)?setChatUrl(ResourceService.url(`profiles/${sender[1][2]}`)):setChatUrl(ResourceService.url("public/images/default_profile.jpg"))}
                                                 
                                                return(
                                                    <React.Fragment>  
                                                        <div id={`chat_msg-${sender[0]}`} data-comment={sender[0]} data-type="1" data-receiver_id={sender[0]} onClick={() => replyChatBox(sender[0],sender[0],sender[1][0],sender[1][2])} style={{cursor:"pointer"}}>
                                                        <ResourceImg remoteSrc={(sender[1][2] !== undefined && sender[1][2] != null)?`profiles/${sender[1][2]}`: ''} src={'images/businessman.jpg'}  alt="icons" className="chat_img" /> 
                                                            <span style={{fontSize:"1rem"}}>{sender[1][0]}</span>
                                                            <i className="far fa-comments" style={{paddingLeft:"5em",fontSize:"small"}}></i>
                                                            <i>
                                                                {(sender[1][1]>0)?<span className="count badge " style={{background:"#CB666A",fontSize:"1em !important"}}>{sender[1][1]}</span>:""}
                                                            </i>
                                                        </div>
                                                        <div className="chatbox-holder chatboxlist" style={{zIndex:"1",display:replyChatBoxDisplay}}>
                                                            <div className={`chatbox ${isMinimized ? 'chatbox-min' : 'chatbox'}`}>
                                                                <div className="chatbox-top">
                                                                    <div className="chatbox-avatar">
                                                                        <a target="_blank" href="#">
                                                                        <ResourceImg remoteSrc={(sender[1][2] !== undefined && sender[1][2] != null)?`profiles/${sender[1][2]}`: ''} src={'images/businessman.jpg'}  alt="" /> 
                                                                        </a> 
                                                                    </div>
                                                                <div className="chat-partner-name">
                                                                    <a target="_blank" href="#" style={{color:"black !important"}}>{sender[1][0]} </a>
                                                                </div>
                                                                <div className="chatbox-icons">
                                                                    <a href="#" onClick={toggleMinimize}><i className="fa fa-minus"></i></a>
                                                                    <a href="#" onClick={closeChat}><i className="fa fa-close close"></i></a>         
                                                                </div>
                                                            </div>
                                                            <div className="chat-messages">
                                                            </div>
                                                            <div className="chat-input-holder">
                                                            <form method="POST" onSubmit={storeChatMessage}>
                                                                <input type="hidden" name="receiver_id" id="receiver_id"/>
                                                                <input type="text" className="chat-input" name="message" placeholder="Type your message" value={inputChatMessageValue} onChange={handleChange} style={{color:"black"}}/>
                                                                <input type="submit" id="chat_btn" className="message-send" data-comment={commentId} data-receiver_id={receiver_id} value="共有"/>
                                                            </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="col-md-12 loglist">
                                            <h3>Log Info </h3>
                                            <ul>
                                                {
                                                logList  && logList.map(log => {
                                                        return(
                                                            <li>
                                                                <div className="chat-head">
                                                                    <div className="chat-image">
                                                                     <ResourceImg remoteSrc={(log['photo'] !== undefined && log['photo'] != null)?`profiles/${log['photo']}`: ''} src={'images/businessman.jpg'}  alt="icons"  /> 
                                                                    </div>
                                                                    <div className="chat-profile">
                                                                        <p>氏名：{log['user']}</p>
                                                                        <p>実行処理：{log['title']}</p>
                                                                        <p style={{left:"52px"}}>開始：{log['start']} ～ 終了：{log['end']}</p>
                                                                    </div>
                                                                </div>
                                                                <div style={{fontSize:"12px",marginTop:"10px",marginLeft:"49px"}}>
                                                                    全件数：{log['total']}件、送信件数：{log['sentCount']}件、送信失敗件数：{log['unsentCount']}件
                                                                </div>
                                                                <div style={{fontSize:"12px",marginTop:"10px",marginLeft:"49px"}}>
                                                                    {log['ids']}
                                                                </div>
                                                            </li>
                                                        );
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
              </Row>
              </Container>
            <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'none', sm: 'block' },
            }}
        >                                
            <Box sx={style}>
                {FileUploadModal}
            </Box>
        </Modal>   
            
        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'block', sm: 'none' },
            }}
        >                                
            <Box sx={MobileModalStyle}>
                {FileUploadModal}
            </Box>
        </Modal>   
        </React.Fragment>
        </>
    );
}

export default Home;