import React, {useState , useEffect} from 'react';
// import {User} from "../../models/User";
import SaleryDetailService from "../../services/SaleryDetailService";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import { CSVLink, CSVDownload } from "react-csv";
import {Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment';
import Loader from "../../components/common/Loader";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface IState{
    loading :boolean;
    salerys  :  [];
    errorMessage : string;  
    perPage : number;
    page : number;
    column: string;
    order: string;
    totalRows : number;
    employee_list : [];
    approve_status_list : [];
}
interface IProps{}

let SaleryDetailList:React.FC<IProps> = () =>{
    
    let [state, setState] = useState<IState>({
        loading : false,
        salerys :  [],
        errorMessage : "",
        page : 0,
        perPage : 10,
        column : "",
        order : "",
        totalRows : 0,
        employee_list : [],
        approve_status_list : [],
    });

    const [search, setSearch] = useState("");  
    const [emp_name, setEmpName] = useState("");  
    const [selected_period_start, setSelectedPeriodStart] = useState(null);  
    const [period_start, setPeriodStart] = useState("");
    const [selected_period_end, setSelectedPeriodEnd] = useState(null);  
    const [period_end, setPeriodEnd] = useState("");  
    const [approve_status, setApproveStatus] = useState("");  
    const [selected, setSelected] = useState('base');
    const [sort, setSort] = useState("0");
    const [no_of_rows, setNoOfRows] = useState(10);
    const [current_page, setCurrentPage] = useState(0);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [showPrevColor, setShowPrevColor] = useState("grey");
    const [showNextColor, setShowNextColor] = useState("grey");

    

    const getData = async (emp_name:any,period_start:any,period_end:any,approve_status:any, perPage:number, page:number, search:any, sort:any, column:string, order:string) => {
        setState({...state,loading :true,})
        SaleryDetailService.list(emp_name,period_start,period_end,approve_status, perPage, page, search, sort, column, order).then((response)=>{
            setState({
                ...state,
                loading:false,
                salerys:response.data.data,
                page : response.data.current_page,
                column : response.data.column,
                order : response.data.order,
                perPage : response.data.per_page,
                totalRows : response.data.total,
                employee_list : response.data.emp_name_list,
                approve_status_list : response.data.select_options.approv_status,

                // approve_status_list : response.data.select_options.job_type,
              
            })
            if(Math.floor(response.data.total/response.data.per_page) >0){
                setShowNextColor("blue");
            }else{
                setShowNextColor("grey");
            }
            if(0 == response.data.current_page-1){
                setShowPrevColor("grey");
            }
            if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
                setShowNextColor("grey");
            }
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
            setState({
                ...state,
                loading:false,
                errorMessage:error.message
            })
        })
    }   
        
    let {loading, salerys, perPage, page, column, order, totalRows, errorMessage,employee_list, approve_status_list} = state;
   
    const defaultColumns = [
        {
            name: "person_id",
            label: "編集",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<Link to={`/salery/detail/${tableMeta.rowData[0]}/edit?yearMonth=${tableMeta.rowData[2]}&period_start=${period_start}&period_end=${period_end}&emp_name=${emp_name}&approv_status=${approve_status}&list_length=${perPage}&page=${page}&search_keywords=${search}`} className="btn_standard btn-sm" style={{textDecoration:"none"}}>
                         編集</Link>);
                    },
                },
           
        },
        {
            "name" :'person_id',
            "label" : '要員ID'
        },
        {
            "name" : 'sal_yyyy_mm',
            "label" : '給与対象年月'
        },
        {
            "name" : 'emp_id',
            "label" : '社員ID'
        },
        {
            "name" :'org_name',
            "label": '所属'
        },
        {
            "name" : 'emp_name',
            "label" : '氏名',
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"80px"}}>
                            {value}
                        </div>
                        );
                    },
                },
        },
        {
            "name" : 'emp_kana',
            "label": '氏名カナ',
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"80px"}}>
                            {value}
                        </div>
                        );
                    },
                },
        },
        {
            "name" : 'worked_days',
            "label" : '出勤日数',
            options:{
                setCellProps: () => ({ style: { minWidth: "100px"}}),
            }
        },
        {
            "name" : 'holidaywork_days',
            "label" : '休日出勤日数'
        },
        {
            "name" : 'substitute_holiday',
            "label" : '振替休暇'
        },
        {
            "name" : 'days_absence',
            "label" : '欠勤日数',
            options:{
                setCellProps: () => ({ style: { minWidth: "100px"}}),
            }
        },
        {
            "name" : 'late_early_time',
            "label" : '遅刻早退'
        },
        {
            "name" :  'paid_holidays',
            "label" : '有給休暇'
        },
        {
            "name" : 'holidays_remain',
            "label" : '有給休暇残',
            options:{
                setCellProps: () => ({ style: { minWidth: "100px"}}),
            }
        },
        {
            "name" : 'worked_time',
            "label": '勤務時間',
            options:{
                setCellProps: () => ({ style: { minWidth: "100px"}}),
            }
        },
        {
            "name" : 'overtime',
            "label": '普通残業'
        },
        {
            "name" : 'overtime_flb',
            "label": '普通残業（自社勤務）'
        },
        {
            "name" : 'hourlywage_flb',
            "label": '自社勤務時間単金'
        },
        {
            "name" : 'mn_overtime',
            "label" : '深夜残業時間'
        },
        {
            "name" : 'worked_holidays',
            "label" : '休日出勤時間',
            options:{
                setCellProps: () => ({ style: { minWidth: "100px"}}),
            }
        },
        {
            "name" : 'mn_overtime_hol',
            "label": '休日深夜時間',
            options:{
                setCellProps: () => ({ style: { minWidth: "100px"}}),
            }
        },
        {
            "name" : 'work_rate',
            "label": '稼働率',
            options:{
                setCellProps: () => ({ style: { minWidth: "100px"}}),
            }
        },
        {
            "name" :  'base_sal',
            "label": '基本給',
            options:{
                setCellProps: () => ({ style: { minWidth: "100px"}}),
            }
        },
        {
            "name" : 'job_allow',
            "label": '職務手当',
            options:{
                setCellProps: () => ({ style: { minWidth: "100px"}}),
            }
        },
        {
            "name" : 'mng_duty_allow',
            "label": '管理職手当',
            options:{
                setCellProps: () => ({ style: { minWidth: "100px"}}),
            }
        },
        {
            "name" : 'housing_allow',
            "label" : '住宅手当',
            options:{
                setCellProps: () => ({ style: { minWidth: "100px"}}),
            }
        },
        {
            "name" : 'qual_allow',
            "label": '資格手当',
            options:{
                setCellProps: () => ({ style: { minWidth: "100px"}}),
            }
        },
        {
            "name" : 'adjust_allow',
            "label": '調整手当',
            options:{
                setCellProps: () => ({ style: { minWidth: "100px"}}),
            }
        },
        {
            "name" : 'fixed_overtime_allow',
            "label": '固定残業手当',
            options:{
                setCellProps: () => ({ style: { minWidth: "130px"}}),
            }
        },
        {
            "name" :  'overtime_allow',
            "label" : '残業代',
            options:{
                setCellProps: () => ({ style: { minWidth: "100px"}}),
            }
        },
        {
            "name" : 'travel_expense',
            "label": '交通費',
            options:{
                setCellProps: () => ({ style: { minWidth: "100px"}}),
            }
        },
        {
            "name" : 'commute_allow',
            "label": '通勤手当'
        },
        {
            "name" : 'taxable_amnt',
            "label" : '課税支給額'
        },
        {
            "name" : 'taxexemption_amnt',
            "label": '非課税支給額'
        },
        {
            "name" : 'pay_amnt',
            "label" : '総支給額'
        },
        {
            "name" : 'health_ins_1',
            "label": '健康保険1'
        },
        {
            "name" : 'emp_pension_1',
            "label": '厚生年金1'
        },
        {
            "name" : 'emp_ins_1',
            "label": '雇用保険1'
        },
        {
            "name" : 'ins_total_1',
            "label": '社会保険合計1',
            options:{
                setCellProps: () => ({ style: { minWidth: "120px"}}),
            }
        },
        {
            "name" : 'health_ins_2',
            "label": '健康保険2',
            options:{
                setCellProps: () => ({ style: { minWidth: "100px"}}),
            }
        },
        {
            "name" : 'emp_pension_2',
            "label": '厚生年金2',
            options:{
                setCellProps: () => ({ style: { minWidth: "100px"}}),
            }
        },
        {
            "name" : 'emp_ins_2',
            "label" : '雇用保険2'
        },
        {
            "name" : 'ins_total_2',
            "label": '社会保険合計2'
        },
        {
            "name" : 'ins_calc_flg',
            "label" : '社会保険計算フラグ',
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                            {value}
                        </div>
                        );
                    },
                },
        },
        {
            "name" : 'health_ins',
            "label" : '健康保険'
        },
        {
            "name" : 'emp_pension',
            "label" : '厚生年金'
        },
        {
            "name" : 'emp_ins',
            "label" : '雇用保険'
        },
        {
            "name" : 'ins_total',
            "label": '社会保険合計'
        },
        {
            "name" : 'health_welfare_total',
            "label" : '健康・厚生年金合計額'
        },
        {
            "name" : 'taxable_net_amnt',
            "label": '課税対象額'
        },
        {
            "name" : 'income_tax',
            "label" : '所得税'
        },
        {
            "name" : 'inhabitant_tax',
            "label": '住民税'
        },
        {
            "name" : 'deduction_total',
            "label" : '控除合計'
        },
        {
            "name" : 'expense',
            "label" : '経費精算'
        },
        {
            "name" : 'taxrefund',
            "label" : '所得税還付'
        },
        {
            "name" :  'net_paid_amt',
            "label" : '差引支給額'
        },
        {
            "name" : 'approv_status',
            "label" : '承認ステータス'
        },
        {
            "name" : 'send_status',
            "label" : '明細送付ステータス'
        },
        {
            "name" : 'overtime_flg',
            "label": '残業フラグ'
        },
        {
            "name" : 'salery_email',
            "label" : 'メール',
            options: {
                display: false,
              }
        },
        {
            "name" : 'send', 
            "label" : "送付",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                  if(tableMeta.rowData[54]=="承認済み" && tableMeta.rowData[55]!="送付済"){
                    return(
                        <button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                           sendSalaryDetail(`${tableMeta.rowData[6]}`,`${tableMeta.rowData[57]}`,`${tableMeta.rowData[3]}`,`${tableMeta.rowData[1]}`,`${tableMeta.rowData[2]}`);
                           }}>送付
                        </button>
                        );
                    }
                  }
                     }
            }
    ]
 
    useEffect(()=>{
        setState({...state, loading: true});
        getData(emp_name,period_start,period_end,approve_status, perPage, page, search, sort, column, order);
    }, [perPage]);
    let changePage = (page:any) => {
        setCurrentPage(page);
        getData(emp_name,period_start,period_end,approve_status, perPage, page+1, search, sort, column, order);
    };
    let changeRowsPerPage = (perPage:any) => {
        getData(emp_name,period_start,period_end,approve_status, perPage, page, search, sort, column, order);
    };
    let handleSort = (column:any, order:any) => {
        getData(emp_name,period_start,period_end,approve_status, perPage, page, search, sort, column, order);
    };

    const sendSalaryDetail = function(emp_name:any, email:any,emp_id:any,person_id:any,sal_yyyy_mm:any){
        var confirmMsg = '「' + emp_name + '」の給料明細を「' + email + '」に送信しますか？';
        var errorMsg = '「' + emp_name + '」の給料明細を「' + email + '」に送信失敗しました';
        let datas = {};
        datas["emp_id"] = emp_id;
        datas["person_id"] = person_id;
        datas["sal_yyyy_mm"] = sal_yyyy_mm;
        let ans = window.confirm(confirmMsg);
        if(ans){
            SaleryDetailService.sendSalaryDetail(datas).then((data)=>{
                if(data.data.data.status == "success"){
                    alert(data.data.data.msg);
                    window.location.href = "/salery/detail";
                }else{
                    alert(errorMsg);
                }
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
            })
        }
    }

    const deleteSaleryDetail = () => {
        if(selectedRows.length == 0){
            alert("削除したい給料明細情報をチェックしてください。");
            return false;
        }
        let ans = window.confirm("削除を実施します、大丈夫ですか？");
        if(ans){
            let ids : any[] = [];
            salerys.forEach((element, index) => {            
                if (selectedRows.length > 0 && selectedRows.includes(index)) {
                    ids.push(element["person_id"]+":"+element["sal_yyyy_mm"]+":"+element["emp_id"]);
                }
            });
    
            let data = {};
            data["ids"] = ids;
            SaleryDetailService.delete(data).then((data)=>{
                if(data.data.success == false){
                    alert(data.data.info.message);
                }
                window.location.reload();
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
            })
        }
    }
    let downloadURL = process.env.REACT_APP_DOWNLOADURL;
    const csvDownload = () => {
        if(selectedRows.length >0){

            let ids : any[] = [];
            salerys.forEach((element, index) => {            
                if (selectedRows.length > 0 && selectedRows.includes(index)) {
                    ids.push(element["person_id"]+":"+element["sal_yyyy_mm"]+":"+element["emp_id"]);
                }
            });

            let data = {}
            data["ids"] = ids;
            SaleryDetailService.yoyoicsvfile(data).then((response:any)=>{
                if(response.data.data.status == "error"){
                    alert(response.data.data.msg);
                }else{
                    SaleryDetailService.dlCSVFile().then((response:any)=>{
                        window.open(`${downloadURL}${response.data.path}`);
                    })
                }
                
            }).catch((error:any) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
                setState({
                    ...state,
                    errorMessage:error.message
                })
            })
        }
    }


    const options = {
        filterType: 'checkbox' as any,
        tableBodyHeight : "360px",
        tableBodyMaxHeight : "360px",
        responsive: "standard" as any,
        selectableRows: "multiple" as any,
        selectableRowsHeader: true as any, 
        rowsPerPage: perPage,
        page : page,
        rowsPerPageOptions:[10,20,50,100,200],
        // selectableRowsOnClick: true  as any,
        count: totalRows,
        serverSide: true,
        filter : false,
        print: false,
        viewColumns : false,
        sort: true,
        search : false,
        download :false,
        // resizableColumns : true,
        customToolbarSelect: (selectedRows:any, displayData:any, setSelectedRows:any) => {         
                let rows : any[] = [];
                selectedRows.data.forEach((element:any, index:any) => {
                    rows.push(element.dataIndex);
                })
                let csvData : any[] = [];
                salerys.forEach((element, index) => {
                        if (rows.includes(index)) {
                            csvData.push(element);
                        }
                });
            const getFileName = () => {
                    let now = new Date().toLocaleDateString();
                    let year = now.slice(0,4);
                    let month = '0' + now.slice(5,6);
                    let day = now.slice(7);
                    let date = year+month+day;
                    return "給料明細情報" +date;
                }
            let headers = [
                {
                    "key" :'person_id',
                    "label" : '要員ID'
                },
                {
                    "key" : 'sal_yyyy_mm',
                    "label" : '給与対象年月'
                },
                {
                    "key" : 'emp_id',
                    "label" : '社員ID'
                },
                {
                    "key" :'org_name',
                    "label": '所属'
                },
                {
                    "key" : 'emp_name',
                    "label" : '氏名'
                },
                {
                    "key" : 'emp_kana',
                    "label": '氏名カナ'
                },
                {
                    "key" : 'worked_days',
                    "label" : '出勤日数'
                },
                {
                    "key" : 'holidaywork_days',
                    "label" : '休日出勤日数'
                },
                {
                    "key" : 'substitute_holiday',
                    "label" : '振替休暇'
                },
                {
                    "key" : 'days_absence',
                    "label" : '欠勤日数'
                },
                {
                    "key" : 'late_early_time',
                    "label" : '遅刻早退'
                },
                {
                    "key" :  'paid_holidays',
                    "label" : '有給休暇'
                },
                {
                    "key" : 'holidays_remain',
                    "label" : '有給休暇残'
                },
                {
                    "key" : 'worked_time',
                    "label": '勤務時間'
                },
                {
                    "key" : 'overtime',
                    "label": '普通残業'
                },
                {
                    "key" : 'overtime_flb',
                    "label": '普通残業（自社勤務）'
                },
                {
                    "key" : 'hourlywage_flb',
                    "label": '自社勤務時間単金'
                },
                {
                    "key" : 'mn_overtime',
                    "label" : '深夜残業時間'
                },
                {
                    "key" : 'worked_holidays',
                    "label" : '休日出勤時間',
                    options:{
                        setCellProps: () => ({ style: { minWidth: "130px",maxWidth: "150px"}}),
                    }
                },
                {
                    "key" : 'mn_overtime_hol',
                    "label": '休日深夜時間'
                },
                {
                    "key" : 'work_rate',
                    "label": '稼働率'
                },
                {
                    "key" :  'base_sal',
                    "label": '基本給'
                },
                {
                    "key" : 'job_allow',
                    "label": '職務手当'
                },
                {
                    "key" : 'mng_duty_allow',
                    "label": '管理職手当'
                },
                {
                    "key" : 'housing_allow',
                    "label" : '住宅手当'
                },
                {
                    "key" : 'qual_allow',
                    "label": '資格手当'
                },
                {
                    "key" : 'adjust_allow',
                    "label": '調整手当'
                },
                {
                    "key" : 'fixed_overtime_allow',
                    "label": '固定残業手当'
                },
                {
                    "key" :  'overtime_allow',
                    "label" : '残業代'
                },
                {
                    "key" : 'travel_expense',
                    "label": '交通費'
                },
                {
                    "key" : 'commute_allow',
                    "label": '通勤手当'
                },
                {
                    "key" : 'taxable_amnt',
                    "label" : '課税支給額'
                },
                {
                    "key" : 'taxexemption_amnt',
                    "label": '非課税支給額'
                },
                {
                    "key" : 'pay_amnt',
                    "label" : '総支給額'
                },
                {
                    "key" : 'health_ins_1',
                    "label": '健康保険1'
                },
                {
                    "key" : 'emp_pension_1',
                    "label": '厚生年金1'
                },
                {
                    "key" : 'emp_ins_1',
                    "label": '雇用保険1'
                },
                {
                    "key" : 'ins_total_1',
                    "label": '社会保険合計1'
                },
                {
                    "key" : 'health_ins_2',
                    "label": '健康保険2'
                },
                {
                    "key" : 'emp_pension_2',
                    "label": '厚生年金2'
                },
                {
                    "key" : 'emp_ins_2',
                    "label" : '雇用保険2'
                },
                {
                    "key" : 'ins_total_2',
                    "label": '社会保険合計2'
                },
                {
                    "key" : 'ins_calc_flg',
                    "label" : '社会保険計算フラグ'
                },
                {
                    "key" : 'health_ins',
                    "label" : '健康保険'
                },
                {
                    "key" : 'emp_pension',
                    "label" : '厚生年金'
                },
                {
                    "key" : 'emp_ins',
                    "label" : '雇用保険'
                },
                {
                    "key" : 'ins_total',
                    "label": '社会保険合計'
                },
                {
                    "key" : 'health_welfare_total',
                    "label" : '健康・厚生年金合計額'
                },
                {
                    "key" : 'taxable_net_amnt',
                    "label": '課税対象額'
                },
                {
                    "key" : 'income_tax',
                    "label" : '所得税'
                },
                {
                    "key" : 'inhabitant_tax',
                    "label": '住民税'
                },
                {
                    "key" : 'deduction_total',
                    "label" : '控除合計'
                },
                {
                    "key" : 'expense',
                    "label" : '経費精算'
                },
                {
                    "key" :  'net_paid_amt',
                    "label" : '差引支給額'
                },
                {
                    "key" : 'approv_status',
                    "label" : '承認ステータス'
                },
                {
                    "key" : 'send_status',
                    "label" : '明細送付ステータス'
                },
                {
                    "key" : 'overtime_flg',
                    "label": '残業フラグ'
                }
              ];
            var now = new Date();
            return (
                <CSVLink  className="btn_standard text-decoration-none" filename={getFileName()} data={csvData}  headers={headers}>ダウンロード</CSVLink>
            );
          },
        textLabels: {
            body: {
              noMatch: "データはありません。",
              toolTip: "Sort"
            },
            pagination: {
              next: "Next Page",
              previous: "Previous Page",
              rowsPerPage: "件表示", 
              displayRows: "すべての件数：",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "行 を選択しました。",
                delete: "削除",
                deleteAria: "選択した行を削除しました。",
              },
          },
        rowsSelected: selectedRows,
        onRowSelectionChange: (rowsSelectedData:any, allRows:any, rowsSelected:any) => {
            setSelectedRows(rowsSelected);
        },
        onChangePage (currentPage:any) {
            changePage(currentPage);
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage (numberOfRows:any) {
            changeRowsPerPage(numberOfRows);
            setNoOfRows(numberOfRows);
        },
        onColumnSortChange: (changedColumn:any, direction:any) => {
            let order = 'desc';
            if (direction === 'asc') {
              order = 'asc';
            }
            handleSort(changedColumn, order);
          },
    };
 
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => { 
        switch (e.target.name) {
            case "search":
                    setSearch(e.target.value);
                    getData(emp_name,period_start,period_end,approve_status, perPage, page, e.target.value, sort, column, order);
                    break;
            default:
                break;
        }
      };
      const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        switch (e.target.name) {
            case "emp_name":
                setEmpName(e.target.value);
                getData(e.target.value,period_start,period_end,approve_status, perPage, page, search, e.target.value, column, order);
                break;
            case "approve_status":
                setApproveStatus(e.target.value);
                getData(emp_name,period_start,period_end,e.target.value, perPage, page, search, e.target.value, column, order);
                break;
            case "sort":
                setSort(e.target.value);
                getData(emp_name,period_start,period_end,approve_status, perPage, page, search, e.target.value, column, order);
                break;
            case "list_length":
                changeRowsPerPage(e.target.value);
                setNoOfRows(parseInt(e.target.value));
                setState({
                    ...state,
                    perPage : parseInt(e.target.value)

                })
                break;
                
            default:
                break;
        }
     }; 

    const handleDateChange = function(date:any, column_name:any){
        // let selected_data = date.getFullYear() +"-"+ (date.getMonth() + 1).toString().padStart(2, "0");
        let selected_data = (date!=null)?date.getFullYear() +"-"+ (date.getMonth() + 1).toString().padStart(2, "0"):"";
        switch (column_name) {
            case "period_start":
                setPeriodStart(selected_data);
                setSelectedPeriodStart(date);
                getData(emp_name,selected_data,period_end,approve_status, perPage, page, search, sort, column, order);
                break;
            case "period_end":
                setPeriodEnd(selected_data);
                setSelectedPeriodEnd(date);
                getData(emp_name,period_start,selected_data,approve_status, perPage, page, search, sort, column, order);
                break;
            default:
                break;
        }

    }

    let nextPage = () => {
        
        // if(Math.floor(totalRows/perPage) >0){
        //     setShowNextColor("blue");
        // }
    
        if(Math.floor(totalRows/perPage) >0){
            changePage(current_page+1);
            setCurrentPage(current_page+1);
            setState({
                ...state,
                page : current_page+1
            })
            setShowNextColor("blue");
        }
        
        if(Math.ceil(totalRows/perPage)-2 == current_page){ // Last Page
            alert("grey");
            setShowNextColor("grey");
        }   
        if(0 < current_page+1){
            setShowPrevColor("blue");
        }     
    
    }
    
    let previousPage = () => {
        if(current_page > 0){
            setCurrentPage(current_page-1);
            changePage(current_page - 1);
            setState({
                ...state,
                page : current_page-1
            });
            setShowPrevColor("blue");
        }
        if(0 == current_page-1){
            setShowPrevColor("grey");
        } 
    
        if(Math.floor(totalRows/perPage) >0){
            setShowNextColor("blue");
        } 
        
    }
     const getMuiTheme = () =>
     createTheme({
         components: {
         MUIDataTable: {
             styleOverrides:{
                 responsiveScroll: {
                     maxHeight: 'none',
                   },
             }
         },
         MuiPaper: {
             styleOverrides:{
                 root: {
                     width: "100%",
                    //  marginTop: "5%",
                    //  marginLeft: "5%",
                    //  marginBottom: "20%"
                 }
             }
         },  
         MUIDataTableHeadCell: {
                 styleOverrides:{
                   root: {
                       backgroundColor: "#444",
                       padding: '0px'
                   }
                 }
         },
         MuiTableCell: {
             styleOverrides:{
                 head: {
                         color : 'white'
                 }
             }
         },
           MUIDataTableBodyCell: {
             styleOverrides:{
               root: {
                   backgroundColor: "#f1f1f1",
                   width: "100%",
                   padding: '0px'
               }
             }
           }
         }
       })
     
    
    return(
        
       <React.Fragment>
       <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                    ml: sizeConfigs.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color
                }}
                >
                 <Toolbar>
                <Typography variant="h6">
                    <header className="top-baner">
                        <Row>
                            <Col xxl={10} xl={10} md={10} sm={10} xs={10}>
                                <div id="search-autocomplete" className="form-outline">
                                    <input type="text" name="search" value={search} id="search" placeholder = "検索(所属、漢字氏名、カタカナ氏名を検索)"
                                        className="form-control"  onChange={onChange}/>
                                </div>
                            </Col>
                            <Col xxl={2} xl={2} md={2} sm={2} xs={2} className="mt-4">
                                <DropDownList />
                            </Col>
                        </Row>
                    </header>
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container>
                    <Row>
                        <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                            <h3 className='header-left-design'>
                                給料明細一覧
                            </h3>
                        </Col>
                        <Col xxl={4} xl={4} md={4} sm={4} xs={2}>
                            <div className="mt-3 text-center">
                                {( loading   == true) ? <Loader /> :<> </>}
                            </div>
                        </Col>
                        <Col xxl={4} xl={4} md={4} sm={4} xs={4} className="float-end">
                            <div className="mt-4">
                                <button type="button" className="btn_danger float-right btn-sm ml_1"  onClick={deleteSaleryDetail}>削除</button>
                            </div>
                        </Col>
                    </Row> 
                    <Row className="check-list">
                        <Col xxl={2} xl={2} md={2} sm={2} xs={6}>
                            <label>対象期間</label>
                            <DatePicker 
                                    value={(selected_period_start != null)?selected_period_start: moment().subtract(1, 'months').format('YYYY-MM')}
                                    dateFormat="yyyy-MM"
                                    locale="ja"
                                    selected={selected_period_start} 
                                    onChange={date =>
                                        handleDateChange(date, "period_start")
                                    }
                                    name="period_start"
                                    isClearable
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    />
                        </Col>
                        <Col xxl={2} xl={2} md={2} sm={2} xs={6}>
                            <label>在職年月</label>
                            <DatePicker 
                                    dateFormat="yyyy-MM"
                                    locale="ja"
                                    selected={selected_period_end} 
                                    onChange={date =>
                                        handleDateChange(date, "period_end")
                                    }
                                    name="period_end"
                                    isClearable
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    />      
                        </Col>
                        <Col xxl={2} xl={2} md={2} sm={2} xs={6}>
                            <label className="control-label">対象者</label><br/>
                            <select name="emp_name" className="filter" onChange={onSelect} value={emp_name} aria-label=".form-select-sm example">
                                <option value=""> </option>
                                {employee_list.map(employee => {
                                        if (employee !== null){
                                            return(<option value={employee}> {employee}</option>)
                                        }
                                    })
                                }
                            </select>    
                        </Col>
                        <Col xxl={2} xl={2} md={2} sm={2} xs={6}>
                            <label className="control-label">承認ステータス</label><br/>
                            <select name="approve_status" className="filter" onChange={onSelect} value={approve_status} aria-label=".form-select-sm example">
                                <option value=""> </option>
                                {approve_status_list.map(approve_status => {
                                        if (approve_status !== null){
                                            return(<option value={approve_status[0]}> {approve_status[1]}</option>)
                                        }
                                    })
                                }
                            </select> 
                        </Col>
                    </Row>
                    <Row className="pt-2 pb-2">
                        <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                            <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                            <select name="sort" id="sort" className="filter" onChange={onSelect}>
                                <option value="0" selected>新しい順</option>
                                <option value="1">古い順</option>
                            </select>
                        </Col>
                        <Col xxl={8} xl={8} md={8} sm={8} xs={6} className="filter_select">
                                <div className="float-end">
                                <button type="button" className="btn_danger  btn-sm ml_1"  onClick={csvDownload}>CSVファイルの作成</button>
                                    <select name="list_length" id="list_length" value={no_of_rows} className="filter list_length" onChange={onSelect}>
                                        <option value="10">10</option>
                                        <option value="20" >20</option>
                                        <option value="50" >50</option>
                                        <option value="100" >100</option>
                                        <option value="200" >200</option>
                                    </select>
                                    <label htmlFor="sort" className="control-label">件表示</label>
                                    <button type="button" className="btn-sm previous-page-icon" style={{border:"0px"}} onClick={() => {
                                                previousPage();
                                                }}>
                                        <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                    </button>
                                    <button type="button" className=" btn-sm next-page-icon" style={{border:"0px"}} onClick={() => {
                                                nextPage();
                                                }}>
                                        <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                                    </button> 
                                </div>
                            </Col>
                    </Row>
                    <div className='datatable freeze-salery-detail-header freeze-salery-detail-cell freeze-salery-detail-frozen-columns xs-hidden'>
                        <MUIDataTable
                            title = {""}
                            data = {salerys}
                            columns={defaultColumns}
                            options={options}
                        />
                    </div>
                    <div className='datatable mobile pc-hidden'>
                        <MUIDataTable
                            title = {""}
                            data = {salerys}
                            columns={defaultColumns}
                            options={options}
                        />
                    </div>
            </Container>
       
        </React.Fragment>
    )

}

export default SaleryDetailList;