import React, {useState , useEffect} from 'react';
// import {User} from "../../models/User";
import HRService from "../../services/HRService";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DatePicker from "react-datepicker";
import DropDownList from "../../components/common/DropDownList";
import { CSVLink, CSVDownload } from "react-csv";
import $ from 'jquery';
import moment from 'moment';
import LineChart from "../../components/common/LineChart";
import BarChart from "../../components/common/BarChart";
import {Data} from "../../components/common/Data";
import {checkEmpty} from "../../components/common/Helpers";
import PageTitle from "../../components/common/PageTitle";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface IState{
    loading :boolean;
    hrs :  [];
    errorMessage : string;  
    perPage : number;
    page : number;
    column: string;
    order: string;
    totalRows : number;
    representative_list : [];
}
interface IProps{}

let HRPerformanceList:React.FC<IProps> = () =>{
    
    let [state, setState] = useState<IState>({
        loading : false,
        hrs :  [],
        errorMessage : "",
        page : 0,
        perPage : 10,
        column : "",
        order : "",
        totalRows : 0,
        representative_list : []
    });
    const [search, setSearch] = useState("");  
    const [company_filter, setCompanyFilter] = useState("");  
    const [representative_filter, setRepresentativeFilter] = useState(""); 
     
    const [selectedTargetDate, setSelectedTargetDate] = useState(null);
    const [target_month, setTargetMonth] = useState("");

    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [start_date, setStartDate] = useState("");

    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [end_date, setEndDate] = useState("");

    const [assignment_status , setAssignmentStatus] = useState("");
    const [propose_status , setProposeStatus] = useState("");
    const [showPrevColor, setShowPrevColor] = useState("grey");
    const [showNextColor, setShowNextColor] = useState("grey");

    const defaultColumns = [
        {
            "name" : 'target_month', 
            "label" : "対象月",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                            {value}
                        </div>
                        );
                    },
            }
        },
        {
            "name" : 'company', 
            "label" : "所属会社名",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                            {value}
                        </div>
                        );
                    },
            }
        },
        {
            "name" : 'user_id', 
            "label" : "利用者ID"
        },
        {
            "name" : 'name_jp',
            "label" : "担当者名",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                            {value}
                        </div>
                        );
                    },
            }
        },
        {
            "name" : 'create_date', 
            "label" : "担当開始日",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                            {value}
                        </div>
                        );
                    },
            }
        },
        {
            "name" : 'person_count', 
            "label" : "要員登録数",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div style={{width:"80px"}}>
                            {value}
                        </div>
                        );
                    },
            }
        },//
        {
            "name" : 'project_incharge_count', 
            "label" : "案件HR担当数"
        },
        {
            "name" : 'assign_user_count',
            "label" : "割り当て数"
        },
        {
            "name" : 'case_count', 
            "label" : "提案された数"
        },
        {
            "name" : 'interview_user_count', 
            "label" : "面談された数"
        },
        {
            "name" : 'pass_user_count', 
            "label" : "合格された数"
        },
    ]

    const [chartData, setChartData] = useState({
        labels: Data.map((data) => data.year.toString()), 
        datasets: [
          {
            label: "Users Gained ",
            data: Data.map((data) => data.userGain)
          }
        ]
      });

    const [chartData2, setChartData2] = useState({
        labels: Data.map((data) => data.year.toString()), 
        datasets: [
          {
            label: "Users Gained ",
            data: Data.map((data) => data.userGain)
          }
        ]
      });
    const [chartData3, setChartData3] = useState({
        labels: Data.map((data) => data.year.toString()), 
        datasets: [
            {
            label: "Users Gained ",
            data: Data.map((data) => data.userGain)
            }
        ]
    });

    const getData = async (company_filter:string, representative_filter:string, target_month:any, start_date:any, end_date:any, perPage:number, page:number, search:any, 
        sort:any, column:string, order:string, assignment_status:any, propose_status:any) => {
        setState({
            ...state,
            loading :true,
        })
        HRService.hrPerformance(company_filter, representative_filter, target_month, start_date, end_date, perPage, page, search, sort, 
            column, order, assignment_status, propose_status).then((response)=>{
            setState({
                ...state,
                loading:false,
                hrs:response.data.data,
                page : response.data.current_page,
                column : response.data.column,
                order : response.data.order,
                perPage : response.data.per_page,
                totalRows : response.data.total,
                representative_list : response.data.representatives,
            });

            let color = [
                'rgb(197, 72, 72)',
                'rgb(197, 135, 72)',
                'rgb(127, 191, 63)',
                'rgb(38, 38, 12)',
                'rgb(63, 63, 191)',
                'rgb(191, 63, 191)',
                'rgb(63, 191, 191)',
                'rgb(191, 191, 63)',
                'rgb(63, 63, 191)',
                'rgb(5, 152, 5)'
            ];
            var labels_fordiagram1 = ["要員登録数", "案件HR担当数", "割り当て数", "提案された数", "面談された数", "合格された数"]
            let datas = response.data.data;
            let user_arr:any[]  = [];

            //Diagram 1
            let person_count_arr:any[]  = [];
            let project_incharge_count_arr:any[]  = [];
            let assign_user_count_arr:any[]  = [];
            let case_count_arr:any[]  = [];
            let interview_user_count_arr:any[]  = [];
            let pass_user_count_arr:any[]  = [];
            let user_name_arr:any[]  = [];
            let data_fordiagram1:any[] = [];

            $.each(datas, function(index, value) {
                if (!user_arr.includes(value.user_id)) {
                let obj = {};
                obj['label'] = value.name_jp;
                obj['data'] = [
                        value.person_count,
                        value.project_incharge_count,
                        value.assign_user_count,
                        value.case_count,
                        value.interview_user_count,
                        value.pass_user_count
                    ];
                obj['backgroundColor'] =  color[index];
                obj['borderColor']  = color[index];
                obj['yAxisID'] = 'y-axis-density';
                data_fordiagram1.push(obj);

                let person_count,project_incharge_count,assign_user_count,case_count,interview_user_count,pass_user_count;
                person_count = checkEmpty(value.person_count);
                project_incharge_count = checkEmpty(value.project_incharge_count);
                assign_user_count = checkEmpty(value.assign_user_count);
                case_count = checkEmpty(value.case_count);
                interview_user_count = checkEmpty(value.interview_user_count);
                pass_user_count = checkEmpty(value.pass_user_count);

                person_count_arr.push(person_count);
                project_incharge_count_arr.push(project_incharge_count);
                assign_user_count_arr.push(assign_user_count);
                case_count_arr.push(case_count);
                interview_user_count_arr.push(interview_user_count);
                pass_user_count_arr.push(pass_user_count);
                user_name_arr.push(value.name_jp);
            }
                user_arr.push(value.user_id);        
            });
            setChartData({
                labels: labels_fordiagram1,
                datasets: data_fordiagram1
              });


            //Diagram 2
            let labels_fordiagram2;
            let data_fordiagram2:any[] = [];
            let dig2_data = [person_count_arr,project_incharge_count_arr,assign_user_count_arr,case_count_arr,interview_user_count_arr,pass_user_count_arr];

            for(var i=0; i<labels_fordiagram1.length; i++) {
                let obj1 = {};
                obj1['label'] = labels_fordiagram1[i];
                obj1['data']  = dig2_data[i];
                obj1['backgroundColor'] =  color[i];
                obj1['borderColor']  = color[i];
                obj1['yAxisID'] = 'y-axis-density';
                data_fordiagram2.push(obj1);
            }
            labels_fordiagram2 = user_name_arr;

            setChartData2({
                labels: labels_fordiagram2,
                datasets: data_fordiagram2
            });

            //Diagram 3

            let person_count_data=  response.data.person_count;
            let project_incharge_count_data = response.data.project_incharge_count;
            let case_count_data = response.data.case_coun;
            let assign_user_count_data = response.data.assign_user_count;
            let interview_user_count_data = response.data.interview_user_count;
            let pass_user_count_data = response.data.pass_user_count;
        
            let propose_status = response.data.$propose_status;
            let registered_start_month = Number(response.data.propose_start_month);
            let registered_start_year = Number(response.data.propose_start_year);
            let user_arr_1:any[]  = [];
            let person_count_arr_1:any[] = [];
            let project_incharge_count_arr_1:any[] = [];
            let case_count_arr_1:any[] = [];
            let assign_user_count_arr_1:any[] = [];
            let interview_user_count_arr_1:any[] = [];
            let pass_user_count_arr_1:any[] = [];
            let user_name_arr_1:any[] = [];
            let labels_fordiagram3:any[] = [];
            let data_fordiagram3:any[] = [];
            var now = new Date();
            var future = new Date(now.setMonth(now.getMonth() + 1, 1));
            
            // var now = new Date();
            // var future = Number(now.setMonth(now.getMonth() + 1, 1));
            // var start = Number(new Date(registered_start_year, registered_start_month));
            for (var d = new Date(registered_start_year, registered_start_month); d <= future; d.setMonth(d.getMonth() + 1)) {
                var p_found = 0;
                var in_found = 0;
                var case_found = 0;
                var assign_user_found = 0;
                var interview_user_found = 0;
                var pass_user_found = 0;
                var date = new Date(d);
                
                var month:any = date.getMonth().toString();
                var year:any = date.getFullYear().toString();
                if(date.getMonth() === 0) {
                    month = "12";
                    year =  (year - 1);
                }
                if(month < 10) {
                    month = '0' + month;
                }
                var label = year+'-'+ month;
                labels_fordiagram3.push(label);
        
        
                $.each(person_count_data, function(index, value) {
                    if (label === index) {
                        p_found = 1;
                        person_count_arr_1.push(value.length);  
                    }        
                });
                
                if(p_found == 0) {
                    person_count_arr_1.push(0);            
                }
        
                $.each(project_incharge_count_data, function(index, value) {
                    if (label === index) {
                        in_found = 1;
                        project_incharge_count_arr_1.push(value.length);
                    }
                    });
        
                if(in_found == 0) {
                    project_incharge_count_arr_1.push(0);            
                }
        
                $.each(case_count_data, function(index, value) {
                    if (label === index) {
                        case_found = 1;
                        case_count_arr_1.push(value.length);
                    }
                    });
        
                if(case_found == 0) {
                    case_count_arr_1.push(0);            
                }
        
                $.each(assign_user_count_data, function(index, value) {
                    if (label === index) {
                        assign_user_found = 1;
                        assign_user_count_arr_1.push(value.length);
                    }
                    });
        
                if(assign_user_found == 0) {
                    assign_user_count_arr_1.push(0);            
                }
        
                $.each(interview_user_count_data, function(index, value) {
                    if (label === index) {
                        interview_user_found = 1;
                        interview_user_count_arr_1.push(value.length);
                    }
                    });
        
                if(interview_user_found == 0) {
                    interview_user_count_arr_1.push(0);            
                }
        
                $.each(pass_user_count_data, function(index, value) {
                    if (label === index) {
                        pass_user_found = 1;
                        pass_user_count_arr_1.push(value.length);
                    }
                    });
        
                if(pass_user_found == 0) {
                    pass_user_count_arr_1.push(0);            
                }

                
                }
            
        
            let dig3_data = [person_count_arr_1,project_incharge_count_arr_1,case_count_arr_1,assign_user_count_arr_1,interview_user_count_arr_1,pass_user_count_arr_1];
            for(var i=0; i<labels_fordiagram1.length; i++) {
                let obj2 = {};
                obj2['label'] = labels_fordiagram1[i];
                obj2['data']  = dig3_data[i];
                obj2['backgroundColor'] =  'rgba(0, 0, 0, 0)';
                obj2['borderColor']  = color[i];
                obj2['yAxisID'] = 'y-axis-density';
                data_fordiagram3.push(obj2);
            }

            setChartData3({
                labels: labels_fordiagram3,
                datasets: data_fordiagram3
              });
        



        }).catch((error) => {
            console.log(error);
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
            setState({
                ...state,
                loading:false,
                errorMessage:error.message
            })
        })
    }   

        
    let {loading, hrs, perPage, page, column, order, totalRows, representative_list, errorMessage} = state;
    const [selected, setSelected] = useState('base');
    const [sort, setSort] = useState("0");
    const [no_of_rows, setNoOfRows] = useState(10);
    const [current_page, setCurrentPage] = useState(0);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);

    useEffect(()=>{
        setState({...state, loading: true});
        getData(company_filter, representative_filter, target_month, start_date, end_date, perPage, page, search, sort, column, order, assignment_status, propose_status);
    }, [perPage]);
    let changePage = (page:any) => {
        setCurrentPage(page);
        getData(company_filter, representative_filter, target_month, start_date, end_date, perPage, page+1, search, sort, column, order, assignment_status, propose_status);
    };
    let changeRowsPerPage = (perPage:any) => {
        getData(company_filter, representative_filter, target_month, start_date, end_date, perPage, page, search, sort, column, order, assignment_status, propose_status);
    };
    let handleSort = (column:any, order:any) => {
        getData(company_filter, representative_filter, target_month, start_date, end_date, perPage, page, search, sort, column, order, assignment_status, propose_status);
    };

    const options = {
        filterType: 'checkbox' as any,
        tableBodyHeight : "360px",
        tableBodyMaxHeight : "360px",
        responsive: "standard" as any,
        selectableRows: "multiple" as any,
        selectableRowsHeader: true as any, 
        rowsPerPage: perPage,
        page: page,
        rowsPerPageOptions:[10,20,50,100,200],
        // selectableRowsOnClick: true  as any,
        count: totalRows,
        serverSide: true,
        filter : false,
        print: false,
        viewColumns : false,
        sort: true,
        search : false,
        download :false,
        // resizableColumns: true,
        customToolbarSelect: (selectedRows:any, displayData:any, setSelectedRows:any) => {         
                let rows : any[] = [];
                selectedRows.data.forEach((element:any, index:any) => {
                    rows.push(element.dataIndex);
                })
                let csvData : any[] = [];
                hrs.forEach((element, index) => {
                        if (rows.includes(index)) {
                            csvData.push(element);
                        }
                });

            const getFileName = () => {
                    let now = new Date().toLocaleDateString();
                    let year = now.slice(0,4);
                    let month = '0' + now.slice(5,6);
                    let day = now.slice(7);
                    let date = year+month+day;
                    return "HRの実績管理" +date;
                }

            let headers = [
                {
                    "key" : 'target_month', 
                    "label" : "対象月"
                },
                {
                    "key" : 'company', 
                    "label" : "所属会社名"
                },
                {
                    "key" : 'user_id', 
                    "label" : "利用者ID"
                },
                {
                    "key" : 'name_jp',
                    "label" : "担当者名"
                },
                {
                    "key" : 'create_date', 
                    "label" : "担当開始日",

                },
                {
                    "key" : 'person_count', 
                    "label" : "要員登録数"
                },//
                {
                    "key" : 'project_incharge_count', 
                    "label" : "案件HR担当数"
                },
                {
                    "key" : 'assign_user_count',
                    "label" : "割り当て数"
                },//
                {
                    "key" : 'case_count', 
                    "label" : "提案された数"
                },//
                {
                    "key" : 'interview_user_count', 
                    "label" : "面談された数"
                },
                {
                    "key" : 'pass_user_count', 
                    "label" : "合格された数"
                },
              ];
            var now = new Date();
            return (
                <CSVLink  className="btn_standard text-decoration-none" filename={getFileName()} data={csvData}  headers={headers}>ダウンロード</CSVLink>
            );
          },
        textLabels: {
            body: {
              noMatch: "データはありません。",
              toolTip: "Sort"
            },
            pagination: {
              next: "Next Page",
              previous: "Previous Page",
              rowsPerPage: "件表示", 
              displayRows: "すべての件数：",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "行 を選択しました。",
                delete: "削除",
                deleteAria: "選択した行を削除しました。",
              },
          },
        rowsSelected: selectedRows,
        onRowSelectionChange: (rowsSelectedData:any, allRows:any, rowsSelected:any) => {
            setSelectedRows(rowsSelected);
        },
        onChangePage (currentPage:any) {
            changePage(currentPage);
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage (numberOfRows:any) {
            changeRowsPerPage(numberOfRows);
            setNoOfRows(numberOfRows);
        },
        onColumnSortChange: (changedColumn:any, direction:any) => {
            let order = 'desc';
            if (direction === 'asc') {
              order = 'asc';
            }
            handleSort(changedColumn, order);
          },
    };
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => { 
        switch (e.target.name) {
            case "search":
                    setSearch(e.target.value);
                    getData(company_filter, representative_filter, target_month, start_date, end_date, perPage, page, e.target.value, sort, column, order, assignment_status, propose_status)
                break;
            default:
                break;
        }
      };
      const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        switch (e.target.name) {
            case "company_filter":
                setCompanyFilter(e.target.value);
                getData(e.target.value, representative_filter, target_month, start_date, end_date, perPage, page, search, sort, column, order, assignment_status, propose_status)
                break;
            case "representative_filter":
                setRepresentativeFilter(e.target.value);
                getData(company_filter, e.target.value, target_month, start_date, end_date, perPage, page, search, sort, column, order, assignment_status, propose_status)
                break;
            case "assignment_status":
                setAssignmentStatus(e.target.value);
                getData(company_filter, representative_filter, target_month, start_date, end_date, perPage, page, search, sort, column, order, e.target.value, propose_status)
                break;
            case "propose_status":
                setProposeStatus(e.target.value);
                getData(company_filter, representative_filter, target_month, start_date, end_date, perPage, page, search, sort, column, order, assignment_status, e.target.value)
                break;
            case "sort":
                setSort(e.target.value);
                getData(company_filter, representative_filter, target_month, start_date, end_date, perPage, page, search, e.target.value, column, order, assignment_status, propose_status)
                break;
            case "list_length":
                changeRowsPerPage(e.target.value);
                setNoOfRows(parseInt(e.target.value));
                setState({
                    ...state,
                    perPage : parseInt(e.target.value)

                })
                break;    
            default:
                break;
        }
     }; 

     let nextPage = () => {
        
        if(Math.floor(totalRows/perPage) >0){
            setShowNextColor("blue");
        }

        if(Math.floor(totalRows/perPage) >0){
            changePage(current_page+1);
            setCurrentPage(current_page+1);
            setState({
                ...state,
                page : current_page+1
            })
            setShowNextColor("blue");
        }
        
        if(Math.ceil(totalRows/perPage)-2 == current_page){ // Last Page
            setShowNextColor("grey");
        }   
        if(0 < current_page+1){
            setShowPrevColor("blue");
        }     

    }

    let previousPage = () => {
        if(current_page > 0){
            setCurrentPage(current_page-1);
            changePage(current_page - 1);
            setState({
                ...state,
                page : current_page-1
            });
            setShowPrevColor("blue");
        }
        if(0 == current_page-1){
            setShowPrevColor("grey");
        } 

        if(Math.floor(totalRows/perPage) >0){
            setShowNextColor("blue");
        } 
        
    }

     const handleDateChange = function(date:any, column_name:any){
        let selected_data = (date != null)?date.getFullYear() +"-"+ (date.getMonth() + 1).toString().padStart(2, "0"): "";
        switch (column_name) {
            case "target_month":
                setSelectedTargetDate(date);
                setTargetMonth(selected_data);
                setStartDate("");
                setEndDate("");
                setSelectedStartDate(null);
                setSelectedEndDate(null);
                getData(company_filter, representative_filter, selected_data, "", "", perPage, page, search, sort, column, order, assignment_status, propose_status)
                break;
            case "start_date":
                setSelectedStartDate(date);
                setStartDate(selected_data);
                setTargetMonth("");
                setSelectedTargetDate(null);
                getData(company_filter, representative_filter, "", selected_data, end_date, perPage, page, search, sort, column, order, assignment_status, propose_status)
                break;
            case "end_date":
                setSelectedEndDate(date);
                setTargetMonth("");
                setSelectedTargetDate(null);
                setEndDate(selected_data);
                getData(company_filter, representative_filter, "", start_date, selected_data, perPage, page, search, sort, column, order,  assignment_status, propose_status)
                break;
            default:
                break;
        }
       
    }
  

     const getMuiTheme = () =>
     createTheme({
         components: {
         MUIDataTable: {
             styleOverrides:{
                 responsiveScroll: {
                     maxHeight: 'none',
                   },
             }
         },
         MuiPaper: {
             styleOverrides:{
                 root: {
                     width: "100%",
                    //  marginTop: "5%",
                    //  marginLeft: "5%",
                    //  marginBottom: "20%"
                 }
             }
         },  
         MUIDataTableHeadCell: {
                 styleOverrides:{
                   root: {
                       backgroundColor: "#444",
                       padding: '0px'
                   }
                 }
         },
         MuiTableCell: {
             styleOverrides:{
                 head: {
                         color : 'white'
                 }
             }
         },
           MUIDataTableBodyCell: {
             styleOverrides:{
               root: {
                   backgroundColor: "#f1f1f1",
                   width: "100%",
                   padding: '0px'
               }
             }
           }
         }
       })
     
    return(
        
       <React.Fragment>
         <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                    ml: sizeConfigs.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color
                }}
                >
                 <Toolbar>
                <Typography variant="h6">
                    <header className="top-baner">
                        <div className="row">
                            <div className="col-10">

                                <div id="search-autocomplete" className="form-outline">
                                    <input type="text" name="search" id="search" placeholder = ""
                                        className="form-control"  onChange={onChange}/>
                                </div>
                            </div>
                            <div className="col-2 mt-4">
                                <DropDownList />
                            </div>
                        </div>
                    </header>
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className="container">
                <PageTitle loading={loading} title=" HRの実績管理" />
                <Row className = " form-group mt-3 mb-3 performance_list filter_select ">
                    <Col xxl={1} xl={1} md={1} sm={2} xs={2} className="mb-3">
                        <label className="control-label">対象月</label>
                    </Col>
                    <Col xxl={2} xl={2} md={2} sm={10} xs={10} >
                        <DatePicker
                            value={(selectedStartDate!=null || selectedEndDate!=null)?"----年--月":(selectedTargetDate != null)?selectedTargetDate: moment().format('yyyy年MM月')}
                            // value = {(selectedTargetDate != null)?selectedTargetDate: moment().format('yyyy年MM月')}
                            dateFormat="yyyy年MM月"
                            locale="ja"
                            selected={selectedTargetDate} 
                            onChange={date =>
                                handleDateChange(date, "target_month")
                            }
                            isClearable
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            />
                    </Col>
                    <Col xxl={1} xl={1} md={1} sm={2} xs={2} className="text-center">
                        <label className="control-label">期間</label>
                    </Col>  
                    <Col xxl={2} xl={2} md={2} sm={8} xs={8} className="mb-3">
                        <DatePicker 
                            dateFormat="yyyy年MM月"
                            locale="ja"
                            selected={selectedStartDate} 
                            onChange={date =>
                                handleDateChange(date, "start_date")
                            }
                            isClearable
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            selectsStart
                            startDate={selectedStartDate}
                            endDate={selectedEndDate}
                            />    
                    </Col>  
                    <Col xxl={1} xl={1} md={1} sm={2} xs={2} className="text-center">
                        <label className="control-label">~</label>
                    </Col>
                    <Col xxl={{ span: 2, offset: 0}} xl={{ span: 2, offset: 0}} md={{ span: 2, offset: 0}} sm={{ span: 8, offset: 2}} xs={{ span: 8, offset: 2}} >
                        <DatePicker 
                            dateFormat="yyyy年MM月"
                            locale="ja"
                            selected={selectedEndDate} 
                            onChange={date =>
                                handleDateChange(date, "end_date")
                            }
                            isClearable
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            selectsEnd
                            startDate={selectedStartDate}
                            endDate={selectedEndDate}
                            minDate={selectedStartDate}
                            />    
                    </Col>
                </Row> 
                <Row className="p-2 xs-hidden">
                    <Col xxl={4} xl={4} md={4} sm={4} xs={12}>
                        <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                        {/* <select name="sort" id="sort" className="filter" onChange={onSelect}>
                            <option value="0" selected>新しい順</option>
                            <option value="1">古い順</option>
                        </select>   */}
                    </Col>
                    <Col xxl={8} xl={8} md={8} sm={8} xs={12} className="filter_select">
                        <div className="float-right">
                            <label className="control-label">会社名</label>
                            <select name="company_filter" className="filter " onChange={onSelect} aria-label=".form-select-sm example">
                                <option value=""> 全て</option>
                                <option value="FloBoard"> FloBoard</option>
                                <option value="FloNet"> FloNet</option>
                            </select>  
                            <label className="control-label">営業担当者</label>
                            <select name="representative_filter" className="filter "  onChange={onSelect} aria-label=".form-select-sm example">
                            <option value=""> 全て</option>
                                {representative_list.map(representative => {
                                        if (representative !== null){
                                            return(<option value={representative[0]}> {representative[1]}</option>)
                                        }
                                    })
                                }
                            </select>
                            
                            <select name="list_length" id="list_length" value={no_of_rows} className="filter list_length" onChange={onSelect}>
                                <option value="10">10</option>
                                <option value="20" >20</option>
                                <option value="50" >50</option>
                                <option value="100" >100</option>
                                <option value="200" >200</option>
                            </select>
                            <label htmlFor="sort" className="control-label">件表示</label>
                            <button type="button" className="btn-sm previous-page-icon" style={{border:"0px"}} onClick={() => {
                                    previousPage();
                                    }}>
                                <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                </button>
                                <button type="button" className=" btn-sm next-page-icon" style={{border:"0px"}} 
                                
                                    onClick={() => {
                                        if (Math.floor(totalRows/perPage)==0) {nextPage();}
                                    }}>
                                    <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                             </button> 
                        </div> 
                    </Col>
                </Row>
                <Row className="pc-hidden filter_select">
                    <Col xs={12}>
                        <label className="control-label">会社名</label>
                        <select name="company_filter" className="filter" onChange={onSelect} aria-label=".form-select-sm example">
                            <option value=""> 全て</option>
                            <option value="FloBoard"> FloBoard</option>
                            <option value="FloNet"> FloNet</option>
                        </select>
                    </Col>
                    <Col xs={12}>
                        <label className="control-label">営業担当者</label>
                        <select name="representative_filter" className="filter"  onChange={onSelect} aria-label=".form-select-sm example">
                        <option value=""> 全て</option>
                            {representative_list.map(representative => {
                                    if (representative !== null){
                                        return(<option value={representative[0]}> {representative[1]}</option>)
                                    }
                                })
                            }
                        </select>           
                    </Col>
                    <Col xs={12}>
                        <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                        {/* <select name="sort" id="sort" onChange={onSelect}>
                            <option value="0" selected>新しい順</option>
                            <option value="1">古い順</option>
                        </select>   */}
                        <div className="float-right">
                            <select name="list_length" id="list_length" value={no_of_rows} className="filter list_length" onChange={onSelect}>
                                <option value="10">10</option>
                                <option value="20" >20</option>
                                <option value="50" >50</option>
                                <option value="100" >100</option>
                                <option value="200" >200</option>
                            </select>
                            <label htmlFor="sort" className="control-label">件表示</label>
                            <button type="button" className="btn-sm previous-page-icon" style={{border:"0px"}} onClick={() => {
                                    previousPage();
                                    }}>
                                <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                </button>
                                <button type="button" className=" btn-sm next-page-icon" style={{border:"0px"}} 
                                
                                    onClick={() => {
                                        if (Math.floor(totalRows/perPage)==0) {nextPage();}
                                    }}>
                                    <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                             </button> 
                        </div>  
                    </Col>
                </Row>
                <div className='datatable freeze-hr-performance-header freeze-hr-performance-cell hr-performance-frozen-columns xs-hidden'>
                    <MUIDataTable
                        title = {""}
                        data = {hrs}
                        columns={defaultColumns}
                        options={options}
                    />
                </div>
                <div className='datatable mobile pc-hidden'>
                    <MUIDataTable
                        title = {""}
                        data = {hrs}
                        columns={defaultColumns}
                        options={options}
                    />
                </div>

                <div className="row">
                    <div className="col-8">
                        <h3 className='header-left-design'>
                           作業分類毎のグラフ
                        </h3>
                        <BarChart chartData={chartData} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-8">
                        <h3 className='header-left-design'>
                          担当者毎のグラフ
                        </h3>
                        <BarChart chartData={chartData2} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-8">
                        <h3 className='header-left-design'>
                          Diagram 3
                        </h3>
                        <label className="control-label">担当者名</label>
                        <select name="assignment_status" className="filter"  onChange={onSelect} aria-label=".form-select-sm example">
                            <option value=""></option>
                            {representative_list.map(representative => {
                                if (representative !== null){
                                    return(<option value={representative[0]}> {representative[1]}</option>)
                                }
                                })
                            }
                        </select>
                        <label className="control-label">期間</label>
                        <select name="propose_status" className="filter"  onChange={onSelect} aria-label=".form-select-sm example">
                            <option value=""></option>
                            <option value="3">値近3ヶ月</option>
                            <option value="6">値近6ヶ月</option>
                            <option value="9">値近9ヶ月</option>
                            <option value="12">値近1年間</option>
                            <option value="24">値近2年間</option>
                            <option value="36">値近3年間</option>                     
                        </select>
                        <LineChart chartData={chartData3} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}

export default HRPerformanceList;