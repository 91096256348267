import React, {useEffect, useState} from 'react';
import SaleryDetailService from "../../services/SaleryDetailService";
import PersonService from "../../services/PersonService";

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Dropdown from 'react-bootstrap/Dropdown';
import Topbar from "../../components/common/Topbar";
import {showErrorInfo,truncateDecimals} from "../../components/common/Helpers";

import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DetailTopbar from "../../components/common/DetailTopbar";
import {SaleryDetailModel} from "../../models/SaleryDetail";
import DatePicker from "react-datepicker";
import {useParams,useSearchParams, Link} from "react-router-dom";
import $ from 'jquery';

interface URLParam {
    id : string;
}

interface IState{
    salery : SaleryDetailModel;
    approve_status_list : [],
    send_status_list : []
}
interface IProps{}
let SaleryDetailUpdate:React.FC<IProps> = () => {
    let {id} = useParams<URLParam | any>();
    let [searchParams, setSearchParams] = useSearchParams();
    let year = (searchParams.get("yearMonth") != null)?searchParams.get("yearMonth"):"0";
    let period_start = (searchParams.get("period_start") != null)?searchParams.get("period_start"):"";
    let period_end = (searchParams.get("period_end") != null)?searchParams.get("period_end"):"";
    let emp_name = (searchParams.get("emp_name") != null)?searchParams.get("emp_name"):"";
    let approv_status = (searchParams.get("approv_status") != null)?searchParams.get("approv_status"):"0";
    let list_length = (searchParams.get("list_length") != null)?searchParams.get("list_length"):"0";
    let page = (searchParams.get("page") != null)?searchParams.get("page"):"0";
    let search_keywords = (searchParams.get("search_keywords") != null)?searchParams.get("search_keywords"):"0";

    const [emp_ins_1, setEmpIns1] = useState(0.0);
    const [emp_ins_2, setEmpIns2] = useState(0.0);
    const [ins_total_1, setInsTotal1] = useState(0.0);
    const [ins_total_2, setInsTotal2] = useState(0.0);
    const [ins_total, setInsTotal] = useState(0.0);
    const [health_welfare_total, setHealthWelfareTotal] = useState(0.0);
    const [deduction_total, setDeductionTotal] = useState(0.0);
    const [pay_amnt, setPayAmount] = useState(0.0);
    const [taxable_net_amnt, setTaxableNetAmt] = useState(0.0);
    const [overtime_flg_checked, setOvertimeFlgChecked] = useState(false);
    const [overtime_flg, setOvertimeFlg] = useState(0);
    const [taxable_amnt, setTaxableAmt] = useState(0.0);
    const [taxexemption_amnt, setTaxExemptionAmnt] = useState(0.0);
    const [net_paid_amt , setNetPaidAmt] = useState(0.0);
    const [health_ins , setHealthIns] = useState(0.0);
    const [emp_pension , setEmpPension] = useState(0.0);
    const [emp_ins, setEmpIns] = useState(0.0);
    const [base_sal, setBaseSal] = useState(0.0);
    const [job_allow, setJobAllow] = useState(0.0);
    const [mng_duty_allow, setMngDutyAllow] = useState(0.0);
    const [housing_allow, setHousingAllow] = useState(0.0);
    const [qual_allow, setQualAllow] = useState(0.0);
    const [fixed_overtime_allow, setFixedOvertimeAllow] = useState(0.0);
    const [overtime_allow, setOvertimeAllow] = useState(0.0);
    const [overtime, setOvertime] = useState(0.0);
    const [pre_month_holiday_remains , setPreMonthHolidayRemains] = useState("");
    const [vacation_startday , setVacationStartDay] = useState("");

    let [state , setState] = useState<IState>({
        salery : {
            emp_id : "",
            sal_yyyy_mm : "",
            person_id: 0,
            org_name : "",
            emp_name :  "",
            emp_kana :  "",
            worked_days : 0,
            holidaywork_days :  0,
            substitute_holiday : 0,
            days_absence :  0,
            late_early_time :  0,
            paid_holidays :  0,
            holidays_remain :  0,
            worked_time :  0,
            overtime:  0,
            overtime_flb:  0,
            hourlywage_flb :  0,
            mn_overtime :  0,
            worked_holidays :  0,
            mn_overtime_hol:  0,
            work_rate :  0,
            ins_calc_flg :  0,
            health_ins :  0,
            emp_pension :  0,
            emp_ins :  0,
            ins_total :  0,
            health_welfare_total :  0,
            base_sal :  0,
            job_allow :  0,
            mng_duty_allow :  0,
            housing_allow :  0,
            qual_allow :  0,
            adjust_allow :  0,
            fixed_overtime_allow :  0,
            overtime_allow :  0,
            overtime_flg :  1,
            travel_expense :  0,
            commute_allow :  0,
            taxable_amnt :  0,
            taxexemption_amnt :  0,
            pay_amnt:  0,
            health_ins_1 :  0,
            emp_pension_1 :  0,
            emp_ins_1:  0,
            ins_total_1 :  0,
            health_ins_2 :  0,
            emp_pension_2 :  0,
            emp_ins_2:  0,
            ins_total_2 :  0,
            taxable_net_amnt :  0,
            income_tax :  0,
            inhabitant_tax :  0,
            deduction_total :  0,
            expense:  0,
            taxrefund : 0,
            net_paid_amt :  0,
            approv_status : "0",
            send_status : "0"
        },
        approve_status_list : [],
        send_status_list : [],
    });

    useEffect(()=>{
            SaleryDetailService.edit(id, year).then((response)=>{
                setState({
                    ...state,
                    salery:response.data.salery_detail,
                    approve_status_list : response.data.approv_status_list,
                    send_status_list : response.data.send_status_list,
                });
                setEmpIns1(response.data.salery_detail.emp_ins_1);
                setEmpIns2(response.data.salery_detail.emp_ins_2);
                setInsTotal1(response.data.salery_detail.ins_total_1);
                setInsTotal2(response.data.salery_detail.ins_total_2);
                setInsTotal(response.data.salery_detail.ins_total);
                setHealthWelfareTotal(response.data.salery_detail.health_welfare_total);
                setDeductionTotal(response.data.salery_detail.deduction_total);
                setHealthWelfareTotal(response.data.salery_detail.health_welfare_total);
                setPayAmount(response.data.salery_detail.pay_amnt);
                setTaxableNetAmt(response.data.salery_detail.taxable_net_amnt);
                setTaxableAmt(response.data.salery_detail.taxable_amnt);
                setTaxExemptionAmnt(response.data.salery_detail.taxexemption_amnt);
                setNetPaidAmt(response.data.salery_detail.net_paid_amt);
                setHealthIns(response.data.salery_detail.health_ins);
                setEmpPension(response.data.salery_detail.emp_pension);
                setEmpIns(response.data.salery_detail.emp_ins);
                let overtime_flg = (response.data.salery_detail.overtime_flg == 0)? true: false;

                setOvertimeFlgChecked(overtime_flg);
                setBaseSal(response.data.salery_detail.base_sal);
                setJobAllow(response.data.salery_detail.job_allow);
                setMngDutyAllow(response.data.salery_detail.mng_duty_allow);
                setHousingAllow(response.data.salery_detail.housing_allow);
                setQualAllow(response.data.salery_detail.qual_allow);
                setFixedOvertimeAllow(response.data.salery_detail.fixed_overtime_allow);
                setOvertimeAllow(response.data.salery_detail.overtime_allow);
                setOvertime(response.data.salery_detail.overtime); 
                setVacationStartDay(response.data.salery_detail.vacation_startday);
                setPreMonthHolidayRemains(response.data.salery_detail.pre_month_holiday_remains);
                
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
            })       

    }, [id]);
    let {salery, approve_status_list, send_status_list} = state;

    const store = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
        state.salery.emp_ins_1 = emp_ins_1;
        state.salery.emp_ins_2 = emp_ins_2;
        state.salery.ins_total_1 = ins_total_1;
        state.salery.ins_total_2 = ins_total_2;
        state.salery.ins_total = ins_total;
        state.salery.health_welfare_total = health_welfare_total;
        state.salery.deduction_total = deduction_total;
        state.salery.pay_amnt = pay_amnt;
        state.salery.taxable_net_amnt = taxable_net_amnt;
        state.salery.taxable_amnt = taxable_amnt;
        state.salery.taxexemption_amnt = taxexemption_amnt;
        state.salery.net_paid_amt = net_paid_amt;
        state.salery.health_ins = health_ins;
        state.salery.emp_pension = emp_pension;
        state.salery.emp_ins = emp_ins;
        state.salery.base_sal = base_sal;
        state.salery.job_allow = job_allow;
        state.salery.mng_duty_allow = mng_duty_allow;
        state.salery.housing_allow = housing_allow;
        state.salery.qual_allow = qual_allow;
        state.salery.fixed_overtime_allow = fixed_overtime_allow;
        state.salery.overtime_allow = overtime_allow;
        state.salery.overtime = overtime;
        // state.salery.overtime_flg = (overtime_flg_checked == true)?1:0;

        if(state.salery["salery"] != undefined && state.salery["salery"] != null){
            delete state.salery["salery"];
        }

        delete state.salery["pre_month_holiday_remains"];
        delete state.salery["vocation_startday"];
        // let data = [];
        // data.emp_ins_1 = emp_ins_1;
        // data.emp_ins_2 = emp_ins_2;
        // data.ins_total_1 = ins_total_1,
        // data.ins_total_2 = ins_total_2,
        // data.ins_total = ins_total;
        // data.health_welfare_total = health_welfare_total;
        // data.deduction_total = deduction_total;
        // data.pay_amnt = pay_amnt;
        // data.taxable_net_amnt = taxable_net_amnt;
        // data.taxable_amnt = taxable_amnt;
        // data.taxexemption_amnt = taxexemption_amnt;
        // data.net_paid_amt = net_paid_amt;
        // data.health_ins = health_ins;
        // data.emp_pension = emp_pension;
        // data.emp_ins = emp_ins;
        // data.base_sal = base_sal;
        // data.job_allow = job_allow;
        // data.mng_duty_allow = mng_duty_allow;
        // data.housing_allow = housing_allow;
        // data.qual_allow = qual_allow;
        // data.fixed_overtime_allow = fixed_overtime_allow;
        // data.overtime_allow = overtime_allow;
        // data.overtime = overtime;

        SaleryDetailService.update(state.salery, id).then((response) => {
           window.location.href = "/salery/detail";
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
            if(error.response.data){
                showErrorInfo(error.response.data);
            }

        })
    };



    let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        switch(event.target.name) {
            case "base_sal": setBaseSal(parseFloat(event.target.value.toString()));
                break;
            case "job_allow": setJobAllow(parseFloat(event.target.value.toString())); 
                break;
            case "mng_duty_allow": setMngDutyAllow(parseFloat(event.target.value.toString()));
                break;
            case "housing_allow": setHousingAllow(parseFloat(event.target.value.toString()));
                break;
            case "qual_allow": setQualAllow(parseFloat(event.target.value.toString()));
                break;
            case "fixed_overtime_allow": setFixedOvertimeAllow(parseFloat(event.target.value.toString()));
                break;
            case "overtime_allow" : setOvertimeAllow(parseFloat(event.target.value.toString()));
                break;
            case "taxable_amnt" : setTaxableAmt(parseFloat(event.target.value.toString()));
                break;
            default : 
                setState({
                    salery : {
                        ...state.salery,
                        [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
                    },
                    approve_status_list : approve_status_list,
                    send_status_list : send_status_list
                }); 
                break;
        }
           

    };

   


    let updateTextArea = (event:React.ChangeEvent<HTMLTextAreaElement>):void => {
        setState({
            salery : {
                ...state.salery,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            },
            approve_status_list : approve_status_list,
            send_status_list : send_status_list
        });
    }
    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setState({
            salery : {
                ...state.salery,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            },
            approve_status_list : approve_status_list,
            send_status_list : send_status_list
        });
     };   


   
    const autoCalTax = function () {
        let data = {};
        data["person_id"] = state.salery.person_id;
        data["emp_id"] = state.salery.emp_id;
        data["sal_yyyy_mm"] = state.salery.sal_yyyy_mm;
        data["taxable_net_amnt"] = taxable_net_amnt;
        SaleryDetailService.calTax(data).then(response=>{
            if(response.data.data.flg == "warning"){
                alert(response.data.data.msg);
            }else{
                setState({
                    salery : {
                        ...state.salery,
                        income_tax : response.data.data.tax // input name 'username' set value 'event target value'
                    },
                    approve_status_list : approve_status_list,
                    send_status_list : send_status_list
                }); 
            }
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
        })
    }

    const calculation = function () {
        getEmpIns();
    }

    const  getEmpIns = function () {
        let data = {};
        data["person_id"] = state.salery.person_id;
        data["emp_id"] = state.salery.emp_id;
        SaleryDetailService.getSaleryEmpIns(data).then(response=>{
            if(response.data.success == false){
                alert(response.data.info.message);
            }
            workRateCal(response.data.data);
            allCalculationFunc(response.data.data);
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
        })
    }

    const workRateCal = function (data:any) {
        let cols = ["base_sal","job_allow","mng_duty_allow","housing_allow","qual_allow","fixed_overtime_allow"];
        let work_rate =  state.salery.work_rate;
        for(var i=0;i<cols.length;i++) {
            var colval = 0;
            switch(cols[i]) {
                case "base_sal": colval =  work_rate * parseFloat(data.base_sal);
                                setBaseSal(colval);
                    break;
                case "job_allow": colval =  work_rate * parseFloat(data.job_allow);
                                setJobAllow(colval); 
                    break;
                case "mng_duty_allow": colval =  work_rate * parseFloat(data.mng_duty_allow);
                               setMngDutyAllow(colval);
                    break;
                case "housing_allow": colval =  work_rate * parseFloat(data.housing_allow);
                            setHousingAllow(colval);
                    break;
                case "qual_allow": colval =  work_rate * parseFloat(data.qual_allow);
                            setQualAllow(colval);
                    break;
                case "fixed_overtime_allow": colval =  work_rate * parseFloat(data.fixed_overtime_allow);
                            setFixedOvertimeAllow(colval);
                    break;
            }
        }
    }

    const allCalculationFunc = function (data:any) {
        getOvertimeAllow(data);
        let taxable_amnt = getTaxableAmnt();
        getTaxexemptionAmnt();
        getPayAmnt();	
        let cal_emp_ins_1 = state.salery.pay_amnt * parseFloat(data.emp_ins_rate_1);
        let cal_emp_ins_2 = state.salery.pay_amnt * parseFloat(data.emp_ins_rate_2);
        setEmpIns1(Math.floor(cal_emp_ins_1));
        setEmpIns2(Math.floor(cal_emp_ins_2));
        getInsTotalWithFlg(1);
        getInsTotalWithFlg(2);
        var flg = state.salery.ins_calc_flg;
        getInsCalculation(flg);
        let ins_total = getInsTotal();
        getHeathWarefareTotal();
        var deduction_total = getDeductionTotal(ins_total);
        getTaxableNetAmnt(taxable_amnt, ins_total);
        getNetPaidAmt(deduction_total);
    }

    function getOvertimeAllow(data:any) {
        let hourlywage_flb = state.salery.hourlywage_flb;
        let overtime_flb =   state.salery.overtime_flb;
        let worked_time =  state.salery.worked_time;
        let count = 0;
        let overtimeVal = 0;
    
        if (parseFloat(worked_time.toString()) > 0) {
            if (parseFloat(worked_time.toString()) > parseFloat(data.adj_hours_high)) {
                count = ((parseFloat(worked_time.toString()) - parseFloat(data.adj_hours_high)) * parseFloat(data.adj_price_excess)) + ((overtime_flb) * (hourlywage_flb));
                overtimeVal = parseFloat(worked_time.toString()) - parseFloat(data.adj_hours_high);
            } else if (parseFloat(worked_time.toString()) < parseFloat(data.adj_hours_low)) {
                count = ((parseFloat(worked_time.toString()) - parseFloat(data.adj_hours_low)) * parseFloat(data.adj_price_deduct)) + ((overtime_flb) * (hourlywage_flb));
                overtimeVal = parseFloat(worked_time.toString()) - parseFloat(data.adj_hours_low);
            } else {
                count = 0.0 + ((overtime_flb) * (hourlywage_flb));
            }
        } else {
            count = 0.0 + ((overtime_flb) * (hourlywage_flb));
        }
    
        if(overtime_flg_checked == false){
            setOvertimeAllow(truncateDecimals(count, 0));
        }
        setOvertime(overtimeVal);
    }

    const getTaxableAmnt = function () {
        var count = 0.0;
        if (state.salery.base_sal != null ){
            count += parseFloat(state.salery.base_sal.toString());
        }
        if (state.salery.job_allow != null ){
            count += parseFloat(state.salery.job_allow.toString());
        }
        if (state.salery.mng_duty_allow != null ){
            count += parseFloat(state.salery.mng_duty_allow.toString());
        }
        if (state.salery.housing_allow != null ){
            count += parseFloat(state.salery.housing_allow.toString());
        }
        if (state.salery.qual_allow != null ){
            count += parseFloat(state.salery.qual_allow.toString());
        }
        if (state.salery.adjust_allow != null ){
            count += parseFloat(state.salery.adjust_allow.toString());
        }
        if (state.salery.fixed_overtime_allow != null ){
            count += parseFloat(state.salery.fixed_overtime_allow.toString());
        }
        if (state.salery.overtime_allow != null ){
            count += parseFloat(state.salery.overtime_allow.toString());
        }
        setTaxableAmt(count);
        setState({
            salery : {
                ...state.salery,
                taxable_amnt : truncateDecimals(count, 0)
            },
            approve_status_list : approve_status_list,
            send_status_list : send_status_list
        });	

        return count;
    }

    const getTaxexemptionAmnt = function () {
        var count = 0.0;
        if (state.salery.travel_expense != null ){
            count += parseFloat(state.salery.travel_expense.toString());
        }
        if (state.salery.commute_allow != null ){
            count += parseFloat(state.salery.commute_allow.toString());
        }
        setTaxExemptionAmnt(truncateDecimals(count, 0));
    }

    const getPayAmnt = function () {
        var count = 0.0;
        if (state.salery.taxable_amnt != null ){
            count += parseFloat(state.salery.taxable_amnt.toString());
        }
        if (state.salery.taxexemption_amnt != null ){
            count += parseFloat(state.salery.taxexemption_amnt.toString());
        }
        setPayAmount(truncateDecimals(count, 0));
    }

    const getInsTotalWithFlg = function (postfix:any) {
        let count = 0.0;
        if(postfix == 1 || postfix == "1"){
            if (state.salery.health_ins_1 != null ){
                count += parseFloat(state.salery.health_ins_1.toString());
            }
            if (state.salery.emp_pension_1 != null ){
                count += parseFloat(state.salery.emp_pension_1.toString());
            }
            if (state.salery.emp_ins_1 != null ){
                count += parseFloat(state.salery.emp_ins_1.toString());
            }
            setInsTotal1(truncateDecimals(count, 0));
        }else{
            if (state.salery.health_ins_2 != null ){
                count += parseFloat(state.salery.health_ins_2.toString());
            }
            if (state.salery.emp_pension_2 != null ){
                count += parseFloat(state.salery.emp_pension_2.toString());
            }
            if (state.salery.emp_ins_2 != null ){
                count += parseFloat(state.salery.emp_ins_2.toString());
            }
            setInsTotal2(truncateDecimals(count, 0));
        }
    }

    const getInsCalculation = function (flg:any) {
		if(flg == 0 && flg != "") {
            setHealthIns(truncateDecimals(state.salery.health_ins_1, 0));
            setEmpPension(truncateDecimals(state.salery.emp_pension_1, 0));
            setEmpIns(truncateDecimals(state.salery.emp_ins_1, 0));	
		} else if(flg == 1)  {
			var totalhealth = parseFloat(state.salery.health_ins_1.toString()) + parseFloat(state.salery.health_ins_2.toString());
			var totalpension = parseFloat(state.salery.emp_pension_1.toString()) + parseFloat(state.salery.emp_pension_2.toString());
			var totalins = parseFloat(state.salery.emp_ins_1.toString()) + parseFloat(state.salery.emp_ins_2.toString());
            setHealthIns(truncateDecimals(totalhealth, 0));
            setEmpPension(truncateDecimals(totalpension, 0));
            setEmpIns(truncateDecimals(totalins, 0));
		} else {
            setHealthIns(0);
            setEmpPension(0);
            setEmpIns(0);
		}
    }
 
    const getInsTotal = function () {
        var count = 0.0;
        if (state.salery.health_ins != null ){
            count += parseFloat(state.salery.health_ins.toString());
        }
        if (state.salery.emp_pension != null ){
            count += parseFloat(state.salery.emp_pension.toString());
        }
        if (state.salery.emp_ins != null ){
            count += parseFloat(state.salery.emp_ins.toString());
        }
       setInsTotal(truncateDecimals(count, 0));
       return count;
    }

    const getHeathWarefareTotal = function () {
        var count = 0.0;
        if (state.salery.health_ins_1 != null ){
            count += parseFloat(state.salery.health_ins_1.toString());
        }
        if (state.salery.health_ins_2 != null ){
            count += parseFloat(state.salery.health_ins_2.toString());
        }
        if (state.salery.emp_pension_1 != null ){
            count += parseFloat(state.salery.emp_pension_1.toString());
        }
        if (state.salery.emp_pension_2 != null ){
            count += parseFloat(state.salery.emp_pension_2.toString());
        }
        setHealthWelfareTotal(truncateDecimals(count, 0));
    }

    const getDeductionTotal = function (ins_total:any) {
        let count = 0;
        if (ins_total != null ){
            count += parseFloat(ins_total.toString());
        }
        if (state.salery.income_tax != null ){
            count += parseFloat(state.salery.income_tax.toString());
        }
        if (state.salery.inhabitant_tax != null ){
            count += parseFloat(state.salery.inhabitant_tax.toString());
        }

        setDeductionTotal(count);
        // setState({
        //     salery : {
        //         ...state.salery,
        //         deduction_total : count
        //     },
        //     approve_status_list : approve_status_list,
        //     send_status_list : send_status_list
        // });	

        return count;
    } 

    const getTaxableNetAmnt = function (taxable_amnt:any, ins_total:any) {
        var countIns = parseFloat(taxable_amnt.toString()) - parseFloat(ins_total.toString());
        setTaxableNetAmt(truncateDecimals(countIns, 0));

        // return countIns;
        // setState({
        //     salery : {
        //         ...state.salery,
        //         taxable_net_amnt : truncateDecimals(countIns, 0)
        //     },
        //     approve_status_list : approve_status_list,
        //     send_status_list : send_status_list
        // });	
    }

    const getNetPaidAmt = function (deduction_total:any) {
        var countIns = parseFloat(pay_amnt.toString()) - parseFloat(deduction_total.toString()) + parseFloat(state.salery.expense.toString());
        setNetPaidAmt(truncateDecimals(countIns, 0));

        setState({
            salery : {
                ...state.salery,
                emp_ins_1 : truncateDecimals(emp_ins_1, 0),
                emp_ins_2 : truncateDecimals(emp_ins_2, 0),
                ins_total_1 : truncateDecimals(ins_total_1, 0),
                ins_total_2 : truncateDecimals(ins_total_2, 0),
                ins_total : truncateDecimals(ins_total, 0),
                health_welfare_total : truncateDecimals(health_welfare_total, 0),
                pay_amnt : truncateDecimals(pay_amnt, 0),
                taxable_amnt : truncateDecimals(taxable_amnt, 0),
                health_ins  : truncateDecimals(health_ins , 0),
                emp_pension : truncateDecimals(emp_pension, 0),
                emp_ins : truncateDecimals(emp_ins, 0),
                base_sal : truncateDecimals(base_sal, 0),
                job_allow : truncateDecimals(job_allow, 0),
                mng_duty_allow : truncateDecimals(mng_duty_allow, 0),
                housing_allow : truncateDecimals(housing_allow, 0),
                qual_allow : truncateDecimals(qual_allow, 0),
                fixed_overtime_allow : truncateDecimals(fixed_overtime_allow, 0),
                overtime_allow : truncateDecimals(overtime_allow, 0),
                overtime : truncateDecimals(overtime, 0),
                deduction_total : truncateDecimals(deduction_total, 0),
                net_paid_amt : truncateDecimals(countIns, 0),
                taxable_net_amnt : truncateDecimals(taxable_net_amnt , 0)
            },
            approve_status_list : approve_status_list,
            send_status_list : send_status_list
        });	
    }

    const handleOvertimeFlg = function(e:any){
        let isChecked = e.target.checked;
        setOvertimeFlgChecked(isChecked);

        if(e.target.checked == true){
            alert(e.target.checked);
            setState({
                salery : {
                    ...state.salery,
                    overtime_flg : 0
                },
                approve_status_list : approve_status_list,
                send_status_list : send_status_list
            }); 
        }else{
            setState({
                salery : {
                    ...state.salery,
                    overtime_flg : 1
                },
                approve_status_list : approve_status_list,
                send_status_list : send_status_list
            }); 
        }
       
    }

   
  

    return(
        <React.Fragment>
            <DetailTopbar />
            <div className="container">
                <div className = "row">
                        <h3 className='header-left-design'>給料情報{(id !== undefined) ?"編集":"登録"}</h3>
                </div>
                <div className="row anken-detail">
                <form className="form create-form " onSubmit={store}>
                    <div className="card">
                        <div className="card-body">
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>社員ID</span>
                                        <input type="text" name="emp_id" value={state.salery.emp_id} id="emp_id" className="form-control" readOnly/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>基本給</span>
                                        <input type="number" name="base_sal" value={base_sal} id="base_sal" className="form-control" onChange={updateInput}/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>給料対象年月</span>
                                        <input type="text" name="sal_yyyy_mm" value={state.salery.sal_yyyy_mm} id="sal_yyyy_mm" className="form-control" onChange={updateInput}/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>職務手当</span>
                                        <input type="number" name="job_allow" value={job_allow} id="job_allow" className="form-control" onChange={updateInput}/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>要員ID</span>
                                        <input type="text" name="person_id" value={state.salery.person_id} id="person_id" className="form-control" readOnly/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>管理職手当</span>
                                        <input type="text" name="mng_duty_allow" value={mng_duty_allow} id="mng_duty_allow" className="form-control" onChange={updateInput}/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>所属</span>
                                        <select name="org_name" className="form-control" onChange={onSelect} value={state.salery.org_name}>
                                            <option value="FloBoard">FloBoard</option>
                                            <option value="FloNet">FloNet</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>住宅手当</span>
                                        <input type="number" name="housing_allow" value={housing_allow} id="housing_allow" className="form-control" onChange={updateInput}/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>氏名</span>
                                        <input type="text" name="emp_name" value={state.salery.emp_name} id="emp_name" className="form-control" onChange={updateInput}/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>資格手当</span>
                                        <input type="number" name="qual_allow" value={qual_allow} id="qual_allow" className="form-control" onChange={updateInput}/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>氏名カナ</span>
                                        <input type="text" name="emp_kana" value={state.salery.emp_kana} id="emp_kana" className="form-control" onChange={updateInput}/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>調整手当(稼働率と自動連動してない)</span>
                                        <input type="text" name="adjust_allow" value={state.salery.adjust_allow} id="adjust_allow" className="form-control" onChange={updateInput}/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>出勤日数</span>
                                        <input type="text" name="worked_days" value={state.salery.worked_days} id="worked_days" className="form-control" onChange={updateInput}/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>固定残業手当</span>
                                        <input type="number" name="fixed_overtime_allow" value={fixed_overtime_allow} id="fixed_overtime_allow" className="form-control" onChange={updateInput}/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-3 input-box">
                                        <span id='text'>休日出勤日数</span>
                                        <input type="text" name="holidaywork_days" value={state.salery.holidaywork_days} id="holidaywork_days" className="form-control" onChange={updateInput}/>
                                    </div>
                                    <div className="col-md-3 input-box">
                                        <span id='text'>振替休暇</span>
                                        <input type="text" name="substitute_holiday" value={state.salery.substitute_holiday} id="substitute_holiday" className="form-control" onChange={updateInput}/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>残業代</span>
                                        <input type="number" name="overtime_allow" value={overtime_allow} id="overtime_allow" className="form-control" onChange={updateInput}/>
                                        <input type="checkbox" name="overtime_flg"  value={(overtime_flg_checked == true)?0: 1} id="overtime_flg" 
                                        checked ={overtime_flg_checked} onChange={e => handleOvertimeFlg(e)}/>
                                        <label htmlFor="overtime_flg" className="overtime_flg" >自動計算外す</label>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>欠勤日数</span>
                                        <input type="text" name="days_absence" value={state.salery.days_absence} id="days_absence" className="form-control" onChange={updateInput}/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>交通費</span>
                                        <input type="text" name="travel_expense" value={state.salery.travel_expense} id="travel_expense" className="form-control"  onChange={updateInput}/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>遅刻早退</span>
                                        <input type="text" name="late_early_time" value={state.salery.late_early_time} id="late_early_time" className="form-control" onChange={updateInput}/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>通勤手当</span>
                                        <input type="text" name="commute_allow" value={state.salery.commute_allow} id="commute_allow" className="form-control" onChange={updateInput}/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>有給休暇</span>
                                        <input type="text" name="paid_holidays" value={state.salery.paid_holidays} id="paid_holiday" className="form-control" onChange={updateInput}/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>課税支給額</span>

                                        <input type="number" name="taxable_amnt" value={state.salery.taxable_amnt}  id="taxable_amnt" className="form-control" readOnly/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4 input-box">
                                                <span id='text'>有給休暇残</span>
                                                <input type="text" name="holidays_remain" value={state.salery.holidays_remain} id="holidays_remain" className="form-control" onChange={updateInput}/>
                                            </div>
                                            <div className="col-md-4 input-box">
                                                <span id='text'>前月までの有給残</span>
                                                <input type="text" name="" value={pre_month_holiday_remains} id="pre_month_holiday_remains" className="form-control" readOnly/>
                                            </div>
                                            <div className="col-md-4 input-box">
                                                <span id='text'>有給開始日</span>
                                                <input type="text" name="" value={vacation_startday} id="vacation_startday" className="form-control" readOnly/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>非課税支給額</span>
                                        <input type="text" name="taxexemption_amnt" value={state.salery.taxexemption_amnt}  id="taxexemption_amnt" className="form-control" readOnly/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>勤務時間</span>
                                        <input type="text" name="worked_time" value={state.salery.worked_time} id="worked_time" className="form-control" onChange={updateInput}/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>総支給額</span>
                                        <input type="text" name="pay_amnt" value={state.salery.pay_amnt}  id="pay_amnt" className="form-control"  readOnly/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>普通残業</span>
                                        <input type="text" name="overtime" value={overtime} id="overtime" className="form-control" onChange={updateInput}/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>健康保険1</span>
                                        <input type="text" name="health_ins_1" value={state.salery.health_ins_1} id="health_ins_1" className="form-control"  onChange={updateInput}/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>普通残業(自社勤務)</span>
                                        <input type="text" name="overtime" value={state.salery.overtime} id="overtime" className="form-control" onChange={updateInput}/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>厚生年金1</span>
                                        <input type="text" name="emp_pension_1" value={state.salery.emp_pension_1} id="emp_pension_1" className="form-control"  onChange={updateInput}/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>自社勤務時間単金</span>
                                        <input type="text" name="hourlywage_flb" value={state.salery.hourlywage_flb} id="hourlywage_flb" className="form-control" onChange={updateInput}/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>雇用保険1</span>
                                        <input type="text" name="emp_ins_1" value={state.salery.emp_ins_1} id="emp_ins_1" className="form-control"  readOnly/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>深夜残業時間</span>
                                        <input type="number" name="mn_overtime" value={state.salery.mn_overtime} id="mn_overtime" className="form-control" onChange={updateInput}/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>社会保険合計１</span>
                                        <input type="text" name="ins_total_1" value={state.salery.ins_total_1} id="ins_total_1" className="form-control"  readOnly/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>休日出勤時間</span>
                                        <input type="text" name="worked_holidays" value={state.salery.worked_holidays} id="worked_holidays" className="form-control" onChange={updateInput}/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>健康保険2</span>
                                        <input type="number" name="health_ins_2" value={state.salery.health_ins_2} id="health_ins_2" className="form-control"  onChange={updateInput}/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>休日深夜時間</span>
                                        <input type="text" name="mn_overtime_hol" value={state.salery.mn_overtime_hol} id="mn_overtime_hol" className="form-control" onChange={updateInput}/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>厚生年金2</span>
                                        <input type="number" name="emp_pension_2" value={state.salery.emp_pension_2} id="emp_pension_2" className="form-control"  onChange={updateInput}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>稼働率</span>
                                        <input type="text" name="work_rate" value={state.salery.work_rate} id="work_rate" className="form-control" onChange={updateInput}/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>雇用保険2</span>
                                        <input type="text" name="emp_ins_2" value={state.salery.emp_ins_2} id="emp_ins_2" className="form-control"  readOnly/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>社会保険計算フラグ</span>
                                        <select name="ins_calc_flg" className="input-box form-control" value={state.salery.ins_calc_flg} onChange={onSelect} aria-label=".form-select-sm example">
                                            <option value=""></option>
                                            <option value="0">保険1のみ出力</option>
                                            <option value="1">保険1+2の合計出力</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>社会保険合計2</span>
                                        <input type="text" name="ins_total_2" value={state.salery.ins_total_2 } id="ins_total_2" className="form-control"  readOnly/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>健康保険</span>
                                        <input type="text" name="health_ins" value={ state.salery.health_ins} id="health_ins" className="form-control" readOnly/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>課税対象額</span>
                                        <input type="text" name="taxable_net_amnt" value={state.salery.taxable_net_amnt} id="taxable_net_amnt" className="form-control"  readOnly/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>厚生年金</span>
                                        <input type="text" name="emp_pension" value={state.salery.emp_pension} id="emp_pension" className="form-control" readOnly/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>所得税</span>
                                        <div className="row">
                                            <div className="col-9">
                                                <input type="text" name="income_tax" value={state.salery.income_tax} id="income_tax" className="form-control" onChange={updateInput}/>
                                            </div>
                                            <div className="col-3">
                                                <button className="btn_standard btn_search w-100" type="button" onClick={autoCalTax}>計算</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>雇用保険</span>
                                        <input type="text" name="emp_ins" value={state.salery.emp_ins} id="emp_ins" className="form-control" readOnly/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>住民税</span>
                                        <input type="text" name="inhabitant_tax" value={state.salery.inhabitant_tax} id="inhabitant_tax" className="form-control" onChange={updateInput}/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>社会保険合計</span>
                                        <input type="text" name="ins_total" value={state.salery.ins_total} id="ins_total" className="form-control" readOnly/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>控除合計</span>
                                        <input type="text" name="deduction_total" value={state.salery.deduction_total}  id="deduction_total"  className="form-control"  readOnly/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>健康・厚生年金合計額</span>
                                        <input type="text" name="health_welfare_total" value={state.salery.health_welfare_total} id="health_welfare_total" className="form-control" readOnly/>
                                    </div>
                                    <div className="col-md-3 input-box">
                                        <span id='text'>経費精算</span>
                                        <input type="text" name="expense" value={state.salery.expense} id="expense" className="form-control" onChange={updateInput}/>
                                    </div>
                                    <div className="col-md-3 input-box">
                                        <span id='text'>所得税還付</span>
                                        <input type="number" name="taxrefund" value={state.salery.taxrefund} id="expense" className="form-control" onChange={updateInput}/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   <div className="col-md-6 input-box">
                                        <span id='text'>差引支給額</span>
                                        <input type="text" name="net_paid_amt" value={state.salery.net_paid_amt} id="net_paid_amt" className="form-control" readOnly/>
                                    </div>
                                    <div className="col-md-6 input-box">
                                        <span id='text'>承認ステータス</span>
                                        <select name="approv_status" className="input-box form-control" value={state.salery.approv_status} onChange={onSelect} aria-label=".form-select-sm example">
                                            <option value="0">承認まち</option>
                                            <option value="1">承認済み</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6 input-box">
                                        <span id='text'>明細送付ステータス</span>
                                        <select name="send_status" className="input-box form-control" value={state.salery.send_status} onChange={onSelect} aria-label=".form-select-sm example">
                                            <option value="0">未処理</option>
                                            <option value="1">送付済</option>
                                            <option value="2">送付エラー</option>
                                        </select>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 text-center">
                            <button className="btn btn_standard" type="button" id="case_create_submit" onClick={calculation}>①計算</button>
                            <Link to={`/salery/detail?sal_yyyy_mm=${year}&emp_name=${emp_name}&period_start=${period_start}&period_end=${period_end}&approv_status=${approv_status}&list_length=${list_length}&page=${page}&search_keywords=${search_keywords}`}  id="case_create_submit" className="text-decoration-none btn btn_cancel">キャンセル</Link>
                            <button className="btn btn_standard" id="case_create_submit" type="submit">②更新</button>
                        </div>
                    </div>
                    </form>
                </div>
                
            </div>
        </React.Fragment>
    );
};
export default SaleryDetailUpdate;