import React, {useEffect, useState} from 'react';
import InterviewReminderService from "../../services/InterviewReminderService";
import { v4 as uuid } from "uuid";
import PartnerService from "../../services/PartnerService";
import {CaseListService} from "../../services/CaseService";

import MUIDataTable from "mui-datatables";
// import Dropdown from 'react-bootstrap/Dropdown';
import {showErrorInfo} from "../../components/common/Helpers";
import DetailTopbar from "../../components/common/DetailTopbar";
import {GroupRoomModel} from "../../models/GroupRoom";
import {useParams, Link} from "react-router-dom";
import GroupRoomService from '../../services/GroupRoomService';

interface URLParam {
    id : string;
}

interface IState{
            // // room_type : string,
            // room_status : string,
            // process_flg : string,
    groupRoom : GroupRoomModel;
}
 interface IProps{}

let GroupRoomCreate:React.FC<IProps> = () => {
    let {id} = useParams<URLParam | any>();

    let [state , setState] = useState<IState>({
        groupRoom : {
            room_id: "",
            room_type : "",
            room_status : "",
            process_flg : "",
        },
        
    });

    const unique_id = (id !== undefined) ? id:uuid();
   

    useEffect(()=>{
        if(id != undefined){
            GroupRoomService.edit(id).then((response)=>{
                setState({
                    ...state,
                    groupRoom : response.data.data,
                    // room_id : response.data.data,
                    // room_type : "go",
                    // room_status : "1",
                    // process_flg : "0"
                })

            }).catch((error) => {
                // console.log(error);
                // setState({
                //     ...state,
                // })
            })
        }}, [id]);
    // let {room_status,process_flg} = state;
    let {groupRoom} = state;
    const store = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
        if(id != undefined){
            GroupRoomService.update(groupRoom,id).then((response) => {
                window.location.href = "/interview/grouproom";
            }).catch((error) => {
                if(error.response.data){
                    showErrorInfo(error.response.data);
                }
            })
        }else{
            GroupRoomService.store(id).then((response) => {
                window.location.href = "/interview/grouproom";
            }).catch((error) => {
                if(error.response.data){
                    showErrorInfo(error.response.data);
                }
            })
        }
    };
  
    let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setState({
            groupRoom : {
                ...state.groupRoom,
                [event.target.name] : event.target.value // input name 'username' set value 'event target value'
            }
        });
    };

    return(
        <React.Fragment>
            <DetailTopbar />
            <div className="container">
                <div className = "row">
                        <h3 className='header-left-design'>面談通知情報 {(id !== undefined) ?"編集":"登録"}</h3>
                </div>
                <div className="row anken-detail">
                <form className="form create-form " onSubmit={store}>
                    <div className="card">
                        <div className="card-body">
                            <div className="row mt-3">
                                <div className="col-md-6 input-box col-xs-12">
                                 <span id='text' className="input-required">会議室ID</span>
                                    <input type="text" name="room_id" id="room_id" className="form-control" aria-label="room_id" value={unique_id} onChange={updateInput}/>
                                </div>
                                <div className="col-md-6 col-xs-12">
                                    <span id='text'>会議室形</span>
                                    <select name="room_type" id="room_type" className="input-box form-control" aria-label="form-select-sm example">
                                        <option value="go"> 1台会議</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="row mt-5">
                            <div className="col-12 text-center">
                                <button className="btn btn_standard" id="case_create_submit" type="submit">{(id !== undefined) ?"更新":"登録"}</button>
                                <Link to={`/interview/grouproom`}  id="case_create_submit" className="text-decoration-none btn btn_cancel">キャンセル</Link>
                            </div>
                        </div>
                </form>
                </div>
            </div>
        </React.Fragment>
    );
};
export default GroupRoomCreate;