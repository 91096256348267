import React, {useState, useContext} from 'react';
import AuthService from "../services/AuthService";
import '../index.css';
import { store } from '../redux/store';
import { Hidden } from '@mui/material';
import { Console } from 'console';
import $ from 'jquery';

import {AppStateContext, AppConstStateActions} from '../redux/AppStore';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {showErrorInfo, PCModalStyle, MobileModalStyle} from "../components/common/Helpers";

interface IProps{}

let TwoFactorForm:React.FC<IProps> = () => {
    const appStateContext = useContext(AppStateContext);

    if(!appStateContext) {
      throw Error('CurrentContext must be used whith a CounterProvider.');
    }
  
    const { appDatas, appAction } = appStateContext;
    let [two_factor_code, setTwoFactorCode] = useState("");
    let [text, setText] = useState("none");
    let [resendtxt, setResendTxt] = useState("none");
    const [btnColor, setBtnColor] = useState("#444444ab");
    const [disabled, setDisabled] = React.useState(false);

    let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setTwoFactorCode(event.target.value);
    };

    // resend code
    let verifyResend = () => {
        
        let data = {};
        data["email"] =  localStorage.getItem("login_email");
        data["password"] = localStorage.getItem("login_pwd");
        AuthService.resend(data).then((response)=>{
            console.log(response);
            if(response.data.status == "200"){
                // setText("none");
                setResendTxt("block");
                $(".factor_form").css("text-align", "center");
                $(".factor_form .resend").remove();
                $(".factor_form .err_txt").remove();
                $(".factor_form").append("<span class='resend' value="+text+">コードが再送信されました</span>");
                setText("none");
                $(".factor_form #err_txt").css("display", "none");
                $('#two-factor-code').val('');
                // window.location.href = "/verify";
            }
        }).catch((error:any) => {
            console.log(error);
        });
    }

    //confirm two verification code
    let store = (event : any):void => {
         // click event type declare in React
        event.preventDefault();
        setBtnColor("#44444459");
        setDisabled(true);
        let data = {};
        data["two_factor_code"] = two_factor_code;
        AuthService.store(data).then((response) => {
            console.log(response);
            setBtnColor("#444444ab");
            setText("");

            if(response.data.status == 200){
                setText("none");
                appAction({
                    type: AppConstStateActions.Set.UserInfo,
                    value: JSON.stringify(response.data.info)
                });
                appAction({
                    type: AppConstStateActions.Set.ApiToken,
                    value: response.data.token
                });
                appAction({
                    type: AppConstStateActions.Set.ApiTokenUpdateAt,
                    value: response.data.token_updated_at
                });
                // localStorage.setItem("token", response.data.token);
                // localStorage.setItem("user", JSON.stringify(response.data.info));
				// localStorage.setItem("token_updated_at", response.data.updated_at);
                // window.location.href = "/person";
                localStorage.removeItem("login_email");
                localStorage.removeItem("login_pwd");
                if(response.data.info.photo != null && response.data.info.photo != ''){
                    localStorage.setItem("profile_image", response.data.info.photo);
                }
                if(response.data.info.role == 7){
                    AuthService.resourceLogin(response.data.token, "/cases");
                }else if(response.data.info.role == 8){
                    AuthService.resourceLogin(response.data.token, "/persons");
                }else{
                    AuthService.resourceLogin(response.data.token, "/home1");
                }
                // AuthService.resourceLogin(response.data.token, "/cases/list");
            }else{
                setText("block");
                $(".factor_form").css("text-align", "center");
                $(".factor_form .resend").remove();
                $(".factor_form .err_txt").remove();
                setDisabled(false);
                $(".factor_form").append("<span class='err_txt' value="+resendtxt+">入力したコードがただしくありません。</span>");
                // setResendTxt("none");
                $(".factor_form #resend").css("display", "none");
            }
        }).catch((error) => {
            setDisabled(false);
            setText("block");
        })
    };

    return(
        <React.Fragment>
            <div  className="g-0" style={{overflow:"hidden"}}> 
                <div className="card">
                <Row>
                    <Col xxl={6} xl={6} md={6} sm={6} xs={12} className=" xs-hidden">
                        <img src="/images/login.png" alt="profile-img"  style={{width: '100%', height:'100%', objectFit: 'cover'}}/>
                        <div className="login-content" style={{zIndex: 999}}>即戦力人材を活用して、<br/>迅速な課題解決が<br/>可能になります。</div>
                    </Col>
                      <Col  xxl={6} xl={6} md={6} sm={6} xs={12}>
                            <div className="card-body two-factor-form ">
                                <Row>
                                    <Col xxl={{ span: 8, offset: 2 }} xl={{ span: 8, offset: 2 }} md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 1 }}>
                                        <div className="mt_10 border-0">
                                            <div className="two-factor-header text-center border-0" style={{backgroundColor : "#FFFF"}}>
                                                <h1 className="p-3">SESMart</h1>
                                                <p>2段階認証のログインコードを登録メールアドレスに送信しました。</p>
                                                <p>
                                                受け取っていない場合,  <a style={{color : "#82817F"}}
                                                    href="" onClick={(event) => {
                                                    event.preventDefault();
                                                    verifyResend();
                                                }}>ここを押す</a>
                                                {/* <a href="{{ route('verify.resend') }}">ここを押す</a> */}
                                                </p>
                                            </div>
                                            <div className="card-body two-factor-form">
                                                <form className="form" >
                                                        <div className="factor_form" style={text=="none" ? {display:resendtxt} : {display:text}}></div>
                                                    {/* <span className="two-factor-code-error" ></span> */}
                                                        <div className="form-group text-center">
                                                            <input type="text" name='two_factor_code' id= 'two-factor-code' onChange={updateInput} value={two_factor_code} placeholder="2段階のログインコード"  className="form-control mb-3 two_factor_code"  required autoFocus/>
                                                        </div>
                                                        <div className="text-center mt-5">
                                                            <button type="button" onClick={store} className="login-btn" style={{backgroundColor: btnColor}} disabled={disabled}>確認</button>
                                                            <a href="/" style={{fontSize:"15px",color : "#82817F"}}>ログインページへ</a>
                                                        </div>
                                                </form>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                    </Col>
                </Row>
                </div>
            </div>                                    
        </React.Fragment>
    )
};
export default TwoFactorForm;