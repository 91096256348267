import React, {useEffect, useState} from 'react';
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Dropdown from 'react-bootstrap/Dropdown';
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import {Link} from "react-router-dom";
import $ from 'jquery';
import { CSVLink, CSVDownload } from "react-csv";
import DatePicker from "react-datepicker";
import {PurchaseOrderModel} from "../../models/PurchaseOrder";
import {OrderUpdate} from "../../models/PurchaseOrder";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import PurchaseOrderService from '../../services/PurchaseOrderService';
import ResourceService from "../../services/ResourceService";
import {showErrorInfo, generatePDF, PCModalStyle, MobileModalStyle} from "../../components/common/Helpers";

import { error } from 'console';
import Loader from "../../components/common/Loader";
import {Canvg} from 'canvg';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface IState{
    loading :boolean;
    hrs :  [];
    distination_list : [];
    errorMessage : string;  
    perPage : number;
    page : number;
    column: string;
    del_flg : string;
    order: string;
    totalRows : number;
    orderUpdateList : OrderUpdate;
}
interface IProps{}
let PurchaseOrderList:React.FC<IProps> = () => {

    const [search_keywords, setSearchKeyword] = useState("");
    const [selectedTargetDate, setSelectedTargetDate] = useState(null);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const [target_month, setTargetMonth] = useState(""); 
    const [work_month, setWorkMonth] = useState("");
    const [send_email, setSendEMail] = useState("");
    const [admit_status, setAdmitStatus] = useState("");  
    const [delivery_status, setDeliveryStatus] = useState("");
    const [work_company, setWorkCompany] = useState("");
    const [recipient_list, setRecipientList] = useState("");
    // const [del_flg, setDelFlg] = useState("");
    const row : string[] = [];
    const [flgchecked, setFlgChecked] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [sort, setSort] = useState("0");
    const [showPrevColor, setShowPrevColor] = useState("grey");
    const [showNextColor, setShowNextColor] = useState("grey");
    const login_user =   JSON.parse(localStorage.getItem("user") || '{}');
    
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height: 700,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };


    const updatedData = (id:any,process:string,position:string) => {
        let data = {};
        data["orderid"] = id;
        data["process"] = process;
        data["position"] = position;
        if(data["process"] == "reject" || data["process"] == "cancel"){
            let remark = prompt("理由:");
            if (remark == null) {
                return;
            }
            data['remark'] = remark;
        }
        
        PurchaseOrderService.admitDate(data).then((data)=>{
            window.location.reload();
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
        });
    }

    const showPdfData = (order:any) =>{
        PurchaseOrderService.showPdf(order).then((response)=>{
            
            // let file = "accounting_path/"+response.data.data.data.filename;
            // window.open(ResourceService.url(file));

            if(response.data.data.success == "error"){
                alert(response.data.data.msg);
            }else{ 
                let orderid =  response.data.data.data.orderid;
                let store_filename  = response.data.data.data.store_filename;
                let template = response.data.data.data.template;
                const file = new Blob([`${template}`], {type: "image/svg+xml;utf8"});
                ResourceService.apiUpload(file, 'accounting_path/', store_filename).then((res)=>{
                    window.location.href = `/management/download/svg/${orderid}?type=3`;
                });
            }
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }

           
        })

    }

    let downloadURL = process.env.REACT_APP_DOWNLOADURL;
    const dlPdfData = (order:any) =>{
        PurchaseOrderService.showPdf(order).then((response)=>{
            // let file = "accounting_path/"+response.data.data.data.filename;
            // window.open(ResourceService.download(file));
            // window.open(`${downloadURL}/${data.data.filename}`);
            if(response.data.data.success == "error"){
                alert(response.data.data.msg);
            }else{ 
                let orderid =  response.data.data.data.orderid;
                let store_filename  = response.data.data.data.store_filename;
                let template = response.data.data.data.template;
                generatePDF(template, response.data.data.data.pdf_file_name);
            }
        }).catch((error) => {
            console.log(error);
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
        })
    }


    const updateOrder = (order:any) => {
        PurchaseOrderService.showOrder(order).then((data)=>{
            //when show the purchase order page,don't display 'reject!' in hr_remark,manager_remark,director_remark columns
            let hr_remark = data.data.purchase_order.hr_remark? data.data.purchase_order.hr_remark.substring(7):data.data.purchase_order.hr_remark;
            let manager_remark = data.data.purchase_order.manager_remark? data.data.purchase_order.manager_remark.substring(7):data.data.purchase_order.manager_remark;
            let director_remark = data.data.purchase_order.director_remark? data.data.purchase_order.director_remark.substring(7):data.data.purchase_order.director_remark;
            setState({
                    ...state,
                    orderUpdateList : {
                        order_id: data.data.purchase_order.order_id,
                        update_type: "normal",
                        work_company : data.data.purchase_order.work_company,
                        work_month : data.data.purchase_order.work_month,
                        company : data.data.purchase_order.company,
                        buyup_order_no : data.data.purchase_order.buyup_order_no,
                        make_date: data.data.purchase_order.make_date,
                        buyup_order_nm: data.data.purchase_order.buyup_order_nm,
                        seller_contruct : data.data.purchase_order.seller_contruct,
                        belong_type: data.data.purchase_order.belong_type,
                        persion_name_jp : data.data.purchase_order.persion_name_jp,
                        jimu_id : data.data.purchase_order.jimu_name_jp,
                        hr_id : data.data.purchase_order.hr_name_jp,
                        hr_remark: hr_remark,
                        hr_approval_date: data.data.purchase_order.hr_approval_date,
                        manager_id: data.data.purchase_order.manager_name_jp,
                        manager_remark : manager_remark,
                        manager_approval_date : data.data.purchase_order.manager_approval_date,
                        director_id : data.data.purchase_order.director_name_jp,
                        director_remark : director_remark,
                        director_approval_date : data.data.purchase_order.director_approval_date,
                        send_date : data.data.purchase_order.send_date,
                        belong_company_id : data.data.purchase_order.belong_company_id,
                        partner_jimu_name : data.data.purchase_order.partner_jimu_name,
                        partner_jimu_email : data.data.purchase_order.partner_jimu_email,
                        partner_sale_email : data.data.purchase_order.partner_sale_email,
                        bill_from_date : data.data.purchase_order.bill_from_date,
                        bill_to_date : data.data.purchase_order.bill_to_date,
                        buyup_order : data.data.purchase_order.buyup_order,
                        o_adj_hours_low : data.data.purchase_order.o_adj_hours_low,
                        o_adj_hours_high : data.data.purchase_order.o_adj_hours_high,
                        o_adj_hours_flg : data.data.purchase_order.o_adj_hours_flg,
                        contract_price_type : data.data.purchase_order.contract_price_type,
                        buyup_price : data.data.purchase_order.buyup_price,
                        cost_unit : data.data.purchase_order.cost_unit,
                        cost : data.data.purchase_order.cost,
                        buyup_price_hour : data.data.purchase_order.buyup_price_hour,
                        buyup_price_deduct : data.data.purchase_order.buyup_price_deduct,
                        buyup_price_excess : data.data.purchase_order.buyup_price_excess,
                        buyup_order_amount : data.data.purchase_order.buyup_order_amount,
                        subtotal_amount : data.data.purchase_order.subtotal_amount,
                        sales_tax : data.data.purchase_order.sales_tax,
                        total_amount : data.data.purchase_order.total_amount,
                        payment_schedule : data.data.purchase_order.payment_schedule,
                        remarks : data.data.purchase_order.remarks,
                    }
                });
                setOpen(true);
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
        })
    
    }

    const updateCaseAssign = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
        let datas = {};
        datas["orderid"] = state.orderUpdateList.order_id;
        datas["remarks"] = state.orderUpdateList.remarks;
        PurchaseOrderService.orderUpdate(datas).then((response) => {
            getData(work_month,admit_status, delivery_status,work_company,recipient_list, perPage,del_flg,send_email,page,search_keywords, sort, column, order);
            handleClose();
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
            if(error.response.data){
                showErrorInfo(error.response.data);
            }

        })
        
    };

    const defaultColumns = [
       
        {
            name: "order_id",
            label: "編集",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // return(<Link to={`/cases/${tableMeta.rowData[1]}`} >{value}</Link>);
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            updateOrder(`${tableMeta.rowData[0]}`);
                            }}>編集
                        </button>);
                    },
                },
           
        },
        {
            "name" : 'order_id', 
            "label" : "注文書連番",
            options: {
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"80px"}}>
                                {value}
                        </div>
                    );
                }
            }
        },
        {
            "name" : 'work_company', 
            "label" : "稼働会社",
            options: {
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"80px"}}>
                                {value}
                        </div>
                    );
                }
            }
        },
        {
            "name" : 'work_month', 
            "label" : "対象月"
        },
        {
            "name" : 'company',
            "label" : "宛先",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            "name" : 'buyup_order_no', 
            "label" : "注文書No."
        },
        {
            "name" : 'make_date', 
            "label" : "作成日",
            class: "longText",
            options: {
                filter: true, 
                // setCellProps: () => ({ style: { maxWidth: "100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            "name" : 'buyup_order_nm', 
            "label" : "業務名",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            "name" : 'seller_contruct',
            "label" : "発注契約種類"
        },
        {
            "name" : 'belong_type', 
            "label" : "所属種別"
        },
        {
            "name" : 'persion_name_jp', 
            "label" : "要員名",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                            {value}
                        </div>
                    );
                    },
                },
        },
        {
            "name" : 'preview', 
            "label" : "表示",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // return(<Link to={`/cases/${tableMeta.rowData[1]}`} >{value}</Link>);
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            showPdfData(`${tableMeta.rowData[0]}`);
                            }}>表示
                        </button>);
                    },
                },
        },
        {
            "name" : 'download', 
            "label" : "ダウンロード",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // return(<Link to={`/cases/${tableMeta.rowData[1]}`} >{value}</Link>);
                        return(<button type="button" className="btn_standard btn-sm" onClick={() => {
                            dlPdfData(`${tableMeta.rowData[0]}`);
                            }}>ダウンロード
                        </button>);
                    },
                },
        },
        {
            "name" : 'jimu_id', 
            "label" : "事務",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                },
        },
        {
            "name" : 'hr_name', 
            "label" : "担当HR"
        },
        {
            "name" : 'hr_remark', 
            "label" : "HR理由",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(value == null){
                        let hr_remark = value;
                        return(hr_remark);
                    }else{
                        let hr_remark = value.substring(0,7);
                        if(hr_remark == 'reject!'){
                            return(value.substring(7));
                        }else
                        {
                            return(value);
                        }    
                    }
                }
            }
        },
        {
            "name" : 'hr_approval_date', 
            "label" : "HR担当承認日",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                if(login_user.role!=6)
                {
                    if((login_user.role == 3 || login_user.role== 4 || login_user.role== 9) && 
                    tableMeta.rowData[14] != null){
                        if(tableMeta.rowData[16]!=""){
                            if(login_user.user_id == tableMeta.rowData[49]){
                                return(
                                    <div>
                                        {tableMeta.rowData[16]}
                                            <button type= "button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                                updatedData(`${tableMeta.rowData[0]}`,"cancel","hr");
                                                }}>取消</button>
                                    </div>
                                    );
                                }else{
                                    return(tableMeta.rowData[16]);
                                }
                        }
                        else{
                            let hr_reject = 0;
                            if(tableMeta.rowData[15]!=null){
                                hr_reject = (tableMeta.rowData[15].substring(0,7) == "reject!")? 1:0;
                            }
                            if(tableMeta.rowData[23]==null){ //send_date
                                if(hr_reject!=1){
                                    return(
                                        <div>
                                            <button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                        updatedData(`${tableMeta.rowData[0]}`,"admit","hr");
                                        }}>承認
                                            </button>
                                            <button type= "button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                        updatedData(`${tableMeta.rowData[0]}`,"reject","hr");
                                        }}>却下</button>
                                        </div>
                                        );
                                }else
                                {
                                    return(
                                        <div>
                                        <button type= "button" className="btn_disable ml_20 btn-sm">却下</button>
                                        </div>
                                        );
                                } 
                            }
                        }
                    }
                }
            }
            },
        },
        {
            "name" : 'manager_name', 
            "label" : "部長"
        },
        {
            "name" : 'manager_remark', 
            "label" : "部長理由",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(value == null){
                        let manager_remark = value;
                        return(manager_remark);
                    }else{
                        let manager_remark = value.substring(0,7);
                        if(manager_remark == 'reject!'){
                            return(value.substring(7));
                        }else
                        {
                            return(value);
                        }    
                    }
                }
            }
        },
        {
            "name" : 'manager_approval_date', 
            "label" : "部門長承認日",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                if(login_user.role!=6)
                {
                    if((login_user.role== 4 || login_user.role== 9) && tableMeta.rowData[17] != null)
                    {
                        if(tableMeta.rowData[19]!=""){ // manager_approval_date
                            if(login_user.user_id == tableMeta.rowData[50] && tableMeta.rowData[23]==null){
                                return(
                                    <div>
                                        {tableMeta.rowData[19]}
                                        <button type= "button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                            updatedData(`${tableMeta.rowData[0]}`,"cancel","manager");
                                            }}>取消</button>
                                    </div>
                                    );
                            }
                            else{
                                return(tableMeta.rowData[19]);
                            }
                        
                        }
                        else{
                            let hr_reject = 0;
                            let manager_reject = 0;
                            if(tableMeta.rowData[18]!=null){
                                    manager_reject = (tableMeta.rowData[18].substring(0,7) == "reject!")? 1:0;
                            }
                            if(tableMeta.rowData[15]!=null){
                                    hr_reject = (tableMeta.rowData[15].substring(0,7) == "reject!")? 1:0;
                            }
                            if(tableMeta.rowData[23]==null){ //send_date
                                    if(hr_reject != 1 && manager_reject != 1){ //manager_remark & hr_remark
                                        return(
                                            <div>
                                            <button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                            updatedData(`${tableMeta.rowData[0]}`,"admit","manager");
                                            }}>承認
                                            </button>
                                            <button type= "button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                            updatedData(`${tableMeta.rowData[0]}`,"reject","manager");
                                            }}>却下</button>
                                            </div>
                                            );
                                    }else
                                    {
                                        if(hr_reject != 1 && manager_reject == 1){
                                            return(
                                                <div>
                                                <button type= "button" className="btn_disable ml_20 btn-sm">却下</button>
                                                </div>
                                                );
                                            }
                                    }
                            }
                        }
                    }
                }
            }
            }   
        },
        {
            "name" : 'director_name', 
            "label" : "責任者",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                            {value}
                        </div>
                    );
                    },
                },
        },
        {
            "name" : 'director_remark', 
            "label" : "責任者理由",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(value == null){
                        let director_remark = value;
                        return(director_remark);
                    }else{
                        let director_remark = value.substring(0,7);
                        if(director_remark == 'reject!'){
                            return(value.substring(7));
                        }else
                        {
                            return(value);
                        }
                    }
                }
            }
        },
        {
            "name" : 'director_approval_date', 
            "label" : "責任者の承認日",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                if(login_user.role!=6)
                {
                    if(login_user.role== 9 && tableMeta.rowData[20] != null)
                    {
                        if(tableMeta.rowData[22]!="")
                        {
                            if(login_user.user_id == tableMeta.rowData[51] && tableMeta.rowData[23]==null){
                                return(
                                    <div>
                                        {tableMeta.rowData[22]}
                                        <button type= "button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                            updatedData(`${tableMeta.rowData[0]}`,"cancel","director");
                                            }}>取消</button>
                                    </div>
                                    );
                            }
                            else{
                                return(tableMeta.rowData[22]);
                            }
                        
                        }
                        else{
                            let hr_reject = 0;
                            let manager_reject = 0;
                            let director_reject = 0;
                            if(tableMeta.rowData[15]!=null){
                                hr_reject = (tableMeta.rowData[15].substring(0,7) == "reject!")? 1:0;
                            }   
                            if(tableMeta.rowData[18]!=null){
                                manager_reject = (tableMeta.rowData[18].substring(0,7) == "reject!")? 1:0;
                            }
                            if(tableMeta.rowData[21]!=null){
                                director_reject = (tableMeta.rowData[21].substring(0,7) == "reject!")? 1:0;
                            }
                            if(tableMeta.rowData[23]==null){ // send_date
                                    if(hr_reject != 1 && manager_reject != 1 && director_reject != 1){
                                        return(
                                            <div>
                                            <button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                            updatedData(`${tableMeta.rowData[0]}`,"admit","director");
                                            }}>承認
                                            </button>
                                            <button type= "button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                            updatedData(`${tableMeta.rowData[0]}`,"reject","director");
                                            }}>却下</button>
                                            </div>
                                            );
                                    }else
                                    {
                                        if(hr_reject != 1 && manager_reject != 1 && director_reject == 1){
                                            return(
                                                <div>
                                                <button type= "button" className="btn_disable ml_20 btn-sm">却下</button>
                                                </div>
                                                );
                                        }
                                    }
                            }
                        }
                    }
                }
            }
            }
        },
                    // if(tableMeta.rowData[22]==""){
                    //     if(tableMeta.rowData[23]==null){
                    //         if(tableMeta.rowData[18]==null && tableMeta.rowData[15]==null && tableMeta.rowData[21]==null){
                    //             return(
                    //                 <div>
                    //                 <button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                    //                 updatedData(`${tableMeta.rowData[0]}`,"admit","director");
                    //                 }}>承認
                    //                 </button>
                    //                 <button type= "button" className="btn_standard ml_20 btn-sm" onClick={() => {
                    //                 updatedData(`${tableMeta.rowData[0]}`,"reject","director");
                    //                 }}>却下</button>
                    //                 </div>
                    //                 );
                    //         }
                    //         {
                    //             if(tableMeta.rowData[18]==null && tableMeta.rowData[15]==null && tableMeta.rowData[21]!=null){
                    //                 return(
                    //                     <div>
                    //                     <button type= "button" className="btn_disable ml_20 btn-sm">却下</button>
                    //                     </div>
                    //                     );
                    //             }
                    //         }
                    //     }else{
                    //         return(tableMeta.rowData[22]);
                    //     }
                    //  }else{
                    //     return(tableMeta.rowData[22]);
                    // }
       
        {
            "name" : 'send_date', 
            "label" : "送付日",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(tableMeta.rowData[23]==null){
                        if(tableMeta.rowData[19]!="" || tableMeta.rowData[22]!=""){
                            return(
                                <div>
                                 <button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                    changeSendMail(`${tableMeta.rowData[0]}`,"send_email");
                                    }}>送付
                                 </button>
                                 </div>
                                 );
                        }
                    }else{
                        return(<div style={{width:"100px"}}>{value.slice(0,10)}</div>); //cut the time from date
                    }
                     }
                }
        },
        {
            "name" : 'belong_company_id', 
            "label" : "送付先ID"
        },
        {
            "name" : 'partner_jimu_name', 
            "label" : "送付先事務担当名"
        },
        {
            "name" : 'partner_jimu_email', 
            "label" : "送付先事務アドレス"
        },
        {
            "name" : 'partner_sale_email', 
            "label" : "送付先営業アドレス"
        },
        {
            "name" : 'bill_from_date', 
            "label" : "作業期間開始日"
        },
        {
            "name" : 'bill_to_date', 
            "label" : "作業期間終了日"
        },
        {
            "name" : 'buyup_order', 
            "label" : "作業内容",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            "name" : 'o_adj_hours_low', 
            "label" : "基準時間下限"
        },
        {
            "name" : 'o_adj_hours_high', 
            "label" : "基準時間上限"
        },
        {
            "name" : 'o_adj_hours_flg', 
            "label" : "精算有フラグ"
        },
        {
            "name" : 'contract_price_type', 
            "label" : "稼働単金種別"
        },
        {
            "name" : 'buyup_price', 
            "label" : "単金"
        },
        {
            "name" : 'cost_unit', 
            "label" : "工数の単位"
        },
        {
            "name" : 'cost', 
            "label" : "工数の値"
        },
        {
            "name" : 'buyup_price_hour', 
            "label" : "時間単価"
        },
        {
            "name" : 'buyup_price_deduct', 
            "label" : "控除単価"
        },
        {
            "name" : 'buyup_price_excess', 
            "label" : "超過単価"
        },
        {
            "name" : 'buyup_order_amount', 
            "label" : "金額（円）"
        },
        {
            "name" : 'subtotal_amount', 
            "label" : "小計"
        },
        {
            "name" : 'sales_tax', 
            "label" : "消費税"
        },
        {
            "name" : 'total_amount', 
            "label" : "合計"
        },
        {
            "name" : 'payment_schedule', 
            "label" : "支払日",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"80px"}}>
                            {value}
                        </div>
                    );
                    },
                },
        },
        {
            "name" : 'remarks', 
            "label" : "その他備考",
            class: "longText",
            options: {
                filter: true, 
                // setCellProps: () => ({ style: { maxWidth: "80px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                            {value}
                        </div>
                    );
                    },
                },
        },
        {
            "name" : 'del_flg', 
            "label" : "削除フラグ",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div style={{width:"60px"}}>
                            {value}
                        </div>
                    );
                    },
                },
        },
        {
            "name" : 'update_date', 
            "label" : "更新日",
            class: "longText",
            options: {
                filter: true, 
                // setCellProps: () => ({ style: { maxWidth: "100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },

         {
            name: "hr_id",
            label: "HR ID",
            "options":{
                "display":false,
             }
        },
        {
            name: "manager_id",
            label: "Manager ID",
            "options":{
                "display":false,
             }
        },
        {
            name: "director_id",
            label: "責任者 ID",
            "options":{
                "display":false,
             }
           },
    ]
   
    let [state, setState] = useState<IState>({
        loading : false,
        hrs: [],
        distination_list : [],
        errorMessage : "",
        page : 0,
        perPage : 10,
        del_flg : "",
        column : "",
        order : "",
        totalRows : 0,
        orderUpdateList : {
            order_id: "",
            update_type: "",
            work_company : "",
            work_month : "",
            company : "",
            buyup_order_no : "",
            make_date: "",
            buyup_order_nm: "",
            seller_contruct : "",
            belong_type: "",
            persion_name_jp : "",
            jimu_id : "",
            hr_id : "",
            hr_remark: "",
            hr_approval_date: "",
            manager_id: "",
            manager_remark : "",
            manager_approval_date : "",
            director_id : "",
            director_remark : "",
            director_approval_date : "",
            send_date : "",
            belong_company_id : "",
            partner_jimu_name : "",
            partner_jimu_email : "",
            partner_sale_email : "",
            bill_from_date : "",
            bill_to_date : "",
            buyup_order : "",
            o_adj_hours_low : "",
            o_adj_hours_high : "",
            o_adj_hours_flg : "",
            contract_price_type : "",
            buyup_price : "",
            cost_unit : "",
            cost : "",
            buyup_price_hour : "",
            buyup_price_deduct : "",
            buyup_price_excess : "",
            buyup_order_amount : "",
            subtotal_amount : "",
            sales_tax : "",
            total_amount : "",
            payment_schedule : "",
            remarks : "",
        }
    });
    const getData = async (work_month:any, admit_status:string, delivery_status:string,work_company:string,recipient_list:any, perPage:number,del_flg:any,send_email:any,page:number,search_keywords:any, sort:any, column:string, order:string) => {
        setState({...state,loading :true,})
        PurchaseOrderService.list(work_month,admit_status,delivery_status,work_company,recipient_list,perPage,del_flg,send_email,page,search_keywords,sort,column,order).then((response)=>{
            setState({
                ...state,
                loading:false,
                hrs : response.data.data,
                distination_list : response.data.distination_list,
                page : response.data.current_page,
                column : response.data.column,
                del_flg : response.data.del_flg,
                order : response.data.order,
                perPage : response.data.per_page,
                totalRows : response.data.total,
            })
            if(Math.floor(response.data.total/response.data.per_page) >0){
                setShowNextColor("blue");
                }else{
                    setShowNextColor("grey");
                }
                if(0 == response.data.current_page-1){
                    setShowPrevColor("grey");
                }
                if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
                    setShowNextColor("grey");
                }
        }).catch((error) => {
            console.log(error);
            // if(error.response.status == 403){
            //     alert(error.response.data.message);
            // }
            // setState({
            //     ...state,
            //     loading:false,
            //     errorMessage:error.message
            // })
        })
    }

    const deletePurchaseOrder = () => {
        if(selectedRows.length == 0){
            alert("削除したい見込み客をチェックしてください。");
            return false;
        }
        let ans = window.confirm("削除を実施します、大丈夫ですか？");
        if(ans){
            let ids : any[] = [];
            hrs.forEach((element, index) => {            
                if (selectedRows.length > 0 && selectedRows.includes(index)) {
                    ids.push(element["order_id"]);
                }
            });
            let data = {};
            data["ids"] = ids;
            PurchaseOrderService.deleteList(data).then((data)=>{
                getData(work_month,admit_status, delivery_status,work_company,recipient_list, perPage,del_flg,send_email,page,search_keywords, sort, column, order);
                setSelectedRows([]);
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
            });
        }
       
    }

    let {loading, hrs,distination_list, perPage, page,column,del_flg,order,totalRows,orderUpdateList, errorMessage} = state;

    useEffect(()=>{
        setState({...state, loading: true});
        getData(work_month,admit_status, delivery_status,work_company,recipient_list, perPage,del_flg,send_email,page,search_keywords, sort, column, order);
    }, [perPage]);

    let changePage = (page:any) => {
         setCurrentPage(page);
         getData(work_month,admit_status, delivery_status,work_company,recipient_list, perPage,del_flg,send_email,page+1,search_keywords, sort, column, order);
    };

    let changeRowsPerPage = (perPage:any) => {
        getData(work_month,admit_status, delivery_status,work_company,recipient_list, perPage,del_flg,send_email, page+1,search_keywords, sort, column, order);
    };

    const [no_of_rows, setNoOfRows] = useState(10);
    const [current_page, setCurrentPage] = useState(0);
    const options = {
        filterType: 'checkbox' as any,
        tableBodyHeight : "360px",
        tableBodyMaxHeight : "360px",
        responsive: "standard" as any,
        selectableRows: "multiple" as any,
        // selectableRowsHeader: true as any, 
        page: page,
        rowsPerPage: perPage,
        rowsPerPageOptions:[10,20,50,100,200],
        // selectableRowsOnClick: true  as any,
        selectableRowsHeader: true,
        fixedHeader: true,
        fixedSelectColumn: true,
        count: totalRows,
        serverSide: true,
        filter : false,
        print: false,
        viewColumns : false,
        sort: true,
        search : false,
        download :false,
        customToolbarSelect: (selectedRows:any, displayData:any, setSelectedRows:any) => {
            let rows : any[] = [];
                selectedRows.data.forEach((element:any, index:any) => {
                    rows.push(element.dataIndex);
                })
            let csvData : any[] = [];
                hrs.forEach((element, index) => {
                        if (rows.includes(index)) {
                            csvData.push(element);
                        }
                });
            const getFileName = () => {
                    let now = new Date().toLocaleDateString();
                    let year = now.slice(0,4);
                    let month = '0' + now.slice(5,6);
                    let day = now.slice(7);
                    let date = year+month+day;
                    return "注文書" +date;
                }
            let headers = [
                    { label: "注文書連番", key: "order_id" },
                    { label: "稼働会社", key: "work_company" },
                    { label: "対象月", key: "work_month" },
                    { label: "宛先", key: "company" },
                    { label: "注文書No", key: "buyup_order_no" },
                    { label: "作成日", key: "make_date" },
                    { label: "業務名", key: "buyup_order_nm" },
                    { label: "発注契約種類", key: "seller_contruct"},
                    { label: "所属種別", key: "belong_type" },   
                    { label: "要員名", key: "persion_name_jp" },   
                    { label: "担当HR", key: "hr_id" },
                    { label: "HR理由", key: "hr_remark" },
                    { label: "HR担当承認日", key: "hr_approval_date"},
                    { label: "部長", key: "manager_id" },
                    { label: "部長理由", key: "manager_remark" },   
                    { label: "部門長承認日", key: "manager_approval_date" }, 
                    { label: "責任者", key: "director_id" },
                    { label: "責任者理由", key: "director_remark" },
                    { label: "責任者の承認日", key: "director_approval_date"},
                    { label: "送付日", key: "send_date" },
                    { label: "送付先ID", key: "belong_company_id" },   
                    { label: "承認日", key: "approval_date" },
                    { label: "送付先事務担当名", key: "partner_jimu_name" },   
                    { label: "送付先事務アドレス", key: "partner_jimu_email" },
                    { label: "送付先営業アドレス", key: "partner_sale_email" },
                    { label: "作業期間開始日", key: "bill_from_date" },
                    { label: "作業期間終了日", key: "bill_to_date" },
                    { label: "作業内容", key: "buyup_order" },
                    { label: "基準時間下限", key: "o_adj_hours_low" },
                    { label: "基準時間上限", key: "o_adj_hours_high" },
                    { label: "精算有フラグ", key: "o_adj_hours_flg" },
                    { label: "稼働単金種別", key: "contract_price_type" },
                    { label: "単金", key: "buyup_price" },
                    { label: "工数の単位", key: "cost_unit" },
                    { label: "工数の値", key: "cost" },
                    { label: "時間単価", key: "buyup_price_hour" },
                    { label: "控除単価", key: "buyup_price_deduct" },
                    { label: "超過単価", key: "buyup_price_excess" },
                    { label: "金額（円）", key: "cost" },
                    { label: "小計", key: "subtotal_amount" },
                    { label: "消費税", key: "sales_tax" },
                    { label: "合計", key: "total_amount" },
                    { label: "支払日", key: "payment_schedule" },
                    { label: "合計", key: "total_amount" },
                    { label: "その他備考", key: "remarks" },
                    { label: "削除フラグ", key: "del_flg" },
                    { label: "更新日", key: "update_date" },
                  ];
            return (<CSVLink  className="btn_standard text-decoration-none" filename={getFileName()} data={csvData}  headers={headers}>ダウンロード</CSVLink>);
        },
        textLabels: {
            body: {
              noMatch: "データはありません。",
              toolTip: "Sort"
            },
            pagination: {
              next: "Next Page",
              previous: "Previous Page",
              rowsPerPage: "件表示", 
              displayRows: "すべての件数：",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
            filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              selectedRows: {
                text: "行 を選択しました。",
                delete: "削除",
                deleteAria: "選択した行を削除しました。",
              },
          },
          rowsSelected: selectedRows,
          onRowSelectionChange: (rowsSelectedData:any, allRows:any, rowsSelected:any) => {
            setSelectedRows(rowsSelected);
        },
        onChangePage (currentPage:any) {
            changePage(currentPage);
            setCurrentPage(currentPage);
        },
          onChangeRowsPerPage (numberOfRows:any) {
            changeRowsPerPage(numberOfRows);
            setNoOfRows(numberOfRows);
         },
         onColumnSortChange: (changedColumn:any, direction:any) => {
            let order = 'desc';
            if (direction === 'asc') {
              order = 'asc';
            }
            handleSort(changedColumn, order);
          },
    };

    let handleSort = (column:any, order:any) => {
        getData(work_month,admit_status, delivery_status,work_company,recipient_list, perPage,del_flg,send_email, page+1,search_keywords, sort,column, order);      
        setSelectedRows([]);
    };

      const changeSendMail = function(order_id:any, column_name:any){
        let selected_data = order_id;
        switch (column_name) {
            case "send_email":
                setSendEMail(selected_data);
                getData(work_month,admit_status, delivery_status,work_company,recipient_list, perPage,del_flg,selected_data, page,search_keywords, sort,column, order);
                break;
            default:
                break;
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => { 
        switch (e.target.name) {
            case "search":
                    setSearchKeyword(e.target.value);
                    getData(work_month,admit_status, delivery_status,work_company,recipient_list, perPage,del_flg,send_email, page,e.target.value, sort, column, order);              
                break;
            default:
                break;
        }
      };

      const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        switch (e.target.name) {
            case "admit_status":
                setAdmitStatus(e.target.value);
                getData(work_month,e.target.value, delivery_status,work_company,recipient_list, perPage,del_flg,send_email,page,search_keywords, sort, column, order);
                break;
            case "delivery_status":
                setDeliveryStatus(e.target.value);
                getData(work_month,admit_status, e.target.value,work_company,recipient_list, perPage,del_flg,send_email, page,search_keywords, sort, column, order);
                break;
            case "work_company":
                setWorkCompany(e.target.value);
                getData(work_month,admit_status, delivery_status,e.target.value,recipient_list, perPage,del_flg,send_email, page,search_keywords, sort, column, order);
                break;
            case "recipient_list":
                setRecipientList(e.target.value);
                getData(work_month,admit_status, delivery_status,work_company,e.target.value, perPage,del_flg,send_email, page,search_keywords, sort, column, order);
                break;
            // case "sort":
            //     setSort(e.target.value);
            //     getData(work_month,admit_status, delivery_status,work_company,recipient_list, perPage,del_flg, page,search_keywords,e.target.value,column, order);
            //     break;
            case "list_length":
                changeRowsPerPage(e.target.value);
                setNoOfRows(parseInt(e.target.value));
                setState({
                    ...state,
                    perPage : parseInt(e.target.value)
    
                })
                    break;
            default:
                break;
       }
      }

      let nextPage = () => {
        
        // if(Math.floor(totalRows/perPage) >0){
        //     setShowNextColor("blue");
        // }

        if(Math.floor(totalRows/perPage) >0){
            changePage(current_page+1);
            setCurrentPage(current_page+1);
            setState({
                ...state,
                page : current_page+1
            })
            setShowNextColor("blue");
        }
        
        if(Math.ceil(totalRows/perPage)-2 == current_page){ // Last Page
            setShowNextColor("grey");
        }   
        if(0 < current_page+1){
            setShowPrevColor("blue");
        }     

    }

    let previousPage = () => {
        if(current_page > 0){
            setCurrentPage(current_page-1);
            changePage(current_page - 1);
            setState({
                ...state,
                page : current_page-1
            });
            setShowPrevColor("blue");
        }
        if(0 == current_page-1){
            setShowPrevColor("grey");
        } 

        if(Math.floor(totalRows/perPage) >0){
            setShowNextColor("blue");
        } 
        
    }

    //   let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
    //     setState({
    //         ...state,
    //         orderUpdateList : {
    //             ...state.orderUpdateList,
    //             [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
    //         }
    //     });   
    // };

    const handleChange  = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.target.name) {
            case "del_flg":
                if(e.target.checked == true){
                    e.target.value = "1";            
                    setFlgChecked(e.target.checked);
                    getData(work_month,admit_status, delivery_status,work_company,recipient_list, perPage,e.target.value,send_email, page,search_keywords, sort, column, order);
                }else
                {
                    setFlgChecked(e.target.checked);
                    e.target.value = "";
                    getData(work_month,admit_status, delivery_status,work_company,recipient_list, perPage,e.target.value,send_email, page,search_keywords, sort, column, order);
                   
                }
                break;
            default:
                break;
        }
      };

    let updateTextArea = (event:React.ChangeEvent<HTMLTextAreaElement>):void => {
        setState({
            ...state,
            orderUpdateList : {
                ...state.orderUpdateList,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        });
    }
      
      const handleDateChange = function(date:any, column_name:any){
        let selected_data =(date != null)? date.getFullYear() +"-"+ (date.getMonth() + 1).toString().padStart(2, "0") : "";
        switch (column_name) {
            case "work_month":
                setSelectedTargetDate(date);
                setWorkMonth(selected_data);
                getData(selected_data,admit_status, delivery_status,work_company,recipient_list, perPage,del_flg,send_email, page,search_keywords, sort, column, order)
                break;
            default:
                break;
        }
    }
        const getMuiTheme = () =>
    
    
         createTheme({
        components: {
        MUIDataTable: {
            styleOverrides:{
                responsiveScroll: {
                    maxHeight: 'none',
                  },
            }
        },
        MuiPaper: {
            styleOverrides:{
                root: {
                    width: "100%",
                    marginTop: "5%",
                    // // marginLeft: "5%",
                    // // marginBottom: "20%"
                }
            }
        },  
        MUIDataTableHeadCell: {
                styleOverrides:{
                  root: {
                      backgroundColor: "rgb(193,193,193)",
                      padding: '0px'
                  }
                }
        },
        MuiTableCell: {
            styleOverrides:{
                head: {
                        color : 'white',
                        backgroundColor: "red !important"
                }
            }
        },
          MUIDataTableBodyCell: {
            styleOverrides:{
              root: {
                  backgroundColor: "#f1f1f1",
                  width: "100%",
                  padding: '0px'
              }
            }
          }
        }
      })

      const edit_modal_box =(
        <div>
            <Row>
                <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                    <button type="button" className="modal_close" onClick={handleClose}>X</button>
                </Col>
            </Row>
            <form className="form purchaseOrderForm" onSubmit={updateCaseAssign}>
                <Row>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="order_id">注文書連番:</label> 
                            <input type="text" name="order_id"  value={orderUpdateList['order_id']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="work_company">稼働会社:</label> 
                            <input type="text" name="work_company"  value={orderUpdateList['work_company']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="work_month">対象月:</label> 
                            <input type="text" name="work_month"  value={orderUpdateList['work_month']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="company">宛先:</label> 
                            <input type="text" name="company"  value={orderUpdateList['company']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="buyup_order_no">注文書No.</label> 
                            <input type="text" name="buyup_order_no"  value={orderUpdateList['buyup_order_no']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="make_date">作成日</label> 
                            <input type="text" name="make_date"  value={orderUpdateList['make_date']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="buyup_order_nm">業務名:</label> 
                            <input type="text" name="buyup_order_nm"  value={orderUpdateList['buyup_order_nm']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="seller_contruct">発注契約種類</label> 
                            <input type="text" name="seller_contruct"  value={orderUpdateList['seller_contruct']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="belong_type">所属種別</label> 
                            <input type="text" name="belong_type"  value={orderUpdateList['belong_type']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="persion_name_jp">要員名:</label> 
                            <input type="text" name="persion_name_jp"  value={orderUpdateList['persion_name_jp']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="jimu_id">事務</label> 
                            <input type="text" name="jimu_id"  value={orderUpdateList['jimu_id']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="hr_id">担当HR</label> 
                            <input type="text" name="hr_id"  value={orderUpdateList['hr_id']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="hr_remark">HR理由</label> 
                            <input type="text" name="hr_remark"  value={orderUpdateList['hr_remark']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="hr_approval_date">HR担当承認日</label> 
                            <input type="text" name="hr_approval_date"  value={orderUpdateList['hr_approval_date']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="manager_id">部長</label> 
                            <input type="text" name="manager_id"  value={orderUpdateList['manager_id']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="manager_remark">部長理由</label> 
                            <input type="text" name="manager_remark"  value={orderUpdateList['manager_remark']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="manager_approval_date">部門長承認日</label> 
                            <input type="text" name="manager_approval_date"  value={orderUpdateList['manager_approval_date']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="director_id">責任者</label> 
                            <input type="text" name="director_id"  value={orderUpdateList['director_id']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="director_remark">責任者理由</label> 
                            <input type="text" name="director_remark"  value={orderUpdateList['director_remark']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="director_approval_date">責任者の承認日</label> 
                            <input type="text" name="director_approval_date"  value={orderUpdateList['director_approval_date']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="send_date">送付日</label> 
                            <input type="text" name="send_date"  value={orderUpdateList['send_date']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="belong_company_id">送付先ID</label> 
                            <input type="text" name="belong_company_id"  value={orderUpdateList['belong_company_id']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="partner_jimu_name">送付先事務担当名</label> 
                            <input type="text" name="partner_jimu_name"  value={orderUpdateList['partner_jimu_name']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="partner_jimu_email">送付先事務アドレス</label> 
                            <input type="text" name="partner_jimu_email"  value={orderUpdateList['partner_jimu_email']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="partner_sale_email">送付先営業アドレス</label> 
                            <input type="text" name="partner_sale_email"  value={orderUpdateList['partner_sale_email']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="bill_from_date">作業期間開始日</label> 
                            <input type="text" name="bill_from_date"  value={orderUpdateList['bill_from_date']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="bill_to_date">作業期間終了日</label> 
                            <input type="text" name="bill_to_date"  value={orderUpdateList['bill_to_date']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="buyup_order">作業内容</label> 
                            <input type="text" name="buyup_order"  value={orderUpdateList['buyup_order']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="o_adj_hours_low">基準時間下限</label> 
                            <input type="text" name="o_adj_hours_low"  value={orderUpdateList['o_adj_hours_low']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="o_adj_hours_high">基準時間上限</label> 
                            <input type="text" name="o_adj_hours_high"  value={orderUpdateList['o_adj_hours_high']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="o_adj_hours_flg">精算有フラグ</label> 
                            <input type="text" name="o_adj_hours_flg"  value={orderUpdateList['o_adj_hours_flg']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="contract_price_type">稼働単金種別</label> 
                            <input type="text" name="contract_price_type"  value={orderUpdateList['contract_price_type']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="buyup_price">単金</label> 
                            <input type="text" name="buyup_price"  value={orderUpdateList['buyup_price']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="cost_unit">工数の単位</label> 
                            <input type="text" name="cost_unit"  value={orderUpdateList['cost_unit']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="cost">工数の値</label> 
                            <input type="text" name="cost"  value={orderUpdateList['cost']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="buyup_price_hour">時間単価</label> 
                            <input type="text" name="buyup_price_hour"  value={orderUpdateList['buyup_price_hour']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="buyup_price_deduct">控除単価</label> 
                            <input type="text" name="buyup_price_deduct"  value={orderUpdateList['buyup_price_deduct']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="buyup_price_excess">超過単価</label> 
                            <input type="text" name="buyup_price_excess"  value={orderUpdateList['buyup_price_excess']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="buyup_order_amount">金額（円）</label> 
                            <input type="text" name="buyup_order_amount"  value={orderUpdateList['buyup_order_amount']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="subtotal_amount">小計</label> 
                            <input type="text" name="subtotal_amount"  value={orderUpdateList['subtotal_amount']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="sales_tax">消費税</label> 
                            <input type="text" name="sales_tax"  value={orderUpdateList['sales_tax']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="total_amount">合計</label> 
                            <input type="text" name="total_amount"  value={orderUpdateList['total_amount']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                        <div className="form-group">
                            <label htmlFor="payment_schedule">支払日</label> 
                            <input type="text" className="form-control" name="payment_schedule"  value={orderUpdateList['payment_schedule']} disabled={true} />
                        </div> 
                    </Col>
                </Row>
                <Row>
                    <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                        <div className="form-group">
                            <span id='text'>その他備考</span>
                            <textarea className="form-control" name = "remarks" id= "remarks" aria-label="With textarea" value={(orderUpdateList["remarks"] != null)?orderUpdateList["remarks"]:""} onChange={updateTextArea}></textarea>
                        </div>          
                    </Col>
                </Row>
                <Row>
                    <div className="form-group mt-5 text-center">
                    <button type="submit" className="button submit-btn btn_standard">更新</button>
                    </div>
                </Row>
            </form>
        </div>
      )

    return(
        <React.Fragment>
            <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                    ml: sizeConfigs.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color
                }}
                >
                 <Toolbar>
                <Typography variant="h6">
                    <header className="top-baner">
                        <Row>
                            <Col xxl={10} xl={10} md={10} sm={10} xs={10}>

                                <div id="search-autocomplete" className="form-outline">
                                    <input type="text" name="search" id="search" placeholder = "検索(注文書連番、宛先、注文書No、業務名、作業内容、発注契約種類、要員名を検索)"
                                        className="form-control"  onChange={onChange}/>
                                </div>
                            </Col>
                            <Col xxl={2} xl={2} md={2} sm={2} xs={2} className="mt-4">
                                <DropDownList />
                            </Col>
                        </Row>
                    </header>
                    </Typography>
                </Toolbar>
                </AppBar>
                <Container>
                        <Row>
                            <Col xxl={4} xl={4} md={4} sm={4} xs={8}>
                                <h3 className='header-left-design'>注文書一覧</h3>
                            </Col>
                            <Col xxl={4} xl={4} md={4} sm={4} xs={4}>
                                <div className="mt-3 text-center">
                                    {( loading   == true) ? <Loader /> :<> </>}
                                </div>
                            </Col>
                        </Row> 

                        <Row className = "mt-3 mb-3 filter_select">
                            <Col xxl={12}>
                                <div className="form-group work_month">
                                    <label className="control-label">対象月</label>
                                    <DatePicker 
                                        dateFormat="yyyy-MM"
                                        locale="ja"
                                        selected={selectedTargetDate} 
                                        onChange={date =>
                                            handleDateChange(date, "work_month")
                                        }
                                        name = "work_month"
                                        isClearable
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        />
                                </div>
                            </Col>
                        </Row>
                        <Row className="p-2">
                            <Col xxl={9} xl={9} md={9} sm={9} xs={12} className="check-list">
                                <label htmlFor="sort" className="control-label">承認状況</label>
                                <select className="filter" name="admit_status" aria-label=".form-select-sm example" onChange={onSelect}>
                                    <option value="">全て</option>
                                    <option value="1">未承認</option>
                                    <option value="2">承認中</option>
                                    <option value="3">承認済</option>
                                </select>
                                <label htmlFor="sort" className="control-label">送付状況</label>
                                <select className="filter" name="delivery_status" aria-label=".form-select-sm example" onChange={onSelect}>
                                    <option value="">全て</option>
                                    <option value="0">未送付</option>
                                    <option value="1">送付済</option>
                                </select>
                                <label htmlFor="sort" className="control-label">稼働会社</label>
                                <select className="filter" name="work_company" aria-label=".form-select-sm example" onChange={onSelect}>
                                    <option value="">全て</option>
                                    <option value= "0">FloBoard</option>
                                    <option value="1">フロネット</option>
                                </select>
                                <label htmlFor="sort" className="control-label">宛先リスト</label>
                                <select className="filter" name="recipient_list" aria-label=".form-select-sm example" onChange={onSelect}>
                                    <option value="">選ばない</option>
                                    {distination_list.map(distination => {
                                            if (distination !== null){
                                                return(<option value={distination['company']}> {distination['company']}</option>)
                                            }
                                        })
                                    }
                                </select>
                        </Col>
                        <Col xxl={3} xl={3} md={3} sm={3} xs={12}>
                            <div className="float-end">
                                <input className="" type="checkbox" id="del_flg" name="del_flg" checked={flgchecked} value="" onChange={handleChange} style={{position:"relative",top:"2px"}} />
                                <label htmlFor="del_flg"  style={{paddingLeft:"10px"}}> 削除済のデータ表示</label>
                                <button type="button" className="btn_danger ml_20 btn-sm" onClick={deletePurchaseOrder}>削除</button>
                            </div>
                        </Col>
                    </Row>
                        <Row className="p-2">
                            <Col xxl={6} xl={6} md={6} sm={6} xs={6}>
                                <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                                {/* <select name="sort" id="sort" className="filter" onChange={onSelect}>
                                    <option value="0" selected>新しい順</option>
                                    <option value="1" >古い順</option>
                                </select> */}
                            </Col>
                            <Col xxl={6} xl={6} md={6} sm={6} xs={6}>
                                <div className="float-right">
                                        <select name="list_length" id="list_length" value={no_of_rows} className="filter list_length" onChange={onSelect}>
                                            <option value="10">10</option>
                                            <option value="20" >20</option>
                                            <option value="50" >50</option>
                                            <option value="100" >100</option>
                                            <option value="200" >200</option>
                                        </select>
                                        <label htmlFor="sort" className="control-label">件表示</label>
                                        <button type="button" className="btn-sm previous-page-icon" style={{border:"0px"}} onClick={() => {
                                            previousPage();
                                            }}>
                                        <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                        </button>
                                        <button type="button" className=" btn-sm next-page-icon" style={{border:"0px"}} onClick={() => {
                                            nextPage();
                                            }}>
                                            <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                                        </button> 
                                </div>
                            </Col>
                        </Row>
                        <div className='datatable purchase-order-freeze-header purchase-order-freeze-cell purchase-order-frozen-columns xs-hidden'>
                            <MUIDataTable
                                title={''}
                                data={hrs}
                                columns={defaultColumns}
                                options = {options}
                            />
                        </div>
                        <div className='datatable mobile pc-hidden'>
                            <MUIDataTable
                                title={''}
                                data={hrs}
                                columns={defaultColumns}
                                options = {options}
                            />
                        </div>
                </Container>
                <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                    sx={ {
                        display: { xs: 'none', sm: 'block' },
                    }}
                >  
                    <Box sx={PCModalStyle}>
                        {edit_modal_box}
                    </Box>
                </Modal>
                <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                    sx={ {
                        display: { xs: 'block', sm: 'none' },
                    }}
                >  
                    <Box sx={MobileModalStyle}>
                           {edit_modal_box}
                    </Box>
                </Modal>
        </React.Fragment>
    );
};
export default PurchaseOrderList;