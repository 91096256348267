import $ from 'jquery';
import {useLocation} from "react-router-dom";
import {Canvg} from 'canvg';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

    export function showErrorInfo(result:any) {
        if(result.errors  && 'object' === typeof result.errors){
            let input_name;
            let textarea_name;
            let select_name;
            $(".input-box .error").remove();

            for(let key in result.errors){
                $('textarea[name="'+key+'"],input[name="'+key+'"], select[name="'+key+'"]').closest(".input-box").append("<span id='star' class='error'>"+result.errors[key].join('\n')+"</span>");
                if(key == "required_skill" || key == "skill"){
                    $(".input-box .check-list").append("<span id='star' class='error'>"+result.errors[key].join('\n')+"</span>");

                }
            }
        }else if(result.message){
            alert(result.message);
        }else{
            alert('サーバーエラー');
        }     
    }

    export function checkEmpty(value:any) {
        let count;
        if(value === null  || value === '' || !value) {
            count = 0;
        }else{
            count = value;
        }
        return count;
    }
    
    export function truncateDecimals(number:any, digits:any) {
        var multiplier = Math.pow(10, digits),
            adjustedNum = number * multiplier,
            truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);
    
        return truncatedNum / multiplier;
    };

    export function checkMaxLength(lengthAttribute:any, length:any , name:string){
        if (lengthAttribute != null && length >= lengthAttribute) {
           
            $("input[name='"+name+"']").closest('.input-box').append("<span id='star' class='error'>入力可能な数は"+lengthAttribute+"までです。</span>");
            $("textarea[name='"+name+"']").closest('.input-box').append("<span id='star' class='error'>入力可能な数は"+lengthAttribute+"までです。</span>");
        }else{
            $("input[name='"+name+"']").next('.error').remove();   
            $("textarea[name='"+name+"']").next('.error').remove();   
        }
    }

    

    export function inputNumber(e:any) {
        const inputValue = e.target.value;
        const max = e.target.getAttribute("maxlength");
        if (e.code === 'Minus' || e.key === 'e' || (max != undefined && /^\d*$/.test(inputValue) && inputValue.length >=max)) {
            e.preventDefault();
        }

        
    };

    export async function generatePDF(svgDataArr:any, filename:any) {
        const A4_WIDTH_MM = 210;
        const A4_HEIGHT_MM = 295;
        // Create a temporary canvas to render the SVG
        const pdf = new jsPDF('p', 'mm', 'a4');
        for (let i = 0; i < svgDataArr.length; i++) {
            const svgData = svgDataArr[i];

            // Set A4 size in pixels for 150 DPI
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = 1880; // Set width according to your SVG
            canvas.height = 2600; // Set height according to your SVG
            
            // Render SVG to canvas
            Canvg.fromString(ctx!, svgData).render();
        
            // Convert the canvas to PNG data URL
            const imgData = canvas.toDataURL('image/png');

            // Create a new PDF
            const imgWidth = A4_WIDTH_MM; // A4 paper width in mm
            const pageHeight = 295; // A4 paper height in mm
            const imgHeight = canvas.height * imgWidth / canvas.width;
            // let heightLeft = imgHeight;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= A4_HEIGHT_MM;
            // Add additional pages if necessary
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position + 30, imgWidth, imgHeight);
                heightLeft -= A4_HEIGHT_MM;
            }
        
            // Add a new page for the next SVG unless it's the last SVG
            if (i < svgDataArr.length - 1) {
                pdf.addPage();
            }
        }
        pdf.save(filename);

      };


    

    export const PCModalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: `calc(100% - 50%)`,
        height:  `calc(100% - 5%)`,
        resize: 'both',
        overflow: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    
    export const MobileModalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: `calc(100% - 10%)`,
        height:  `calc(100% - 5%)`,
        resize: 'both',
        overflow: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    


