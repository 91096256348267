import React, {useState, useContext, useEffect} from 'react';
import {IUser} from "../models/IUser";
import AuthService from "../services/AuthService";
import '../index.css';
import '../login.css';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {showErrorInfo, PCModalStyle, MobileModalStyle} from "../components/common/Helpers";
import {AppStateContext, AppConstStateActions} from '../redux/AppStore';
import $ from 'jquery';


interface IState{
    user : IUser
}
interface IProps{}

let LoginForm:React.FC<IProps> = () => {
    const appStateContext = useContext(AppStateContext);

    if(!appStateContext) {
      throw Error('CurrentContext must be used whith a CounterProvider.');
    }
	const navigate = useNavigate();
    const { appDatas, appAction } = appStateContext;
    let [state , setState] = useState<IState>({
        user : {
            email : '',
            password : ''
        }
    });

    let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setState({
            user : {
                ...state.user,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        });
    };
    let [login_error, setLoginError] = useState("none");
    const [open, setOpen] = React.useState(false);
	const [url,setURL] = useState("http://localhost:3000/verify");
    const [btnColor, setBtnColor] = useState("#444444ab");
    const [disabled, setDisabled] = React.useState(false);

    const handleClose = () => setOpen(false);

	

    // const style = {
    //     position: 'absolute' as 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: 800,
    //     height: 700,
    //     bgcolor: 'background.paper',
    //     border: '2px solid #000',
    //     boxShadow: 24,
    //     p: 4,
    //   };

    let login =  (event:any) =>{// click event type declare in React
        event.preventDefault();
		setBtnColor("#44444459");
		setDisabled(true);

		let datas = {};
		datas["email"]= state.user.email;
		datas["password"] = state.user.password;
		datas["url"] = url;
		// datas["server_url"] = url;

        AuthService.login(datas).then((response) => {
			setLoginError("none");
			setBtnColor("#444444ab");
			$(".login-form .form #star").remove();

            if(response.data.status == 200){
                setLoginError("none");
                // localStorage.setItem("token", response.data.token);
                localStorage.setItem("login_email", datas["email"]);
				localStorage.setItem("login_pwd", datas["password"]);
				// localStorage.setItem("datas", JSON.stringify(response.data.datas));
				// window.location.href = "/verify";
				navigate('/verify');
                // AuthService.resourceLogin(response.data.token, "/cases/list");
            }else if(response.data.status == 401){

				setBtnColor("#444444ab");
                setLoginError("block");
				setDisabled(false);
                $(".login-error").css("display", "block !important");
				$(".login-form .form").prepend("<span id='star'>"+response.data.message+"</span>"); 
			}else{
				setBtnColor("#444444ab");
                setLoginError("block");
				setDisabled(false);
                $(".login-error").css("display", "block !important");
				$(".login-form .form").prepend("<span id='star'>メールアドレスまたはパスワードが間違っています。</span>"); 
            }
            
        }).catch((error) => {
			setDisabled(false);
            setLoginError("block");
            // if(error.response.data){
            //     showErrorInfo(error.response.data);
            // }

        })
    };

	const  policyModal = (
		<div className="row">
		<div className="col-12">
			<div className="modal-content">
						<div className="modal-header">
						<h3 className="modal-title" id="exampleModalLabel">SESMart企業用　利用規約</h3>
						<button type="button" className="modal_close" onClick={handleClose}>X</button>
						{/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button> */}
						</div>
						<div className="modal-body">
							<div className="float-left">
								<h3 className="float-left">第１条（定義）</h3>
								<p className="float-left pl-4 mb-0 mt-3" style={{fontSize:"13px"}}>本規約において使用する用語の意義は、次の各号に定めるとおりとします。</p>
								<ol type="1" className="float-left mt-0 " style={{fontSize:"13px"}}>
									<li>「本規約」とは、「SESMart®企業用　利用規約」をいいます。</li>
									<li>「当社」とは、株式会社ＦｌｏＢｏａｒｄをいいます。</li>
									<li>「本サービス」とは、当社が運営する転職支援サービス「SESMart®」（その理由を問わずサービスの名称または内容が変更された場合、当該変更後のサービスを含みます）をいいます。</li>
									<li>「利用者」とは、本サービスを利用する者をいいます。</li>
									<li>「その他の規程」とは、ガイドライン、ヘルプ、その他の本規約以外の本サービスに関する当社所定の規程をいいます。</li>
									<li>「本目的」とは、利用者が本サービスを利用する目的として当社が許諾したものをいい、具体的には利用者が行う自己の採用活動等のことをいいます。</li>
									<li>「本サイト」とは、本サービスに関する情報が掲載された当社が運営するウェブサイトをいいます。</li>
									<li>「利用希望者」とは、本サービスを利用することを希望する者をいいます。</li>
									<li>「ＩＤ・パスワード」とは、アカウントごとに発行されるＩＤ・パスワードをいいます。</li>
									<li>「登録事項」とは、利用希望者が本サービスの申込みの際に登録する当社所定の情報をいいます。</li>
									<li>「利用環境」とは、ハードウェア、ソフトウェア、インターネット接続回線、セキュリティの確保等、本サービスの利用に必要な環境をいいます。</li>
									<li>「知的財産権等」とは、特許権、実用新案権、意匠権、商標権、著作権、不正競争防止法上の権利、その他一切の財産的もしくは人格的権利をいいます。</li>
								</ol>	
							</div>
							<div className="float-left">
								<h3 className="float-left">第２条（適用範囲）</h3><br/>
								<ol type="1" className="" style={{fontSize:"13px"}}>
									<li>本規約は、本サービスにおいて当社と利用者とに適用されます。利用者は、本規約の全ての内容に同意した上で、本サービスを利用するものとします。</li>
									<li>当社は、本サービスまたは本サイトへの掲載その他当社所定の方法により、その他の規程を定める場合があります。その他の規程は、本規約の一部を構成するものとしますが、本規約とその他の規程の内容が異なる場合は、本規約が優先して適用されます。</li>
								</ol>	
							</div>
							<div className="float-left">
								<h3 className="float-left">第３条（本サービス）</h3><br/>
								<ol type="1" className="" style={{fontSize:"13px"}}>
									<li>当社は、本サービスの仕様の改良、追加、削除等の変更を行うことがあり、利用者は、これを予め承諾します。</li>
									<li>当社は、本サービスの遂行を、必要に応じて第三者に委託することができるものとし、利用者は、これを予め承諾します。</li>
								</ol>	
							</div>
							<div className="float-left">
								<h3 className="float-left" >第４条（有料オプションサービスの対価および支払方法等）</h3><br/>
								<ol type="1" className="" style={{fontSize:"13px"}}>
									<li>本有料オプションサービス（以下「有料サービス」という）の対価として利用者は、当社所定の申込書（以下「本申込書」という）上に記載された金額を当社に支払うものとします。</li>
									<li>利用者は本申込書に表示する料金を、当社が発行する請求書に基づき支払うものとします。</li>
									<li>本申込書において、当社からの対価請求先として利用者と異なる法人等を指定した場合（以下「対価支払者」という）、当社は、対価支払者による弁済を認めるものとします。但し、対価支払者が支払を行わなかった場合は、利用者が支払を行うものとします。</li>
									<li>前項の定めにより対価支払者が弁済を行なった場合といえども、それにより利用者の権利義務が対価支払者に移転するものではありません。</li>
									<li>利用者又は対価支払者は、有料サービスにより発生する対価について支払を遅延したときは、当社に対して、支払期日の翌日から完済に至るまでの期間について、年14.6％の割合（年365日日割計算）による遅延損害金を支払うものとします。</li>
									<li>有料サービスが終了した場合（当社の責に帰すべき事由による場合を除きます。）においても、当社は、利用者が既に当社に対して支払った利用料に関して、いかなる事由であっても利用者に返還する義務を負わないものとします。ただし、当社が別に認めた場合にはこの限りではありません。</li>
								</ol>	
							</div>
							<div className="float-left">
								<h3 className="float-left">第５条（本サービスの非保証等）</h3><br/>
								<ol type="1" className="" style={{fontSize:"13px"}}>
									<li>当社は、本サービスにおける品質や機能、または本サービスを通じて取得するその他の情報等に関して、その完全性、正確性及び有用性等につき、明示的であれ黙示的であれ、一切の保証を行わないものとします。また、利用者が本サービスを利用して行った求人、募集その他の活動の結果につき、一切の責任を負わないものとします。 利用者は自己の責任に基づき本サービスを利用するものとします。</li>
									<li>本サービスの提供または利用に関連して、第三者との間にトラブルが発生した場合は、当社は、当社の故意または重過失がある場合を除き、かかるトラブルにつき何ら関与せず、責任を負わないものとし、利用者が自己の責任と費用をもって解決するものとします。</li>
									<li>前項に定める他、当社は、次の各号につき、いかなる保証も行うものではありません。さらに、利用者が当社から直接または間接に、本サービスに関する情報を得た場合であっても、当社は、利用者に対し、本規約において規定されている内容を超えて、いかなる保証も行うものではありません。
										<ol type="1" className="m-0" style={{fontSize:"13px"}}>
											<li>本サービスの利用に起因して利用環境に不具合や障害が生じないこと</li>
											<li>本サービスが正確かつ完全であること</li>	
											<li>本サービスが永続的に稼働すること</li>
											<li>本サービスが利用者の特定の目的に適合し、有用であること</li>	
											<li>本サービスが利用者に適用のある法令、業界団体の内部規則等に適合すること</li>
										</ol>
									</li>
									<li>前項の定めにより対価支払者が弁済を行なった場合といえども、それにより利用者の権利義務が対価支払者に移転するものではありません。</li>
									<li>利用者又は対価支払者は、有料サービスにより発生する対価について支払を遅延したときは、当社に対して、支払期日の翌日から完済に至るまでの期間について、年14.6％の割合（年365日日割計算）による遅延損害金を支払うものとします。</li>
									<li>有料サービスが終了した場合（当社の責に帰すべき事由による場合を除きます。）においても、当社は、利用者が既に当社に対して支払った利用料に関して、いかなる事由であっても利用者に返還する義務を負わないものとします。ただし、当社が別に認めた場合にはこの限りではありません。</li>
								</ol>	
							</div>
							<div className="float-left">
								<h3 className="float-left">第６条（利用条件）</h3><br/>
								<ol type="1" className="" style={{fontSize:"13px"}}>
									<li>利用者は、自らの責任と費用において、利用環境を整備します。</li>
									<li>利用者は、本目的のために、本規約で認められた範囲で、本サービスを利用することができます。</li>
									<li>利用者は、本目的の範囲を超えて本サービスを利用することはできません。例えば、本サービス以外に独自のホームページを作成したりすることは許されません。但し、当社が別途個別に認めた場合は、この限りではありません。</li>
								</ol>	
							</div>
							<div className="float-left">
								<h3 className="float-left">第７条（申込）</h3><br/>
								<ol type="1" className="" style={{fontSize:"13px"}}>
									<li>利用希望者は、本規約の内容に同意した上で、当社所定の方法により、本サービス利用の申込みを行うものとします。利用希望者は、登録事項が、全て正確であることを保証します。</li>
									<li>当社は、当社所定の基準により、利用希望者の申込みの可否を判断します。利用希望者が以下のいずれかに該当しまたは該当すると当社が判断した場合は、利用希望者の申込みを認めないことができます。なお、当社は、上記判断に関する理由を開示する義務は負いません。
										<ol type="1" className="m-0">
											<li>当社所定の方法によらずに登録の申込を行った場合</li>
											<li>登録事項の必須項目に全部または一部につき、虚偽、誤記または記載漏れがあった場合</li>
											<li>本規約に違反するおそれがあると当社が判断した場合</li>
											<li>過去に本規約に違反した者またはその関係者であると当社が判断した場合</li>
											<li>その他当社が登録を妥当でないと判断した場合</li>
										</ol>
									</li>
									<li>利用者は、登録事項に変更が生じた場合は、直ちに当社所定の方法により、登録事項の変更の手続きを行うものとします。これを怠ったことによって利用者が損害を被ったとしても、当社は一切責任を負わないものとします。</li>
								</ol>	
							</div>
							<div className="float-left">
								<h3 className="float-left">第８条（ＩＤ・パスワードの管理）</h3><br/>
								<ol type="1" className="" style={{fontSize:"13px"}}>
									<li>当社は、利用者にＩＤ・パスワードを発行して、これを通知します。</li>
									<li>利用者は、ＩＤ・パスワードの削除・変更を求める際は、当社所定の方法により、これを申し込むものとします。</li>
									<li>利用者は、自己の責任において、ＩＤ・パスワードを適切に管理・保管するものとし、これを第三者に開示、利用、貸与、譲渡、売買、担保提供、これらに準ずる行為等をしてはならないものとします。</li>
									<li>当社は、ログイン時に使用されたＩＤ・パスワードが登録されたものと一致することを所定の方法により確認した場合、当該ログインした者を真正な利用者とみなします。</li>
									<li>利用者によるＩＤ・パスワードの管理不十分、使用上の過誤、不正使用等によって利用者が損害を被ったとしても、当社は一切責任を負わないものとします。</li>
								</ol>	
							</div>
							<div className="float-left">
								<h3 className="float-left">第９条（利用者の義務）</h3><br/>
								<ol type="1" className="" style={{fontSize:"13px"}}>
									<li>利用者は、本サービスの利用において職業安定法、労働基準法、その他労働法規等に違反するような行為を行わないものとします。</li>
									<li>利用者は、本サービスによる応募者・会員から質問・応募・登録・その他の連絡があった場合、原則１週間以内に返信を行うものとします。</li>
									<li>本サービスに掲載された求人案件に対して本サービスを通じて応募があった場合、利用者は、選考結果または採否を明示的に連絡するものとします。</li>
									<li>利用者は、事前に当社の書面による承諾を得た場合を除き、当社が知的財産権を有するコンテンツを複製、公開、送信、頒布、譲渡、貸与、翻訳、翻案、使用許諾、転載、再利用等しないものとします。</li>
									<li>利用者が前項に違反した場合には、当社は、求人広告、当該コンテンツの複製、転載等の使用を当社が差止めする権利を有するとともに、当該行為によって利用者が得た利益相当額及び違反により発生した当社の損害額の賠償を請求できるものとします。</li>
								</ol>	
							</div>
							<div className="float-left">
								<h3 className="float-left">第10条（権利帰属）</h3><br/>
								<ol type="1" className="" style={{fontSize:"13px"}}>
									<li>本サービスを利用して利用者が作成した成果物に関する知的財産権等は、利用者に帰属します。但し、当該成果物を構成するコンテンツのうち、当社が従前から知的財産権等を有するものの知的財産権等は当社に帰属します。</li>
									<li>利用者は、当社が人材支援サービスや当社の広告宣伝等に必要な範囲で、当該成果物を使用する場合があることを予め承諾します。なお、当社は当該成果物を使用する際は、原則として、利用者へ事前に許可を得ることとします。</li>
								</ol>	
							</div>
							<div className="float-left">
								<h3 className="float-left">第11条（禁止行為）</h3><br/>
								<p  className="float-left pl-4 mb-0 mt-3">利用者は、本サービスの利用にあたり、本規約に別途定める他、以下の各号のいずれかに該当しまたは該当すると当社が判断する行為をしてはなりません</p>
								<ol type="1" className="" style={{fontSize:"13px"}}>
									<li>本目的の範囲を超えて本サービスを利用する行為</li>
									<li>法令に違反する行為</li>
									<li>犯罪に関連する行為</li>
									<li>公序良俗に反する行為</li>
									<li>当社または第三者の知的財産権等、プライバシー権、名誉権、信用、その他一切の権利または利益を侵害する行為</li>
									<li>本サービスの運営・維持を妨げる行為</li>
									<li>本サービスのネットワークまたはシステム等に過度の負担をかける行為</li>
									<li>プログラム等により自動的にアクセスする行為</li>
									<li>本サービスのネットワークに不正にアクセスする行為</li>
									<li>第三者になりすます行為</li>
									<li>第三者に本サービスを利用させる行為</li>
									<li>反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ）の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与をする行為</li>
									<li>利用者が前各号の行為を行うことを看過しまたは是正しない行為</li>
									<li>前各号の行為を直接または間接に惹起しまたは容易にする行為</li>
									<li>その他、当社が不適切と判断する行為</li>
								</ol>	
							</div>
							<div className="float-left">
								<h3 className="float-left">第12条（契約解除等）</h3><br/>
								<ol type="1" className="" style={{fontSize:"13px"}}>
									<li>当社は、利用者が以下の各号のいずれかに該当しまたは該当すると当社が判断した場合は、事前に通知することなく、本サービスの全部または一部の利用を停止することができます。なお、当社は、上記判断に関する理由を開示する義務は負いません。
										<ol type="1" className="m-0" style={{fontSize:"13px"}}>
											<li>本規約のいずれかの条項に違反した場合</li>	
											<li>第７条（申込）第２項各号に該当することが判明した場合</li>
											<li>支払停止もしくは支払不能となり、または、破産、民事再生手続き開始、会社更生手続開始、特別清算手続開始もしくはこれらに類する手続きの開始の申立てがあった場合</li>
											<li>自ら振出し、もしくは引受けた手形または小切手につき、不渡りの処分を受けた場合</li>
											<li>差押、仮差押、仮処分、強制執行または競売の申立てがあった場合</li>
											<li>租税公課を滞納し、その保全差押を受けた場合</li>
											<li>解散または営業停止状態となった場合</li>
											<li>第３乃至第７号の他、利用者の信用状態に重大な変化が生じたと当社が判断した場合</li>
											<li>当社からの問い合わせに対して、20日間以上応答がない場合</li>
											<li>その他、当社が本サービスの利用を適当でないと判断した場合</li>
										</ol>
									</li>
									<li>当社は、本条に基づき当社が行った行為により利用者に生じた損害について、一切の責任を負いません</li>
								</ol>	
							</div>
							<div className="float-left">
								<h3 className="float-left">第13条（本サービスの変更、中断、終了）</h3><br/>
								<ol type="1" className="" style={{fontSize:"13px"}}>
									<li>当社は、事業上の理由、システムの過負荷・システムの不具合・メンテナンス・法令の制定改廃・天災地変・偶発的事故・停電・通信障害・不正アクセス、その他の事由により、本サービスをいつでも変更、中断、終了することができるものとし、これによって利用者に生じたいかなる損害についても、一切責任を負いません。</li>
									<li>当社は、前項の変更、中断、終了にあたっては、事前に相当期間をもって予告するよう努めます。但し、緊急やむを得ない場合は、この限りでありません。</li>
								</ol>	
							</div>
							<div className="float-left">
								<h3 className="float-left">第14条（紛争処理及び損害賠償）</h3><br/>
								<ol type="1" className="" style={{fontSize:"13px"}}>
									<li>利用者は、本サービスの利用に関連して当社に損害を与えた場合、当社に対し、その損害を賠償するものとします。</li>
									<li>利用者が、本サービスに関連して第三者からクレームを受けまたは第三者との間で紛争が生じた場合、利用者は、直ちにその内容を当社に通知すると共に、利用者の費用と責任において、当該クレームまたは紛争を処理し、その進捗及び結果を当社に報告するものとします。</li>
									<li>当社が、利用者による本サービスの利用に関連して第三者からクレームを受けまたは第三者との間で紛争が生じた場合、利用者は、利用者の費用と責任において、当該クレームまたは紛争を処理し、その進捗及び結果を当社に報告すると共に、当社が支払いを余儀なくされた金額その他の損害を賠償するものとします。</li>
									<li>当社は、本サービスの提供に際して、自己の故意または重過失により利用者に損害を与えた場合について、これを賠償するものとします。</li>
									<li>前項または法律の適用により当社が損害賠償義務を負う場合に、賠償すべき損害の範囲は、利用者に現実に発生した通常の損害に限る（逸失利益を含む特別の損害は含まない）ものとします。なお、本条は、債務不履行、瑕疵担保責任、原状回復義務、不当利得、不法行為その他請求原因を問わず、全ての損害賠償等に適用されるものとします。</li>
								</ol>	
							</div>
							<div className="float-left">
								<h3 className="float-left">第15条（免責）</h3><br/>
								<ol type="1" className="" style={{fontSize:"13px"}}>
									<li>当社は、本サービスに関連して生じた契約者及び第三者の結果的損害、付随的損害、逸失利益等の間接損害について、それらの予見または予見可能性の有無にかかわらず一切の責任を負いません。</li>
									<li>利用者による第三者の知的財産権等の侵害について、当社は責任を負うものではありません。</li>
									<li>当社は、コンピューターシステムの障害、それに伴う誤表示、コンピューター等に付随する環境に基づく損害については、一切の責任を負いません</li>
									<li>本条第1項から第3項の規定は、当社に故意または重過失があった場合には適用されません。</li>
								</ol>	
							</div>
							<div className="float-left">
								<h3 className="float-left">第16条（秘密保持）</h3><br/>
								<ol type="1" className="" style={{fontSize:"13px"}}>
									<li>利用者及び当社は、本サービスに関連して知り得た相手方の営業上、技術上の秘密（契約の内容を含む）に属する情報（以下、総称して「秘密情報」という）及び個人情報を、書面による事前の承諾なくして、採用選考等本来の目的以外で使用してはならず、また第三者（本規約に定める再委託先は除きます）に開示・漏洩等しないものとします。なお、利用者及び当社は、秘密情報を相手方に開示する場合には、秘密である旨の表示をするものとします。但し、次の各号のいずれかに該当する情報は、秘密情報から除くものとします。
										<ol type="1" className="m-0" style={{fontSize:"13px"}}>
											<li>開示の時点で既に保有、または公知されたもの、及び開示後秘密情報を受領した当事者（以下「受領者」という）の責によらずして公知となったもの</li>
											<li>受領者が第三者から秘密保持義務を負うことなく正当に入手したもの</li>
										</ol>
									</li>
									<li>本条の規定は、契約期間終了後も存続するものとします。</li>
								</ol>	
							</div>
							<div className="float-left">
								<h3 className="float-left">第17条（本規約の変更）</h3><br/>
								<ol type="1" className="" style={{fontSize:"13px"}}>
									<li>当社は、その理由を問わず本規約をいつでも任意に変更することができるものとし、利用者はこれに同意します。</li>
									<li>当社が別途定める場合を除き、本規約の変更は、本サイトまたは本サービス上に掲載する方法によって利用者へ通知します。</li>
									<li>本規約の変更は、前項の通知において指定した日付より効力を生じるものとします。</li>
									<li>当社は、利用料金等の重要事項を変更する場合は、前項の指定した日付までに相応の期間をもって、前項の通知を行うよう努めるものとします。</li>
								</ol>	
							</div>
							<div className="float-left">
								<h3 className="float-left">第18条（連絡）</h3><br/>
								<ol type="1" className="" style={{fontSize:"13px"}}>
									<li>当社から利用者への連絡は、書面の送付、電子メールの送信、または本サービスもしくは本サイトへの掲載等、当社が適当と判断する手段によって行います。当該連絡が、電子メールの送信または本サービスもしくは本サイトへの掲載によって行われる場合は、インターネット上に配信された時点で利用者に到達したものとします。</li>
									<li>利用者から当社への連絡は、当社所定の問合せフォーム宛に行うものとします。当社は、問合せフォーム以外からの問い合わせについては、対応する義務は負いません。</li>
								</ol>	
							</div>
							<div className="float-left">
								<h3 className="float-left">第19条（契約上の地位の譲渡等）</h3><br/>
								<ol type="1" className="" style={{fontSize:"13px"}}>
									<li>利用者は、当社の事前の書面による承諾なく、本サービス上の地位または本サービスに基づく権利義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできないこととします。</li>
									<li>当社は、本サービスに係る事業を第三者に譲渡（通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします）した場合には、当該事業譲渡に伴い、本サービス上の地位または本サービスに基づく権利義務並びに登録事項、個人情報、その他の情報を当該事業譲渡の譲受人に譲渡することができるものとし、利用者は、かかる譲渡に予め同意します。</li>
								</ol>	
							</div>
							<div className="float-left">
								<h3 className="float-left">第20条（分離可能性）</h3><br/>
								<p  className="float-left pl-4 mt-3" style={{fontSize:"13px"}}>本規約の規定の一部が、法令または裁判所により違法、無効または不能であるとされた場合においても、当該規定のその他の部分及び本規約のその他の規定は有効に存続し、また、違法、無効または不能であるとされた部分については、当該部分の趣旨に最も近い有効な規定を無効な部分と置き換えて適用し、もしくは当該部分の趣旨に最も近い有効な規定となるよう合理的な解釈を加えて適用します。</p>
							</div>
							<div className="float-left">
								<h3 className="float-left">第21条（準拠法）</h3><br/>
								<p  className="float-left pl-4 mt-3" style={{fontSize:"13px"}}>本規約の準拠法は、日本法とします。</p>
							</div>
							
							<div className="float-left">
								<h3 className="float-left">第22条（管轄）</h3><br/>
								<p  className="float-left pl-4 mt-3" style={{fontSize:"13px"}}>本サービスに関連して利用者と当社の間で紛争が生じた場合、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
								<p className="mt-3 pl-4">以上</p>
								
							</div>
							<div className="float-left">
								<h3 className="float-left pt-3" style={{fontSize:"13px"}}>お客様の個人情報を取得するにあたって</h3><br/>
								<p  className="float-left pl-4" style={{fontSize:"13px"}}>株式会社ＦｌｏＢｏａｒｄ（以下「FloBoard」といいます）は、お客様（個人のお客様と企業のご担当者様のいずれも含みます、以下同じ）から取得する個人情報を、以下のとおり取り扱います。以下の内容について同意をいただいたうえで個人情報をご提供いただきますようお願い致します。</p>
								<ol type="1" style={{fontSize:"13px"}}>
									<li className="text-bold">個人情報とは
										<p >個人情報の定義は、以下のとおりとします。
											個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述、または個人別に付けられた番号、記号その他の符号、画像もしくは音声によって当該個人を識別できるもの（当該情報のみでは識別できないが、他の情報と容易に照合することができ、それにより当該個人を識別できるものを含む）。
										</p>
									</li>
									<li className="text-bold">個人情報の利用について
										<ol type="1" className="m-0" style={{fontSize:"13px"}}>
											<li>個人情報は、以下に定める目的の範囲内で取得し、利用します。
												<ol type="1" className="m-0" style={{fontSize:"13px"}}>
													<li>お客様の個人認証・本人確認・審査・登録・管理</li>
													<li>本サービスの提供</li>
													<li> 本サービスの利用状況確認</li>
													<li>本サービスの向上及び新サービスの開発</li>
													<li>アフターサービス、お問い合わせ対応（本人確認も含みます）</li>
													<li>求人企業及び職業紹介会社への、採用業務及び職業紹介業務に関するコンサルティング</li>
													<li>本サービス、その他FloBoardが提供する各種サービスに関する情報提供</li>
													<li>アンケートの実施及びプレゼントの発送</li>
													<li>利用者プロファイル等の統計的処理</li>
												</ol>
											</li>
											<li>FloBoardは前号の利用目的の範囲内で、本サービスにおける電話の内容を録音する場合があります。</li>
										</ol>
									</li>
									<li className="text-bold">個人情報提供の任意性
										<p className="" style={{fontSize:"13px"}}>個人情報の提供は、本人の任意によるものとします。但し、必要となる情報が不足している場合は、FloBoardから各種サービスを提供することができない場合があります。</p>
									</li>
									<li className="text-bold" style={{fontSize:"13px"}}>外部委託
										<p>FloBoardは、個人情報に関わる業務遂行を目的として、郵送や統計処理等の業務とともに個人情報処理を外部に委託する場合があります。委託に関しては、十分な個人情報保護水準を確保していることを条件として委託先を選定し、機密保持契約を締結したうえで行ないます。</p>
									</li>
									<li className="text-bold" style={{fontSize:"13px"}}>個人情報の第三者への開示
										<p>FloBoardは、本人の同意を得ずに個人情報を第三者に開示することは、原則いたしません。開示を行なうのは、提供先・提供情報内容を特定したうえで、本人の同意を得た場合に限ります。</p>
										<p>但し以下の場合は、関係法令に反しない範囲で、本人の同意なく個人情報を開示することがあります。</p>
										<ol type="1" style={{fontSize:"13px"}}>
											<li>人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合</li>
											<li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の承諾を得ることが困難である場合</li>
											<li> 国の機関若しくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合で、本人の同意を得ることによりその事務の遂行に支障を及ぼすおそれがある場合</li>
											<li>裁判所、検察庁、警察、弁護士会、消費者センターまたはこれらに準じた権限を有する機関から、個人情報についての開示を求められた場合</li>
											<li>法令により開示または提供が許容されている場合</li>
										</ol>
									</li>
									<li className="text-bold" style={{fontSize:"13px"}}>個人情報の管理
										<p>FloBoardは、個人情報への不当なアクセスまたは個人情報の紛失、破壊、改竄、漏洩などの危険に対して、技術面及び組織面において必要な安全対策を継続的に講じるよう努めています。</p>
									</li>
									<li className="text-bold">個人情報の利用目的の通知、開示、訂正・追加・削除、利用又は提供の拒否権
										<ol type="1" className="m-0" style={{fontSize:"13px"}}>
											<li> 個人情報の利用目的の通知、開示、利用又は提供の拒否権
												　ご利用サービスのお問い合わせ窓口（11.お問い合わせ先　に記載）にご連絡ください。
											</li>
											<li>開示、訂正・追加・削除
												　ご利用サービスのページにてお客様ご自身が行うことができます。
											</li>
										</ol>
									</li>
									<li className="text-bold">書類の返却
										<p>FloBoardが提供するサービスをご利用いただくにあたってお預かりした書類一式は、本人及び第三者にいかなる場合も返却致しません。また、個人情報を保管する必要がなくなったとFloBoardが判断した場合は、本人の同意を得ることなく廃棄する場合があります。</p>
									</li>
									<li className="text-bold">Cookieについて
										<p>FloBoardが提供するサービスでは、快適にWebサイトをご利用いただけるように、閲覧情報等の情報収集を行うべく、一部のサイトにおいてCookie（クッキー）を使用しております。なお、クッキーにより収集した情報には、個人を特定できる情報は含んでおりません。クッキーを無効化させる設定をされていると、一部サービスをご利用いただけない場合がございますので、あらかじめご了承ください。</p>
									</li>
									<li className="text-bold">個人情報管理責任者
										<p>個人情報は、以下の者が責任をもって管理するものとします。</p>
										<p>	〒101-0031 東京都千代田区東神田二丁目7番４‐305号</p>
										<p>	株式会社ＦｌｏＢｏａｒｄ 取締役副社長（個人情報保護管理者）</p>
									</li>
									<li className="text-bold">お問い合わせ先
										<p>個人情報に関するお問い合わせは、サービス事務局宛にご連絡ください。</p>
										<p>SESMartサービス事務局宛</p>
										<p className="mt-3">e-mail： info@floboard.co.jp</p>
											
										<span>	以上</span>
									</li>
								</ol>
							</div>
						</div>
						<div className="modal-footer">
						<button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleClose}>Close</button>
						</div>
					</div>
		</div>
	</div>
	)

    return(
        <React.Fragment>
			<div className="g-0" style={{overflow:"hidden"}}>
            {/* <div className="card" style={{overflow:"hidden"}}> */}
				<Row>
                    <Col xxl={6} xl={6} md={6} sm={6} xs={12} className=" xs-hidden">
                        <img src="/images/login.png" alt="profile-img"  style={{width: '100%', height:'100%', objectFit: 'cover'}}/>
                        <div className="login-content" style={{zIndex: 999}}>即戦力人材を活用して、<br/>迅速な課題解決が<br/>可能になります。</div>
                    </Col>
                    <Col  xxl={6} xl={6} md={6} sm={6} xs={12}>
                        <div className="card-body login-form login-container">
                        <Row>
                            <Col xxl={{ span: 8, offset: 2 }} xl={{ span: 8, offset: 2 }} md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 1 }}>
                                <div className="card mt_10 border-0">
                                    <div className="card-header header text-center border-0" style={{backgroundColor : "#FFFF"}}>
                                        <h1 className="p-3">SESMart</h1>
                                        <p>Welcome to the SESMart Sales System</p>
                                    </div>
                                    <div className="card-body">
                                        <form className="form text-center">
                                        	<span className="login-error" style={{display:login_error}}></span>
                                            <div className="form-group text-center">
                                                <input type="text"  name='email' onChange={updateInput} value={state.user.email} className="form-control mb-3" placeholder="メールアドレス" autoComplete="email" autoFocus/>
                                            </div>
                                            <div className="form-group text-center">
                                                <input type="password" name="password" onChange={updateInput} className="form-control mb-3" placeholder="パスワード" value={state.user.password} />
                                            </div>
                                            <div className="form-group text-center mb_25" style={{fontSize:"12px"}}>
                                                <input className="ml_20" type="checkbox" value="" id="privacy" name ="privacy" style={{marginRight:"8px",marginTop:"5px"}}required/> 
                                                <a 
                                                    href="" onClick={(event) => {
                                                    event.preventDefault();
                                                    setOpen(true);
                                                }}>SESMart利用規約・個人情報</a>に関する同意書に同意する。 
                                            </div>
                                            <div className="form-group text-center mt-3">
                                                <button type="button"  onClick={login} className="login-btn" style={{backgroundColor: btnColor}} 
												disabled={disabled}>ログイン</button><br/>
                                            </div>
                                            {/* <div className="form-group text-center security mt-5" style={{fontSize: "12px"}}>
                                                <a  href="https://floboard.co.jp/www/index.php/company/privacy" target="_blank">個人情報保護方針 </a>
                                                <br/> 
                                                <a href="https://floboard.co.jp/www/index.php/company/security"  target="_blank">情報セキュリティ方針</a>
                                            </div> */}
                                        </form>
                                    </div>
                                </div>
                            </Col>
                       </Row>
                        </div>
                    </Col>
                </Row>
                {/* </div> */}
                </div>
                <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
					sx={ {
						display: { xs: 'none', sm: 'block' },
					}}
                >  
                    <Box sx={PCModalStyle}>
                          {policyModal}
                    </Box>
                </Modal>

				<Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
					sx={ {
						display: { xs: 'block', sm: 'none' },
					}}
                >  
                    <Box sx={MobileModalStyle}>
                          {policyModal}
                    </Box>
                </Modal>
        
		
		</React.Fragment>
    )
};
export default LoginForm;