import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
// const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;
const token = localStorage.getItem("token"); // retrieve stored user information from local storage
const header =  {
    headers :  {
        'Authorization': `Bearer ${token}` ,
        // "server_url" : `${SERVER_URL}`,
        "client-domain" :  `${CLIENT_DOMAIN}`,
       } 
};
class HomeService {
    getHomeData(){
        let dataURL:string = API_URL + `/home`;
        // dataURL += `yearflg=${yearflg}&monthflg=${monthflg}`;

        return axios.get(dataURL, header);
    }

    storeComment(data:any){
        let dataURL:string = API_URL + "/comment/store1";
        return axios.post(dataURL,data, { headers: { 'Authorization': `Bearer ${token}` ,'Content-Type': 'multipart/form-data' , "Accept": "application/json","type": "formData"} });
        // return axios.post(dataURL,data, { headers: { 'Authorization': `Bearer ${token}` , "server_url" : `${SERVER_URL}`, 'Content-Type': 'multipart/form-data' , "Accept": "application/json","type": "formData"} });
    }

    updateComment(datas:any){
        let dataURL:string = API_URL + "/comment/update1";
        return axios.post(dataURL,datas, { headers: { 'Authorization': `Bearer ${token}` , 'Content-Type': 'multipart/form-data' , "Accept": "application/json","type": "formData"} });
    }

    // destroy(id:any){
    //     let dataURL:string = API_URL + "/persons/delete/"+id;
    //     console.log(dataURL);
    //     return axios.get(dataURL, header);
    // }
    deleteComment(id:any){
        let dataURL:string = API_URL + "/comment/deleteComment/"+id;
        console.log(dataURL);
        return axios.get(dataURL,header);
    }

    getChatMessage(data:any){
        let dataURL:string = API_URL + "/get/chatMessage";
        console.log("Receiver:" +data);
        console.log(dataURL);
        return axios.post(dataURL,data, { headers: { 'Authorization': `Bearer ${token}` , 'Content-Type': 'multipart/form-data' , "Accept": "application/json","type": "formData"} });
        // return axios.post(dataURL,data, { headers: { 'Authorization': `Bearer ${token}` , "server_url" : `${SERVER_URL}`, 'Content-Type': 'multipart/form-data' , "Accept": "application/json","type": "formData"} });
    }

    storeChatMessage(datas:any){
        let dataURL:string = API_URL + "/chatMessage/store1";
        // console.log("Receiver:" +datas);
        console.log(dataURL);
        return axios.post(dataURL,datas,{ headers: { 'Authorization': `Bearer ${token}` , 'Content-Type': 'multipart/form-data' , "Accept": "application/json","type": "formData"} });

        // return axios.post(dataURL,datas,{ headers: { 'Authorization': `Bearer ${token}` , "server_url" : `${SERVER_URL}`, 'Content-Type': 'multipart/form-data' , "Accept": "application/json","type": "formData"} });
    }

    storeLike(data:any){
        let dataURL:string = API_URL + "/like/store1";
        // console.log("Receiver:" +datas);
        return axios.post(dataURL,data,{ headers: { 'Authorization': `Bearer ${token}` , 'Content-Type': 'multipart/form-data' , "Accept": "application/json","type": "formData"} });
        // return axios.post(dataURL,data,{ headers: { 'Authorization': `Bearer ${token}` , "server_url" : `${SERVER_URL}`, 'Content-Type': 'multipart/form-data' , "Accept": "application/json","type": "formData"} });
    }

    removeFile(data:any){
        let dataURL:string = API_URL + "/remove/file";
        // console.log("Receiver:" +datas);
        return axios.post(dataURL,data,header);
    }
}
export default new HomeService();