import React, {useState , useEffect} from 'react';
// import {User} from "../../models/User";
import PartnerService from "../../services/PartnerService";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import $ from 'jquery';
import { CSVLink, CSVDownload } from "react-csv";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import DatePicker from "react-datepicker";
import {showErrorInfo, PCModalStyle, MobileModalStyle} from "../../components/common/Helpers";
import {PartnerModel} from "../../models/Partner";
import {useSearchParams, useMatch ,useLocation } from "react-router-dom";
import Loader from "../../components/common/Loader";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface IState{
    loading :boolean;
    partners :  [];
    errorMessage : string;  
    perPage : number;
    page : number;
    column: string;
    order: string;
    totalRows : number;
    partner_type_list : [];
    payment_site_list : [];
    contract_return_flg_list : [];
    partner_model : PartnerModel;
}
interface IProps{}

let PartnerList:React.FC<IProps> = () =>{
    const match = useMatch("/:firstRoute/:secondRoute/:thirdRoute/*");
    let lastRoute = match?.params["*"];
    let type = "";
    if (!!lastRoute) {
        type = lastRoute;
    }else{
        type = "0";
    }
 
    let [state, setState] = useState<IState>({
        loading : false,
        partners :  [],
        errorMessage : "",
        page : 0,
        perPage : 10,
        column : "",
        order : "",
        totalRows : 0,
        partner_type_list : [],
        payment_site_list : [],
        contract_return_flg_list : [],
        partner_model : {
            partner_id: "",
            partner_type : type,
            company: "",
            president : "",
            company_tel : "",
            address : "",
            url : "",
            contact_name : "",
            contact_dept : "",
            contact_email : "",
            contact_tel : "",
            basic_contract_date : "",
            contract_sent_date : "",
            officer_name : "",
            officer_mailadress : "",
            company_profile: "",
            remarks: "",
            payment_site : 20,
            invoice_no : "",
            contract_return_flg : 0,
            contract_creator : "",
        }
    });
    
    let [searchParams, setSearchParams] = useSearchParams();
    let id = "";
    let type_id = "";
    if(!!searchParams.get("id")){
        id = searchParams.get("id")!;
    }
    if(!!searchParams.get("type")){
        type_id = searchParams.get("type")!;
    }else{
        type_id = type;
    }
    const [search, setSearch] = useState(id);  
    const [partner_type, setPartnerType] = useState(type_id);  
    const [par_type, setType] = useState(type);
    const [sort, setSort] = useState("0");
    const [no_of_rows, setNoOfRows] = useState(10);
    const [current_page, setCurrentPage] = useState(0);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false); 
    let [selectedBasicContractDate, setSelectedBasicContractDate] = useState<Date | null>(null);
    let [selectedContractSentDate, setselectedContractSentDate] = useState<Date | null>(null);
    const login_user =   JSON.parse(localStorage.getItem("user") || '{}');
    const [showPrevColor, setShowPrevColor] = useState("grey");
    const [showNextColor, setShowNextColor] = useState("grey");
    const [width, setWidth] = useState(700);
    const [height, setHeight] = useState(700);
    const [url_partner_id, setURLPartnerId] = useState("");

    const getData = async (par_type: any, partner_type:any, perPage:number, page:number, search:any, sort:any, column:string, order:string, url_partner_id:any) => {
        setState({...state,loading :true,})
        PartnerService.list(par_type, partner_type, perPage, page, search, sort, column, order, url_partner_id).then((response)=>{
            setState({
                ...state,
                loading:false,
                partners:response.data.data,
                page : response.data.current_page,
                column : response.data.column,
                order : response.data.order,
                perPage : response.data.per_page,
                totalRows : response.data.total,
                partner_type_list : response.data.select_options.partner_type,
                payment_site_list : response.data.select_options.payment_site,
                contract_return_flg_list : response.data.select_options.contract_return_flg
            })
            if(Math.floor(response.data.total/response.data.per_page) >0){
                setShowNextColor("blue");
            }
            else{
                setShowNextColor("grey");
            }
            if(0 == response.data.current_page){
                setShowPrevColor("grey");
            }
            // if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
            //     setShowNextColor("grey");
            // }
        })
        .catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
            setState({
                ...state,
                loading:false,
                errorMessage:error.message
            })
        })
    }
        
    let {loading, partners, perPage, page, column, order, totalRows, partner_type_list, payment_site_list, contract_return_flg_list, partner_model, errorMessage} = state;
    const [selected, setSelected] = useState('base');
 
    useEffect(()=>{
        setState({...state, loading: true});
        
        if(!!searchParams.get("id")){
            setURLPartnerId(searchParams.get("id")!);
        }
        if(!!searchParams.get("type")){
            setPartnerType(searchParams.get("type")!);
        }

        if(!!searchParams.get("id") && !!searchParams.get("type")){
            getData(par_type, partner_type, perPage, page, search, sort, column, order,searchParams.get("id")!);
        }else{
            getData(par_type, partner_type, perPage, page, search, sort, column, order, url_partner_id);
        }

    }, [perPage]);
    let changePage = (page:any) => {
        setCurrentPage(page);
        getData(par_type, partner_type, perPage, page+1, search, sort, column, order, url_partner_id);
    };
    let changeRowsPerPage = (perPage:any) => {
        getData(par_type, partner_type, perPage, page, search, sort, column, order, url_partner_id);
    };
    let handleDelete = (rowData:any, rowState:any) => {
        // let rows : any[] = [];
        // rowData.data.forEach((element:any, index:any) => {
        //     rows.push(element.dataIndex);
        // })
        // let ids : any[] = [];
        // assignment.forEach((element, index) => {
        //   if (rows.includes(index)) {
        //     ids.push(element["assignment_id"]);
        //   }
        // });
        // AssignmentService.assignmentDelete(ids).then((response:any)=>{
        //     if(response.data.success == false){
        //         alert(response.data.info.message);
        //         window.location.reload();
        //     }
        // }).catch((error:any) => {
        //     setState({
        //         ...state,
        //         errorMessage:error.message
        //     })
        // })
    }

    let handleSort = (column:any, order:any) => {
        let temp = {};
        temp["column"] = column;
        temp["order"] = order;
        getData(par_type, partner_type, perPage, page, search, sort, column, order, url_partner_id);
      };

    let updatePartner = (partner_id:any) => {
        PartnerService.showPartner(partner_id, type).then((data)=>{
           setState({
            ...state,
            partner_model : {
                partner_id: data.data.partner_id,
                partner_type : data.data.partner_type,
                company: data.data.company,
                president : data.data.president,
                company_tel : data.data.company_tel,
                address : data.data.address,
                url : data.data.url,
                contact_name : data.data.contact_name,
                contact_dept : data.data.contact_dept,
                contact_email : data.data.contact_email,
                contact_tel : data.data.contact_tel,
                basic_contract_date : data.data.basic_contract_date,
                contract_sent_date : data.data.contract_sent_date,
                officer_name : data.data.officer_name,
                officer_mailadress : data.data.officer_mailadress,
                company_profile: data.data.company_profile,
                remarks: data.data.remarks,
                payment_site : data.data.payment_site,
                invoice_no : data.data.invoice_no,
                contract_return_flg : data.data.contract_return_flg,
                contract_creator : data.data.contract_creator,
            }
           })
           setOpen(true);
        }).catch((error:any) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
        });
    }

    const defaultColumns = [
        {
            name: "partner_id",
            label: "編集",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // return(<Link to={`/cases/${tableMeta.rowData[1]}`} >{value}</Link>);
                        return(<button type="button" className="btn_standard btn-sm" onClick={() => {
                            updatePartner(`${tableMeta.rowData[0]}`);
                            }}>編集
                        </button>);
                    },
                },
        },
        {
            name : 'partner_type', 
            label : "種別"
        },
        {
            name : 'partner_id', 
            label : "パートナーID",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div style={{width:"80px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            name : 'company', 
            label : "会社名",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            name : 'president', 
            label : "代表者名",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"80px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            name : 'company_tel', 
            label : "会社電話",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            name : 'address', 
            label : "住所",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:" 100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            name : 'url', 
            label : "ホームページ",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:" 100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            name : 'contact_name', 
            label : "担当者",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:" 80px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            name : 'contact_dept', 
            label : "担当者部署名"
        },
        {
            name : 'contact_email', 
            label : "担当者メールアドレス"
        },
        {
            name : 'contact_tel', 
            label : "担当者電話番号"
        },
        {
            name : 'basic_contract_date', 
            label : "基本契約締結日"
        },
        {
            name : 'contract_sent_date', 
            label : "基本契約郵送日"
        },
        {
            name : 'officer_name', 
            label : "事務担当者"
        },
        {
            name : 'officer_mailadress', 
            label : "事務担当者メールアドレス"
        },
        {
            name : 'company_profile', 
            label : "会社紹介",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:" 80px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            name : 'remarks', 
            label : "特記事項",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:" 100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            name : 'payment_site', 
            label : "支払サイト"
        },
        {
            name : 'invoice_no', 
            label : "インボイス番号"
        },
        {
            name : 'contract_return_flg', 
            label : "基本契約返却フラグ",
            options: {
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div style={{width:"50px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            name : 'contract_creator', 
            label : "基本契約担当者"
        },
    ];


    const options = {
        filterType: 'checkbox' as any,
        tableBodyHeight : "360px",
        tableBodyMaxHeight : "360px",
        responsive: "standard" as any,
        selectableRows: "multiple" as any,
        selectableRowsHeader: true as any, 
        rowsPerPage: perPage,
        page: page,
        rowsPerPageOptions:[10,20,50,100,200],
        // selectableRowsOnClick: true  as any,
        count: totalRows,
        serverSide: true,
        filter : false,
        print: false,
        viewColumns : false,
        sort: true,
        search : false,
        download :false,
        // resizableColumns : true,
    
        customToolbarSelect: (selectedRows:any, displayData:any, setSelectedRows:any) => {         
                let rows : any[] = [];
                selectedRows.data.forEach((element:any, index:any) => {
                    rows.push(element.dataIndex);
                })
                let csvData : any[] = [];
                partners.forEach((element, index) => {
                        if (rows.includes(index)) {
                            csvData.push(element);
                        }
                });

                const getFileName = () => {
                    let now = new Date().toLocaleDateString();
                    let year = now.slice(0,4);
                    let month = '0' + now.slice(5,6);
                    let day = now.slice(7);
                    let date = year+month+day;
                    return "取引先一覧" +date;
                }

            let headers = [
                {
                    key : 'partner_type', 
                    label : "種別"
                },
                {
                    key : 'partner_id', 
                    label : "パートナーID"
                },
                {
                    key : 'company', 
                    label : "会社名"
                },
                {
                    key : 'president', 
                    label : "代表者名"
                },
                {
                    key : 'company_tel', 
                    label : "会社電話"
                },
                {
                    key : 'address', 
                    label : "住所",
                },
                {
                    key : 'url', 
                    label : "ホームページ"
                },
                {
                    key : 'contact_name', 
                    label : "担当者"
                },
                {
                    key : 'contact_dept', 
                    label : "担当者部署名"
                },
                {
                    key : 'contact_email', 
                    label : "担当者メールアドレス"
                },
                {
                    key : 'contact_tel', 
                    label : "担当者電話番号"
                },
                {
                    key : 'basic_contract_date', 
                    label : "基本契約締結日"
                },
                {
                    key : 'contract_sent_date', 
                    label : "基本契約郵送日"
                },
                {
                    key : 'officer_name', 
                    label : "事務担当者"
                },
                {
                    key : 'officer_mailadress', 
                    label : "事務担当者メールアドレス"
                },
                {
                    key : 'company_profile', 
                    label : "会社紹介"
                },
                {
                    key : 'remarks', 
                    label : "特記事項"
                },
                {
                    key : 'payment_site', 
                    label : "支払サイト"
                },
                {
                    key : 'invoice_no', 
                    label : "インボイス番号"
                },
                {
                    key : 'contract_return_flg', 
                    label : "基本契約返却フラグ"
                },
                {
                    key : 'contract_creator', 
                    label : "基本契約担当者"
                }   
              ];
            var now = new Date();
            if(login_user.role == 9){
                return (
                    <CSVLink  className="btn_standard text-decoration-none" filename={getFileName()} data={csvData}  headers={headers}>ダウンロード</CSVLink>
                );
            }
          },
        textLabels: {
            body: {
              noMatch: "データはありません。",
              toolTip: "Sort"
            },
            pagination: {
              next: "Next Page",
              previous: "Previous Page",
              rowsPerPage: "件表示", 
              displayRows: "すべての件数：",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "行 を選択しました。",
                delete: "削除",
                deleteAria: "選択した行を削除しました。",
              },
          },
        rowsSelected: selectedRows,
        onRowSelectionChange: (rowsSelectedData:any, allRows:any, rowsSelected:any) => {
            setSelectedRows(rowsSelected);
        },
        onChangePage (currentPage:any) {
            changePage(currentPage);
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage (numberOfRows:any) {
            changeRowsPerPage(numberOfRows);
            setNoOfRows(numberOfRows);
        },
       
        onCellClick : (colData:any, cellMeta:any) =>{
            // goToDetail(colData, cellMeta);

        },
        onRowsDelete(rowData :any, rowState:any) {
            handleDelete(rowData, rowState);
        },
        onColumnSortChange: (changedColumn:any, direction:any) => {
            let order = 'desc';
            if (direction === 'asc') {
              order = 'asc';
            }
            handleSort(changedColumn, order);
          },
    };
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => { 
        // if (queryParams.has('id')) {
        //     queryParams.delete('id');
        //     queryParams.delete('type');
        //     history.replace({
        //         search: queryParams.toString(),
        //       })
        // }

        switch (e.target.name) {
            case "search":
                    setSearch(e.target.value);
                    getData(par_type, partner_type, perPage, page, e.target.value, sort, column, order, url_partner_id)
                break;
            default:
                break;
        }
      };
      const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
            // if (queryParams.has('id')) {
            //     queryParams.delete('id');
            //     queryParams.delete('type');
            //     history.replace({
            //         search: queryParams.toString(),
            //       })
            // }

        switch (e.target.name) {
            case "partner_type":
                setPartnerType(e.target.value);
                getData(par_type, e.target.value, perPage, page, search, sort, column, order, url_partner_id)
                break;
            case "sort":
                setSort(e.target.value);
                getData(par_type, partner_type, perPage, page, search, e.target.value, column, order, url_partner_id)
                break;
            case "list_length":
                changeRowsPerPage(e.target.value);
                setNoOfRows(parseInt(e.target.value));
                setState({
                    ...state,
                    perPage : parseInt(e.target.value)
                })
                break;
            
            default:
                break;
        }
     }; 
     let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setState({
            ...state,
            partner_model : {
                ...state.partner_model,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        });   
    };

    const handleDateChange = function(date:any, column_name:any){
        // let selected_data = date.getFullYear() +"/"+ (date.getMonth() + 1)+"/"+(date.getMonth());
        let selected_data =(date != null)? date.getFullYear() +"/"+ (date.getMonth() + 1)+"/"+(date.getDate()) : "";
        switch (column_name) {
            case "basic_contract_date":
                setSelectedBasicContractDate(date);
                setState({
                    ...state,
                    partner_model : {
                        ...state.partner_model,
                        basic_contract_date : selected_data // input name 'username' set value 'event target value'
                    }
                });
                break;
            case "contract_sent_date":
                setselectedContractSentDate(date);
                setState({
                    ...state,
                    partner_model : {
                        ...state.partner_model,
                        contract_sent_date : selected_data // input name 'username' set value 'event target value'
                    }
                });
                break;
            default:
                break;
        }
       
    }
     const getMuiTheme = () =>
     createTheme({
         components: {
         MUIDataTable: {
             styleOverrides:{
                 responsiveScroll: {
                     maxHeight: 'none',
                   },
             }
         },
         MuiPaper: {
             styleOverrides:{
                 root: {
                     width: "100%",
                    //  marginTop: "5%",
                    //  marginLeft: "5%",
                    //  marginBottom: "20%"
                 }
             }
         },  
         MUIDataTableHeadCell: {
                 styleOverrides:{
                   root: {
                       backgroundColor: "#444",
                       padding: '0px'
                   }
                 }
         },
         MuiTableCell: {
             styleOverrides:{
                 head: {
                         color : 'white'
                 }
             }
         },
           MUIDataTableBodyCell: {
             styleOverrides:{
               root: {
                   backgroundColor: "#f1f1f1",
                   width: "100%",
                   padding: '0px'
               }
             }
           }
         }
       })
     
    //    const style = {
    //     position: 'absolute' as 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: 800,
    //     height: 700,
    //     bgcolor: 'background.paper',
    //     border: '2px solid #000',
    //     boxShadow: 24,
    //     p: 4,
    //   };
    
    const onSelectPartner = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setState({
            ...state,
            partner_model : {
                ...state.partner_model,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            }
        });
    }

    const checkPartnerType = (type:any) =>{
        let return_url = "";
        switch(type){
            case '1': return_url = "/sale/partners/list/"+type;break;
            case '2': return_url = "/procurement/partners/list/"+type;break;
            case '3': return_url = "/management/partners/list/"+type;break;
            default: return_url = "/management/partners/list";break;
        }
        return return_url;
    }
  
    const storeUpdatePartner = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
        if(state.partner_model.partner_id == ""){
            PartnerService.store(state.partner_model, type).then((response) => {
                // window.location.href = checkPartnerType(type);
                checkPartnerType(type);
                getData(par_type, partner_type, perPage, page, search, sort, column, order, url_partner_id);
                handleClose();
                // window.location.href = checkPartnerType(type);
             }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
                 if(error.response.data){
                     showErrorInfo(error.response.data);
                 }
             })
        }else{
            PartnerService.update(state.partner_model , type).then((response) => {
                checkPartnerType(type);
                getData(par_type, partner_type, perPage, page, search, sort, column, order, url_partner_id);
                handleClose();
                // switch(type){
                //     case '1': window.location.href = "/sale/partners/list?type="+type;break;
                //     case '2': window.location.href = "/procurement/partners/list?type="+type;break;
                //     case '0': window.location.href = "/management/partners/list?type="+type;break;
                //     default: window.location.href = "/management/partners/list?type="+type;break;
                // }
             }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }else if(error.response.data){
                     showErrorInfo(error.response.data);
                 }
     
             })
        }
        
    };

    const addPartner = () => {
        setState({
            ...state,
            partner_model : {
                partner_id: "",
                partner_type :(type =="2")?"2":"1",
                company: "",
                president : "",
                company_tel : "",
                address : "",
                url : "",
                contact_name : "",
                contact_dept : "",
                contact_email : "",
                contact_tel : "",
                basic_contract_date : "",
                contract_sent_date : "",
                officer_name : "",
                officer_mailadress : "",
                company_profile: "",
                remarks: "",
                payment_site : 20,
                invoice_no : "",
                contract_return_flg : 0,
                contract_creator : "",
            }
        })
        setSelectedBasicContractDate(null);
        setOpen(true);
    }

    const deletePartner = () => {
        if(selectedRows.length == 0){
            alert("削除したいパートナーをチェックしてください。");
            return false;
        }
        let ans = window.confirm("削除を実施します、大丈夫ですか？");
        if(ans){
            let ids : any[] = [];
            partners.forEach((element, index) => {            
                if (selectedRows.length > 0 && selectedRows.includes(index)) {
                    ids.push(element["partner_id"]);
                }
            });
    
            let data = {};
            data["ids"] = ids;
            PartnerService.delete(data, type).then((data)=>{
                if(data.data.success == false){
                    alert(data.data.info.message);
                }
                window.location.href = checkPartnerType(type);
                // window.location.href = "/procurement/partners/list?type="+type;
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
                alert(error.data.info.message);
            });
        }
    }

    let nextPage = () => {
        
        // if(Math.floor(totalRows/perPage) >0){
        //     setShowNextColor("blue");
        // }
    
        if(Math.floor(totalRows/perPage) >0){
            changePage(current_page+1);
            setCurrentPage(current_page+1);
            setState({
                ...state,
                page : current_page+1
            })
            setShowNextColor("blue");
        }
        
        if(Math.ceil(totalRows/perPage)-2 == current_page){ // Last Page
            setShowNextColor("grey");
        }   
        if(0 < current_page+1){
            setShowPrevColor("blue");
        }     
    
    }
    
    let previousPage = () => {
        if(current_page > 0){
            setCurrentPage(current_page-1);
            changePage(current_page - 1);
            setState({
                ...state,
                page : current_page-1
            });
            setShowPrevColor("blue");
        }
        if(0 == current_page-1){
            setShowPrevColor("grey");
        } 
    
        if(Math.floor(totalRows/perPage) >0){
            setShowNextColor("blue");
        } 
        
    }

    let title = "";
    if(type == "1"){
        title += "取引先";
    }else if(type == "2"){
        title += "パートナー";
    }else{
        title += "取引先/パートナー";
    }

    const edit_modal_box =(
        <div>
              <Row>
                    <Col xxl={6} xl={6} md={6} sm={6} xs={6} >
                        <h3>{title}{(partner_model['partner_id'] == "")? "登録": "編集"}</h3>
                    </Col>
                    <Col xxl={6} xl={6} md={6} sm={6} xs={6}>
                         <button type="button" className="modal_close" onClick={handleClose}>X</button>
                    </Col>            
                </Row>
                {/* <h3>案件割り当て編集</h3> */}
                <form className="form partner_create_form" onSubmit={storeUpdatePartner}>
                    {/* <BulkMailModal data={send_mail_arr} /> */}
                    <input type="hidden" name="partner_id" value={partner_model["partner_id"]} />
                    <Row>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group ">
                                <label htmlFor="exampleInputEmail1">種別:</label> 
                                <select name="partner_type" onChange={onSelectPartner} value={(partner_model["partner_type"] != null)? partner_model["partner_type"] : ""} style={{width:'100%'}}>
                                    {partner_type_list.map(partner_type => {
                                            return(<option value={partner_type[0]}> {partner_type[1]}</option>)
                                        })
                                    }
                                </select>  
                            </div>       
                        </Col>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className="input-required">会社名:</label> 
                                <input type="text" className="form-control" name="company"  value={(partner_model['company'] != null)?partner_model['company']: ""}  onChange={updateInput} />
                            </div>          
                        </Col>
                    </Row>                  
                    <Row>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">代表者名:</label> 
                                <input type="text" className="form-control" name="president"  value={(partner_model['president'] != null)?partner_model['president']:""}  onChange={updateInput} />                                     
                            </div>
                        </Col>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className="input-required">会社電話:</label> 
                                <input type="text" className="form-control" name="company_tel"  value={(partner_model['company_tel'] != null)?partner_model['company_tel']:""}  onChange={updateInput} />
                            </div>           
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">住所:</label> 
                                <input type="text" className="form-control" name="address"  value={(partner_model['address'] != null)?partner_model['address']:""}  onChange={updateInput} />
                            </div>  
                        </Col>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">ホームページ:</label> 
                            <input type="text" className="form-control" name="url"  value={(partner_model['url'] != null)?partner_model['url']:""}  onChange={updateInput} />       
                            </div>     
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className="input-required">担当者:</label> 
                                <input type="text" className="form-control" name="contact_name"  value={(partner_model['contact_name'] != null)?partner_model['contact_name']: ""}  onChange={updateInput} />
                            </div>        
                        </Col>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                                <label htmlFor="contract_price_type">担当者部署名:</label>
                                <input type="text" className="form-control" name="contact_dept"  value={(partner_model['contact_dept'] != null)?partner_model['contact_dept']: ""}  onChange={updateInput} />                      
                            </div>         
                        </Col>
                    </Row>
                   <Row>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className="input-required">担当者メールアドレス:</label> 
                                <input type="text" className="form-control" name="contact_email"  value={(partner_model['contact_email'] != null)?partner_model['contact_email']:""}  onChange={updateInput} />                      
                            </div>        
                        </Col>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">担当者電話番号:</label> 
                                <input type="text" className="form-control" name="contact_tel"  value={(partner_model['contact_tel'] != null)?partner_model['contact_tel']:""}  onChange={updateInput} />
                            </div>             
                        </Col>
                   </Row>
                    <Row>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">基本契約締結日:</label> 
                                <DatePicker 
                                        value={(partner_model["basic_contract_date"] != null)?partner_model["basic_contract_date"]:""}
                                        dateFormat="yyyy/MM/dd"
                                        locale="ja"
                                        selected={selectedBasicContractDate} 
                                        onChange={date =>
                                            handleDateChange(date, "basic_contract_date")
                                        }
                                        name="basic_contract_date"
                                        isClearable
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                />     
                            </div>         
                        </Col>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">基本契約郵送日:</label> 
                                <DatePicker 
                                        value={(partner_model["contract_sent_date"] != null)?partner_model["contract_sent_date"]: ""}
                                        dateFormat="yyyy/MM/dd"
                                        locale="ja"
                                        selected={selectedContractSentDate} 
                                        onChange={date =>
                                            handleDateChange(date, "contract_sent_date")
                                        }
                                        name="contract_sent_date"
                                        isClearable
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                />           
                            </div>        
                        </Col>
                    </Row>  
                    <Row>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                                <span id='text'>事務担当者</span>
                                <input type="text" className="form-control" name="officer_name"  value={(partner_model['officer_name'] != null)?partner_model['officer_name']: ""}  onChange={updateInput} />
                            </div>           
                        </Col>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                                <span id='text'>事務担当者メールアドレス</span>
                                <input type="text" className="form-control" name="officer_mailadress"  value={(partner_model['officer_mailadress'] != null)?partner_model['officer_mailadress']:""}  onChange={updateInput} />
                            </div>           
                        </Col>
                    </Row> 
                    <Row>
                        <Col xxl={12} xl={12} md={12} sm={12} xs={12} className="input-box">
                            <div className="form-group">
                                <span id='text'>会社紹介</span>
                                <input type="text" className="form-control" name="company_profile"  value={partner_model['company_profile']}  onChange={updateInput} />
                            </div>           
                        </Col>
                    </Row> 
                    <Row>
                        <Col xxl={12} xl={12} md={12} sm={12} xs={12} className="input-box">
                            <div className="form-group">
                                <span id='text'>特記事項</span>
                                <input type="text" className="form-control" name="remarks"  value={partner_model['remarks']}  onChange={updateInput} />
                            </div>           
                        </Col>
                    </Row> 
                    <Row>
                        <Col xxl={3} xl={3} md={3} sm={3} xs={6} className="input-box">
                            <div className="form-group">
                                <span id='text'>支払サイト</span>
                                <select name="payment_site" onChange={onSelectPartner} value={partner_model["payment_site"]} style={{width:'100%'}}>
                                    {payment_site_list.map(payment_site => {
                                            return(<option value={payment_site}> {payment_site}</option>)
                                        })
                                    }
                                </select> 
                            </div>           
                        </Col>
                        <Col xxl={3} xl={3} md={3} sm={3} xs={6} className="input-box">
                            <div className="form-group">
                                <span id='text'>インボイス番号</span>
                                <input type="text" className="form-control" name="invoice_no"  value={partner_model['invoice_no']}  onChange={updateInput} />
                            </div>           
                        </Col>
                        <Col xxl={3} xl={3} md={3} sm={3} xs={6} className="input-box">
                            <div className="form-group">
                                <span id='text'>基本契約返却フラグ</span>
                                <select name="contract_return_flg" onChange={onSelectPartner} value={partner_model["contract_return_flg"]} style={{width:'100%'}}>
                                    {contract_return_flg_list.map(contract_return_flg => {
                                            return(<option value={contract_return_flg[0]}> {contract_return_flg[1]}</option>)
                                        })
                                    }
                                </select> 
                            </div>           
                        </Col>
                        <Col xxl={3} xl={3} md={3} sm={3} xs={6} className="input-box">
                            <div className="form-group">
                                <span id='text'>基本契約担当者</span>
                                <input type="text" className="form-control" name="contract_creator"  value={partner_model['contract_creator']}  onChange={updateInput} />
                            </div>           
                        </Col>
                    </Row>  
                    <Row>
                        <div className="form-group mt-5 text-center">
                            <button type="submit" className="button submit-btn btn_standard">{(partner_model['partner_id'] == "")? "登録": "更新"}</button>
                        </div>
                    </Row>
                </form>
        </div>
    )
    return(
        
       <React.Fragment>
          <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                    ml: sizeConfigs.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color
                }}
                >
                 <Toolbar>
                <Typography variant="h6">
                    <header className="top-baner">
                        <Row>
                            <Col xxl={10} xl={10} md={10} sm={10} xs={10}>
                                <div id="search-autocomplete" className="form-outline">
                                    <input type="text" name="search"  id="search" placeholder = "検索(会社ID、会社名、社長、担当者、事務担当、特記事項を検索)"
                                        className="form-control"  onChange={onChange}/>
                                </div>
                            </Col>
                            <Col xxl={2} xl={2} md={2} sm={2} xs={2} className="mt-4">
                                <DropDownList />
                            </Col>
                        </Row>
                    </header>
                    </Typography>
                </Toolbar>
                </AppBar>
                <Container>
                    <Row>
                        <Col className="pc-hidden">
                                <div className="mt-3 text-center">
                                    {( loading   == true) ? <Loader /> :<> </>}
                                </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                            <h3 className='header-left-design'>
                               {title}一覧
                            </h3>
                        </Col>
                        <Col xxl={4} xl={4} md={4} sm={4} xs={6} className="xs-hidden">
                            <div className="mt-3 text-center">
                                {( loading   == true) ? <Loader /> :<> </>}
                            </div>
                        </Col>
                        <Col xxl={4} xl={4} md={4} sm={4} xs={6} className="float-end">
                            <div className="anken-add action mt-4">
                                <button type="button" className="btn_standard ml_20 btn-sm" onClick={addPartner}>追加</button>
                                <button type="button" className="btn_danger ml_20 btn-sm"  onClick={deletePartner}>削除</button>
                            </div>
                        </Col>
                    </Row> 
                    <Row>
                        <Col xxl={3} xl={3} md={3} sm={3} xs={12}>
                            <select name="partner_type" onChange={onSelect} value={partner_type} className="form-select form-select-sm" aria-label=".form-select-sm example">
                                {partner_type_list.map(partner_type => {
                                        if (partner_type !== null){
                                            return(<option value={partner_type[0]}> {partner_type[1]}</option>)
                                        }
                                    })
                                }
                            </select>
                        </Col>
                    </Row>
                    <Row className="pt-2 pb-2">
                        <Col xxl={4} xl={4} md={4} sm={4} xs={4}>
                            <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                        </Col>
                        <Col xxl={8} xl={8} md={8} sm={8} xs={8} className="filter_select">
                                <div className="float-right">
                                    <select name="list_length" id="list_length" value={no_of_rows} className="filter list_length" onChange={onSelect}>
                                        <option value="10">10</option>
                                        <option value="20" >20</option>
                                        <option value="50" >50</option>
                                        <option value="100" >100</option>
                                        <option value="200" >200</option>
                                    </select>
                                    <label htmlFor="sort" className="control-label">件表示</label>
                                    <button type="button" className="btn-sm previous-page-icon" style={{border:"0px"}} onClick={() => {
                                                previousPage();
                                                }}>
                                        <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                    </button>
                                    <button type="button" className=" btn-sm next-page-icon" style={{border:"0px"}} onClick={() => {
                                                nextPage();
                                                }}>
                                        <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                                    </button> 
                                </div>
                            </Col>
                    </Row>
                    <div className='datatable partner-freeze-header partner-freeze-cell partner-freeze-columns xs-hidden'>
                        <MUIDataTable
                            title={''}
                            data={partners}
                            columns={defaultColumns}
                            options = {options}
                        />
                    </div>
                    <div className='datatable mobile pc-hidden'>
                        <MUIDataTable
                            title={''}
                            data={partners}
                            columns={defaultColumns}
                            options = {options}
                        />
                    </div>
                </Container>
               
                <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'none', sm: 'block' },
            }}
        >                                
            <Box sx={PCModalStyle}
            >
              {edit_modal_box}
            </Box>
        </Modal>    
        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'block', sm: 'none' },
            }}
        >                                
            <Box sx={MobileModalStyle}
            >
              {edit_modal_box}
            </Box>
        </Modal>                   
        </React.Fragment>
    )

}

export default PartnerList;