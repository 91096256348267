import { RotatingLines } from "react-loader-spinner";

function Loader() {
  return (
    <div className="loader-container">
      <RotatingLines
        strokeColor="grey"
        strokeWidth="5"
        animationDuration="0.75"
        width="46"
        visible={true}
      />
    </div>
  )
}
export default Loader;
