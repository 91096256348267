import axios from "axios";
const DATABASE_ID = process.env.REACT_APP_DATABASE_ID;
const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;

export const myAxios = (resetAxios: any) => {

    if(resetAxios?.interceptors) {
        axios.interceptors.request.use((config) => {
            if(config.headers){
                config.headers["RESOURCE_SESSION"] = localStorage.getItem("rs")??'';
                config.headers["database-id"] = `${DATABASE_ID}`??'';
                config.headers["service-id"] = `${SERVICE_ID}`??'';
            }
            return config; 
        },
        (error) => {
            return Promise.reject(error);
        });

        axios.interceptors.response.use((response) => {
            return response; 
        },
        (error) => {
            return Promise.reject(error);
        });
    }
};