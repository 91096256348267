import React, {useEffect, useState} from 'react';
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import BillService from '../../services/BillService';
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import {BillModel} from "../../models/Bill";
import DatePicker from "react-datepicker";
import { CSVLink, CSVDownload } from "react-csv";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {showErrorInfo, generatePDF, PCModalStyle, MobileModalStyle} from "../../components/common/Helpers";
import {BillUpdate} from "../../models/Bill";
import ResourceService from "../../services/ResourceService";
import Loader from "../../components/common/Loader";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import {PDFDocument} from 'pdf-lib';
// import { render } from 'canvas';
import {Canvg} from 'canvg';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


interface IState{
    loading :boolean;
    bills :  [];
    distination_list : [];
    errorMessage : string;  
    perPage : number;
    page : number;
    column: string;
    del_flg : string;
    order: string;
    totalRows : number;
    billUpdateList : BillUpdate;
}

interface IProps{}
let BillOrderList:React.FC<IProps> = () => {
    const [search_keywords, setSearchKeyword] = useState("");
    const [work_month, setWorkMonth] = useState("");
    const [bill_admit_status, setBillAdmitStatus] = useState("");  
    const [delivery_status, setDeliveryStatus] = useState("");
    const [work_company, setWorkCompany] = useState("");
    const [send_email, setSendEmail] = useState("");
    const [recipient_list, setRecipientList] = useState("");
    const [sort, setSort] = useState(""); 
    const [selectedTargetDate, setSelectedTargetDate] = useState(null);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [flgchecked, setFlgChecked] = useState<boolean>(false);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const login_user =   JSON.parse(localStorage.getItem("user") || '{}');
    const [showPrevColor, setShowPrevColor] = useState("grey");
    const [showNextColor, setShowNextColor] = useState("grey");
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height: 700,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

      const updateBill = (bill:any) => {
        BillService.showBill(bill).then((data)=>{
            //remove reject! value
           let sale_remark = data.data.bill.sale_remark?data.data.bill.sale_remark.substring(7):data.data.bill.sale_remark;
           let manager_remark = data.data.bill.manager_remark?data.data.bill.manager_remark.substring(7):data.data.bill.manager_remark;
           let director_remark = data.data.bill.director_remark?data.data.bill.director_remark.substring(7):data.data.bill.director_remark;
             setState({
                    ...state,
                    billUpdateList : {
                        bill_id : data.data.bill.bill_id,
                        update_type : "normal",
                        work_company : data.data.bill.work_company,
                        work_month : data.data.bill.work_month,
                        company : data.data.bill.company,
                        bill_no : data.data.bill.bill_no,
                        make_date : data.data.bill.make_date,
                        sale_order_nm : data.data.bill.sale_order_nm,
                        buyer_contruct : data.data.bill.buyer_contruct,
                        sale_order_no : data.data.bill.sale_order_no,
                        person_name_jp : data.data.bill.person_name_jp,
                        jimu_id : data.data.bill.jimu_name_jp,
                        sale_id : data.data.bill.sale_name_jp,
                        sale_remark : sale_remark,
                        sale_approval_date : data.data.bill.sale_approval_date,
                        manager_id : data.data.bill.manager_name_jp,
                        manager_remark : manager_remark,
                        manager_approval_date : data.data.bill.manager_approval_date,
                        director_id : data.data.bill.director_name_jp,
                        director_remark : director_remark,
                        director_approval_date : data.data.bill.director_approval_date,
                        send_date : data.data.bill.send_date,
                        partner_id : data.data.bill.partner_id,
                        partner_jimu_name : data.data.bill.partner_jimu_name,
                        partner_jimu_email : data.data.bill.partner_jimu_email,
                        partner_sale_email : data.data.bill.partner_sale_email,
                        bill_from_date : data.data.bill.bill_from_date,
                        bill_to_date : data.data.bill.bill_to_date,
                        sale_order : data.data.bill.sale_order,
                        settlement : data.data.bill.settlement,
                        settlement_to : data.data.bill.settlement_to,
                        work_hour : data.data.bill.work_hour,
                        deduct_hour : data.data.bill.deduct_hour,
                        excesss_hour : data.data.bill.excesss_hour,
                        bill_flg : data.data.bill.bill_flg,
                        rate    : data.data.bill.rate,
                        contract_price_type : data.data.bill.contract_price_type,
                        sale_price : data.data.bill.sale_price,
                        sale_price_deduct : data.data.bill.sale_price_deduct,
                        sale_price_excess : data.data.bill.sale_price_excess,
                        sale_order_amount : data.data.bill.sale_order_amount,
                        subtotal_amount : data.data.bill.subtotal_amount,
                        sales_tax : data.data.bill.sales_tax,
                        expense_subtotal : data.data.bill.expense_subtotal,
                        total_amount : data.data.bill.total_amount,
                        payment_schedule : data.data.bill.payment_schedule,
                        remarks : data.data.bill.remarks,
                    }
                });
                setOpen(true);
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
        })
    
    }
    const updateBillAssign = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
        let datas = {};
        datas["billid"] = state.billUpdateList.bill_id;
        datas["remarks"] = state.billUpdateList.remarks;
        BillService.billUpdate(datas).then((response) => {
            getData(work_month,bill_admit_status,delivery_status,work_company,recipient_list,perPage,del_flg,send_email,page,search_keywords,sort,column,order);
            handleClose();
            // window.location.href = "list";
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
            if(error.response.data){
                showErrorInfo(error.response.data);
            }

        })
        
    };

    const updatedData = (id:any,process:string,position:string) => {
        let data = {};
        data["orderid"] = id;
        data["process"] = process;
        data["position"] = position;
        if(data["process"] == "reject" || data["process"] == "cancel"){
            let remark = prompt("理由:");
            if (remark == null) {
                return;
            }
            data['remark'] = remark;
        }
        
        BillService.admitDate(data).then((data)=>{
            window.location.reload();
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
        });
    }

    let downloadURL = process.env.REACT_APP_DOWNLOADURL;

    const showPdfData = (bill:any) =>{
        BillService.showPdf(bill).then((response)=>{
            // let file = "accounting_path/"+response.data.data.data.filename;
            // window.open(ResourceService.url(file));            
            if(response.data.data.success == "error"){
                alert(response.data.data.msg);
            }else{ 
                let orderid =  response.data.data.data.orderid;
                let store_filename  = response.data.data.data.store_filename;
                let template = response.data.data.data.template;

                const file = new Blob([`${template}`], {type: "image/svg+xml;utf8"});
                ResourceService.apiUpload(file, 'accounting_path/', store_filename).then((res)=>{
                    window.location.href = `/management/download/svg/${orderid}?type=4`;
                });
            }

        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
        });
    }

    const dlPdfData = async (bill:any) =>{
        BillService.showPdf(bill).then((response)=>{
            // let file = "accounting_path/"+response.data.data.data.filename;
            // window.open(ResourceService.url(file));            
            if(response.data.data.success == "error"){
                alert(response.data.data.msg);
            }else{ 
                let orderid =  response.data.data.data.orderid;
                let store_filename  = response.data.data.data.store_filename;
                let template = response.data.data.data.template;
                generatePDF(template, response.data.data.data.pdf_file_name);
                // const file = new Blob([`${template}`], {type: "image/svg+xml;utf8"});
                // ResourceService.apiUpload(file, 'accounting_path/', store_filename).then((res)=>{
                //     window.location.href = `/management/download/svg/${orderid}?type=4`;
                // });
            }

        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
        });

        // BillService.dlPdf(bill).then((response)=>{
        //     let file = "accounting_path/"+response.data.data.data.filename;
        //     window.open(ResourceService.download(file));
        // }).catch((error) => {
        //     if(error.response.status == 403){
        //         alert(error.response.data.message);
        //     }
        // })
    }

    function getSvgDimensions(svgData:any) {
        // Create a temporary SVG element
        const tempSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        tempSvg.innerHTML = svgData;

        // Get width and height attributes
        const widthAttr = tempSvg.getAttribute('width');
        const heightAttr = tempSvg.getAttribute('height');
        const viewBoxAttr = tempSvg.getAttribute('viewBox');

        let width = widthAttr ? parseFloat(widthAttr) : 0;
        let height = heightAttr ? parseFloat(heightAttr) : 0;
      
        // Calculate dimensions based on viewBox if width/height are not provided
        if (!width || !height) {
          if (viewBoxAttr) {
            const viewBox = viewBoxAttr.split(' ').map(Number);
            width = viewBox[2] || 0; // viewBox width (x, y, width, height)
            height = viewBox[3] || 0; // viewBox height
          }
        }
      
        return { width, height };
      }

    // const generatePDF = async (svgDataArr:any) => {
    //     // Create a temporary canvas to render the SVG
    //     for (let i = 0; i < svgDataArr.length; i++) {
    //         const svgData = svgDataArr[i];
    //         // Set A4 size in pixels for 150 DPI
    //         const canvas = document.createElement('canvas');
    //         const ctx = canvas.getContext('2d');
    //         canvas.width = 1880; // Set width according to your SVG
    //         canvas.height = 2600; // Set height according to your SVG
            
    //         // Render SVG to canvas
    //         Canvg.fromString(ctx, svgData).render();
        
    //         // Convert the canvas to PNG data URL
    //         // const pngDataUrl = canvas.toDataURL('image/png');
    //         const imgData = canvas.toDataURL('image/png');

    //         // Create a new PDF
    //         const pdf = new jsPDF('p', 'mm', 'a4');
    //         const imgWidth = 210; // A4 paper width in mm
    //         const pageHeight = 295; // A4 paper height in mm
    //         const imgHeight = canvas.height * imgWidth / canvas.width;
    //         let heightLeft = imgHeight;

    //         let position = 0;

    //         pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //         heightLeft -= pageHeight;

    //         while (heightLeft >= 0) {
    //             position = heightLeft - imgHeight;
    //             pdf.addPage();
    //             pdf.addImage(imgData, 'PNG', 0, position+30, imgWidth, imgHeight);
    //             heightLeft -= pageHeight;
    //         }

    //         pdf.save('document.pdf');
    //     }

    //     // // Fetch the PNG image bytes
    //     // const pngImageBytes = await fetch(pngDataUrl).then(res => res.arrayBuffer());
    
    //     // // Create a new PDF document
    //     // const pdfDoc = await PDFDocument.create();
    //     // const page = pdfDoc.addPage([595.276, 841.89]); // Page size in points
    
    //     // // Embed the PNG image into the PDF
    //     // const pngImage = await pdfDoc.embedPng(pngImageBytes);
    
    //     // // Draw the PNG image on the page
    //     // const { width, height } = pngImage.size();
    //     // page.drawImage(pngImage, {
    //     //   x: 50, // Adjust as needed
    //     //   y: 741.890 - height - 50, // Adjust as needed, A4 height - image height - margin
    //     //   width: width,
    //     //   height: height,
    //     // });
    
    //     // // Serialize the document to bytes
    //     // const pdfBytes = await pdfDoc.save();
    
    //     // // Trigger file download
    //     // const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    //     // const link = document.createElement('a');
    //     // link.href = URL.createObjectURL(blob);
    //     // link.download = 'document.pdf';
    //     // link.click();
    //   };

    // const downloadPdf = async (svgData:any) => {
    //     // Create a temporary SVG element
    //     const svgElement = document.createElement('div');
    //     svgElement.innerHTML = svgData;
    //     svgElement.style.position = 'absolute'; // Ensure it's out of view if needed
    //     svgElement.style.top = '-10000px'; // Move it out of the viewport
    //     svgElement.style.left = '-10000px';
    //     document.body.appendChild(svgElement);
    
    //     // Wait for the SVG to be fully rendered
    //     await new Promise(resolve => setTimeout(resolve, 100));
    
    //     // Capture the SVG with html2canvas
    //     html2canvas(svgElement).then(canvas => {
    //       // Convert the canvas to a PNG data URL
    //       const imgData = canvas.toDataURL('image/png');
          
    //       // Create a link element and trigger a download
    //       const link = document.createElement('a');
    //       link.href = imgData;
    //       link.download = 'screenshot.png';
    //       link.click();
          
    //       // Clean up the temporary element
    //       document.body.removeChild(svgElement);
    //     });
    //   };

      
    // const downloadPdf = async (svgElement:any) => {
    //     try {
    //         // Capture SVG as canvas
    //         const canvas = await html2canvas(svgElement!, {
    //             useCORS: true, // Enables cross-origin resource sharing (CORS)
    //             allowTaint: true, // Allows canvas to be tainted by cross-origin content
    //         });
    //         // const canvas = document.createElement('canvas');

    //         // Convert canvas to image data
    //         const imgData = canvas.toDataURL('image/png');

    //         // Create a new PDF
    //         const pdf = new jsPDF('p', 'mm', 'a4');
    //         const imgWidth = 210; // A4 paper width in mm
    //         const pageHeight = 295; // A4 paper height in mm
    //         const imgHeight = canvas.height * imgWidth / canvas.width;
    //         let heightLeft = imgHeight;

    //         let position = 20;

    //         pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //         heightLeft -= pageHeight;

    //         while (heightLeft >= 0) {
    //             position = heightLeft - imgHeight;
    //             pdf.addPage();
    //             pdf.addImage(imgData, 'PNG', 0, position+30, imgWidth, imgHeight);
    //             heightLeft -= pageHeight;
    //         }

    //         pdf.save('document.pdf');
    //     } catch (error) {
    //         console.error('Error generating PDF:', error);
    //     }
    // }

    // const generatePDF = async (svgElement:any) => {
    //     // Create a new PDF document
    //     const pdfDoc = await PDFDocument.create();
        
    //     // Add a page to the document
    //     const page = pdfDoc.addPage([600, 400]); // Page size in points
    
    //     // SVG data
    //     // const svgData = '<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><rect width="100" height="100" fill="red" /></svg>';
    
    //     // Convert SVG to PNG
    //     const canvas = document.createElement('canvas');
    //     canvas.width = 100; // Set width according to your SVG
    //     canvas.height = 100; // Set height according to your SVG
    //     const ctx = canvas.getContext('2d');
    
    //     Canvg.fromString(ctx, svgElement).render();
    
    //     // Get PNG data URL
    //     const pngDataUrl = canvas.toDataURL('image/png');
    
    //     // Embed PNG into PDF
    //     const pngImageBytes = await fetch(pngDataUrl).then(res => res.arrayBuffer());
    //     const pngImage = await pdfDoc.embedPng(pngImageBytes);
    
    //     // Draw the PNG image on the page
    //     const { width, height } = pngImage.size();
    //     page.drawImage(pngImage, {
    //       x: 50,
    //       y: 300,
    //       width: width,
    //       height: height,
    //     });
    
    //     // Serialize the document to bytes
    //     const pdfBytes = await pdfDoc.save();
    
    //     // Trigger file download
    //     const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    //     const link = document.createElement('a');
    //     link.href = URL.createObjectURL(blob);
    //     link.download = 'document.pdf';
    //     link.click();
    //   };
    


    const defaultColumns = [
        {
            name: "bill_id",
            label: "編集",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<button type="button" className="btn_standard btn-sm" onClick={() => {
                            updateBill(`${tableMeta.rowData[0]}`);
                            }}>編集
                        </button>);
                    },
                },
           
        },
        {
            "name" : 'bill_id', 
            "label" : "請求書連番"
        },
        {
            "name" : 'work_company', 
            "label" : "稼働会社",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div style={{width:"80px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            "name" : 'work_month', 
            "label" : "対象月"
        },
        {
            "name" : 'company',
            "label" : "宛先",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            "name" : 'bill_no', 
            "label" : "請求書No."
        },
        {
            "name" : 'make_date', 
            "label" : "作成日",
            options: {
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            "name" : 'sale_order_nm', 
            "label" : "業務名",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            "name" : 'buyer_contruct',
            "label" : "受注契約"
        },
        {
            "name" : 'sale_order_no', 
            "label" : "受注番号",
            options: {
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            "name" : 'person_name_jp', 
            "label" : "要員名"
        },
        {
            "name" : 'preview', 
            "label" : "表示",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            showPdfData(`${tableMeta.rowData[0]}`);
                            }}>表示
                        </button>);
                    },
                },
        },
        {
            "name" : 'download', 
            "label" : "ダウンロード",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // return(<Link to={`/cases/${tableMeta.rowData[1]}`} >{value}</Link>);
                        return(<button type="button" className="btn_standard btn-sm" onClick={() => {
                            dlPdfData(`${tableMeta.rowData[0]}`);
                            }}>ダウンロード
                        </button>);
                    },
                },
        },
        {
            "name" : 'jimu_id', 
            "label" : "事務",
            options: {
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div style={{width:"80px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            "name" : 'sale_name', 
            "label" : "営業担当者"
        },
        {
            "name" : 'sale_remark', 
            "label" : "営業担当理由",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(value == null){
                        let sale_remark = value;
                        return(sale_remark);
                    }else{
                        let sale_remark = value.substring(0,7);
                        if(sale_remark == 'reject!'){
                            return(value.substring(7));
                        }else
                        {
                            return(value);
                        }    
                    } 
                }
            }
        },
        {
            "name" : 'sale_approval_date', 
            "label" : "営業担当承認日",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                if(login_user.role != 6){
                    if(login_user.role == 1 || login_user.role ==2 || login_user.role ==9 && tableMeta.rowData[14] != null){
                        if(tableMeta.rowData[16]!=""){
                            if(login_user.user_id == tableMeta.rowData[51]){
                                return(<div>
                                    {tableMeta.rowData[16]}
                                    <button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                        updatedData(`${tableMeta.rowData[0]}`,"cancel","sale");
                                        }}>取消
                                    </button>
                                </div>);
                            }else{
                                return(tableMeta.rowData[16]);
                            }
                        }else{
                            if(tableMeta.rowData[23]==null){//send_date
                                let saleReject = 0;
                                if(tableMeta.rowData[15]!=null){
                                    saleReject = (tableMeta.rowData[15].substring(0,7) == "reject!")?1:0
                                }
                                if(saleReject !=1){
                                    return(
                                        <div>
                                            <button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                            updatedData(`${tableMeta.rowData[0]}`,"admit","sale");
                                            }}>承認
                                            </button>
                                            <button type= "button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                            updatedData(`${tableMeta.rowData[0]}`,"reject","sale");
                                            }}>却下</button>
                                        </div>
                                        );
                                }else
                                {
                                    return(
                                        <div>
                                        <button type= "button" className="btn_disable ml_20 btn-sm">却下</button>
                                        </div>
                                        );
                                }
                            }
                        }
                         
                    }
                }
            },
        }
        },
        {
            "name" : 'manager_name', 
            "label" : "部長",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"50px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            "name" : 'manager_remark', 
            "label" : "部長理由",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(value == null){
                        let manager_remark = value;
                        return(manager_remark);
                    }else{
                        let manager_remark = value.substring(0,7);
                        if(manager_remark == 'reject!'){
                            return(value.substring(7));
                        }else
                        {
                            return(value);
                        }    
                    }
                }
            }
        },
        {
            "name" : 'manager_approval_date', 
            "label" : "部門長承認日",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(login_user.role != 6){
                        if(login_user.role ==2 || login_user.role ==9 && tableMeta.rowData[17] != null){
                            if(tableMeta.rowData[19]!=""){
                                if(login_user.user_id == tableMeta.rowData[52] && tableMeta.rowData[23] == null){
                                    return(<div>
                                        {tableMeta.rowData[19]}
                                        <button type="button" className="btn_standard btn-sm" onClick={() => {
                                            updatedData(`${tableMeta.rowData[0]}`,"cancel","manager");
                                            }}>取消
                                        </button>
                                    </div>);
                                }else{
                                    return(tableMeta.rowData[19]);
                                }
                            }
                            else{
                                if(tableMeta.rowData[23] == null){//send_date
                                    let saleReject = 0;
                                    let managerReject = 0;
                                    if(tableMeta.rowData[15]!=null){
                                        saleReject = (tableMeta.rowData[15].substring(0,7) == "reject!")?1:0
                                    }
                                    if(tableMeta.rowData[18]!=null){
                                        managerReject = (tableMeta.rowData[18].substring(0,7) == "reject!")?1:0
                                    }
                                    if(saleReject !=1 && managerReject !=1){
                                        return(
                                            <div>
                                            <button type="button" className="btn_standard btn-sm" onClick={() => {
                                            updatedData(`${tableMeta.rowData[0]}`,"admit","manager");
                                            }}>承認
                                            </button>
                                            <button type= "button" className="btn_standard btn-sm" onClick={() => {
                                            updatedData(`${tableMeta.rowData[0]}`,"reject","manager");
                                            }}>却下</button>
                                            </div>
                                            );
                                    }
                                    else if(saleReject !=1 && managerReject ==1)
                                    {
                                        return(
                                            <div>
                                            <button type= "button" className="btn_disable btn-sm">却下</button>
                                            </div>
                                            );
                                    }
                                } 
                            }
                               
                        }
                    }
                    },
                }
        },
        {
            "name" : 'director_name', 
            "label" : "責任者",
            options: {
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div style={{width:"80px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            "name" : 'director_remark', 
            "label" : "責任者理由",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(value == null){
                        let director_remark = value;
                        return(director_remark);
                    }else{
                        let director_remark = value.substring(0,7);
                        if(director_remark == 'reject!'){
                            return(value.substring(7));
                        }else
                        {
                            return(value);
                        }  
                    }
                }
            }
        },
        {
            "name" : 'director_approval_date', 
            "label" : "責任者の承認日",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(login_user.role != 6){
                        if(login_user.role ==9 && tableMeta.rowData[20] != null){
                            if(tableMeta.rowData[22]!=""){
                                if(login_user.user_id == tableMeta.rowData[53] && tableMeta.rowData[23] == null){
                                    return(<div>
                                        {tableMeta.rowData[22]}
                                        <button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                            updatedData(`${tableMeta.rowData[0]}`,"cancel","director");
                                            }}>取消
                                        </button>
                                    </div>);
                                }
                                else{
                                    return(tableMeta.rowData[22]); 
                                }
                            }
                            else{
                                if(tableMeta.rowData[23]==null){
                                    let saleReject = 0;
                                    let managerReject = 0;
                                    let directorReject = 0;
                                    if(tableMeta.rowData[15]!=null){
                                        saleReject = (tableMeta.rowData[15].substring(0,7) == "reject!")?1:0
                                    }
                                    if(tableMeta.rowData[18]!=null){
                                        managerReject = (tableMeta.rowData[18].substring(0,7) == "reject!")?1:0
                                    }
                                    if(tableMeta.rowData[21]!=null){
                                        directorReject = (tableMeta.rowData[21].substring(0,7) == "reject!")?1:0
                                    }
                                    if(saleReject!=1 && managerReject!=1 && directorReject!=1){
                                        return(
                                            <div>
                                            <button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                            updatedData(`${tableMeta.rowData[0]}`,"admit","director");
                                            }}>承認
                                            </button>
                                            <button type= "button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                            updatedData(`${tableMeta.rowData[0]}`,"reject","director");
                                            }}>却下</button>
                                            </div>
                                            );
                                    }else if(saleReject!=1 && managerReject!=1 && directorReject==1)
                                    {
                                        return(
                                            <div>
                                            <button type= "button" className="btn_disable ml_20 btn-sm">却下</button>
                                            </div>
                                            );
                                    }
                                }
                            }
                        }
                    }
                    },
                }
        },
        {
            "name" : 'send_date', 
            "label" : "送付日",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(tableMeta.rowData[23]==null){
                        if(tableMeta.rowData[19]!="" || tableMeta.rowData[22]!=""){
                            return(
                                 <button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                    changeSendMail(`${tableMeta.rowData[0]}`,"send_email");
                                    }}>送付
                                 </button>
                                 );
                        }
                    }else{
                        return(<div style={{width:"100px"}}>{value.slice(0,10)}</div>);
                    }
                     }
            }
        },
        {
            "name" : 'partner_id', 
            "label" : "取引先ID"
        },
        {
            "name" : 'partner_jimu_name', 
            "label" : "送付先事務担当名"
        },
        {
            "name" : 'partner_jimu_email', 
            "label" : "送付先事務アドレス"
        },
        {
            "name" : 'partner_sale_email', 
            "label" : "送付先営業アドレス"
        },
        {
            "name" : 'bill_from_date', 
            "label" : "作業期間開始日"
        },
        {
            "name" : 'bill_to_date', 
            "label" : "作業期間終了日"
        },
        {
            "name" : 'sale_order', 
            "label" : "請求備考",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"80px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            "name" : 'settlement', 
            "label" : "基準時間下限"
        },
        {
            "name" : 'settlement_to', 
            "label" : "基準時間上限"
        },
        {
            "name" : 'work_hour', 
            "label" : "稼働時間"
        },
        {
            "name" : 'deduct_hour', 
            "label" : "控除時間"
        },
        {
            "name" : 'excess_hour', 
            "label" : "超過時間"
        },
        {
            "name" : 'bill_flg', 
            "label" : "請求有フラグ"
        },
        {
            "name" : 'rate', 
            "label" : "稼働率"
        },
        {
            "name" : 'contract_price_type', 
            "label" : "稼働単金種別"
        },
        {
            "name" : 'sale_price', 
            "label" : "単金"
        },
        {
            "name" : 'sale_price_deduct', 
            "label" : "控除単価"
        },
        {
            "name" : 'sale_price_excess', 
            "label" : "超過単価"
        },
        {
            "name" : 'sale_order_amount', 
            "label" : "金額（円）"
        },
        {
            "name" : 'subtotal_amount', 
            "label" : "小計"
        },
        {
            "name" : 'sales_tax', 
            "label" : "消費税"
        },
        {
            "name" : 'expense_subtotal', 
            "label" : "経費"
        },
        {
            "name" : 'total_amount', 
            "label" : "合計"
        },
        {
            "name" : 'payment_schedule', 
            "label" : "支払日",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            "name" : 'remarks', 
            "label" : "その他備考",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"80px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            "name" : 'del_flg', 
            "label" : "削除フラグ"
        },
        {
            "name" : 'update_date', 
            "label" : "更新日",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            name: "sale_id",
            label: "Sale ID",
            "options":{
                "display":false,
             }
        },
        {
            name: "manager_id",
            label: "Manager ID",
            "options":{
                "display":false,
             }
        },
        {
            name: "director_id",
            label: "責任者 ID",
            "options":{
                "display":false,
             }
           },
    ]

    let [state, setState] = useState<IState>({
        loading : false,
        bills: [],
        distination_list : [],
        errorMessage : "",
        page : 0,
        perPage : 10,
        del_flg : "",
        column : "",
        order : "",
        totalRows : 0,
        billUpdateList : {
            bill_id : "",
            update_type : "",
            work_company : "",
            work_month : "",
            company : "",
            bill_no : "",
            make_date : "",
            sale_order_nm : "",
            buyer_contruct : "",
            sale_order_no : "",
            person_name_jp : "",
            jimu_id : "",
            sale_id : "",
            sale_remark : "",
            sale_approval_date : "",
            manager_id : "",
            manager_remark : "",
            manager_approval_date : "",
            director_id : "",
            director_remark : "",
            director_approval_date : "",
            send_date : "",
            partner_id : "",
            partner_jimu_name : "",
            partner_jimu_email : "",
            partner_sale_email : "",
            bill_from_date : "",
            bill_to_date : "",
            sale_order : "",
            settlement : "",
            settlement_to : "",
            work_hour : "",
            deduct_hour : "",
            excesss_hour : "",
            bill_flg : "",
            rate : "",
            contract_price_type : "",
            sale_price : "",
            sale_price_deduct : "",
            sale_price_excess : "",
            sale_order_amount : "",
            subtotal_amount : "",
            sales_tax : "",
            expense_subtotal : "",
            total_amount : "",
            payment_schedule : "",
            remarks : "",
        }
    });
    const getData = async (work_month:any, bill_admit_status:string, delivery_status:string,work_company:string,recipient_list:any, perPage:number,del_flg:any,send_email:any, page:number,search_keywords:any, sort:any, column:string, order:string) => {
        setState({...state,loading :true,})
        BillService.list(work_month,bill_admit_status,delivery_status,work_company,recipient_list,perPage,del_flg,send_email,page,search_keywords,sort,column,order).then((response)=>{
            setState({
                ...state,
                loading:false,
                bills : response.data.data,
                distination_list : response.data.distination_list,
                page : response.data.current_page,
                column : response.data.column,
                del_flg : response.data.del_flg,
                order : response.data.order,
                perPage : response.data.per_page,
                totalRows : response.data.total,
            })
            if(Math.floor(response.data.total/response.data.per_page) >0){
                setShowNextColor("blue");
                }else{
                    setShowNextColor("grey");
                }
                if(0 == response.data.current_page-1){
                    setShowPrevColor("grey");
                }
                if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
                    setShowNextColor("grey");
                }
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
            setState({
                ...state,
                loading:false,
                errorMessage:error.message
            })
        })
    }

    const deleteBill = () => {
        if(selectedRows.length == 0){
            alert("削除したい見込み客をチェックしてください。");
            return false;
        }
        let ans = window.confirm("削除を実施します、大丈夫ですか？");
        if(ans){
            let ids : any[] = [];
            bills.forEach((element, index) => {            
                if (selectedRows.length > 0 && selectedRows.includes(index)) {
                    ids.push(element["bill_id"]);
                }
            });
            let data = {};
            data["ids"] = ids;
            BillService.deleteList(data).then((data)=>{
                getData(work_month,bill_admit_status,delivery_status,work_company,recipient_list,perPage,del_flg,send_email,page,search_keywords,sort,column,order);
                setSelectedRows([]);
                // window.location.reload();
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
            });
        }
       
    }

    let {loading, bills, distination_list,perPage, page,column,del_flg,order, totalRows,billUpdateList, errorMessage} = state;

    useEffect(()=>{
        setState({...state, loading: true});
        getData(work_month,bill_admit_status,delivery_status,work_company,recipient_list,perPage,del_flg,send_email,page,search_keywords,sort,column,order);
    }, [perPage]);

    let changePage = (page:any) => {
        setCurrentPage(page);
        getData(work_month,bill_admit_status,delivery_status,work_company,recipient_list,perPage,del_flg,send_email,page+1,search_keywords,sort,column,order);
   };

    let changeRowsPerPage = (perPage:any) => {
        getData(work_month,bill_admit_status,delivery_status,work_company,recipient_list,perPage,del_flg,send_email,page+1,search_keywords,sort,column,order);
    };

    const [no_of_rows, setNoOfRows] = useState(10);
    const [current_page, setCurrentPage] = useState(0);
    const options = {
        filterType: 'checkbox' as any,
        tableBodyHeight : "360px",
        tableBodyMaxHeight : "360px",
        responsive: "standard" as any,
        selectableRows: "multiple" as any,
        // selectableRowsHeader: true as any, 
        page: page,
        rowsPerPage: perPage,
        rowsPerPageOptions:[10,20,50,100,200],
        // selectableRowsOnClick: true  as any,
        selectableRowsHeader: true,
        fixedHeader: true,
        fixedSelectColumn: true,
        count: totalRows,
        serverSide: true,
        filter : false,
        print: false,
        viewColumns : false,
        sort: true,
        search : false,
        download :false,
        customToolbarSelect: (selectedRows:any, displayData:any, setSelectedRows:any) => {
            let rows : any[] = [];
                selectedRows.data.forEach((element:any, index:any) => {
                    rows.push(element.dataIndex);
                })
            let csvData : any[] = [];
                bills.forEach((element, index) => {
                        if (rows.includes(index)) {
                            csvData.push(element);
                        }
                });
            const getFileName = () => {
                    let now = new Date().toLocaleDateString();
                    let year = now.slice(0,4);
                    let month = '0' + now.slice(5,6);
                    let day = now.slice(7);
                    let date = year+month+day;
                    return "請求書" +date;
                }
            let headers = [
                    { label: "請求書連番", key: "bill_id" },
                    { label: "稼働会社", key: "work_company" },
                    { label: "対象月", key: "work_month" },
                    { label: "宛先", key: "company" },
                    { label: "請求書No.", key: "bill_no" },
                    { label: "作成日", key: "make_date" },
                    { label: "業務名", key: "sale_order_nm" },
                    { label: "受注契約", key: "buyer_contruct"},
                    { label: "受注番号", key: "sale_order_no" },   
                    { label: "要員名", key: "person_name_jp" },
                    { label: "表示", key: "preview" },
                    { label: "ダウンロード", key: "download" },
                    { label: "事務", key: "jimu_id" },
                    { label: "営業担当者", key: "sale_id" },
                    { label: "営業担当理由", key: "sale_remark"},
                    { label: "営業担当承認日", key: "sale_approval_date" },
                    { label: "部長", key: "manager_id" },   
                    { label: "部長理由", key: "manager_remark" }, 
                    { label: "部門長承認日", key: "manager_approval_date" },
                    { label: "責任者", key: "director_id" },
                    { label: "責任者理由", key: "director_remark" },
                    { label: "責任者の承認日", key: "director_approval_date"},
                    { label: "送付日", key: "send_date" },
                    { label: "取引先ID", key: "partner_id" },
                    { label: "送付先事務担当名", key: "partner_jimu_name" },   
                    { label: "送付先事務アドレス", key: "partner_jimu_email" },
                    { label: "送付先営業アドレス", key: "partner_sale_email" },
                    { label: "作業期間開始日", key: "bill_from_date" },
                    { label: "作業期間終了日", key: "bill_to_date" },
                    { label: "請求備考", key: "sale_order" },
                    { label: "基準時間下限", key: "settlement" },
                    { label: "基準時間上限", key: "settlement_to" },
                    { label: "稼働時間", key: "work_hour" },
                    { label: "控除時間", key: "deduct_hour" },
                    { label: "超過時間", key: "excess_hour" },
                    { label: "請求有フラグ", key: "bill_flg" },
                    { label: "稼働率", key: "rate" },
                    { label: "稼働単金種別", key: "contract_price_type" },
                    { label: "単金", key: "sale_price" },
                    { label: "控除単価", key: "sale_price_deduct" },
                    { label: "超過単価", key: "sale_price_excess" },
                    { label: "金額（円）", key: "sale_order_amount" },
                    { label: "小計", key: "subtotal_amount" },
                    { label: "消費税", key: "sales_tax" },
                    { label: "経費", key: "expense_subtotal" },
                    { label: "合計", key: "total_amount" },
                    { label: "支払日", key: "payment_schedule" },
                    { label: "その他備考", key: "remarks" },
                    { label: "削除フラグ", key: "del_flg" },
                    { label: "更新日", key: "update_date" },
                  ];
            return (<CSVLink  className="btn_standard text-decoration-none" filename={getFileName()} data={csvData}  headers={headers}>ダウンロード</CSVLink>);
        },
        textLabels: {
            body: {
              noMatch: "データはありません。",
              toolTip: "Sort"
            },
            pagination: {
              next: "Next Page",
              previous: "Previous Page",
              rowsPerPage: "件表示", 
              displayRows: "すべての件数：",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
            filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              selectedRows: {
                text: "行 を選択しました。",
                delete: "削除",
                deleteAria: "選択した行を削除しました。",
              },
          },
          rowsSelected: selectedRows,
          onRowSelectionChange: (rowsSelectedData:any, allRows:any, rowsSelected:any) => {
            setSelectedRows(rowsSelected);
        },
        onChangePage (currentPage:any) {
            changePage(currentPage);
            setCurrentPage(currentPage);
            // changePage(currentPage);
        },
          onChangeRowsPerPage (numberOfRows:any) {
            changeRowsPerPage(numberOfRows);
            setNoOfRows(numberOfRows);
         },
         onColumnSortChange: (changedColumn:any, direction:any) => {
            let order = 'desc';
            if (direction === 'asc') {
              order = 'asc';
            }
            handleSort(changedColumn, order);
          },
    };
    const handleDateChange = function(date:any, column_name:any){
        let selected_data =(date != null)? date.getFullYear() +"-"+ (date.getMonth() + 1).toString().padStart(2, "0") : "";
        switch (column_name) {
            case "work_month":
                setSelectedTargetDate(date);
                setWorkMonth(selected_data);
                getData(selected_data,bill_admit_status,delivery_status,work_company,recipient_list,perPage,del_flg,send_email,page,search_keywords,sort,column,order);
                break;
            default:
                break;
        }
    }
    let handleSort = (column:any, order:any) => {
        getData(work_month,bill_admit_status,delivery_status,work_company,recipient_list,perPage,del_flg,send_email,page+1,search_keywords,sort,column,order);    
        setSelectedRows([]);
    };
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => { 
        switch (e.target.name) {
            case "search":
                    setSearchKeyword(e.target.value);
                    getData(work_month,bill_admit_status,delivery_status,work_company,recipient_list,perPage,del_flg,send_email,page,e.target.value,sort,column,order);              
                break;
            default:
                break;
        }
      };

    const changeSendMail = function(bill_id:any, column_name:any){
        let selected_data = bill_id;
        window.alert("請求書を送付しました。");
        switch (column_name) {
            case "send_email":
                setSendEmail(selected_data);
                getData(work_month,bill_admit_status,delivery_status,work_company,recipient_list,perPage,del_flg,selected_data,page,search_keywords,sort,column,order);
                break;
            default:
                break;
        }
    }

    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    switch (e.target.name) {
        case "admit_status":
            setBillAdmitStatus(e.target.value);
            getData(work_month,e.target.value,delivery_status,work_company,recipient_list,perPage,del_flg,send_email,page,search_keywords,sort,column,order);
            break;
        case "delivery_status":
            setDeliveryStatus(e.target.value);
            getData(work_month,bill_admit_status,e.target.value,work_company,recipient_list,perPage,del_flg,send_email,page,search_keywords,sort,column,order);
            break;
        case "work_company":
            setWorkCompany(e.target.value);
            getData(work_month,bill_admit_status,delivery_status,e.target.value,recipient_list,perPage,del_flg,send_email,page,search_keywords,sort,column,order);
            break;
        case "recipient_list":
            setRecipientList(e.target.value);
            getData(work_month,bill_admit_status,delivery_status,work_company,e.target.value,perPage,del_flg,send_email,page,search_keywords,sort,column,order);
            break;
        case "list_length":
            changeRowsPerPage(e.target.value);
            setNoOfRows(parseInt(e.target.value));
            setState({
                ...state,
                perPage : parseInt(e.target.value)

            })
                break;
        default:
            break;
    }
  };
  
  let updateTextArea = (event:React.ChangeEvent<HTMLTextAreaElement>):void => {
    setState({
        ...state,
        billUpdateList : {
            ...state.billUpdateList,
            [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
        }
    });
}

let nextPage = () => {
        
    // if(Math.floor(totalRows/perPage) >0){
    //     setShowNextColor("blue");
    // }

    if(Math.floor(totalRows/perPage) >0){
        changePage(current_page+1);
        setCurrentPage(current_page+1);
        setState({
            ...state,
            page : current_page+1
        })
        setShowNextColor("blue");
    }
    
    if(Math.ceil(totalRows/perPage)-2 == current_page){ // Last Page
        setShowNextColor("grey");
    }   
    if(0 < current_page+1){
        setShowPrevColor("blue");
    }     

}

let previousPage = () => {
    if(current_page > 0){
        setCurrentPage(current_page-1);
        changePage(current_page - 1);
        setState({
            ...state,
            page : current_page-1
        });
        setShowPrevColor("blue");
    }
    if(0 == current_page-1){
        setShowPrevColor("grey");
    } 

    if(Math.floor(totalRows/perPage) >0){
        setShowNextColor("blue");
    } 
    
}
  const handleChange  = (e: React.ChangeEvent<HTMLInputElement>): void => {
    switch (e.target.name) {
        case "del_flg":
            if(e.target.checked == true){
                e.target.value = "1";
                setFlgChecked(e.target.checked);
                getData(work_month,bill_admit_status,delivery_status,work_company,recipient_list,perPage,e.target.value,send_email,page,search_keywords,sort,column,order);
            }else
            {
                setFlgChecked(e.target.checked);
                e.target.value = "";
                getData(work_month,bill_admit_status,delivery_status,work_company,recipient_list,perPage,e.target.value,send_email,page,search_keywords,sort,column,order);
            }
            break;
        default:
            break;
    }
    };
    const getMuiTheme = () =>
        
        
    createTheme({
    components: {
    MUIDataTable: {
        styleOverrides:{
            responsiveScroll: {
                maxHeight: 'none',
            },
        }
    },
    MuiPaper: {
        styleOverrides:{
            root: {
                width: "100%",
                marginTop: "5%",
                // // marginLeft: "5%",
                // // marginBottom: "20%"
            }
        }
    },  
    MUIDataTableHeadCell: {
            styleOverrides:{
            root: {
                backgroundColor: "rgb(193,193,193)",
                padding: '0px'
            }
            }
    },
    MuiTableCell: {
        styleOverrides:{
            head: {
                    color : 'white',
                    backgroundColor: "red !important"
            }
        }
    },
    MUIDataTableBodyCell: {
        styleOverrides:{
        root: {
            backgroundColor: "#f1f1f1",
            width: "100%",
            padding: '0px'
        }
        }
    }
    }
    })

    const edit_modal_box = (
        <div>
            <Row>
                <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                    <button type="button" className="modal_close" onClick={handleClose}>X</button>
                </Col>            
            </Row>
            <form className="form" onSubmit={updateBillAssign}>
                <Row>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="bill_id">請求書連番:</label> 
                            <input type="text" name="bill_id" className="form-control" value={billUpdateList['bill_id']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="work_company">稼働会社:</label> 
                            <input type="text" name="work_company" className="form-control" value={billUpdateList['work_company']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="work_month">対象月:</label> 
                            <input type="text" name="work_month" className="form-control" value={billUpdateList['work_month']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="company">宛先:</label> 
                            <input type="text" name="company" className="form-control" value={billUpdateList['company']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="bill_no">請求書No.</label> 
                            <input type="text" name="bill_no" className="form-control"  value={billUpdateList['bill_no']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="make_date">作成日</label> 
                            <input type="text" name="make_date" className="form-control" value={billUpdateList['make_date']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="sale_order_nm">業務名:</label> 
                            <input type="text" name="sale_order_nm" className="form-control" value={billUpdateList['sale_order_nm']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="buyer_contruct">受注契約</label> 
                            <input type="text" name="buyer_contruct" className="form-control" value={billUpdateList['buyer_contruct']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="sale_order_no">受注番号</label> 
                            <input type="text" name="sale_order_no" className="form-control" value={billUpdateList['sale_order_no']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="person_name_jp">要員名:</label> 
                            <input type="text" name="person_name_jp" className="form-control" value={billUpdateList['person_name_jp']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="jimu_id">事務</label> 
                            <input type="text" name="jimu_id"  className="form-control" value={billUpdateList['jimu_id']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="sale_id">営業担当者</label> 
                            <input type="text" name="sale_id"  className="form-control" value={billUpdateList['sale_id']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="sale_remark">営業担当理由</label> 
                            <input type="text" name="sale_remark" className="form-control" value={billUpdateList['sale_remark']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="sale_approval_date">営業担当承認日</label> 
                            <input type="text" name="sale_approval_date" className="form-control" value={billUpdateList['sale_approval_date']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="manager_id">部長</label> 
                            <input type="text" name="manager_id"  className="form-control" value={billUpdateList['manager_id']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="manager_remark">部長理由</label> 
                            <input type="text" name="manager_remark" className="form-control"  value={billUpdateList['manager_remark']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="manager_approval_date">部門長承認日</label> 
                            <input type="text" name="manager_approval_date" className="form-control" value={billUpdateList['manager_approval_date']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="director_id">責任者</label> 
                            <input type="text" name="director_id" className="form-control" value={billUpdateList['director_id']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="director_remark">責任者理由</label> 
                            <input type="text" name="director_remark" className="form-control" value={billUpdateList['director_remark']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="director_approval_date">責任者の承認日</label> 
                            <input type="text" name="director_approval_date" className="form-control" value={billUpdateList['director_approval_date']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="send_date">送付日</label> 
                            <input type="text" name="send_date" className="form-control" value={billUpdateList['send_date']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="partner_id">取引先ID</label> 
                            <input type="text" name="partner_id" className="form-control" value={billUpdateList['partner_id']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="partner_jimu_name">送付先事務担当名</label> 
                            <input type="text" name="partner_jimu_name" className="form-control" value={billUpdateList['partner_jimu_name']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="partner_jimu_name">送付先事務アドレス</label> 
                            <input type="text" name="partner_jimu_name"  className="form-control" value={billUpdateList['partner_jimu_email']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="partner_sale_email">送付先営業アドレス</label> 
                            <input type="text" name="partner_sale_email" className="form-control" value={billUpdateList['partner_sale_email']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="bill_from_date">作業期間開始日</label> 
                            <input type="text" name="bill_from_date" className="form-control"  value={billUpdateList['bill_from_date']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="bill_to_date">作業期間終了日</label> 
                            <input type="text" name="bill_to_date" className="form-control"  value={billUpdateList['bill_to_date']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="sale_order">請求備考</label> 
                            <input type="text" name="sale_order" className="form-control" value={billUpdateList['sale_order']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="settlement">基準時間下限</label> 
                            <input type="text" name="settlement" className="form-control" value={billUpdateList['settlement']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="settlement_to">基準時間上限</label> 
                            <input type="text" name="settlement_to" className="form-control"  value={billUpdateList['settlement_to']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="work_hour">稼働時間</label> 
                            <input type="text" name="work_hour" className="form-control" value={billUpdateList['work_hour']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="deduct_hour">控除時間</label> 
                            <input type="text" name="deduct_hour" className="form-control"  value={billUpdateList['deduct_hour']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="excesss_hour">超過時間</label> 
                            <input type="text" name="excesss_hour" className="form-control" value={billUpdateList['excesss_hour']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="bill_flg">請求有フラグ</label> 
                            <input type="text" name="bill_flg" className="form-control" value={billUpdateList['bill_flg']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="rate">稼働率</label> 
                            <input type="text" name="rate" className="form-control" value={billUpdateList['rate']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="contract_price_type">稼働単金種別</label> 
                            <input type="text" name="contract_price_type" className="form-control" value={billUpdateList['contract_price_type']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="sale_price">単金</label> 
                            <input type="text" name="sale_price"  className="form-control" value={billUpdateList['sale_price']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="sale_price_deduct">控除単価</label> 
                            <input type="text" name="sale_price_deduct" className="form-control" value={billUpdateList['sale_price_deduct']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="sale_price_excess">超過単価</label> 
                            <input type="text" name="sale_price_excess" className="form-control"  value={billUpdateList['sale_price_excess']} disabled={true}/>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="sale_order_amount">金額（円）</label> 
                            <input type="text" name="sale_order_amount" className="form-control" value={billUpdateList['sale_order_amount']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="subtotal_amount">小計</label> 
                            <input type="text" name="subtotal_amount" className="form-control" value={billUpdateList['subtotal_amount']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="sales_tax">消費税</label> 
                            <input type="text" name="sales_tax" className="form-control" value={billUpdateList['sales_tax']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="expense_subtotal">経費</label> 
                            <input type="text" name="expense_subtotal" className="form-control"  value={billUpdateList['expense_subtotal']} disabled={true} />
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="total_amount">合計</label> 
                            <input type="text" name="total_amount" className="form-control" value={billUpdateList['total_amount']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <div className="form-group">
                            <label htmlFor="payment_schedule">支払日</label> 
                            <input type="text" name="payment_schedule"  className="form-control" value={billUpdateList['payment_schedule']} disabled={true}/>
                        </div>          
                    </Col>
                    <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                        <div className="form-group">
                            <span id='text'>その他備考</span>
                            <textarea className="form-control" name = "remarks"  id= "remarks" aria-label="With textarea" value={billUpdateList["remarks"]} onChange={updateTextArea}></textarea>
                        </div>          
                    </Col>
                </Row>
                <Row>
                    <div className="form-group mt-5 text-center">
                    <button type="submit" className="button submit-btn btn_standard">更新</button>
                    </div>
                </Row>
            </form>
        </div>
    )

    return(
        <React.Fragment>
               <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                    ml: sizeConfigs.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color
                }}
                >
                    <Toolbar>
                        <Typography variant="h6">
                            <header className="top-baner">
                                <Row>
                                    <Col xxl={10} xl={10} md={10} sm={10} xs={10}>
                                        <div id="search-autocomplete" className="form-outline">
                                            <input type="text" name="search" id="search" placeholder = "検索(案件ID、案件名、一行アピール、職種、募集エリア、案件概要、作業場所、取引先名を検索)"
                                                className="form-control" onChange={onChange}/>
                                        </div>
                                    </Col>
                                    <Col xxl={2} xl={2} md={2} sm={2} xs={2} className="mt-4">
                                        <DropDownList />
                                    </Col>
                                </Row>
                            </header>
                        </Typography>
                    </Toolbar>
                </AppBar>
                        <Container>
                            <Row>
                                <Col xxl={4} xl={4} md={4} sm={4} xs={8}>
                                    <h3 className='header-left-design'>請求書一覧</h3>
                                </Col>
                                <Col xxl={4} xl={4} md={4} sm={4} xs={4}>
                                    <div className="mt-3 text-center">
                                        {( loading   == true) ? <Loader /> :<> </>}
                                    </div>
                                </Col>
                            </Row>
                            <Row className = "form-group mt-3 mb-3 filter_select">
                                <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                                    <div className="form-group work_month">
                                        <label className="control-label">対象月</label>
                                        <DatePicker 
                                            dateFormat="yyyy-MM"
                                            locale="ja"
                                            selected={selectedTargetDate} 
                                            onChange={date =>
                                                handleDateChange(date, "work_month")
                                            }
                                            name = "work_month"
                                            isClearable
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col xxl={9} xl={9} md={9} sm={9} xs={12} className="check-list">
                                    <label htmlFor="sort" className="control-label">承認状況</label>
                                    <select className="filter" name="admit_status" aria-label=".form-select-sm example" onChange={onSelect}>
                                        <option value="">全て</option>
                                        <option value="1">未承認</option>
                                        <option value="2">承認中</option>
                                        <option value="3">承認済</option>
                                    </select>
                                    <label htmlFor="sort" className="control-label">送付状況</label>
                                    <select className="filter" name="delivery_status" aria-label=".form-select-sm example" onChange={onSelect}>
                                        <option value="">全て</option>
                                        <option value="0">未送付</option>
                                        <option value="1">送付済</option>
                                    </select>
                                    <label htmlFor="sort" className="control-label">稼働会社</label>
                                    <select className="filter" name="work_company" aria-label=".form-select-sm example" onChange={onSelect}>
                                        <option value="">全て</option>
                                        <option value= "0">FloBoard</option>
                                        <option value="1">フロネット</option>
                                    </select>
                                    <label htmlFor="sort" className="control-label">宛先リスト</label>
                                    <select className="filter" name="recipient_list" aria-label=".form-select-sm example" onChange={onSelect}>
                                        <option value="">選ばない</option>
                                        {distination_list.map(distination => {
                                                if (distination !== null){
                                                    return(<option value={distination['company']}> {distination['company']}</option>)
                                                }
                                            })
                                        }
                                    </select>
                                </Col>
                                <Col xxl={3} xl={3} md={3} sm={3} xs={12}>
                                    <div className="float-end">
                                        <input className="" type="checkbox" id="del_flag_chk" name="del_flg" checked={flgchecked} value="" onChange={handleChange} style={{position:"relative",top:"2px"}}/> 
                                        <label htmlFor="del_flag_chk"  style={{paddingLeft:"10px"}}>削除済のデータ表示</label>
                                        <button type="button" className="btn_danger ml_20 btn-sm" onClick={deleteBill}>削除</button>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col xxl={6} xl={6} md={6} sm={6} xs={6}>
                                    <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                                </Col>
                                <Col xxl={6} xl={6} md={6} sm={6} xs={6}>
                                    <div className="float-right">
                                        <select name="list_length" id="list_length" value={no_of_rows} className="filter list_length" onChange={onSelect}>
                                            <option value="10">10</option>
                                            <option value="20" >20</option>
                                            <option value="50" >50</option>
                                            <option value="100" >100</option>
                                            <option value="200" >200</option>
                                        </select>
                                        <label htmlFor="sort" className="control-label">件表示</label>
                                        <button type="button" className="btn-sm previous-page-icon" style={{border:"0px"}} onClick={() => {
                                            previousPage();
                                            }}>
                                        <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                        </button>
                                        <button type="button" className=" btn-sm next-page-icon" style={{border:"0px"}} onClick={() => {
                                            nextPage();
                                            }}>
                                            <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                                        </button> 
                                    </div>
                                </Col>
                            </Row>
                            <div className='datatable bill-freeze-header bill-freeze-cell bill-frozen-columns xs-hidden'>
                                <MUIDataTable
                                    title={''}
                                    data={bills}
                                    columns={defaultColumns}
                                    options = {options}
                                />
                            </div>
                            <div className='datatable mobile pc-hidden'>
                                <MUIDataTable
                                    title={''}
                                    data={bills}
                                    columns={defaultColumns}
                                    options = {options}
                                />
                            </div>
                        </Container>
                        <Modal
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="keep-mounted-modal-title"
                            aria-describedby="keep-mounted-modal-description"
                            sx={ {
                                display: { xs: 'none', sm: 'block' },
                            }}
                        >  
                        <Box sx={PCModalStyle}>
                            {edit_modal_box}  
                        </Box>
                    </Modal>
                    <Modal
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="keep-mounted-modal-title"
                            aria-describedby="keep-mounted-modal-description"
                            sx={ {
                                display: { xs: 'block', sm: 'none' },
                            }}
                        >  
                        <Box sx={MobileModalStyle}>
                            {edit_modal_box}  
                        </Box>
                    </Modal>
        </React.Fragment>

    );
};
export default BillOrderList;