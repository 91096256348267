import React, {useContext, useState , useEffect} from 'react';
import './App.css';
import LoginForm from "./components/LoginForm";
import TwoFactorForm from "./components/TwoFactorForm";
import {Routes, Route, Navigate} from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import { routes } from "./routes";
import "react-datepicker/dist/react-datepicker.css";
import 'react-dropdown/style.css';
import './media.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend ,CategoryScale, registerables } from "chart.js";
import {AppStateContext} from './redux/AppStore';

function App() {
  const appStateContext = useContext(AppStateContext);

  if(!appStateContext) {
    throw Error('CurrentContext must be used whith a CounterProvider.');
  }

  const { appAuth } = appStateContext;
  const [isAuthenticated, setIsisAuthenticated] = useState(false);

  useEffect(()=>{
    if(appAuth.isAuthenticated()){
      setIsisAuthenticated(true);
    } else {
      if(!["/", "/verify", "/login"].includes(window.location.pathname) ){
        window.location.href = "/";
      }
    }
  }, []);

  return (
    <React.Fragment>
            <Routes>
                {
                  isAuthenticated?
                    <Route path="/" element={<MainLayout/>} >
                      {routes}
                    </Route>
                  :
                  <>
                    <Route path={'/'} element={<LoginForm/>} />
                    <Route path={'/login'} element={<LoginForm/>} />
                    <Route path='/verify' element={<TwoFactorForm/>} />
                  </>
                }
            </Routes>
    </React.Fragment>
  );
}
ChartJS.register(CategoryScale, ArcElement, Tooltip, Legend, ...registerables);
export default App;
