import React, {useState , useEffect} from 'react';
// import {ICompany} from "../../models/ICompany";
import CompanyInfoService from "../../services/CompanyInfoService";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import { CSVLink, CSVDownload } from "react-csv";
import {Link} from "react-router-dom";
import {CompanyInfoModel} from "../../models/CompanyInfo";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import DatePicker from "react-datepicker";
import {useSearchParams, useMatch } from "react-router-dom";
import Loader from "../../components/common/Loader";
import {showErrorInfo, PCModalStyle, MobileModalStyle} from "../../components/common/Helpers";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface IState{
    loading :boolean;
    companyInfo :  [];
    errorMessage : string;  
    perPage : number;
    page : number;
    column: string;
    order: string;
    totalRows : number;
    companyinfo_model : CompanyInfoModel;
    main_company_flg_list : []; 
}
interface IProps{}

let CompanyInfoList:React.FC<IProps> = () =>{
    
    let [state, setState] = useState<IState>({
        loading : false,
        companyInfo : [],
        errorMessage : "",
        perPage : 10,
        page : 0,
        column : "",
        order : "",
        totalRows : 0,
        companyinfo_model : {
            company_id : "",
            company_name : "",
            postal_code : "",
            address : "",
            telephone_no : "",
            fax : "",
            main_company_flg : "",
            closing_month : "",
            tax_rate : "",
            invoice_no : "",
            remarks : ""
        },
        main_company_flg_list : []
    });

    const [search, setSearch] = useState("");  
    const [sort, setSort] = useState("0");
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [current_page, setCurrentPage] = useState(0);
    const [no_of_rows, setNoOfRows] = useState(10);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const [width, setWidth] = useState(719);
    const [height, setHeight] = useState(650);
    const [showPrevColor, setShowPrevColor] = useState("grey");
    const [showNextColor, setShowNextColor] = useState("grey");
    

    const getData = async (perPage:number,page:number,search:any, sort:any, column:string, order:string) => {
        setState({...state,loading :true,})
        CompanyInfoService.list(perPage,page,search, sort, column, order).then((response)=>{
            setState({
                ...state,
                loading:false,
                companyInfo:response.data.data,
                page : response.data.current_page,
                column : response.data.column,
                order : response.data.order,
                perPage : response.data.per_page,
                totalRows : response.data.total,
                main_company_flg_list : response.data.main_company_flg
            })
            if(Math.floor(response.data.total/response.data.per_page) >0){
                setShowNextColor("blue");
            }else{
                setShowNextColor("grey");
            }
            if(0 == response.data.current_page-1){
                setShowPrevColor("grey");
            }
            if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
                setShowNextColor("grey");
            }
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
           
            setState({
                ...state,
                loading:false,
                errorMessage:error.message
            })
        })
    }   
        
    let {loading, companyInfo, perPage,page, column, order, totalRows, main_company_flg_list, errorMessage, companyinfo_model} = state;
 
    useEffect(()=>{
        setState({...state, loading: true});
        getData(perPage,page,search, sort, column, order);
    }, [perPage]);
    let changePage = (page:any) => {
        setCurrentPage(page);
        getData(perPage,page+1,search, sort, column, order);
    };
    let changeRowsPerPage = (perPage:any) => {
        getData(perPage,page,search, sort, column, order);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.target.name) {
            case "search":
                    setSearch(e.target.value);
                    getData(perPage,page,e.target.value, sort, column, order);              
                break;
            default:
                break;
        }
        
    };
    let handleSort = (column:any, order:any) => {
        getData(perPage,page,search, sort, column, order);              
      };

    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        switch (e.target.name) {
            case "sort":
                setSort(e.target.value);
                getData(perPage, page, search, e.target.value, column, order);
                break;
            case "list_length":
                changeRowsPerPage(e.target.value);
                setNoOfRows(parseInt(e.target.value));
                setState({
                    ...state,
                    perPage : parseInt(e.target.value)

                })
                break;
            case "main_company_flg":
                setState({
                    ...state,
                    companyinfo_model : {
                        ...state.companyinfo_model,
                       "main_company_flg" : e.target.value  // input name 'username' set value 'event target value'
                    }
                });  
                break;    
            default:
                break;
        }
     }; 
    
     const addCompany = () => {
        setState({
            ...state,
            companyinfo_model : {
                company_id : "",
                company_name : "",
                postal_code : "",
                address : "",
                telephone_no : "",
                fax : "",
                main_company_flg : "1",
                closing_month : "",
                tax_rate : "",
                invoice_no : "",
                remarks : ""
            },
        })
        setOpen(true);
    }

    let updateCompany = (company_id:any) => {
        CompanyInfoService.showCompany(company_id).then((data)=>{
           setState({
            ...state,
            companyinfo_model : {
                company_id : data.data.company_id,
                company_name : data.data.company_name,
                postal_code : data.data.postal_code,
                address : data.data.address,
                telephone_no : data.data.telephone_no,
                fax : data.data.fax,
                main_company_flg :  data.data.main_company_flg,
                closing_month :  data.data.closing_month,
                tax_rate :  data.data.tax_rate,
                invoice_no :  data.data.invoice_no,
                remarks :  data.data.remarks
            },
           })
           setOpen(true);
        }).catch((error:any) => {
            console.log(error);
        });
    }

    const deleteCompany = () => {
        if(selectedRows.length == 0){
            alert("削除したい会社情報をチェックしてください。");
            return false;
        }
        let ans = window.confirm("削除を実施します、大丈夫ですか？");
        if(ans){
            let ids : any[] = [];
            companyInfo.forEach((element, index) => {            
                if (selectedRows.length > 0 && selectedRows.includes(index)) {
                    ids.push(element["company_id"]);
                }
            });
    
            let data = {};
            data["ids"] = ids;
            CompanyInfoService.deleteList(data).then((data)=>{
                if(data.data.success == false){
                    alert(data.data.info.message);
                }
                window.location.href = "/setting/company-info/list";           
            }).catch((error) => {
                console.log(error);
                // alert(error.data.info.message);
                // console.log(error.data.info);
            });
        }
    }

    const storeUpdateCompany = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
        if(state.companyinfo_model.company_id == ""){
            CompanyInfoService.store(state.companyinfo_model).then((response) => {    
                window.location.href = "/setting/company-info/list";           
             }).catch((error) => {
                console.log(error);
                 if(error.response.data){
                     showErrorInfo(error.response.data);
                 }
             })
        }else{
            CompanyInfoService.update(state.companyinfo_model, state.companyinfo_model.company_id).then((response) => {
                window.location.href = "/setting/company-info/list";           
             }).catch((error) => {
                 if(error.response.data){
                     showErrorInfo(error.response.data);
                 }
     
             })
        }
        
    };

    let updateTextArea = (event:React.ChangeEvent<HTMLTextAreaElement>):void => {
        setState({
            ...state,
            companyinfo_model : {
                ...state.companyinfo_model,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        });  
    }
    let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setState({
            ...state,
            companyinfo_model : {
                ...state.companyinfo_model,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        });   
    };

    let nextPage = () => {
        
        // if(Math.floor(totalRows/perPage) >0){
        //     setShowNextColor("blue");
        // }
    
        if(Math.floor(totalRows/perPage) >0){
            changePage(current_page+1);
            setCurrentPage(current_page+1);
            setState({
                ...state,
                page : current_page+1
            })
            setShowNextColor("blue");
        }
        
        if(Math.ceil(totalRows/perPage)-2 == current_page){ // Last Page
            setShowNextColor("grey");
        }   
        if(0 < current_page+1){
            setShowPrevColor("blue");
        }     
    
    }
    
    let previousPage = () => {
        if(current_page > 0){
            setCurrentPage(current_page-1);
            changePage(current_page - 1);
            setState({
                ...state,
                page : current_page-1
            });
            setShowPrevColor("blue");
        }
        if(0 == current_page-1){
            setShowPrevColor("grey");
        } 
    
        if(Math.floor(totalRows/perPage) >0){
            setShowNextColor("blue");
        } 
        
    }


    const defaulColumns = [
        {
            name: "company_id",
            label: "編集",
            options: {
                filter: true, 
                // setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // return(<Link to={`/cases/${tableMeta.rowData[1]}`} >{value}</Link>);
                        return(<button type="button" className="btn_standard btn-sm" onClick={() => {
                            updateCompany(`${tableMeta.rowData[0]}`);
                            }}>編集
                        </button>);
                    },
                },
        },
        {"name" : 'company_id', "label" : "会社ID"},
        {"name" : 'company_name', "label" : "会社名",
            class: "longText",
             options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {"name" : 'postal_code', "label" : "郵便",
            class: "longText",
             options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {"name" : 'address', "label" : "住所",
            class: "longText",
             options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {"name" : 'telephone_no', "label" : "電話番号"},
        {"name" : 'fax', "label" : "FAX"},
        {"name" : 'main_company_flg', "label" : "メイン会社"},
        {"name" : 'closing_month', "label" : "決算月"},
        {"name" : 'tax_rate', "label" : "消費税率"},
        {"name" : 'invoice_no', "label" : "インボイス番号"},
        {"name" : 'remarks', "label" : "備考",
            class: "longText",
             options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
            },
        },
    ]


    const options = {
        filterType: 'checkbox' as any,
        tableBodyHeight : "360px",
        tableBodyMaxHeight : "360px",
        responsive: "standard" as any,
        selectableRows: "multiple" as any,
        selectableRowsHeader: true as any, 
        rowsPerPage: perPage,
        rowsPerPageOptions:[10,20,50,100,200],
        // selectableRowsOnClick: true  as any,
        count: totalRows,
        serverSide: true,
        filter : false,
        print: false,
        viewColumns : false,
        sort: true,
        search : false,
        download :false,
        // resizableColumns: true,
        customToolbarSelect: (selectedRows:any, displayData:any, setSelectedRows:any) => {
         
            const handleClick = () => {
                
                
            };
                let rows : any[] = [];
                selectedRows.data.forEach((element:any, index:any) => {
                    rows.push(element.dataIndex);
                })
                let csvData : any[] = [];
                companyInfo.forEach((element, index) => {
                    if (rows.includes(index)) {
                        csvData.push(element);
                    }
                });
                const getFileName = () => {
                    let now = new Date().toLocaleDateString();
                    let year = now.slice(0,4);
                    let month = '0' + now.slice(5,6);
                    let day = now.slice(7);
                    let date = year+month+day;
                    return "会社情報" +date;
                }
                let headers = [
                    {"key" : 'company_id', "label" : "会社ID"},
                    {"key" : 'company_name', "label" : "会社名"},
                    {"key" : 'postal_code', "label" : "郵便"},
                    {"key" : 'address', "label" : "住所"},
                    {"key" : 'telephone_no', "label" : "電話番号"},
                    {"key" : 'fax', "label" : "FAX"},
                    {"key" : 'main_company_flg', "label" : "メイン会社"},
                    {"key" : 'closing_month', "label" : "決算月"},
                    {"key" : 'tax_rate', "label" : "消費税率"},
                    {"key" : 'invoice_no', "label" : "インボイス番号"},
                    {"key" : 'remarks', "label" : "備考"},
                ];
               
              
            var now = new Date();
            return (
                <CSVLink  className="btn_standard text-decoration-none" filename={getFileName()} data={csvData}  headers={headers}>ダウンロード</CSVLink>
            );
          },
        textLabels: {
            body: {
              noMatch: "データはありません。",
              toolTip: "Sort"
            },
            pagination: {
              next: "Next Page",
              previous: "Previous Page",
              rowsPerPage: "件表示", 
              displayRows: "すべての件数：",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "行 を選択しました。",
                delete: "削除",
                deleteAria: "選択した行を削除しました。",
              },
          },
        rowsSelected: selectedRows,
        onRowSelectionChange: (rowsSelectedData:any, allRows:any, rowsSelected:any) => {
            setSelectedRows(rowsSelected);
        },
        onChangePage (currentPage:any) {
            changePage(currentPage);
        },
        onChangeRowsPerPage (numberOfRows:any) {
            changeRowsPerPage(numberOfRows);
            setNoOfRows(numberOfRows);
        },
        // onRowClick: (rowData:any, rowState:any) => {
        //    goToDetail(rowData, rowState);
        // },
        onCellClick : (colData:any, cellMeta:any) =>{
            // goToDetail(colData, cellMeta);

        },
        onRowsDelete(rowData :any, rowState:any) {
            // handleDelete(rowData, rowState);
        },
        onColumnSortChange: (changedColumn:any, direction:any) => {
            let order = 'desc';
            if (direction === 'asc') {
              order = 'asc';
            }
            handleSort(changedColumn, order);
          },
        // onChangePage: (page) => {
        // this.setState({page}, () => {
        //     this.sort(this.state.sortOrder.name, this.state.sortOrder.direction);
        // });
        // }
    };
 
     const getMuiTheme = () =>
     createTheme({
         components: {
         MUIDataTable: {
             styleOverrides:{
                 responsiveScroll: {
                     maxHeight: 'none',
                   },
             }
         },
         MuiPaper: {
             styleOverrides:{
                 root: {
                     width: "100%",
                 }
             }
         },  
         MUIDataTableHeadCell: {
                 styleOverrides:{
                   root: {
                       backgroundColor: "#444",
                       padding: '0px'
                   }
                 }
         },
         MuiTableCell: {
             styleOverrides:{
                 head: {
                         color : 'white'
                 }
             }
         },
           MUIDataTableBodyCell: {
             styleOverrides:{
               root: {
                   backgroundColor: "#f1f1f1",
                   width: "100%",
                   padding: '0px'
               }
             }
           }
         }
       })
    //    const style = {
    //     position: 'absolute' as 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: 800,
    //     height: 700,
    //     bgcolor: 'background.paper',
    //     border: '2px solid #000',
    //     boxShadow: 24,
    //     p: 4,
    //   };
    
    const edit_modal_box = (
        <div>
            <div className="row">
                    <div className="col-6">
                        <h3>会社情報{(companyinfo_model['company_id'] == "")? "登録": "編集"}</h3>
                    </div>
                    <div className="col-6">
                         <button type="button" className="modal_close" onClick={handleClose}>X</button>
                    </div>            
                </div>
                {/* <h3>案件割り当て編集</h3> */}
                <form className="form" onSubmit={storeUpdateCompany}>
                    {/* <BulkMailModal data={send_mail_arr} /> */}
                    <input type="hidden" name="company_id" value={companyinfo_model["company_id"]} />
                    <div className="row">
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">会社名:</label> 
                                <input type="text" className="form-control" name="company_name"  value={companyinfo_model['company_name']}  onChange={updateInput} /> 
                            </div>       
                        </div>
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" >郵便:</label> 
                                <input type="text" className="form-control" name="postal_code"  value={companyinfo_model['postal_code']}  onChange={updateInput} />
                            </div>          
                        </div>
                    </div>      
                    <div className="row">
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">住所:</label> 
                                <textarea className="form-control" rows={3} name = "address" id= "withdraw_reason" aria-label="With textarea" value={companyinfo_model['address']} onChange={updateTextArea}></textarea>
                            </div>       
                        </div>
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" >電話番号:</label> 
                                <input type="text" className="form-control" name="telephone_no"  value={companyinfo_model['telephone_no']}  onChange={updateInput} />
                            </div>          
                        </div>
                    </div> 
                    <div className="row">
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">FAX:</label> 
                                <input type="text" className="form-control" name="fax"  value={companyinfo_model['fax']}  onChange={updateInput} /> 
                            </div>       
                        </div>
                        <div className="col-6">
                            <div className="form-group input-box">
                                <label htmlFor="exampleInputEmail1" >メイン会社:</label> 
                                <select name="main_company_flg" onChange={onSelect} value={companyinfo_model["main_company_flg"]} style={{width:'100%'}}>
                                    {main_company_flg_list.map(main_company_flg => {
                                            return(<option value={main_company_flg}> {main_company_flg}</option>)
                                        })
                                    }
                                </select>                           
                            </div>          
                        </div>
                    </div>      

                    <div className="row">
                        <div className="col-6">
                            <div className="form-group input-box">
                                <label htmlFor="exampleInputEmail1">決算月:</label> 
                                <input type="text" className="form-control" name="closing_month"  value={companyinfo_model['closing_month']}  onChange={updateInput} />                           
                            </div>          
                        </div>
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">消費税率:</label> 
                                <input type="text" className="form-control" name="tax_rate"  value={companyinfo_model['tax_rate']}  onChange={updateInput} />                                     
                            </div>           
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">インボイス番号:</label> 
                                <input type="text" className="form-control" name="invoice_no"  value={companyinfo_model['invoice_no']}  onChange={updateInput} />                                     
                            </div>  
                        </div>
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">備考:</label> 
                                <textarea className="form-control" rows={3} name = "remarks" id= "remarks" aria-label="With textarea" value={companyinfo_model['remarks']} onChange={updateTextArea}></textarea>
                            </div>     
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group mt-5 text-center">
                            <button type="submit" className="button submit-btn btn_standard">{(companyinfo_model['company_id'] == "")? "登録": "更新"}</button>
                        </div>
                    </div>
                </form>
        </div>
    )

    return(
        
       <React.Fragment>
         <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                    ml: sizeConfigs.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color
                }}
                >
                 <Toolbar>
                <Typography variant="h6">
                    <header className="top-baner">
                        <Row>
                            <Col xxl={10} xl={10} md={10} sm={10} xs={10}>
                                <div id="search-autocomplete" className="form-outline">
                                    <input type="text" name="search" id="search" placeholder = "検索(要員ID、イニシャル、漢字氏名、カタカナ氏名、英語氏名、国籍、スキル、特記事項を検索)"
                                        className="form-control"  onChange={onChange}/>
                                </div>
                            </Col>
                            <Col className="col-2 mt-4">
                                <DropDownList />
                            </Col>
                        </Row>
                    </header>
                    </Typography>
                </Toolbar>
                </AppBar>
                <Container>
                <Row>
                        <Col xs={12} className="pc-hidden">
                            <div className="mt-3 text-center">
                                {( loading   == true) ? <Loader /> :<> </>}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                            <h3 className='header-left-design'>
                                会社情報一覧
                            </h3>
                            </Col>
                        <Col xxl={4} xl={4} md={4} sm={4} xs={4} className="xs-hidden">
                            <div className="mt-3 text-center">
                                {( loading   == true) ? <Loader /> :<> </>}
                            </div>
                        </Col>
                        <Col xxl={4} xl={4} md={4} sm={4} xs={6} className="float-end">
                            <div className="anken-add action  mt-4">
                                <button type="button" className="btn_standard ml_20 btn-sm" onClick={addCompany}>追加</button>
                                <button type="button" className="btn_danger ml_20 btn-sm"  onClick={deleteCompany}>削除</button>
                            </div>
                        </Col>
                    </Row> 
                    <Row className="pt-2 pb-2 ">
                        <Col xxl={12} xl={12} md={12} sm={12} xs={12} >
                            <div style={{display:"inline"}}>
                            <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                            <select name="sort" id="sort" className="filter" onChange={onSelect}>
                                <option value="0" selected>新しい順</option>
                                <option value="1">古い順</option>
                            </select>
                            </div>
                          
                      
                                <div className="float-right">
                                    <select name="list_length" id="list_length" value={no_of_rows} className="filter list_length" onChange={onSelect}>
                                        <option value="10">10</option>
                                        <option value="20" >20</option>
                                        <option value="50" >50</option>
                                        <option value="100" >100</option>
                                        <option value="200" >200</option>
                                    </select>
                                    <label htmlFor="sort" className="control-label">件表示</label>
                                    <button type="button" className="btn-sm previous-page-icon" style={{border:"0px"}} onClick={() => {
                                                previousPage();
                                                }}>
                                        <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                    </button>
                                    <button type="button" className=" btn-sm next-page-icon" style={{border:"0px"}} onClick={() => {
                                                nextPage();
                                                }}>
                                        <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                                    </button>
                                </div>
                            </Col>
                    </Row> 
                   
                    <div className='datatable freeze-company-info-header freeze-company-info-cell company-info-frozen-columns xs-hidden'>
                        <MUIDataTable
                            title = {""}
                            data = {companyInfo}
                            columns={defaulColumns}
                            options={options}
                        />
                    </div>
                    <div className='datatable mobile pc-hidden'>
                        <MUIDataTable
                            title = {""}
                            data = {companyInfo}
                            columns={defaulColumns}
                            options={options}
                        />
                    </div>
            </Container>

            <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'none', sm: 'block' },
            }}
        >                                
            <Box sx={PCModalStyle}
            >
                {edit_modal_box}
            </Box>
        </Modal>  

          <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'block', sm: 'none' },
            }}
        >                                
            <Box sx={MobileModalStyle}
            >
                {edit_modal_box}
            </Box>
        </Modal>               
        </React.Fragment>
    )

}
export default CompanyInfoList;