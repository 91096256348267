
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from "./Loader";

function PageTitle({ loading , title }:any) {
    return (
        <div>
            <Row>
                <Col className="pc-hidden">
                        <div className="mt-3 text-center">
                            {( loading   == true) ? <Loader /> :<> </>}
                        </div>
                </Col>
            </Row>
            <Row>
                <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                    <h3 className='header-left-design'>
                        {title}
                    </h3>
                </Col>
                <Col xxl={4} xl={4} md={4} sm={4} xs={6} className="xs-hidden">
                    <div className="mt-3 text-center">
                        {( loading   == true) ? <Loader /> :<> </>}
                    </div>
                </Col>
            </Row> 
        </div>
    )}
export default PageTitle;
                  