import React, {useEffect, useState} from 'react';
import {CaseListService} from "../../services/CaseService";
import PartnerService from "../../services/PartnerService";

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Dropdown from 'react-bootstrap/Dropdown';
import Topbar from "../../components/common/Topbar";
import {showErrorInfo, checkMaxLength} from "../../components/common/Helpers";

import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DetailTopbar from "../../components/common/DetailTopbar";
import {CaseModel} from "../../models/ICase";
import DatePicker from "react-datepicker";
import {useParams, Link} from "react-router-dom";
import { useLocation } from 'react-router-dom';
import $ from 'jquery';
import moment from 'moment';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface URLParam {
    id : string;
}

interface IState{
    cases : CaseModel;
    // errorMessage : string;  
    // perPage : number;
    // page : number;
    // totalRows : number;
    // saleList : [];
    // hrList : [];
}
interface IProps{}
let CaseCreate:React.FC<IProps> = () => {
    // const [status, setCaseStatus] = useState("未着手");
    // const [customerId, setCustomerId] = useState("");
    // const [caseName, setCaseName] = useState("");
    // const [appealCmnt, setAppealCmnt] = useState("");
    // const [recuritArea, setRecuritArea] = useState("");
    // const [contactName, setContactName] = useState("");
    // const [contactEmail, setContactEmail] = useState("");
    // const [flbHrId, setflbHrId] = useState("選択なし");
    // const [startDatePlan, setStartDatePlan] = useState("");
    // const [recruitNum, setRecruitNum] = useState("");
    // const [workPlace,setWorkPlace] = useState("");
    // const [requestDate,setRequestDate] = useState("");
    // const [caseOverview,setCaseOverview] = useState("");
    let {id} = useParams<URLParam | any>();
    const login_user =   JSON.parse(localStorage.getItem("user") || '{}');

    let [state , setState] = useState<IState>({
        cases : {
            customer_id : "",
            customer_dept:"",
            contact_name : "",
            contact_email:"",
            flb_sales_id: (login_user != null)?login_user.user_id: "",
            required_skill : [],
            case_name : "",
            case_status : "未着手",
            appeal_cmnt: "",
            category : "システムエンジニア",
            recurit_area:"東京エリア",
            contact_tel:"",
            case_overview:"",
            requisition: "",
            case_period_type: "短期",
            flb_hr_id:"",
            start_date_plan:"",
            end_date_plan:"",
            recruit_num:"",
            work_place:"",
            budget:"",
            settlement:"",
            expect_contract_type:"準委任",
            recruit_deadline:"",
            publish_date:"",
            published_flg: "0",
            interview_num: 1,
            requested_date:"",
            proposal_deadline:"",
            remarks:"",
            flb_shared_cmt: "",
            priority : 0
        }
    });

    const [partner_list, setPartnerList] = useState([]);
    const [hr_list, setHRList] = useState([]);
    const [sale_list, setSaleList] = useState([]);
    const [case_status_list, setCaseStatusList] = useState([]);
    const [category_list, setCategoryList] = useState([]);
    const [case_period_type_list, setCasePeriodTypeList] = useState([]);
    const [expect_contract_type_list, setExpectContractTypeList] = useState([]);
    const [publish_flg_list, setPublishFlgList] = useState([]);
    const [recurit_area_list, setRecuritAreaList] = useState([]);

    const [partner_list_display, setPartnerListDisplay] = useState("none");
    const [partner_search_result , setSearchResult] = useState("");
    let [encode, setEncode] = useState("");
    let [priority_list, setPriorityList] = useState([]);

    // const [requiredSkill,setRequiredSkill] = useState("");

    let skill : string[]= [];
    let [requiredSkill, setRequiredSkill] = useState(skill);  

    let [updateRequiredSkill, setUpdateRequiredSkill] = useState(skill);
    const [startDate, setStartDate] = useState(new Date());
    let [casename_error, setCaseNameError] = useState("none");

    let location = useLocation();
    let search = location.search;


    useEffect(()=>{
        if(id){
            CaseListService.edit(id).then((response)=>{
                setState({
                    ...state,
                    cases:response.data.case,
                })
                if(login_user.role == 8){
                    setState({
                        cases : {
                            ...state.cases,
                            customer_id : response.data.case.customer_company,
                            customer_dept: response.data.case.customer_dept,
                            contact_name : response.data.case.contact_name,
                            contact_email: response.data.case.contact_email,
                            flb_sales_id: response.data.case.flb_sales_id,
                            required_skill : response.data.case.required_skill,
                            case_name : response.data.case.case_name,
                            case_status : response.data.case.case_status,
                            appeal_cmnt: response.data.case.appeal_cmnt,
                            category : response.data.case.category,
                            recurit_area: response.data.case.recurit_area,
                            contact_tel: response.data.case.contact_tel,
                            case_overview: response.data.case.case_overview,
                            requisition: response.data.case.requisition,
                            case_period_type: response.data.case.case_period_type,
                            flb_hr_id: response.data.case.flb_hr_id,
                            start_date_plan: response.data.case.start_date_plan,
                            end_date_plan : response.data.case.end_date_plan,
                            recruit_num: response.data.case.recruit_num,
                            work_place: response.data.case.work_place,
                            budget: response.data.case.budget,
                            settlement: response.data.case.settlement,
                            expect_contract_type: response.data.case.expect_contract_type,
                            recruit_deadline: response.data.case.recruit_deadline,
                            publish_date: response.data.case.publish_date,
                            published_flg: response.data.case.published_flg,
                            interview_num: response.data.case.interview_num,
                            requested_date: response.data.case.requested_date,
                            proposal_deadline: response.data.case.proposal_deadline,
                            remarks: response.data.case.remarks,
                            flb_shared_cmt: response.data.case.flb_shared_cmt
                        }
                    });
                }
                setRequiredSkill(response.data.case.required_skill);
                setUpdateRequiredSkill(response.data.case.required_skill);
                response.data.case.required_skill.map((required_skill:any, index:any)=> {
                    let key = "";
                    if(required_skill === "other"){
                        key = index + 1;
                    }
                    if(key !== ""){
                        setOther(response.data.case.required_skill[key]);  //bind data in other textbox
                        setDisabled(false);
                        setOtherClickFlag(false);
                    }  
                    if(response.data.case.customer != undefined){
                        setSearchResult("会社名:" +response.data.case.customer.company);         
                    }
                   
                });
                setEncode(response.data.case.encode);
                setHRList(response.data.select_options.flb_hr_id);
                setSaleList(response.data.select_options.flb_sales_id);
                setCaseStatusList(response.data.select_options.case_status);
                setCasePeriodTypeList(response.data.select_options.case_period_type);
                setCategoryList(response.data.select_options.category);
                setExpectContractTypeList(response.data.select_options.expect_contract_type);
                setPublishFlgList(response.data.select_options.published_flg);
                setRecuritAreaList(response.data.select_options.recurit_area);
                setPriorityList(response.data.select_options.priority);

                if(response.data.case.start_date_plan  !== undefined  && response.data.case.start_date_plan != null){
                    setSelectedStartDate(new Date(response.data.case.start_date_plan));
                }
                if(response.data.case.end_date_plan  !== undefined  && response.data.case.end_date_plan != null){
                    setSelectedEndDate(new Date(response.data.case.end_date_plan));
                }
                if(response.data.case.requested_date !== undefined && response.data.case.requested_date !== null){
                    setSelectedRequestedDate(new Date(response.data.case.requested_date));
                }
                if(response.data.case.publish_date !== undefined && response.data.case.publish_date !== null){
                    setSelectedPublishDate(new Date(response.data.case.publish_date));
                }
                if(response.data.case.proposal_deadline !== undefined && response.data.case.proposal_deadline !== null){
                    setSelectedProposalDeadlineDate(new Date(response.data.case.proposal_deadline));
                }
                if(response.data.case.recruit_deadline !== undefined && response.data.case.recruit_deadline !== null){
                    setSelectedRecruitDate(new Date(response.data.case.recruit_deadline));
                }
               
            }).catch((error) => {
                console.log(error);

            })
        }else{
            // if(login_user.role == 8){
                // if(login_user.role == 8){
                  
                // }

                // setState({
                //     cases : {
                //         ...state.cases,
                //         customer_id : login_user.company,
                //         customer_dept : login_user.dept_name,
                //         contact_name : login_user.name_jp,
                //         contact_email : login_user.email,
                //         contact_tel : login_user.tel_no
                //          // input name set value 'event target value'
                //     }
                // })
            //}
            CaseListService.create().then((response)=>{
                // setState({
                //     ...state,
                //     staff_list:response.data.staff_list,
                // })
                if(login_user.role == 8){
                    setState({
                        cases : {
                            ...state.cases,
                            customer_id : login_user.company,
                            customer_dept : login_user.dept_name,
                            contact_name : login_user.name_jp,
                            contact_email : login_user.email,
                            flb_hr_id : response.data.default_flb_hr_id,
                            required_skill : [],
                            case_name : "",
                            case_status : "未着手",
                            appeal_cmnt: "",
                            category : "システムエンジニア",
                            recurit_area:"東京エリア",
                            contact_tel : login_user.tel_no,
                            case_overview:"",
                            requisition: "",
                            case_period_type: "短期",
                            start_date_plan:"",
                            end_date_plan:"",
                            recruit_num:"",
                            work_place:"",
                            budget:"",
                            settlement:"",
                            expect_contract_type:"準委任",
                            recruit_deadline:"",
                            publish_date:"",
                            published_flg: "0",
                            interview_num: 1,
                            requested_date:"",
                            proposal_deadline:"",
                            remarks:"",
                            flb_shared_cmt: "",
                            priority : 0
                        }
                    });
                }
                setHRList(response.data.select_options.flb_hr_id);
                setSaleList(response.data.select_options.flb_sales_id);
                setCaseStatusList(response.data.select_options.case_status);
                setCasePeriodTypeList(response.data.select_options.case_period_type);
                setCategoryList(response.data.select_options.category);
                setExpectContractTypeList(response.data.select_options.expect_contract_type);
                setPublishFlgList(response.data.select_options.published_flg);
                setRecuritAreaList(response.data.select_options.recurit_area);
                setPriorityList(response.data.select_options.priority);
            }).catch((error) => {
                console.log(error);
                // setState({
                //     ...state,
                // })
            })
        }

    }, [id]);
    
    const store = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
        // for (let key in state.cases) {
        //     if (key == "skill") {
        //         delete state.cases[key];
        //     }
        // }
        // let other_inserted_flg = false;


      
        if(id != undefined){
            if((other != "" && other != null) && other_click_flag == true){
                state.cases.required_skill.map((required_skill, index) => {
                    let key = 0;
                    if(required_skill === "other"){
                        key = index+1;
                        state.cases.required_skill[key] = other;
                        setOtherClickFlag(false);
                    }
                   
                });
                state.cases.required_skill.filter((skill) => skill !== null)
            }else if(other == ""  || other == null){
                state.cases.required_skill.map((required_skill, index) => {
                    if(required_skill === "other"){
                        state.cases.required_skill.splice(index, 2);
                        setOtherClickFlag(false);
                    }

                });
            }

            for (let key in state.cases) {
                if (key == "hr" || key == 'customer' || key == 'customer_company' || key == 'saler') {
                    delete state.cases[key];
                }
            }
        
            CaseListService.update(state.cases, id).then((response) => {
                window.location.href = "/cases/"+response.data.data.encode;
            }).catch((error) => {
                if(error.response.data){
                    showErrorInfo(error.response.data);
                }

            })
        }else{
            if(other != "" && other_click_flag == true){
                state.cases.required_skill.push(other);
                setOtherClickFlag(false);
            }
            state.cases.required_skill.filter((skill) => skill !== null);
            CaseListService.store(state.cases).then((response) => {
                window.location.href = "/cases";
            }).catch((error) => {
                if(error.response.data){
                    showErrorInfo(error.response.data);
                }

            })
        }
    };

    const destroy = (encode:any) => {
        let ans = window.confirm("コピー作成された案件情報を削除しても宜しいでしょうか。");
        if(ans){
            CaseListService.destroy(id).then((response:any) => {
                window.location.href = "/cases/"+localStorage.getItem("case_encode");
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                    window.location.href = "/cases/"+localStorage.getItem("case_encode");
                }
            })
        }
    }
    // showErrorInfo(result);
//    let showErrorInfo = function(result:any){
//     console.log(result.errors);
//         if(result.errors  && 'object' === typeof result.errors){
//             let input_name;
//             let textarea_name;
//             let select_name;

//             for(let key in result.errors){
//                 console.log(key);
//                 $('textarea[name="'+key+'"],input[name="'+key+'"], select[name="'+key+'"]').closest(".input-box").append("<span id='star'>"+result.errors[key].join('\n')+"</span>");
//                 if(key == "required_skill"){
//                     $(".input-box .check-list").append("<span id='star'>"+result.errors[key].join('\n')+"</span>");

//                 }
//             }
//         }else if(result.message){
//             alert(result.message);
//         }else{
//             console.info(result);
//             alert('サーバーエラー');
//         }
//     }

    let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        checkMaxLength(event.target.getAttribute("maxlength"), event.target.value.length ,event.target.name);

        if(event.target.name == "customer_id"){
            setSearchResult("");
        }
        if(event.target.name == "other_input"){
            if(id != ""){
                state.cases.required_skill.map((required_skill, index) => {
                    let key = 0;
                    if(required_skill === "other"){
                        key = index+1;
                    }
                    if(key > 0 &&  state.cases.required_skill[key] != undefined){
                        state.cases.required_skill[key] = other;
                    }else{
                        setOther(event.target.value);  
                        setOtherClickFlag(true);         
                    }
                })   
            }else{
                setOther(event.target.value);
                setOtherClickFlag(true);           
            }

    }
    else if(event.target.name == "case_name"){
        var pattern = /^[A-Za-z0-9 一-龠ぁ-ゔァ-ヴー々〆〤ａ-ｚＡ-Ｚ０-９_＿/／()（）#＃.。　 ☆★ ※]+$/;            
        var value = event.target.value;
        if (!pattern.test(value)) {
            setCaseNameError("block");
            setState({
                cases : {
                    ...state.cases,
                    [event.target.name] : value.slice(0, -1)  // input name 'username' set value 'event target value'
                }
            }); 

        }else{
            setCaseNameError("none");
            setState({
                cases : {
                    ...state.cases,
                    [event.target.name] : value  // input name 'username' set value 'event target value'
                }
            });  
        }
    }
    else{
        setState({
            cases : {
                ...state.cases,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        });   
        }     
    };

    let updateTextArea = (event:React.ChangeEvent<HTMLTextAreaElement>):void => {
        checkMaxLength(event.target.getAttribute("maxlength"), event.target.value.length ,event.target.name);

        setState({
            cases : {
                ...state.cases,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        });
    }
    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setState({
            cases : {
                ...state.cases,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            }
        });
     };
    
    


    // const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    //     switch (e.target.name) {
    //         case "case_status":
    //             setCaseStatus(e.target.value);
    //             break;
    //             case "flb_hr_id":
    //                 setflbHrId(e.target.value);
    //                 break;
    //         default:
    //             break;
    //     }
    //  };

     const handleSkill = function(e:any){
        let isChecked = e.target.checked;
        let value = e.target.value;
        if(value === "other"){
            if(isChecked === true){
                setDisabled(false);
            }else{
                setOther("");
                setDisabled(true);
                // for (var i = 0; i < state.cases.required_skill.length; i++) {
                //     console.log(state.cases.required_skill[i]);
                // }

                state.cases.required_skill.map((required_skill, index) => {
                    let key = 0;
                    if(required_skill === "other"){
                        key = index+1;
                    }
                    if(key >0){
                        delete state.cases.required_skill[key];
                    }
                    
                })

            }
            setOtherClickFlag(true);
        }
       
        if(isChecked === true){
            requiredSkill.push(value);
            setRequiredSkill(requiredSkill);
            setState({
                cases : {
                    ...state.cases,
                    required_skill: requiredSkill  // input name 'username' set value 'event target value'
                }
            });
        }
        else{            
            const skill = requiredSkill.filter(function(skill){
                return skill != value; 
             });
             setRequiredSkill(skill);

             setState({
                cases : {
                    ...state.cases,
                    required_skill: skill  // input name 'username' set value 'event target value'
                }
            });
        }

        
    }

    const partnerSearch = function(){
        // CaseListService.searchPartner("company",state.cases.customer_id,"cases").then((response) => {
            PartnerService.search("company",state.cases.customer_id,"cases").then((response) => {
            setPartnerList(response.data.data);
            setPartnerListDisplay("block");
        //    window.location.href = "/cases";
        })
    }

    let assignPartnerId = (e: React.ChangeEvent<HTMLSelectElement>): void => { // change event type declare in React
        var index = e.target.selectedIndex;
        setSearchResult(e.target[index].innerHTML);
        setState({
            cases : {
                ...state.cases,
                customer_id : e.target.value ,
                 // input name set value 'event target value'
            }
        })
        setPartnerListDisplay("none");       
    };

    //  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => { 
    //     switch (e.target.name) {
    //         case "customer_id":
    //             setCustomerId(e.target.value);
    //             setSearchResult("");
    //             break;
    //         case "case_name":
    //             setCaseName(e.target.value);
    //             console.log('name');
    //             break;
    //             case "appeal_cmnt":
    //                 setAppealCmnt(e.target.value);
    //                 console.log('appeal_cmnt');
    //                 break;
    //             case "contact_email":
    //             setContactEmail(e.target.value);
    //             break;
    //             case "recurit_area":
    //                 setRecuritArea(e.target.value);
    //                 console.log('recurit_area');
    //                 break;
    //             case "case_overview":
    //                 setCaseOverview(e.target.value);
    //                 break;
    //             case "start_date_plan":
    //                     setStartDatePlan(e.target.value);
    //                     break;
    //                 case "recruit_num":
    //                         setRecruitNum(e.target.value);
    //                         break;
    //                         case "contact_name":
    //                             setContactName(e.target.value);
    //                             break;
    //                     case "work_place":
    //                             setWorkPlace(e.target.value);
    //                             break;
    //                             case "requested_date":
    //                                 setRequestDate(e.target.value);
    //                                 break;
    //         default:
    //             break;
    //     }
    // };

    const all_skill_list = {
        "JAVA": "JAVA",
        "Javascript": "Javascript",
        "C": "C/C++",
        "VB": "VB",
        "VB.NET": "VB.NET",
        "VBA": "VBA",
        "C#" : "C#",
        "asp" : "ASP.NET",
        "PLSQL" : "PLSQL(PL/SQL)",
        "ruby" : "Ruby",
        "python" : "Python",
        "cobol" : "COBOL",
        "Shell" : "Shell",
        "perl" : "Perl",
        "SAP": "SAP(ABAP)",
        "CRM" : "CRM",
        "Salesforce" : "Salesforce",
        "PHP" : "PHP",
        "iOS(Swift)" : "iOS(Swift)",
        "ObjectC": "ObjectC",
        "Android": "Android",
        "Linux" : "Linux",
        "Oracle" : "Oracle",
        "sqlserver" : "SQL Server",
        "postgre": "Postgre SQL",
        "db2" : "DB2",
        "pmo" : "PMO",
        "インフラ構築":"インフラ構築",
        "インフラ運用保守" : "インフラ運用保守",
        "システム運用保守" : "システム運用保守",
        "リーダー経験": "リーダー経験",
        "handoutai" : "半導体"
    }
    const [selectedStartDate, setSelectedStartDate] =  useState<Date | null>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
    const [selectedRequestedDate, setSelectedRequestedDate] = useState<Date | null>(null);
    const [selectedPublishDate, setSelectedPublishDate] = useState<Date | null>(null);
    const [selectedProposalDeadlineDate, setSelectedProposalDeadlineDate] = useState<Date | null>(null);
    const [selectedRecruitDate, setSelectedRecruitDate] = useState<Date | null>(null);
    let [other_disabled, setDisabled] = useState(true);
    let [other, setOther] = useState("");
    let [other_click_flag, setOtherClickFlag] = useState(false);

    
 
    const handleDateChange = function(date:any, column_name:any){
        let selected_data:any = "";
        if(date !== null){
            selected_data = date.getFullYear() +"/"+ (date.getMonth() + 1).toString().padStart(2, "0")+"/"+(date.getDate());
        }
    // let selected_data = date.getFullYear() +"-"+ (date.getMonth() + 1) +"-"+ (date.getDate()); 
        switch (column_name) {
            case "start_date_plan":
                setSelectedStartDate(date);
                setState({
                    cases : {
                        ...state.cases,
                        start_date_plan : selected_data // input name 'username' set value 'event target value'
                    }
                });              
                break;
                case "end_date_plan":
                    setSelectedEndDate(date);
                    setState({
                        cases : {
                            ...state.cases,
                            end_date_plan : selected_data // input name 'username' set value 'event target value'
                        }
                    }); 
                break;
                case "requested_date":
                    setSelectedRequestedDate(date);
                    setState({
                        cases : {
                            ...state.cases,
                            requested_date : selected_data // input name 'username' set value 'event target value'
                        }
                    }); 
                break; 
                case "publish_date":
                    setSelectedPublishDate(date);
                    setState({
                        cases : {
                            ...state.cases,
                            publish_date : selected_data // input name 'username' set value 'event target value'
                        }
                    }); 
                break;
                case "proposal_deadline":
                    setSelectedProposalDeadlineDate(date);
                    setState({
                        cases : {
                            ...state.cases,
                            proposal_deadline : selected_data // input name 'username' set value 'event target value'
                        }
                    }); 
                break;
                case "recruit_deadline":
                    setSelectedRecruitDate(date);
                    setState({
                        cases : {
                            ...state.cases,
                            recruit_deadline : selected_data // input name 'username' set value 'event target value'
                        }
                    }); 
                break;
            default:
                break;
        }

    }

    let {cases} = state;
    const required_skill = requiredSkill;
    const skillArray = Object.values(required_skill || []);

   
    return(
        <React.Fragment>
            <DetailTopbar />
            <Container>
                <Row>
                    <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                        <h3 className='header-left-design'>案件情報{(id !== undefined) ?"編集":"登録"}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                    {
                            (() => {
                                if(search == "?process=copy") {
                                return( <span>案件ID：{id}</span>)
                                }
                            })()
                    }
                    </Col>
                </Row>    
                <Row className=" anken-detail">

                <form className="form create-form " onSubmit={store}>
                    <div className="card">
                        <div className="card-body">
                            <Row>
                                <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                                    <h5 id='title'>基本情報</h5>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xxl={8} xl={8} md={8} sm={8} xs={12} className="input-box">
                                    <span id='text' className="input-required">案件名</span>
                                    <input type="text" maxLength={50} name="case_name" id="case_name" className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" value={state.cases.case_name} onChange={updateInput}/>
                                    <span  className="error"  style={{display: casename_error }}>漢字とromaji しか入力出来ないです</span>
                                </Col>
                                <Col xxl={4} xl={4} md={4} sm={4} xs={12} className="input-box">
                                    <span id='text'>案件ステータス</span>
                                    <select name="case_status" id="case_status" className="form-control" aria-label=".form-select-sm example" value={state.cases.case_status} onChange={onSelect}>
                                    {/* <option value=""> 選択なし</option> */}
                                        {case_status_list.map(case_status => {
                                                if (case_status !== null){
                                                    return(<option value={case_status}> {case_status}</option>)
                                                }
                                            })
                                        }
                                    </select> 
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xxl={4} xl={4} md={4} sm={4} xs={12} className="input-box">
                                    <span id='text'>職種</span>
                                    <select name="category" id="category" className="form-control" aria-label=".form-select-sm example" value={state.cases.category} onChange={onSelect}>
                                        {category_list.map(category => {
                                                if (category !== null){
                                                    return(<option value={category}> {category}</option>)
                                                }
                                            })
                                        }
                                    </select>
                                </Col>
                                <Col xxl={4} xl={4} md={4} sm={4} xs={12} className="input-box">
                                    <span className="text">優先順位</span>
                                    <select className="form-control" name="priority" onChange={onSelect} value={state.cases.priority}>
                                    {priority_list.map(priority => {
                                                if (priority !== null){
                                                    return(<option value={priority[0]}> {priority[1]}</option>)
                                                }
                                            })
                                        }
                                    </select>
                                </Col>
                                <Col xxl={4} xl={4} md={4} sm={4} xs={12} className="input-box">
                                    <span id='text' className="input-required">募集エリア</span>
                                    <select name="recurit_area" id="recurit_area" className="form-control" aria-label=".form-select-sm example" value={state.cases.recurit_area} onChange={onSelect}>
                                        {recurit_area_list.map(recurit_area => {
                                                if (recurit_area !== null){
                                                    return(<option value={recurit_area}> {recurit_area}</option>)
                                                }
                                            })
                                        }
                                    </select>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xxl={12} xl={12} md={12} sm={12} xs={12} className="input-box">
                                    <span id='text' className="input-required">一行アピール</span>
                                    <input type="text" name="appeal_cmnt" id="appeal_cmnt" className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" maxLength={50} value={state.cases.appeal_cmnt} onChange={updateInput}/>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xxl={12} xl={12} md={12} sm={12} xs={12} className="input-box">
                                    <span id='text' className="input-required">案件概要</span>
                                    <textarea className="form-control" rows={3} name = "case_overview" id= "case_overview"  maxLength={250} aria-label="With textarea" value={state.cases.case_overview} onChange={updateTextArea}></textarea>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xxl={12} xl={12} md={12} sm={12} xs={12} className="input-box">
                                    <span id='text'>要求内容</span>
                                    <textarea className="form-control" rows={3} name = "requisition" id= "requisition" maxLength={250} aria-label="With textarea" value={state.cases.requisition} onChange={updateTextArea}></textarea>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xxl={12} xl={12} md={12} sm={12} xs={12} className="input-box">
                                    <span id='text' className="input-required">要求スキル</span>
                                    <div className="check-list" data-filter="skill_list"  id="case-filter-by-skill" >
                                            <div className="pl-5">
                                                <Row>
                                                {Object.keys(all_skill_list).map((key, index) => (
                                                    <Col  key={index} xxl={3} xl={3} md={6} sm={6} xs={6} className="mb-3">
                                                        <input type="checkbox" id={key} value={key}  checked ={skillArray.includes(key)? true: false}  onChange={e => handleSkill(e)}/>
                                                        <label htmlFor={key}> {(key == "handoutai")? "半導体": key}</label>
                                                    </Col>
                                                    ))}
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col xxl={2} xl={2} md={3} sm={3} xs={4}>
                                                    <input type="checkbox" id="other"  value="other" checked ={skillArray.includes("other")? true: false} onChange={e => handleSkill(e)}/>
                                                    <label htmlFor="other"> その他</label>
                                                </Col>
                                                <Col xxl={4} xl={4} md={9} sm={9} xs={8}  className="pl-0 float-left">
                                                    <input type="text"  className="form-control" name="other_input" maxLength={50}  value={other}  onChange={updateInput} disabled = {other_disabled} />
                                                </Col>
                                            </Row>  
                                        </div>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xxl={4} xl={4} md={4} sm={4} xs={12} className="input-box">
                                    <span id='text'>期間種別</span>
                                    <select name="case_period_type" id="case_period_type" className="form-control horizontal-select" aria-label="form-select-sm example" value={state.cases.case_period_type} onChange={onSelect}>
                                        {case_period_type_list.map(case_period_type => {
                                                if (case_period_type !== null){
                                                    return(<option value={case_period_type}> {case_period_type}</option>)
                                                }
                                            })
                                        }
                                    </select>
                                </Col>
                                <Col xxl={4} xl={4} md={4} sm={4} xs={12} className="input-box " >
                                    <span id='text' className="input-required">募集人数</span>
                                    <input type="text" name="recruit_num" id="recruit_num" className=" form-control horizontal-input" aria-label="Recipient's username" aria-describedby="basic-addon2" value={state.cases.recruit_num} onChange={updateInput}/>
                                </Col>
                                <Col xxl={4} xl={4} md={4} sm={4} xs={12} className="input-box " >
                                    <span id='text' className="input-required">作業場所</span>
                                    <input type="text" name="work_place" maxLength={100} id="work_place" className="form-control horizontal-input" aria-label="Recipient's username" aria-describedby="basic-addon2" value={state.cases.work_place} onChange={updateInput} />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xxl={4} xl={4} md={4} sm={4} xs={12} className="input-box">
                                    <span id='text' className="input-required">開始年月日</span>
                                    <DatePicker 
                                            value={(state.cases.start_date_plan != null)?state.cases.start_date_plan: ""}
                                            dateFormat="yyyy/MM/dd"
                                            locale="ja"
                                            selected={selectedStartDate} 
                                            onChange={date =>
                                                handleDateChange(date, "start_date_plan")
                                            }
                                            name="start_date_plan"
                                            isClearable
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            // showMonthYearPicker
                                            // showTimeSelect
                                            // timeIntervals={30}
                                            />

                                </Col>
                                <Col xxl={4} xl={4} md={4} sm={4} xs={12} className="input-box">
                                    <span id='text'>終了予定年月日</span>
                                    <DatePicker 
                                            value={(state.cases.end_date_plan != null)?state.cases.end_date_plan: ""}
                                            dateFormat="yyyy/MM/dd"
                                            locale="ja"
                                            selected={selectedEndDate} 
                                            onChange={date =>
                                                handleDateChange(date, "end_date_plan")
                                            }
                                            name="end_date_plan"
                                            isClearable
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            // showMonthYearPicker
                                            // showTimeSelect
                                            // timeIntervals={30}
                                            />
                                </Col>
                                <Col xxl={4} xl={4} md={4} sm={4} xs={12} className="input-box">
                                    <span id='text' className="input-required">案件依頼日</span>
                                    <DatePicker 
                                            value={(state.cases.requested_date != null)?state.cases.requested_date: ""}
                                            dateFormat="yyyy/MM/dd"
                                            locale="ja"
                                            selected={selectedRequestedDate} 
                                            onChange={date =>
                                                handleDateChange(date, "requested_date")
                                            }
                                            name = "requested_date"
                                            isClearable
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            // showMonthYearPicker
                                            // showTimeSelect
                                            // timeIntervals={30}
                                            />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xxl={4} xl={4} md={4} sm={4} xs={12} className="input-box">
                                    <span id='text'>予算</span> 
                                    <input type="text" name="budget" id="budget" maxLength={30} className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" value={state.cases.budget} onChange={updateInput} />
                                </Col>
                                <Col xxl={4} xl={4} md={4} sm={4} xs={12} className="input-box">
                                    <span id='text'>精算</span> 
                                    <input type="text" name="settlement" id="settlement" maxLength={50} className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" value={state.cases.settlement} onChange={updateInput} />
                                </Col>
                                <Col xxl={4} xl={4} md={4} sm={4} xs={12} className="input-box">
                                    <span id='text'>予定受注契約</span> 
                                    <select name="expect_contract_type" id="expect_contract_type" className="form-control" aria-label=".form-select-sm example" value={state.cases.expect_contract_type} onChange={onSelect}>
                                     {expect_contract_type_list.map(expect_contract_type => {
                                                if (expect_contract_type !== null){
                                                    return(<option value={expect_contract_type}> {expect_contract_type}</option>)
                                                }
                                            })
                                        }
                                    </select>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xxl={4} xl={4} md={4} sm={4} xs={12} className="input-box">
                                    <span id='text'>提案締切日</span>
                                    <DatePicker 
                                            value={(state.cases.proposal_deadline != null)?state.cases.proposal_deadline: ""}
                                            dateFormat="yyyy/MM/dd"
                                            locale="ja"
                                            selected={selectedProposalDeadlineDate} 
                                            onChange={date =>
                                                handleDateChange(date, "proposal_deadline")
                                            }
                                            name="proposal_deadline"
                                            isClearable
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            // showMonthYearPicker
                                            // showTimeSelect
                                            // timeIntervals={30}
                                            />
                                </Col>
                                <Col xxl={4} xl={4} md={4} sm={4} xs={12} className="input-box">
                                    <span id='text'>募集期限</span>
                                    <DatePicker 
                                            value={(state.cases.recruit_deadline != null)?state.cases.recruit_deadline: ""}
                                            dateFormat="yyyy/MM/dd"
                                            locale="ja"
                                            selected={selectedRecruitDate} 
                                            onChange={date =>
                                                handleDateChange(date, "recruit_deadline")
                                            }
                                            name="recurit_deadline"
                                            isClearable
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            // showMonthYearPicker
                                            // showTimeSelect
                                            // timeIntervals={30}
                                            />
                                </Col>
                                <Col xxl={4} xl={4} md={4} sm={4} xs={12} className="input-box">
                                    <span id='text' className="input-required">面談回数</span> 
                                    <input type="number" name="interview_num" id="interview_num" className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" value={state.cases.interview_num} onChange={updateInput} />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xxl={12} xl={12} md={12} sm={12} xs={12} className="input-box">
                                    <span id='text'>特記事項 </span> 
                                    <input type="text" name="remarks" id="remarks" maxLength={250} className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" value={state.cases.remarks} onChange={updateInput} />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="card-body">
                            <div className="row">
                                 <h5 id='title'>取引先情報</h5>
                            </div>
                            <Row className="mt-3">
                                {
                                    (() => {
                                        if(id !== undefined){
                                            if((login_user.role == 1 || login_user.role==2 || login_user.role==9)) {   //編集
                                                return(
                                            <Col xxl={6} xl={6} md={6} sm={6} xs={12} className="input-box">
                                                    <span id='text' className="input-required">取引先</span>
                                                    <Row className="partner_search">
                                                        <Col xxl={9} xl={9} md={9} sm={9} xs={8}>
                                                            <input type="text" name="customer_id" id="customer_id" className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" value={state.cases.customer_id} onChange={updateInput} />
                                                        </Col>
                                                        <Col xxl={3} xl={3} md={3} sm={3} xs={4}>
                                                            <button className="btn_standard btn_search w-100" onClick={partnerSearch} type="button">検索</button>
                                                        </Col>
                                                        <span>{partner_search_result}</span>
                                                    </Row>
                                                <Row className="mt-3" style={{display: partner_list_display }}>
                                                    <Col xxl={12} xl={12}  md={12} sm={12} xs={12}  className="input-box">
                                                        <select name="partner_id" className="form-control" onChange={assignPartnerId}>
                                                            <option value="" selected disabled></option>
                                                        {partner_list.length > 0 && partner_list.map((key,val) => {
                                                            return(<option value={key['partner_id']}>会社名{key["company"]}</option>);
                                                            })
                                                        }
                                                        </select>
                                                        {
                                                        partner_list.length == 0 ?
                                                        <span>検索結果はありません</span>
                                                        : null
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>)
                                                }else if(login_user.role==8){
                                                    return(
                                                    <Col xxl={6} xl={6} md={6} sm={6} xs={12} className="input-box">
                                                        <span id='text' className="input-required">取引先</span> 
                                                        <input type="text" name="customer_id" id="customer_id" className="form-control" aria-describedby="basic-addon2" value={state.cases.customer_id} onChange={updateInput} />
                                                    </Col>)
                                                }
                                        }else{
                                            if(login_user.role!=8){    // 登録
                                                return(
                                                <Col xxl={6} xl={6} md={6} sm={6} xs={12} className="input-box">
                                                    <span id='text' className="input-required">取引先</span>
                                                    <Row className="partner_search">
                                                        <Col  xxl={9} xl={9} md={9} sm={9} xs={8}>
                                                            <input type="text" name="customer_id" id="customer_id" className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" value={state.cases.customer_id} onChange={updateInput} />
                                                        </Col>
                                                        <Col  xxl={3} xl={3} md={3} sm={3} xs={4}>
                                                            <button className="btn_standard btn_search w-100" onClick={partnerSearch} type="button">検索</button>
                                                        </Col>
                                                        <span>{partner_search_result}</span>
                                                    </Row>
                                                    <Row className="mt-3" style={{display: partner_list_display }}>
                                                        <Col xxl={12} xl={12} md={12} sm={12} xs={12} className="input-box">
                                                            <select name="partner_id" className="form-control" onChange={assignPartnerId}>
                                                                <option value="" selected disabled></option>
                                                            {partner_list.length > 0 && partner_list.map((key,val) => {
                                                                return(<option value={key['partner_id']}>会社名{key["company"]}</option>);
                                                                })
                                                            }
                                                            </select>
                                                            {
                                                            partner_list.length == 0 ?
                                                            <span>検索結果はありません</span>
                                                            : null
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Col>)
                                            } else if(login_user.role==8){
                                                return(
                                                <Col xxl={6} xl={6} md={6} sm={6} xs={12} className="input-box">
                                                    <span id='text' className="input-required">取引先</span> 
                                                    <input type="text" name="customer_id" id="customer_id" className="form-control" aria-describedby="basic-addon2" value={state.cases.customer_id} onChange={updateInput} />
                                                </Col>)
                                            }
                                        } })()
                                        
                                }

                             
                               
                              {
                                (() => {
                                    if(id == undefined || ((login_user.role == 1 || login_user.role ==2 || login_user.role ==8 || login_user.role==9 )  && id != undefined)) {
                                        return( 
                                        <Col xxl={6} xl={6} md={6} sm={6} xs={12} className="input-box">
                                            <span id='text'>取引先部門</span>
                                            <input type="text" name="customer_dept" id="customer_dept" maxLength={100} className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" value={state.cases.customer_dept} onChange={updateInput} />
                                        </Col>)
                                        }
                                })()
                              }
                            </Row>
                            {
                                (() => {
                                    if(id == undefined || ((login_user.role == 1 || login_user.role ==2 || login_user.role ==8 || login_user.role==9) && id != undefined)) {
                                        return( 
                                        <Row className="mt-3">
                                            <Col xxl={6} xl={6} md={6} sm={6} xs={12} className="input-box">
                                                <span id='text' className="input-required">取引先担当者</span>
                                                <input type="text" name="contact_name" id="contact_name" maxLength={50} className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2"  value={state.cases.contact_name} onChange={updateInput}/>
                                            </Col>
                                            <Col xxl={6} xl={6} md={6} sm={6} xs={12} className="input-box">
                                                <span id='text' className="input-required">取引先担当者メール</span>
                                                <input type="text" name="contact_email" id="contact_email" maxLength={100} className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" value={state.cases.contact_email} onChange={updateInput}/>
                                            </Col>
                                        </Row>
                                        )
                                        }
                                })()
                              }
                            
                            <Row className="mt-3">
                                {
                                (() => {
                                    if(id == undefined || ((login_user.role == 1 || login_user.role ==2 || login_user.role ==8 || login_user.role==9) && id != undefined)) {
                                        return( 
                                            <Col xxl={6} xl={6} md={6} sm={6} xs={12} className="input-box">
                                                <span id='text'>取引先担当者電話</span>
                                                <input type="text" name="contact_tel" id="contact_tel" maxLength={50} className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" value={state.cases.contact_tel} onChange={updateInput}/>
                                            </Col>
                                        )
                                        }
                                })()
                                }
                                {
                                (() => {
                                    if((login_user.role != 8)) {
                                        return( 
                                            <Col xxl={6} xl={6} md={6} sm={6} xs={12} className="input-box">
                                                <span id='text'>社内共有メモ</span>
                                                <textarea className="form-control"  maxLength={250}  name = "flb_shared_cmt" id= "flb_shared_cmt" aria-label="With textarea" value={state.cases.flb_shared_cmt} onChange={updateTextArea}></textarea>
                                            </Col>
                                        )
                                        }
                                })()
                                }
                               
                               </Row>
                                <Row>
                                    <Col xxl={3} xl={3} md={3} sm={3} xs={6} className="input-box">
                                        <span id='text'>担当営業ID</span>
                                        <select name="flb_sales_id" className="input-box form-control" aria-label=".form-select-sm example" value={state.cases.flb_sales_id} onChange={onSelect}>
                                            <option value=""> 選択なし</option>
                                            {sale_list.map(saler => {
                                                    if (saler !== null){
                                                        return(<option value={saler[0]}> {saler[1]}</option>)
                                                    }
                                                })
                                            }
                                        </select>
                                    </Col>
                                    {/* {
                                        (() => {
                                            if(login_user.role != 8) {
                                                return(  */}
                                                    <Col xxl={3} xl={3} md={3} sm={3} xs={6} className="input-box">
                                                        <span id='text' className="input-required">担当HRID</span>
                                                        <select name="flb_hr_id" className="form-control" aria-label=".form-select-sm example" value={state.cases.flb_hr_id} onChange={onSelect}>
                                                            <option value=""> 選択なし</option>
                                                            {hr_list.map(hr => {
                                                                    if (hr !== null){
                                                                        return(<option value={hr[0]}> {hr[1]}</option>)
                                                                    }
                                                                })
                                                            }
                                                        </select>
                                                    </Col>
                                                {/* )
                                                }
                                        })()
                                    } */}
                                    {
                                    (() => {
                                     if(login_user.role != 7 && login_user.role != 8) {
                                         return( 
                                        <Col xxl={3} xl={3} md={3} sm={3} xs={6} className="input-box publish_date">
                                            <span className="pc-ml-1" id='text'>公開日</span>
                                            <DatePicker 
                                                    value={(state.cases.publish_date != null)?state.cases.publish_date: ""}
                                                    dateFormat="yyyy/MM/dd"
                                                    locale="ja"
                                                    selected={selectedPublishDate} 
                                                    onChange={date =>
                                                        handleDateChange(date, "publish_date")
                                                    }
                                                    name="publish_date"
                                                    isClearable
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    onKeyDown={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                    className="pc-ml-1"
                                                    // showMonthYearPicker
                                                    // showTimeSelect
                                                    // timeIntervals={30}
                                                    />
                                        </Col>
                                         )
                                        }
                                })()
                            }
                                  
                                    {
                                        (() => {
                                            if(login_user.role == 1 || login_user.role==2 || login_user.role==9) {
                                                return( 
                                                    <Col xxl={3} xl={3} md={3} sm={3} xs={6} className="input-box">
                                                        <span id='text' className="input-required">公開フラグ</span>
                                                        <select name="published_flg" className="form-control" aria-label=".form-select-sm example" value={state.cases.published_flg} onChange={onSelect}>
                                                        {publish_flg_list.map(publish_flg => {
                                                                    if (publish_flg !== null){
                                                                        return(<option value={publish_flg[0]}> {publish_flg[1]}</option>)
                                                                    }
                                                                })
                                                            }
                                                        </select>
                                                    </Col>

                                                    
                                                )
                                                }
                                        })()
                                    }
                                   
                            </Row>
                        </div>
                    </div>
                    <Row className="mt-5">
                        <Col xxl={12} xl={12} md={12} sm={12} xs={12} className="text-center">
                            <button className="btn btn_standard" id="case_create_submit" type="submit">{(id !== undefined) ?"更新":"登録"}</button>
                            {
                                        (() => {
                                            if(search == "?process=copy") {
                                            return( 
                                                <button className="btn btn_standard" id="case_copy_delete" onClick={() => {destroy(encode)}} >削除</button>
                                            )
                                            }else{
                                                return( 
                                                    (id !== undefined) ?
                                                        <Link to={`/cases/${encode}`}  id="case_create_submit" className="text-decoration-none btn btn_cancel">キャンセル</Link>
                                                        :<Link to={`/cases`}  id="case_create_submit" className="text-decoration-none btn btn_cancel">キャンセル</Link>
                                                )  
                                            }
                                        })()
                            }
                            {/* {(id !== undefined) ?
                                <Link to={`/cases/${encode}`}  id="case_create_submit" className="text-decoration-none btn btn_cancel">キャンセル</Link>
                                :<Link to={`/cases`}  id="case_create_submit" className="text-decoration-none btn btn_cancel">キャンセル</Link>
                            }
                             */}
                        </Col>
                    </Row>
                </form>
                </Row>
                
            </Container>
        </React.Fragment>
    );
};
export default CaseCreate;