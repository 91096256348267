import React, {useState , useEffect} from 'react';
// import {User} from "../../models/User";
import SaleryService from "../../services/SaleryService";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import { CSVLink, CSVDownload } from "react-csv";
import {Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import $ from 'jquery';
import moment from 'moment';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageTitleWithButton from "../../components/common/PageTitleWithButton";


interface IState{
    loading :boolean;
    salerys  :  [];
    errorMessage : string;  
    perPage : number;
    page : number;
    column: string;
    order: string;
    totalRows : number;
    contract_type_list : [];
    job_type_list : [];
    salery_stage_list : [];
    only_retirees_list: [];
}
interface IProps{}

let SaleryList:React.FC<IProps> = () =>{
    
    let [state, setState] = useState<IState>({
        loading : false,
        salerys :  [],
        errorMessage : "",
        page : 0,
        perPage : 10,
        column : "",
        order : "",
        totalRows : 0,
        contract_type_list : [],
        job_type_list : [],
        salery_stage_list : [],
        only_retirees_list: []
    });

    const [search, setSearch] = useState("");  
    const [contract_type, setContractType] = useState("全て");  
    const [job_type, setJobType] = useState("全て");  
    const [salery_stage, setSaleryStage] = useState("全て");  
    const [only_retirees, setOnlyRetirees] = useState("4");  
    const [reemploy_date, setReemployDate] = useState(null);  
    const [year_of_employment, setYearOfEmployment] = useState(null);
    const [init_year_of_employment, setInitYearOfEmployment] = useState(null);

    const [selected, setSelected] = useState('base');
    const [sort, setSort] = useState("0");
    const [no_of_rows, setNoOfRows] = useState(10);
    const [current_page, setCurrentPage] = useState(0);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [showPrevColor, setShowPrevColor] = useState("grey");
    const [showNextColor, setShowNextColor] = useState("grey");

    const defaultColumns = [
        {
            name: "person_id",
            label: "編集",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<Link to={`/salery/${tableMeta.rowData[0]}/edit`} className="btn_standard btn-sm" style={{textDecoration:"none"}}>
                         編集</Link>);
                    },
                },
           
        },
        {
            "name" : 'person_id', 
            "label" : "要員ID"
        },
        {
            "name" : 'emp_id',
            "label" : "社員ID"
        },
        {
            name: "company_name",
            label: "会社名"
        },
        {
            name: "org_name",
            label: "部署名",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                            {value}
                        </div>
                        );
                    },
                },
        },
        {
            name: "full_name",
            label: "漢字氏名"
        },
        {
            name: "kana_name",
            label: "氏名カナ",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                            {value}
                        </div>
                        );
                    },
                },
        },
        {
            name: "email",
            label: "メールアドレス",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                            {value}
                        </div>
                        );
                    },
                },
        },
        {
            name: "birthday",
            label: "生年月日",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return((value!=null)?value.slice(0,10):"");
                }
            }
        },
        {
            name: "contract_type",
            label: "契約種類"
        },
        {
            name: "job_type",
            label: "職種",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"50px"}}>
                            {value}
                        </div>
                        );
                    },
                },
        },
        {
            name: "position",
            label: "役職"
        },
        {
            name: "salery_stage",
            label: "ステージ",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"50px"}}>
                            {value}
                        </div>
                        );
                    },
                },
        },
        {
            name: "salery_grade",
            label: "グレード",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div style={{width:"30px"}}>
                            {value}
                        </div>
                        );
                    },
                },
        },
        {
            name: "base_sal",
            label: "基本給"
        },
        {
            name: "job_allow",
            label: "職務手当"
        },
        {
            name: "mng_duty_allow",
            label: "管理職手当"
        },
        {
            name: 'housing_allow',
            label: '住宅手当'
        },
        {
            name: 'qual_allow',
            label: '資格手当'
        },
        {
            name: 'adjust_allow',
            label: '調整手当'
        },
        {
            name: 'fixed_overtime_allow',
            label: '固定残業手当'
        },
        {
            name: 'adj_hours_low',
            label : '精算控除時間'
        },
        {
            name: 'adj_price_deduct',
            label: '精算控除時間単金'
        },
        {
            name: 'adj_hours_high',
            label: '精算超過時間'
        },
        {
            name: 'adj_price_excess',
            label: '精算超過時間単金'
        },
        {
            name: 'travel_expense',
            label: '交通費'
        },
        {
            name: 'commute_allow',
            label: '通勤手当'
        },
        {
            name: 'taxable_pay',
            label: '予定課税支給額',
        },
        {
            name: 'nontaxable_pay',
            label: '予定非課税支給額'
        },
        {
            name: 'gross_pay',
            label: '予定総支給額'
        },
        {
            name: 'standard_monthly_rem',
            label: '社会保険標準月額'
        },
        {
            name: 'health_ins_1',
            label: '健康保険1'
        },
        {
            name: 'welfare_pension_1',
            label: '厚生年金1'
        },
        {
            name: 'emp_ins_rate_1',
            label: '雇用保険率1'
        },
        {
            name: 'emp_ins_1',
            label: '雇用保険1',
        },
        {
            name: 'ins_total_1',
            label: '社会保険合計1'
        },
        {
            name: 'health_ins_2',
            label: '健康保険2'
        },
        {
            name: 'welfare_pension_2',
            label: '厚生年金2'
        },
        {
            name: 'emp_ins_rate_2',
            label: '雇用保険率2'
        },
        {
            name: 'emp_ins_2',
            label: '雇用保険2'
        },
        {
            name:'ins_total_2',
            label: '社会保険合計2'
        },
        {
            name: 'ins_calc_flg',
            label: '社会保険計算方法',
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                            {value}
                        </div>
                        );
                    },
                },
        },
        {
            name: 'health_welfare_total',
            label: '健康・厚生年金合計額'
        },
        {
            name:  'ins_total',
            label: '社会保険合計額'
        },
        {
            name: 'dependants_num',
            label: '扶養親族の数'
        },
        {
            name: 'tax_calc_type',
            label: '税月額表適用区分'
        },
        {
            name: 'taxable_net_amnt',
            label: '予定課税対象額'
        },
        {
            name: 'tax_pay',
            label: '予定所得税'
        },
        {
            name: 'inhabitant_tax',
            label: '住民税'
        },
        {
            name: 'deduction_total',
            label: '控除合計（住民税以外）'
        },
        {
            name: 'payment_amount',
            label: '予定支給額'
        },
        {
            name: 'employment_date',
            label: '入職年月日'
        },
        {
            name: 'reemploy_date' ,
            label: '契約更新日'
        },
        {
            name: 'vacation_startday',
            label: '有給開始日'
        },
        {
            name: 'retirement_date',
            label: '退職年月日'
        },
        {
            name: 'remarks',
            label: '備考'
        },
        {
            name:  'age',
            label: '年齢'
        }
    ]

    const getData = async (contract_type:any,job_type:any,salery_stage:any,only_retirees:any,reemploy_date:any,year_of_employment:any, perPage:number, page:number, search:any, sort:any, column:string, order:string) => {
        setState({...state,loading :true,})
        SaleryService.list(contract_type,job_type,salery_stage,only_retirees,reemploy_date,year_of_employment, perPage, page, search, sort, column, order).then((response)=>{
            setState({
                ...state,
                loading:false,
                salerys:response.data.data,
                page : response.data.current_page,
                column : response.data.column,
                order : response.data.order,
                perPage : response.data.per_page,
                totalRows : response.data.total,
                contract_type_list : response.data.select_options.contract_type,
                job_type_list : response.data.select_options.job_type,
                salery_stage_list : response.data.select_options.salery_stage,
                only_retirees_list: response.data.select_options.only_retire
            })
            if(Math.floor(response.data.total/response.data.per_page) >0){
                setShowNextColor("blue");
            }else{
                setShowNextColor("grey");
            }
            if(0 == response.data.current_page-1){
                setShowPrevColor("grey");
            }
            if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
                setShowNextColor("grey");
            }
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
            setState({
                ...state,
                loading:false,
                errorMessage:error.message
            })
        })
    }   
        
    let {loading, salerys, perPage, page, column, order, totalRows, errorMessage, contract_type_list,job_type_list,salery_stage_list, only_retirees_list } = state;
   
  
 
    useEffect(()=>{
        setState({...state, loading: true});
        getData(contract_type,job_type,salery_stage,only_retirees,reemploy_date,year_of_employment, perPage, page, search, sort, column, order);
        // setYearOfEmployment('Tue Jun 18 2024 00:00:00 GMT+0900 (日本標準時)');
    }, [perPage]);
    let changePage = (page:any) => {
        setCurrentPage(page);
        getData(contract_type,job_type,salery_stage,only_retirees,reemploy_date,year_of_employment, perPage, page+1, search, sort, column, order);
    };
    let changeRowsPerPage = (perPage:any) => {
        getData(contract_type,job_type,salery_stage,only_retirees,reemploy_date,year_of_employment, perPage, page, search, sort, column, order);
    };
    let handleSort = (column:any, order:any) => {
        getData(contract_type,job_type,salery_stage,only_retirees,reemploy_date,year_of_employment, perPage, page, search, sort, column, order);
    };

    
    const deleteReminder = () => {
        if(selectedRows.length == 0){
            alert("削除したい面談通知情報をチェックしてください。");
            return false;
        }
        let ans = window.confirm("削除を実施します、大丈夫ですか？");
        if(ans){
            let ids : any[] = [];
            salerys.forEach((element, index) => {            
                if (selectedRows.length > 0 && selectedRows.includes(index)) {
                    ids.push(element["person_id"]);
                }
            });
    
            let data = {};
            data["ids"] = ids;
            SaleryService.delete(data).then((data)=>{
                if(data.data.success == false){
                    alert(data.data.info.message);
                }
                window.location.href = "/salery/list";
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
            });
        }
    }

    const nextMonthOperation = () => {
        let ids : any[] = [];
        let checktaxpayInfo = true;
        if(selectedRows.length > 0 ){
            salerys.forEach((element, index) => {            
                if (selectedRows.includes(index)) { //only selected data
                   if(Math.trunc(element["tax_pay"]) == 0){
                     checktaxpayInfo = false;
                   }
                   ids.push(element["person_id"]);
                }
            });
        }else{
            salerys.forEach((element, index) => {            
                if(Math.trunc(element["tax_pay"]) == 0){
                    checktaxpayInfo = false;
                }
                ids.push(element["person_id"]);
            });
        }
        
        if(checktaxpayInfo) {
            copyCheck(ids, year_of_employment);
           
        }else{
            if(window.confirm("給料情報の設定が未完了（予定所得税が未設定）のデータもあるので、完了のみのデータを作成しますか？")){
                copyCheck(ids , year_of_employment);
            }else{
                return false;
            }
        }

        // if(selectedRows.length == 0){
        //     alert("削除したい面談通知情報をチャックしてください。");
        //     return false;
        // }
    }

    const copyCheck = (ids:any, year_of_employment :any) => {
        let data = {};
        data["ids"] = ids;
        data["yearOfEmployment"] = year_of_employment;
        SaleryService.copyCheck(data).then((result)=>{
            if(result.data.data[0].length != 0){
                var alertMessage = "要員ID：";
                $.each(result.data.data[0], function(index, item) {
                    alertMessage = alertMessage + item + "、";
                });
                alertMessage = alertMessage.slice(0,-1);
                alertMessage = alertMessage + "の前月の稼働時間が未入力ですが、給料明細の作成は継続しますか？";
                if (window.confirm(alertMessage)){
                    SaleryService.copyOverwriteCheck(data).then((result)=>{
                        if (result.data.data[0][1].length != 0) {  //result.data[0]
                            var alertMessage = "要員ID：";
                            $.each(result.data.data[0][1], function(index, item) {
                                alertMessage = alertMessage + item + "、";
                            });  //loop
                            
                            alertMessage = alertMessage.slice(0,-1);
                            alertMessage = alertMessage + "の給料明細のデータが既にあるので、上書きで再作成しますか？";
    
                            if (window.confirm(alertMessage)){
                                if(result.data.data[0][0].length != 0) {
                                    var alertMessage = "要員ID：";
                                    $.each(result.data.data[0][0], function(index, item) {
                                        alertMessage = alertMessage + item;
                                        if(result.data.data[0][0].length > 1){
                                            alertMessage += "、";
                                        } 
                                    });
                                    alert(alertMessage +'のデータが承認済みだから、更新出来ません.');
                                    return false;
                                }
                                copy(ids, year_of_employment);
                            } else {
                                return false;
                            }
                        } else {
                            copy(ids, year_of_employment);
                        }
                    }).catch((error) => {
                        if(error.response.status == 403){
                            alert(error.response.data.message);
                        }
                    })
                } else {
                    return false;
                }
            }else{
                SaleryService.copyOverwriteCheck(data).then((result)=>{
                    if (result.data.data[0].length != 0) {
                        var alertMessage = "要員ID：";
                        $.each(result.data.data[0][1], function(index, item) {
                            alertMessage = alertMessage + item + "、";
                        });
                        
                        alertMessage = alertMessage.slice(0,-1);
                        alertMessage = alertMessage + "の給料明細のデータが既にあるので、上書きで再作成しますか？";
                        if (window.confirm(alertMessage)){
                            if(result.data.data[0][0].length != 0) {
                                var alertMessage = "要員ID：";
                                $.each(result.data.data[0][0], function(index, item) {
                                    alertMessage = alertMessage + item;
                                    if(result.data.data[0][0].length > 1){
                                        alertMessage += "、";
                                    } 
                                });
                                alert(alertMessage +'のデータが承認済みだから、更新出来ません.');
                                return false;
                            }
                            copy(ids, year_of_employment);
                        } else {
                            return false;
                        }
                    } else {
                        copy(ids, year_of_employment);
                    }
                }).catch((error) => {
                    if(error.response.status == 403){
                        alert(error.response.data.message);
                    }
                })
            }    
        })
    }

    const copy = (ids:any, year_of_employment :any) => {
        if(ids.length > 0){
            let data = {};
            data["ids"] = ids;
            data["yearOfEmployment"] = year_of_employment;
            SaleryService.copy(data).then((result)=>{
                alert(result.data.data);
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
            })
        }

    }

    const options = {
        filterType: 'checkbox' as any,
        tableBodyHeight : "360px",
        tableBodyMaxHeight : "360px",
        responsive: "standard" as any,
        selectableRows: "multiple" as any,
        selectableRowsHeader: true as any, 
        rowsPerPage: perPage,
        page: page,
        rowsPerPageOptions:[10,20,50,100,200],
        // selectableRowsOnClick: true  as any,
        count: totalRows,
        serverSide: true,
        filter : false,
        print: false,
        viewColumns : false,
        sort: true,
        search : false,
        download :false,
        // resizableColumns : true,
        customToolbarSelect: (selectedRows:any, displayData:any, setSelectedRows:any) => {         
                let rows : any[] = [];
                selectedRows.data.forEach((element:any, index:any) => {
                    rows.push(element.dataIndex);
                })
                let csvData : any[] = [];
                salerys.forEach((element, index) => {
                        if (rows.includes(index)) {
                            csvData.push(element);
                        }
                });
            const getFileName = () => {
                    let now = new Date().toLocaleDateString();
                    let year = now.slice(0,4);
                    let month = '0' + now.slice(5,6);
                    let day = now.slice(7);
                    let date = year+month+day;
                    return "給料情報" +date;
                }
            let headers = [
                {
                    key : 'person_id', 
                    label : "要員ID"
                },
                {
                    key : 'emp_id',
                    label : "社員ID"
                },
                {
                    key: "company_name",
                    label: "会社名"
                },
                {
                    key: "org_name",
                    label: "部署名"
                },
                {
                    key: "full_name",
                    label: "漢字氏名"
                },
                {
                    key: "kana_name",
                    label: "氏名カナ"
                },
                {
                    key: "email",
                    label: "メールアドレス"
                },
                {
                    key: "birthday",
                    label: "生年月日"
                },
                {
                    key: "contract_type",
                    label: "契約種類"
                },
                {
                    key: "job_type",
                    label: "職種"
                },
                {
                    key: "position",
                    label: "役職"
                },
                {
                    key: "salery_stage",
                    label: "ステージ"
                },
                {
                    key: "salery_grade",
                    label: "グレード"
                },
                {
                    key: "base_sal",
                    label: "基本給"
                },
                {
                    key: "job_allow",
                    label: "職務手当"
                },
                {
                    key: "mng_duty_allow",
                    label: "管理職手当"
                },
                {
                    key: 'housing_allow',
                    label: '住宅手当'
                },
                {
                    key: 'qual_allow',
                    label: '資格手当'
                },
                {
                    key: 'adjust_allow',
                    label: '調整手当'
                },
                {
                    key: 'fixed_overtime_allow',
                    label: '固定残業手当'
                },
                {
                    key: 'adj_hours_low',
                    label : '精算控除時間'
                },
                {
                    key: 'adj_price_deduct',
                    label: '精算控除時間単金'
                },
                {
                    key: 'adj_hours_high',
                    label: '精算超過時間'
                },
                {
                    key: 'adj_price_excess',
                    label: '精算超過時間単金'
                },
                {
                    key: 'travel_expense',
                    label: '交通費'
                },
                {
                    key: 'commute_allow',
                    label: '通勤手当'
                },
                {
                    key: 'taxable_pay',
                    label: '予定課税支給額',
                },
                {
                    key: 'nontaxable_pay',
                    label: '予定非課税支給額'
                },
                {
                    key: 'gross_pay',
                    label: '予定総支給額'
                },
                {
                    key: 'standard_monthly_rem',
                    label: '社会保険標準月額'
                },
                {
                    key: 'health_ins_1',
                    label: '健康保険1'
                },
                {
                    key: 'welfare_pension_1',
                    label: '厚生年金1'
                },
                {
                    key: 'emp_ins_rate_1',
                    label: '雇用保険率1'
                },
                {
                    key: 'emp_ins_1',
                    label: '雇用保険1',
                },
                {
                    key: 'ins_total_1',
                    label: '社会保険合計1'
                },
                {
                    key: 'health_ins_2',
                    label: '健康保険2'
                },
                {
                    key: 'welfare_pension_2',
                    label: '厚生年金2'
                },
                {
                    key: 'emp_ins_rate_2',
                    label: '雇用保険率2'
                },
                {
                    key: 'emp_ins_2',
                    label: '雇用保険2'
                },
                {
                    key:'ins_total_2',
                    label: '社会保険合計2'
                },
                {
                    key: 'ins_calc_flg',
                    label: '社会保険計算方法'
                },
                {
                    key: 'health_welfare_total',
                    label: '健康・厚生年金合計額'
                },
                {
                    key:  'ins_total',
                    label: '社会保険合計額'
                },
                {
                    key: 'dependants_num',
                    label: '扶養親族の数'
                },
                {
                    key: 'tax_calc_type',
                    label: '税月額表適用区分'
                },
                {
                    key: 'taxable_net_amnt',
                    label: '予定課税対象額'
                },
                {
                    key: 'tax_pay',
                    label: '予定所得税'
                },
                {
                    key: 'inhabitant_tax',
                    label: '住民税'
                },
                {
                    key: 'deduction_total',
                    label: '控除合計（住民税以外）'
                },
                {
                    key: 'payment_amount',
                    label: '予定支給額'
                },
                {
                    key: 'employment_date',
                    label: '入職年月日'
                },
                {
                    key: 'reemploy_date' ,
                    label: '契約更新日'
                },
                {
                    key: 'vacation_startday',
                    label: '有給開始日'
                },
                {
                    key: 'retirement_date',
                    label: '退職年月日'
                },
                {
                    key: 'remarks',
                    label: '備考'
                },
                {
                    key:  'age',
                    label: '年齢'
                }
              ];
            var now = new Date();
            return (
                <CSVLink  className="btn_standard text-decoration-none" filename={getFileName()} data={csvData}  headers={headers}>ダウンロード</CSVLink>
            );
          },
        textLabels: {
            body: {
              noMatch: "データはありません。",
              toolTip: "Sort"
            },
            pagination: {
              next: "Next Page",
              previous: "Previous Page",
              rowsPerPage: "件表示", 
              displayRows: "すべての件数：",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "行 を選択しました。",
                delete: "削除",
                deleteAria: "選択した行を削除しました。",
              },
          },
        rowsSelected: selectedRows,
        onRowSelectionChange: (rowsSelectedData:any, allRows:any, rowsSelected:any) => {
            setSelectedRows(rowsSelected);
        },
        onChangePage (currentPage:any) {
            changePage(currentPage);
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage (numberOfRows:any) {
            changeRowsPerPage(numberOfRows);
            setNoOfRows(numberOfRows);
        },
        onColumnSortChange: (changedColumn:any, direction:any) => {
            let order = 'desc';
            if (direction === 'asc') {
              order = 'asc';
            }
            handleSort(changedColumn, order);
          },
    };
 
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => { 
        switch (e.target.name) {
            case "search":
                    setSearch(e.target.value);
                    getData(contract_type,job_type,salery_stage,only_retirees,reemploy_date,year_of_employment, perPage, page, e.target.value, sort, column, order);
                    break;
            default:
                break;
        }
      };
      const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        switch (e.target.name) {
            case "contract_type":
                setContractType(e.target.value);
                getData(e.target.value,job_type,salery_stage,only_retirees,reemploy_date,year_of_employment, perPage, page, search, sort, column, order);
                break;
            case "job_type":
                setJobType(e.target.value);
                getData(contract_type,e.target.value,salery_stage,only_retirees,reemploy_date,year_of_employment, perPage, page, search, sort, column, order);
                break;
            case "salery_stage":
                setSaleryStage(e.target.value);
                getData(contract_type,job_type,e.target.value,only_retirees,reemploy_date,year_of_employment, perPage, page, search, sort, column, order);
                break;
            case "only_retirees":
                setOnlyRetirees(e.target.value);
                getData(contract_type,job_type,salery_stage,e.target.value,reemploy_date,year_of_employment, perPage, page, search, sort, column, order);
                break;
            case "sort":
                setSort(e.target.value);
                getData(contract_type,job_type,salery_stage,only_retirees,reemploy_date,year_of_employment, perPage, page, search, e.target.value, column, order);
                break;
            case "list_length":
                changeRowsPerPage(e.target.value);
                setNoOfRows(parseInt(e.target.value));
                setState({
                    ...state,
                    perPage : parseInt(e.target.value)

                })
                break;
                
            default:
                break;
        }
     }; 

    const handleDateChange = function(date:any, column_name:any){
        // alert(date);
        // let selected_data = date.getFullYear() +"-"+ (date.getMonth() + 1); 
        let selected_data =(date != null)? date.getFullYear() +"-"+ (date.getMonth() + 1) : "";
        // let selected_data = date.getFullYear() +"-"+ (date.getMonth() + 1).toString().padStart(2, "0");
        switch (column_name) {
            case "reemploy_date":
                setReemployDate(date);
                getData(contract_type,job_type,salery_stage,only_retirees,selected_data,"", perPage, page, search, sort, column, order);
                break;
            case "year_of_employment":
                setYearOfEmployment(date);
                getData(contract_type,job_type,salery_stage,only_retirees,"",selected_data, perPage, page, search, sort, column, order);
                break;
            default:
                break;
        }

    }
    let nextPage = () => {
        
        // if(Math.floor(totalRows/perPage) >0){
        //     setShowNextColor("blue");
        // }
    
        if(Math.floor(totalRows/perPage) >0){
            changePage(current_page+1);
            setCurrentPage(current_page+1);
            setState({
                ...state,
                page : current_page+1
            })
            setShowNextColor("blue");
        }
        
        if(Math.ceil(totalRows/perPage)-2 == current_page){ // Last Page
            setShowNextColor("grey");
        }   
        if(0 < current_page+1){
            setShowPrevColor("blue");
        }     
    
    }
    
    let previousPage = () => {
        if(current_page > 0){
            setCurrentPage(current_page-1);
            changePage(current_page - 1);
            setState({
                ...state,
                page : current_page-1
            });
            setShowPrevColor("blue");
        }
        if(0 == current_page-1){
            setShowPrevColor("grey");
        } 
    
        if(Math.floor(totalRows/perPage) >0){
            setShowNextColor("blue");
        } 
        
    }
     const getMuiTheme = () =>
     createTheme({
         components: {
         MUIDataTable: {
             styleOverrides:{
                 responsiveScroll: {
                     maxHeight: 'none',
                   },
             }
         },
         MuiPaper: {
             styleOverrides:{
                 root: {
                     width: "100%",
                    //  marginTop: "5%",
                    //  marginLeft: "5%",
                    //  marginBottom: "20%"
                 }
             }
         },  
         MUIDataTableHeadCell: {
                 styleOverrides:{
                   root: {
                       backgroundColor: "#444",
                       padding: '0px'
                   }
                 }
         },
         MuiTableCell: {
             styleOverrides:{
                 head: {
                         color : 'white'
                 }
             }
         },
           MUIDataTableBodyCell: {
             styleOverrides:{
               root: {
                   backgroundColor: "#f1f1f1",
                   width: "100%",
                   padding: '0px'
               }
             }
           }
         }
       })
     
    
    return(
        
       <React.Fragment>
       <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                    ml: sizeConfigs.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color
                }}
                >
                 <Toolbar>
                <Typography variant="h6">
                    <header className="top-baner">
                        <Row>
                            <Col xxl={10} xl={10} md={10} sm={10} xs={10}>
                                <div id="search-autocomplete" className="form-outline">
                                    <input type="text" name="search" id="search" value={search} placeholder = "検索(所属、漢字氏名、カタカナ氏名、会社名、メールアドレスを検索)"
                                        className="form-control"  onChange={onChange}/>
                                </div>
                            </Col>
                            <Col xxl={2} xl={2} md={2} sm={2} xs={2} className="mt-4">
                                <DropDownList />
                            </Col>
                        </Row>
                    </header>
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container>
                     <PageTitleWithButton loading={loading} title="給料情報一覧"  btnText="追加" btnAction={`/salery/create`}/>

                    <Row className="check-list">
                        <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                            <label className="control-label">契約種類</label>
                            <select name="contract_type" className="filter" onChange={onSelect} value={contract_type} aria-label=".form-select-sm example">
                                <option value="全て"> 全て</option>
                                {contract_type_list.map(contract_type => {
                                        if (contract_type !== null){
                                            return(<option value={contract_type[0]}> {contract_type[1]}</option>)
                                        }
                                    })
                                }
                            </select>  
                            <label className="control-label">職種</label>
                            <select name="job_type" className="filter" onChange={onSelect} value={job_type} aria-label=".form-select-sm example">
                                <option value="全て"> 全て</option>
                                {job_type_list.map(job_type => {
                                        if (job_type !== null){
                                            return(<option value={job_type[0]}> {job_type[1]}</option>)
                                        }
                                    })
                                }
                            </select> 
                            <label className="control-label">ステージ</label>
                            <select name="salery_stage" className="filter" onChange={onSelect}  value={salery_stage} aria-label=".form-select-sm example">
                                <option value="全て"> 全て</option>
                                {salery_stage_list.map(salery_stage => {
                                        if (salery_stage !== null){
                                            return(<option value={salery_stage[0]}> {salery_stage[1]}</option>)
                                        }
                                    })
                                }
                            </select>  
                            <label className="control-label">在職状況</label>
                            <select name="only_retirees" className="filter" onChange={onSelect} value={only_retirees} aria-label=".form-select-sm example">
                                <option value="全て"> 全て</option>
                                {only_retirees_list.map(only_retirees => {
                                        if (only_retirees !== null){
                                            return(<option value={only_retirees[0]}> {only_retirees[1]}</option>)
                                        }
                                    })
                                }
                            </select> 
                            <label>契約更新年月</label>
                            <DatePicker 
                                    value={(reemploy_date != null)?reemploy_date: ""}
                                    dateFormat="yyyy-MM"
                                    locale="ja"
                                    selected={reemploy_date} 
                                    onChange={date =>
                                        handleDateChange(date, "reemploy_date")
                                    }
                                    name="reemploy_date"
                                    isClearable
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    disabled={(year_of_employment != null)?true:false}/>
                            <label>在職年月</label>
                            <DatePicker 
                                    //契約更新年月nullじゃない場合、在職年月をdisableにする
                                    value={(reemploy_date != null)?"":(year_of_employment != null)?year_of_employment: moment().subtract(1, 'months').format('YYYY-MM')}
                                    dateFormat="yyyy-MM"
                                    locale="ja"
                                    selected={year_of_employment} 
                                    onChange={date =>
                                        handleDateChange(date, "year_of_employment")
                                    }
                                    name="year_of_employment"
                                    isClearable
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    disabled={(reemploy_date != null)?true:false}/>
                        </Col>
                    </Row>
                    <Row className="pt-2 pb-2">
                        <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                            <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                            <select name="sort" id="sort" className="filter" onChange={onSelect}>
                                <option value="0" selected>新しい順</option>
                                <option value="1">古い順</option>
                            </select>
                        </Col>
                        <Col xxl={8} xl={8} md={8} sm={8} xs={6} className="filter_select">
                            <div className="float-right">
                                <button type="button" className="btn_danger  btn-sm ml_1"  onClick={nextMonthOperation}>給料データ明細の作成</button>
                                <select name="list_length" id="list_length" value={no_of_rows} className="filter list_length" onChange={onSelect}>
                                    <option value="10">10</option>
                                    <option value="20" >20</option>
                                    <option value="50" >50</option>
                                    <option value="100" >100</option>
                                    <option value="200" >200</option>
                                </select>
                                <label htmlFor="sort" className="control-label">件表示</label>
                                <button type="button" className="btn-sm previous-page-icon" style={{border:"0px"}} onClick={() => {
                                                previousPage();
                                                }}>
                                        <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                    </button>
                                    <button type="button" className=" btn-sm next-page-icon" style={{border:"0px"}} onClick={() => {
                                                nextPage();
                                                }}>
                                        <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                                    </button> 
                                </div>
                            </Col>
                    </Row>
                    <div className='datatable freeze-salery-header freeze-salery-cell freeze-salery-frozen-columns xs-hidden'>
                        <MUIDataTable
                            title = {""}
                            data = {salerys}
                            columns={defaultColumns}
                            options={options}
                        />
                    </div>
                    <div className='datatable mobile pc-hidden'>
                        <MUIDataTable
                            title = {""}
                            data = {salerys}
                            columns={defaultColumns}
                            options={options}
                        />
                    </div>
            </Container>
       
        </React.Fragment>
    )

}

export default SaleryList;