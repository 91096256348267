import {ReactNode, createContext, useReducer, Dispatch, useEffect, useState} from 'react';
import AuthService from "../services/AuthService";

export interface AppState {
    userInfo?: any,
    apiToken: string,
    apiSession: string,
    isAuthenticated: boolean,
    apiTokenUpdateAt: string,
    resourceSession: string,
}
export const AppConstStateActions = {
    Set: {
        UserInfo: 'setUserInfo',
        ApiToken: 'setApiToken',
        ApiSession: 'setApiSession',
        Authenticated: 'setIsAuthenticated',
        ApiTokenUpdateAt: 'setApiTokenUpdateAt',
        ResourceSession: 'setResourceSession',
        All:'setAll'
    },
    Remove: {
        UserInfo: 'removeUserInfo',
        ApiToken: 'removeApiToken',
        ApiSession: 'removeApiSession',
        Authenticated: 'removeIsAuthenticated',
        ApiTokenUpdateAt: 'removeApiTokenUpdateAt',
        ResourceSession: 'removeResourceSession',
        All: 'removeAll'
    },
    KEY:{
        USER: "user",
        TOKEN: "token",
        TOKEN_UPDATED_AT: "token_updated_at",
        RESOURCE_SESSION: "rs"
    }
}
const defaultDatas = {
    userInfo: {},
    apiToken: "",
    apiSession: "",
    isAuthenticated: false,
    apiTokenUpdateAt: "1997-01-01T00:00:00.000000Z",
    resourceSession: ""
}
type AppAction = {
    type: string,
    value: any
}
interface AppStateContextProps {
    appDatas: AppState,
    appAction: Dispatch<AppAction>
    appAuth: any
}

const AppStateContext = createContext<AppStateContextProps | undefined>(undefined);

const AppStateAction = (appDatas: AppState, appAction: AppAction) => {
    switch (appAction.type) {
        case AppConstStateActions.Set.ApiToken: {
            localStorage.setItem(AppConstStateActions.KEY.TOKEN, appAction.value);
            return {
                ...appDatas,
                apiToken: appAction.value
            }
        }
        case AppConstStateActions.Set.ApiSession: {
            return {
                ...appDatas,
                apiSession: appAction.value
            }
        }
        case AppConstStateActions.Set.ApiTokenUpdateAt: {
            localStorage.setItem(AppConstStateActions.KEY.TOKEN_UPDATED_AT, appAction.value);
            return {
                ...appDatas,
                apiTokenUpdateAt: appAction.value
            }
        }
        case AppConstStateActions.Set.UserInfo: {
            localStorage.setItem(AppConstStateActions.KEY.USER, appAction.value);
            return {
                ...appDatas,
                userInfo: appAction.value
            }
        }
        case AppConstStateActions.Set.ResourceSession: {
            localStorage.setItem(AppConstStateActions.KEY.RESOURCE_SESSION, appAction.value);
            return {
                ...appDatas,
                resourceSession: appAction.value
            }
        }
        case AppConstStateActions.Set.Authenticated: {
            return {
                ...appDatas,
                Authenticated: appAction.value
            }
        }
        case AppConstStateActions.Set.All: {
            localStorage.setItem(AppConstStateActions.KEY.RESOURCE_SESSION, appAction.value?.resourceSession);
            localStorage.setItem(AppConstStateActions.KEY.TOKEN, appAction.value?.apiToken);
            localStorage.setItem(AppConstStateActions.KEY.TOKEN_UPDATED_AT, appAction.value?.apiTokenUpdateAt);
            localStorage.setItem(AppConstStateActions.KEY.USER, appAction.value?.userInfo);
            return {
                ...appAction.value
            }
        }
        case AppConstStateActions.Remove.UserInfo: {
            localStorage.removeItem(AppConstStateActions.KEY.USER);
            return {
                ...appDatas,
                userInfo: {}
            }
        }
        case AppConstStateActions.Remove.ApiTokenUpdateAt: {
            localStorage.removeItem(AppConstStateActions.KEY.TOKEN_UPDATED_AT);
            return {
                ...appDatas,
                apiTokenUpdateAt: "1997-01-01T00:00:00.000000Z"
            }
        }
        case AppConstStateActions.Remove.ApiSession: {
            return {
                ...appDatas,
                apiSession: ""
            }
        }
        case AppConstStateActions.Remove.ApiToken: {
            localStorage.removeItem(AppConstStateActions.KEY.TOKEN);
            return {
                ...appDatas,
                apiToken: ""
            }
        }
        case AppConstStateActions.Remove.ResourceSession: {
            localStorage.removeItem(AppConstStateActions.KEY.RESOURCE_SESSION);
            return {
                ...appDatas,
                resourceSession: ""
            }
        }
        case AppConstStateActions.Remove.All: {
            localStorage.removeItem(AppConstStateActions.KEY.TOKEN);
            localStorage.removeItem(AppConstStateActions.KEY.TOKEN_UPDATED_AT);
            localStorage.removeItem(AppConstStateActions.KEY.USER);
            localStorage.removeItem(AppConstStateActions.KEY.RESOURCE_SESSION);
            return {
                ...defaultDatas
            }
        }
        default: {
            throw Error(`UnKnown action: ${appAction.type}`)
        }
    }
}

function AppStateProvider({ children }:{
    children: ReactNode
}) {
    const [isFinishedStep1, setIsFinishedStep1] = useState(false);
    // const [isFinishedStep2, setIsFinishedStep2] = useState(false);

    const [appDatas, appAction] = useReducer(
        AppStateAction,
        defaultDatas
    );
    
    const appAuth:any = {

        isAuthenticated: () => {

            const chekToken = (datas:any) => {
                if(
                    datas.apiToken.length > 0 &&
                    datas.apiTokenUpdateAt.length > 0
                ) {
                    let tokenUpdatedAt = new Date(datas.apiTokenUpdateAt);
                    tokenUpdatedAt.setMinutes(tokenUpdatedAt.getMinutes() + 120);
                    let nowDate = new Date();
                    if (nowDate > tokenUpdatedAt){
                        return false;
                    }
        
                    return true;
                }
        
                return false;
            }
            
            let datas = {
                userInfo: localStorage.getItem(AppConstStateActions.KEY.USER)??JSON.stringify({}),
                apiToken: localStorage.getItem(AppConstStateActions.KEY.TOKEN)??"",
                apiSession: "",
                isAuthenticated: false,
                apiTokenUpdateAt: localStorage.getItem(AppConstStateActions.KEY.TOKEN_UPDATED_AT)??"1997-01-01T00:00:00.000000Z",
                resourceSession: localStorage.getItem(AppConstStateActions.KEY.RESOURCE_SESSION)??"",
            }
            appAction({
                type: AppConstStateActions.Set.All,
                value: datas
            })

            if(chekToken(datas)){
                appAction({
                    type: AppConstStateActions.Set.Authenticated,
                    value: true
                })
                return true;
            }
            return false;
        },
        logout: () => {
            appAction({
                type: AppConstStateActions.Remove.All,
                value: {}
            });
            window.location.href = "/";
        }
    }
    const providerValue = {appDatas, appAction, appAuth};

    let urlParams = new URLSearchParams(document.location.search);
    
    useEffect( () => {
        if( urlParams.get('rs') != null ){
          console.log(appDatas);
          appAction({
            type: AppConstStateActions.Set.ResourceSession,
            value: urlParams.get('rs')??''
          });
        } else {
            if((localStorage.getItem(AppConstStateActions.KEY.RESOURCE_SESSION)??"").length > 0){
                AuthService.resourceLoginCheck().then(res=>{
                    if( res.data.status !== 200 ){
                        appAuth.logout();
                    } else {
                        let token_updated_at = res.data.token_updated_at??"";
                        if(token_updated_at.length > 0){
                            appAction({
                                type: AppConstStateActions.Set.ApiTokenUpdateAt,
                                value: token_updated_at
                            });
                        }
                    }
                })
            }
        }
        if( urlParams.get('error') ){
          let errorCode = urlParams.get('error')??"";
          console.log(errorCode);
          if( ["401", "405"].includes(errorCode) ){
            appAuth.logout();
          }
        }
        
        const checkLoginInterval = setInterval(() => {
            if(!appAuth.isAuthenticated()){
                appAuth.logout();
            }
        }, 1000*60*30);
        setIsFinishedStep1(true);
        return () => {
            clearInterval(checkLoginInterval);
        }

    }, []);

    useEffect(() => {
        // if( !["/", "/verify", "/login"].includes(window.location.pathname)) {
        if( isFinishedStep1 && appAuth.isAuthenticated() ){

            const updateWindowCount = () => {
                let count = parseInt(localStorage.getItem('appWinCount')??"1");
                if(count < 1){
                    count = 1;
                }
                localStorage.setItem('appWinCount', `${count + 1}`);
            };
            const handleBeforeUnload = (event:any) => {
                const confirmationMessage = "";
                event.returnValue = confirmationMessage; 
                let count = parseInt(localStorage.getItem('appWinCount')??"1");
                count = count-1;
                localStorage.setItem('appWinCount', `${count}`);
    
                if (count < 1) {
                    appAuth.logout();
                }
                return confirmationMessage; 
            };
    
            updateWindowCount();
    
            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => {
              window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }
      }, [isFinishedStep1]);

    return (
        <AppStateContext.Provider value={providerValue} >
            {children}
        </AppStateContext.Provider>
    );
}

export {AppStateContext, AppStateProvider};