import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
// const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;
const token = localStorage.getItem("token"); // retrieve stored user information from local storage
const header =  {
    headers :  {
        'Authorization': `Bearer ${token}` ,
        // "server_url" : `${SERVER_URL}`,
        "client-domain" :  `${CLIENT_DOMAIN}`,
        "Access-Control-Allow-Origin": "*" ,
       } 
};
class SaleryService {
    list(contract_type:any,job_type:any,salery_stage:any,only_retirees:any,reemploy_date:any,year_of_employment:any, perPage:number, page:number,search_keywords:any, sort:any, column:string, order:string){
        let dataURL:string = API_URL + `/salerys/list?contract_type=${contract_type}&job_type=${job_type}&salery_stage=${salery_stage}&only_retirees=${only_retirees}&reemploy_date=${reemploy_date}&year_of_employment=${year_of_employment}&list_length=${perPage}&page=${page}&search_keywords=${search_keywords}&sort=${sort}&column=${column}&order=${order}`;
        return axios.get(dataURL, header);
    }

    create(){
        let dataURL:string = API_URL + `/salerys/create1`;
        return axios.get(dataURL, header);
    }

    store(data:any) {
        let dataURL:string = API_URL + "/salerys/store1";
        return axios.post(dataURL,data, header);
    }

    edit(id:any){
        let dataURL:string = API_URL + "/salerys/"+id+"/edit";
        return axios.get(dataURL, header);
    }

    update(data:any ,id:any) {
        console.log(data);
        let dataURL:string = API_URL + "/salerys/update1/"+id;
        return axios.post(dataURL,data, header);
    }

    delete(data:any) {
        let dataURL:string = API_URL + "/salerys/list/destroy1";
        return axios.post(dataURL,data, header);
    }

    calTax(data:any){
        let dataURL:string = API_URL + "/salerys/tax/pay1";
        return axios.post(dataURL,data, header);
    }

    copyCheck(data:any){
        let dataURL:string = API_URL + "/salery/copy/check1";
        return axios.post(dataURL,data, header);
    }

    copyOverwriteCheck(data:any){
        let dataURL:string = API_URL + "/salery/copy/overwrite/check1";
        return axios.post(dataURL,data, header);
    }

    copy(data:any){
        let dataURL:string = API_URL +"/salery/copy1";
        return axios.post(dataURL,data, header);
    }
    
}

  export default new SaleryService();