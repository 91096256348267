import React, {useEffect, useState} from 'react';
import {AssignmentService} from "../../services/AssignmentService";
import AuthService from "../../services/AuthService";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Dropdown from 'react-bootstrap/Dropdown';
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import {Link} from "react-router-dom";
import $ from 'jquery';
import { CSVLink, CSVDownload } from "react-csv";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {AssignmentApprove} from "../../models/Assignment";
import DatePicker from "react-datepicker";
import {showErrorInfo, PCModalStyle, MobileModalStyle} from "../../components/common/Helpers";
import Loader from "../../components/common/Loader";
import {useLocation} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageTitle from "../../components/common/PageTitle";

interface IState{
    assignment : [],
    errorMessage : string;  
    perPage : number;
    page : number;
    column: string;
    order: string;
    totalRows : number;
    approveList : [];
    paymentSiteList : [];
    oPaymentSiteList : [];
    buyerContractList : [];
    sellerContractList : [];
    contractPriceType : [];
    assignmentApproveList : AssignmentApprove;
    loading : boolean;
}
interface IProps{}
let AssignmentApproveList:React.FC<IProps> = () => {
   
    const row : string[] = [];
    const [checkedRows, setCheckedRows] = useState(row);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false); 
    let [selectedWorkStartDate, setSelectedWorkStartDate] = useState(null);
    let [selectedWorkEndDatePlan, setSelectedWorkEndDatePlan] = useState(null);
    const [showPrevColor, setShowPrevColor] = useState("grey");
    const [showNextColor, setShowNextColor] = useState("grey");
    const [width, setWidth] = useState(719);
    const [height, setHeight] = useState(770);
    const [show_case_name, setShowCaseName] = useState("");
    const [show_person_nm, setShowPersonName] = useState("");

    const handleDateChange = function(date:any, column_name:any){
        let selected_data:any = "";
        if(date !== null){
            selected_data = date.getFullYear() +"/"+ (date.getMonth() + 1).toString().padStart(2, "0")+"/"+(date.getDate());
        }   
        switch (column_name) {
            case "work_start_date":
                setSelectedWorkStartDate(date);
                setState({
                    ...state,
                    assignmentApproveList : {
                        ...state.assignmentApproveList,
                        work_start_date : selected_data // input name 'username' set value 'event target value'
                    }
                });
                break;
            case "work_end_date_plan":
                setSelectedWorkEndDatePlan(date);
                setState({
                    ...state,
                    assignmentApproveList : {
                        ...state.assignmentApproveList,
                        work_end_date_plan : selected_data // input name 'username' set value 'event target value'
                    }
                });
                break;
            default:
                break;
        }
       
    }
    let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setState({
            ...state,
            assignmentApproveList : {
                ...state.assignmentApproveList,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        });   
    };

    let updateTextArea = (event:React.ChangeEvent<HTMLTextAreaElement>):void => {
        setState({
            ...state,
            assignmentApproveList : {
                ...state.assignmentApproveList,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        });
    }
    const onSelectAssignment = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setState({
            ...state,
            assignmentApproveList : {
                ...state.assignmentApproveList,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            }
        });
     };

     const updateAssign = (assign:any) => {
        AssignmentService.showAssignment(assign).then((data)=>{
             setState({
                    ...state,
                    assignmentApproveList : {
                        assignment_id: data.data.assignment_id,
                        update_type: "approve",
                        buyer_contract : data.data.buyer_contract,
                        seller_contract : data.data.seller_contract,
                        work_company : data.data.work_company,
                        work_start_date : data.data.work_start_date,
                        work_place : data.data.work_place,
                        work_end_date_plan: data.data.work_end_date_plan,
                        contract_price_type : data.data.contract_price_type,
                        proposal_price : data.data.proposal_price,
                        settlement : data.data.settlement,
                        settlement_to : data.data.settlement_to,
                        settlement_biko : data.data.settlement_biko,
                        payment_site: data.data.payment_site,
                        person_cost: data.data.person_cost,
                        o_adj_hours_low: data.data.o_adj_hours_low,
                        o_adj_hours_high: data.data.o_adj_hours_high,
                        o_adj_hours_biko: data.data.o_adj_hours_biko,
                        o_payment_site: data.data.o_payment_site,
                        flb_shared_cmt: data.data.flb_shared_cmt // input name 'username' set value 'event target value'
                    }
                });
                setShowCaseName(data.data.case_name);
                setShowPersonName(data.data.person_name);
                setOpen(true);
        }).catch((error) => {
           console.log(error);
        })
    }

    const updatePersonAssign = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
        AssignmentService.assignmentUpdate(state.assignmentApproveList).then((response) => {
            getData(approve,perPage,page,search_keywords, sort, column, order);
            handleClose();
            // window.location.reload();
        }).catch((error) => {
            if(error.response.data){
                showErrorInfo(error.response.data);
            }
        })
    };

    const approveAssignment = (assign:any) => {
        AssignmentService.assignmentApprove(assign).then((data)=>{
            window.location.reload();
        }).catch((error) => {
            console.log(error);
        })
    }

    const sendMail = (assign:any) => {
        AssignmentService.assignmentSendEmail(assign).then((data)=>{
           alert(data.data.data.msg);
        }).catch((error) => {
            console.log(error);
        })
    }

    const defaultcolumns = [
        {
            name: "assignment_id",
            label: "編集",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<button type="button" className="btn_standard btn-sm" onClick={() => {
                            updateAssign(`${tableMeta.rowData[0]}`);
                            }}>編集
                        </button>);
                    },
                },
           
        },
        {
            name: "assignment_id",
            label: "承認",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        if(tableMeta.rowData[22] != null && tableMeta.rowData[26] != null && tableMeta.rowData[21] != null && tableMeta.rowData[40] == null)
                        {
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                approveAssignment(`${tableMeta.rowData[1]}`);
                                }}>承認
                            </button>);
                            
                        } else{
                            return(<span></span>);
                        }
                    },
                },
           
        },
        {
            name: "assignment_id",
            label: "合格メール",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        if(tableMeta.rowData[22] != null && (tableMeta.rowData[27] != null || tableMeta.rowData[41] != null) && 
                           tableMeta.rowData[32] != null && tableMeta.rowData[33] != null && tableMeta.rowData[42] != null && 
                           tableMeta.rowData[42] != "他社")
                        {
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                sendMail(`${tableMeta.rowData[2]}`);
                                }}>送付
                            </button>);
                            
                        } else{
                            return(<span></span>);
                        }
                    },
                },
           
        },
        {
            name: "assignment_id",
            label: "割り当てID"
        },
        {
            name: "person-encode",
            label: "Encode",
            "options":{
                "display":false,
             }
        },
        {
            name: "person-full_name",
            label: "要員名",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to="" onClick={()=>personDetail(tableMeta.rowData[4])} >
                        <div className='longText' title={value} style={{color:"blue"}}>
                            {value}
                        </div>
                        </Link>);
                    },
                },
           },
           {
            name: "work_company",
            label: "稼働会社",
           },
           {
            name: "case-hr-name_jp",
            label: "HR担当者",
           },
           { name: 'person-company-company', 
             label: '所属',
             class: "longText",
             options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                },
           },
           {
            name: "person_id",
            label: "社員ID",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "80px" ,maxWidth: "100px" }}),
            }
           },
           { 
            name : 'person-belong_contact_email',
            label : '所属連絡メールアドレス',
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "180px" ,maxWidth: "220px" }}),
            }
           },
           {
            name : 'person-belong_contact_tel',
            label : "所属担当者電話番号",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                },
                },
           },
           {
            name: "assigner-name_jp",
            label: "割り当て担当",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "120px" ,maxWidth: "130px" }}),
            }
           },
           {
            name: "assign_start_date",
            label: "割り当て開始日",
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "100px",maxWidth: "130px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                    return(value);
                    },
                },
           },
           {
            name: "case-case_id",
            label: "案件ID"
           },
           {
            name: "case-encode",
            label: "Encode",
            "options":{
                "display":false,
             }
           },
           {
            name: "case-case_name",
            label: "案件名",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "300px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to="" onClick={() => caseDetail(tableMeta.rowData[15])} >
                        <div className='longText' title={value} style={{color:"blue"}}>
                                {value}
                        </div>
                        </Link>);
                    },
                },
           },
           {
            name: "case-saler-name_jp",
            label: "営業担当",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                },
           },
           {
            name: "case-customer-name_jp",
            label: "取引先",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "130px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                {value}
                        </div>
                        );
                    },
                },
            },
            {
                name: "case-contact_name",
                label: "取引先担当者",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { minWidth: "130px" , maxWidth: "150px" }}),
                }
            },    
            {
                name: 'buyer_contract', 
                label: "受注契約",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                }
            },
            {
                name : 'seller_contract', 
                label : "発注契約",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                }
            },
           {
            name: "work_start_date",
            label: "稼働開始日",
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                    return(value);
                    },
                },
           },
           {
            name: "work_place",
            label: "勤務場所",
            class : "longText",
            options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                {value}
                        </div>
                        );
                }
            }
           },
           {
            name: "work_end_date_plan",
            label: "稼働終了予定日",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // let value = value.getFullYear() +"/"+ (value.getMonth() + 1)+"/"+(value.getMonth());
                    // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                                {value}
                        </div>
                        );
                    },
                },
           },
          {
            name: "contract_price_type",
            label: "稼働単金種別",
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "130px" ,maxWidth: "150px"}}),
            }
           },
           {
            name: "proposal_price",
            label: "希望単価",
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "100px" ,maxWidth: "130px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(value > 99999){
                        value = Math.ceil(value/10000)+"万";
                    }else{
                        value = (value)?value: "";
                    }
                   return (value);
                    },
                },
           },
           {
            name: "person_cost",
            label: "コスト",
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "80px" , maxWidth: "100px" }}),
            }
           },
           {
            name: "settlement",
            label: "精算From",
            options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
            }
           },
           {
            name: "settlement_to",
            label: "精算To",
            options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
            }
           },
           {
            name: "settlement_biko",
            label: "精算備考",
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "100px" ,maxWidth: "130px"}}),
            }
           },
           {
            name: "payment_site",
            label: "支払サイト",
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "100px" ,maxWidth: "130px"}}),
            }
           },
           {
            name: 'o_adj_hours_low',
            label: "乙方精算From",
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "100px" ,maxWidth: "130px"}}),
            }
           },
           {
            name: 'o_adj_hours_high',
            label: "乙方精算To",
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "100px" ,maxWidth: "130px"}}),
            }
           },
           {
            name: 'o_adj_hours_biko', 
            label: "乙方精算備考",
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "120px" ,maxWidth: "130px"}}),
            }
           },
           {
            name: 'o_payment_site',
            label: "乙方支払サイト",
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "130px" ,maxWidth: "150px"}}),
            }
           },
           {
            name: 'adoption_flg', 
            label: "最終結果",
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "130px" }}),
            }
           },
           {
            name: "approval_date",
            label: "承認日",
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "100px",maxWidth: "130px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                    return(
                        <div className='longText' title={value}>
                                {value}
                        </div>
                        );
                    },
                },
           },
           {
            name: "assign_end_date",
            label: "割り当て終了日",
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "130px", maxWidth: "150px",textAlign:"center"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // value = (value != null)?value.substring(0, value.indexOf(" ")):value;
                    return(
                        <div className='longText' title={value}>
                                {value}
                        </div>
                        );
                    },
                },
           },
           {
            name: "flb_shared_cmt",
            label: "社内共有メモ",
            class: "LongText",
            options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "150px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                },
           },
           {
            name: "approval_user_id",
            label: "承認者",
            "options":{
                "display":false,
             }
           },
           {
            name: "person-person_cost",
            label: "要員のコスト",
            "options":{
                "display":false,
             }
           },
           {
            name: "person-belong_type",
            label: "要員の所属種別",
            "options":{
                "display":false,
             }
           },
        ];

    const getMuiTheme = () =>
    createTheme({
        components: {
        MUIDataTable: {
            styleOverrides:{
                responsiveScroll: {
                    maxHeight: 'none',
                  },
            }
        },
        MuiPaper: {
            styleOverrides:{
                root: {
                    width: "100%",
                    marginTop: "5%",
                    // // marginLeft: "5%",
                    // // marginBottom: "20%"
                }
            }
        },  
        MUIDataTableHeadCell: {
                styleOverrides:{
                  root: {
                      backgroundColor: "rgb(193,193,193)",
                      padding: '0px'
                  }
                }
        },
        // MuiTableCell: {
        //     head: {
        //         backgroundColor: "red !important"
        //     }
        // }

        MuiTableCell: {
            styleOverrides:{
                head: {
                        color : 'white',
                        backgroundColor: "red !important"
                }
            }
        },
          MUIDataTableBodyCell: {
            styleOverrides:{
              root: {
                  backgroundColor: "#f1f1f1",
                  width: "100%",
                  padding: '0px'
              }
            }
          }
        }
      })

    let [state , setState] = useState<IState>({
        assignment : [],
        errorMessage : "",
        page : 0,
        perPage : 10,
        column : "",
        order : "",
        totalRows : 0,
        approveList : [],
        paymentSiteList : [],
        oPaymentSiteList : [],
        buyerContractList : [],
        sellerContractList : [],
        contractPriceType : [],
        assignmentApproveList : {
            assignment_id: "",
            update_type: "",
            buyer_contract : "請負",
            seller_contract : "請負",
            work_company : "",
            work_start_date : "",
            work_place : "",
            work_end_date_plan: "",
            contract_price_type : 0,
            proposal_price : null,
            settlement : null,
            settlement_to : null,
            settlement_biko : "",
            payment_site: 20,
            person_cost : null,
            o_adj_hours_low : null,
            o_adj_hours_high : null,
            o_adj_hours_biko : "",
            o_payment_site : 20,
            flb_shared_cmt : ""
        },
        loading: false
    });
    const [search_keywords, setSearch] = useState("");
    const [sort, setSort] = useState("0");
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const login_user =   JSON.parse(localStorage.getItem("user") || '{}');
    const [checked, isChecked] = useState(false);
    const [approve, setApprove] = useState("0");  

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height: 700,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    const getData = async (approve:any,perPage:number,page:number,search_keywords:any, sort:any, column:string, order:string) => {
        setState({...state,loading :true,})
        AssignmentService.getAllAssignmentApproveList(approve,perPage,page,search_keywords, sort, column, order).then((response)=>{
            setState({
                ...state,
                assignment:response.data.data,
                page : response.data.current_page,
                perPage : response.data.per_page,
                column : response.data.column,
                order : response.data.order,
                totalRows : response.data.total,
                approveList: response.data.select_options.is_approved,
                paymentSiteList: response.data.select_options.payment_site,
                oPaymentSiteList : response.data.select_options.o_payment_site,
                buyerContractList : response.data.select_options.buyer_contract,
                sellerContractList : response.data.select_options.seller_contract,
                contractPriceType : response.data.select_options.contract_price_type,
                loading:false,
            })
            if(Math.floor(response.data.total/response.data.per_page) >0){
                setShowNextColor("blue");
            }else{
                setShowNextColor("grey");
            }
            if(0 == response.data.current_page){
                setShowPrevColor("grey");
            }
            if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
                setShowNextColor("grey");
            }
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
               }
            setState({
                ...state,
                errorMessage:error.message,
                loading:false,
            })
        })
    }
    let {assignment, perPage, page, column, order, totalRows,approveList, paymentSiteList, oPaymentSiteList,buyerContractList,sellerContractList, contractPriceType, assignmentApproveList, loading, errorMessage} = state;

    // const selectAll = (e:any) => {
    //     if(checked == false){
    //         isChecked(true);
    //         let ids : any[] = [];
    //         cases.forEach((element, index) => {
    //             ids.push(element["case_id"]);
    //             setCheckedRows(ids);
    //           });
    //         $('input[type=checkbox][name="list_check[]').prop('checked', true).trigger('change');
    //     }else{
    //         isChecked(false);
    //         setCheckedRows([]);
    //         $('input[type=checkbox][name="list_check[]').prop('checked', false).trigger('change');
    //     }

    //     // .addClass("list_check_all");
    //     console.log(checkedRows);
    //   };

    useEffect(()=>{
        setState({...state,
            loading: true
        });
        getData(approve,perPage,page,search_keywords, sort, column, order);
    }, [perPage]);
    let changePage = (page:any) => {
        setCurrentPage(page);
        getData(approve,perPage,page+1,search_keywords, sort, column, order);
    };
    let changeRowsPerPage = (perPage:any) => {
        getData(approve,perPage,page-1,search_keywords, sort, column, order);
    };
   
   

   

    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        switch (e.target.name) {
            case "approve":
                setApprove(e.target.value);
                getData(e.target.value,perPage,page,search_keywords, sort, column, order);
                break;
            case "sort":
                setSort(e.target.value);
                getData(approve,perPage,page,search_keywords, e.target.value, column, order);
                break;
            case "list_length":
                changeRowsPerPage(e.target.value);
                setNoOfRows(parseInt(e.target.value));
                setState({
                    ...state,
                    perPage : parseInt(e.target.value)
                })

                break;
            default:
                break;
        }
     };

     let handleDelete = (rowData:any, rowState:any) => {
        let rows : any[] = [];
        rowData.data.forEach((element:any, index:any) => {
            rows.push(element.dataIndex);
        })
        let ids : any[] = [];
        assignment.forEach((element, index) => {
          if (rows.includes(index)) {
            ids.push(element["assignment_id"]);
          }
        });
        AssignmentService.assignmentDelete(ids).then((response:any)=>{
            if(response.data.success == false){
                alert(response.data.info.message);
                window.location.reload();
            }
        }).catch((error:any) => {
            setState({
                ...state,
                errorMessage:error.message
            })
        })
    }

    let handleSort = (column:any, order:any) => {
        let temp = {};
        temp["column"] = column;
        temp["order"] = order;
        getData(approve,perPage,page,search_keywords, sort, column, order);       
      };

  
      let nextPage = () => {
        
        // if(Math.floor(totalRows/perPage) >0){
        //     setShowNextColor("blue");
        // }

        if(Math.floor(totalRows/perPage) >0){
            changePage(current_page+1);
            setCurrentPage(current_page+1);
            setState({
                ...state,
                page : current_page+1
            })
            setShowNextColor("blue");
        }
        
        if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
            setShowNextColor("grey");
        }   
        if(0 < current_page+1){
            setShowPrevColor("blue");
        }     

    }

    let previousPage = () => {
        if(current_page > 0){
            setCurrentPage(current_page-1);
            changePage(current_page - 1);
            setState({
                ...state,
                page : current_page-1
            });
            setShowPrevColor("blue");
        }
        

        if(0 == current_page-1){
            setShowPrevColor("grey");
        } 

        if(Math.floor(totalRows/perPage) >0){
            setShowNextColor("blue");
        } 
        
    }
   
   
   
    const [no_of_rows, setNoOfRows] = useState(10);
    const [current_page, setCurrentPage] = useState(0);

    const options = {
        filterType: 'checkbox' as any,
        tableBodyHeight : "360px",
        tableBodyMaxHeight : "360px",
        responsive: "standard" as any,
        selectableRows: "multiple" as any,
        // selectableRowsHeader: true as any, 
        rowsPerPage: perPage,
        page : page,
        rowsPerPageOptions:[10,20,50,100,200],
        // selectableRowsOnClick: true  as any,
        count: totalRows,
        serverSide: true,
        filter : false,
        print: false,
        viewColumns : false,
        sort: true,
        search : false,
        download :false,
        // resizableColumns: true,
        // onDownload: (buildHead:any, buildBody:any, columns:any, rows:any) => {
        //     console.log(rows); //  <-------- can't get only selected rows
        //   },
        customToolbarSelect: (selectedRows:any, displayData:any, setSelectedRows:any) => {
         
            const handleClick = () => {
                
                //console.log(selectedRows.data);
                
            };
                let rows : any[] = [];
                selectedRows.data.forEach((element:any, index:any) => {
                    rows.push(element.dataIndex);
                })
                let csvData : any[] = [];
                assignment.forEach((element, index) => {
                        if (rows.includes(index)) {
                            csvData.push(element);
                        }
                });
                const getFileName = () => {
                    let now = new Date().toLocaleDateString();
                    let year = now.slice(0,4);
                    let month = '0' + now.slice(5,6);
                    let day = now.slice(7);
                    let date = year+month+day;
                    return "割り当て承認" +date;
                }
            let headers = [
                {
                    key: "assignment_id",
                    label: "割り当てID"
                },
                {
                    key: "person-full_name",
                    label: "要員名"
                   },
                   {
                    key: "work_company",
                    label: "稼働会社",
                   },
                   {
                    key: "case-hr-name_jp",
                    label: "HR担当者",
                   },
                   { key: 'person-company-company', 
                     label: '所属',
                   },
                   {
                    key: "person_id",
                    label: "社員ID",
                   },
                   { 
                    key : 'person-belong_contact_email',
                    label : '所属連絡メールアドレス'
                   },
                   {
                    key : 'person-belong_contact_tel',
                    label : "所属担当者電話番号"
                   },
                   {
                    key: "assigner-name_jp",
                    label: "割り当て担当",
                   },
                   {
                    key: "assign_start_date",
                    label: "割り当て開始日"
                   },
                   {
                    key: "case-case_id",
                    label: "案件ID"
                   },
                   {
                    key: "case-case_name",
                    label: "案件名"
                   },
                   {
                    key: "case-saler-name_jp",
                    label: "営業担当",
                   },  
                   {
                    key: "case-customer-name_jp",
                    label: "取引先",
                    },
                    {
                        key: "case-contact_name",
                        label: "取引先担当者",
                    },    
                    {
                        key: 'buyer_contract', 
                        label: "受注契約"
                    },
                    {
                        key : 'seller_contract', 
                        label : "発注契約"
                    },
                   {
                    key: "work_start_date",
                    label: "稼働開始日",
                   },
                   {
                    key: "work_place",
                    label: "勤務場所",
                   },
                   {
                    key: "work_end_date_plan",
                    label: "稼働終了予定日",
                   },
                  {
                    key: "contract_price_type",
                    label: "稼働単金種別",
                   },
                   {
                    key: "proposal_price",
                    label: "希望単価",
                   },
                   {
                    key: "person_cost",
                    label: "コスト",
                   },
                   {
                    key: "settlement",
                    label: "精算From",
                   },
                   {
                    key: "settlement_to",
                    label: "精算To",
                   },
                   {
                    key: "settlement_biko",
                    label: "精算備考",
                   },
                   {
                    key: "payment_site",
                    label: "支払サイト",
                   },
                   {
                    key: 'o_adj_hours_low',
                    label: "乙方精算From"
                   },
                   {
                    key: 'o_adj_hours_high',
                    label: "乙方精算To"
                   },
                   {
                    key: 'o_adj_hours_biko', 
                    label: "乙方精算備考"
                   },
                   {
                    key: 'o_payment_site',
                    label: "乙方支払サイト"
                   },
                   {
                    key: 'adoption_flg', 
                    label: "最終結果"
                   },
                   {
                    key: "approval_date",
                    label: "承認日",
                   },
                   {
                    key: "assign_end_date",
                    label: "割り当て終了日",
                   },
                   {
                    key: "flb_shared_cmt",
                    label: "社内共有メモ",
                   }
                  
              ];
            var now = new Date();
            return (
                <CSVLink  className="btn_standard text-decoration-none" filename={getFileName()} data={csvData}  headers={headers}>ダウンロード</CSVLink>
            );
          },
        textLabels: {
            body: {
              noMatch: "データはありません。",
              toolTip: "Sort"
            },
            pagination: {
              next: "Next Page",
              previous: "Previous Page",
              rowsPerPage: "件表示", 
              displayRows: "すべての件数：",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "行 を選択しました。",
                delete: "削除",
                deleteAria: "選択した行を削除しました。",
              },
          },
        rowsSelected: selectedRows,
        onRowSelectionChange: (rowsSelectedData:any, allRows:any, rowsSelected:any) => {
            setSelectedRows(rowsSelected);
        },
        onChangePage (currentPage:any) {
            changePage(currentPage);
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage (numberOfRows:any) {
            changeRowsPerPage(numberOfRows);
            setNoOfRows(numberOfRows);
        },
       
        onCellClick : (colData:any, cellMeta:any) =>{
            // goToDetail(colData, cellMeta);

        },
        onRowsDelete(rowData :any, rowState:any) {
            handleDelete(rowData, rowState);
        },
        onColumnSortChange: (changedColumn:any, direction:any) => {
            let order = 'desc';
            if (direction === 'asc') {
              order = 'asc';
            }
            handleSort(changedColumn, order);
          },
    };
 
    
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {

        switch (e.target.name) {
            case "search":
                    setSearch(e.target.value);
                    getData(approve,perPage,page+1,e.target.value, sort, column, order);
                break;
            default:
                break;
        }
        
    };  
    
    const makeApprove = () => {

        if(selectedRows.length == 0){
            alert("割り当てをチェックしてください。");
            return false;
        }
        
        var sList = "";

        let ids : any[] = [];
        assignment.forEach((element, index) => {            
            if (selectedRows.length > 0 && selectedRows.includes(index)) {
                ids.push(element["assignment_id"]);
            }
        });

        let data = ids.map((item) => item).join(', ');
        
        AssignmentService.assignmentApprove(data).then((data:any)=>{
            window.location.reload();
        });
    }

    let location = useLocation();
    const personDetail = function (encode:any) {
        localStorage.setItem('previousRoute', location.pathname);
        localStorage.setItem('rootRoute', location.pathname);
        window.location.href = `/persons/${encode}`;
    }
    const caseDetail = function (encode:any) {
        localStorage.setItem('rootRoute', location.pathname);
        localStorage.setItem('previousRoute', location.pathname);
        window.location.href = `/cases/${encode}`;
    }

    const edit_moda_box = (
        <div>
              <div className="row">
                    <div className="col-12">
                         <button type="button" className="modal_close" onClick={handleClose}>X</button>
                    </div>            
                </div>
                {/* <h3>案件割り当て編集</h3> */}
                <form className="form create-form" onSubmit={updatePersonAssign}>
                    {/* <BulkMailModal data={send_mail_arr} /> */}
                    <input type="hidden" name="assignment_id" value={assignmentApproveList["assignment_id"]} />
                    <input type="hidden" name="update_type" value={assignmentApproveList["update_type"]} />
                    <Row>
                        <Col xxl={3} xl={3} md={3} sm={3} xs={4}>
                            <label htmlFor="exampleInputEmail1">割り当てID:</label> 
                            <span style={{fontSize:"15px"}}>{assignmentApproveList["assignment_id"]}</span>
                        </Col>
                        <Col xxl={3} xl={3} md={3} sm={3} xs={8}>
                            <label htmlFor="exampleInputEmail1">要員名</label> 
                            <span style={{fontSize:"15px"}}>{show_person_nm}</span>
                        </Col>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={12}>
                            <label htmlFor="exampleInputEmail1">案件名</label> 
                            <span style={{fontSize:"15px"}}>{show_case_name}</span>
                        </Col>
                    </Row>
                    {/* <div className="form-group">
                        <label htmlFor="exampleInputEmail1">割り当てID:</label> 
                        <span style={{fontSize:"15px"}}>{assignmentApproveList["assignment_id"]}</span>
                    </div> */}
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">受注契約:</label> 
                                <select name="buyer_contract" onChange={onSelectAssignment} value={assignmentApproveList["buyer_contract"]} style={{width:'100%'}}>
                                    {buyerContractList.map(buyer_contract => {
                                            return(<option value={buyer_contract}> {buyer_contract}</option>)
                                        })
                                    }
                                </select>
                            </div>       
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">発注契約:</label> 
                                <select name="seller_contract" onChange={onSelectAssignment} value={assignmentApproveList["seller_contract"]} style={{width:'100%'}}>
                                {sellerContractList.map(seller_contract => {
                                            return(<option value={seller_contract}> {seller_contract}</option>)
                                        })
                                    }
                                </select>
                            </div>          
                        </div>
                    </div>                  
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">稼働会社:</label> 
                                <select name="work_company" onChange={onSelectAssignment}  value={(assignmentApproveList['work_company'] != null)?assignmentApproveList['work_company']:""} style={{width:'100%'}}>
                                    <option value="FloBoard">FloBoard</option>
                                    <option value="FloNet">FloNet</option>
                                </select>       
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">稼働開始日:</label> 
                                <DatePicker 
                                        value={(assignmentApproveList["work_start_date"] != null)?assignmentApproveList["work_start_date"]: ""}
                                        dateFormat="yyyy/MM/dd"
                                        locale="ja"
                                        selected={selectedWorkStartDate} 
                                        onChange={date =>
                                            handleDateChange(date, "work_start_date")
                                        }
                                        name="work_start_date"
                                        isClearable
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                />   
                            </div>           
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">勤務場所:</label> 
                                <input type="text" className="form-control" name="work_place"  value={(assignmentApproveList['work_place'] != null)?assignmentApproveList['work_place']:""}  onChange={updateInput} />
                            </div>   
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">稼働終了予定日:</label> 
                            <DatePicker 
                                    value={(assignmentApproveList["work_end_date_plan"] != null)?assignmentApproveList["work_end_date_plan"]: ""}
                                    dateFormat="yyyy/MM/dd"
                                    locale="ja"
                                    selected={selectedWorkEndDatePlan} 
                                    onChange={date =>
                                        handleDateChange(date, "work_end_date_plan")
                                    }
                                    name="work_end_date_plan"
                                    isClearable
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                            />            
                            </div>     
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="contract_price_type">稼働単金種別</label>
                                <select name="contract_price_type" id="type" onChange={onSelectAssignment} value={(assignmentApproveList["contract_price_type"] != null)?assignmentApproveList["contract_price_type"]: ''} style={{width:'100%'}}>
                                    {contractPriceType.map(contract_price_type => {
                                            return(<option value={contract_price_type[0]}> {contract_price_type[1]}</option>)
                                        })
                                    }
                                </select>                        
                            </div>         
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">希望単価:</label> 
                                <input type="number" className="form-control" name="proposal_price"  value={(assignmentApproveList['proposal_price'] != null)?assignmentApproveList['proposal_price']: ""}  onChange={updateInput} />
                            </div>        
                        </div>
                    </div>
                   <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">精算From:</label> 
                                <input type="text" className="form-control" name="settlement"  value={(assignmentApproveList['settlement'] != null)?assignmentApproveList['settlement']:""}  onChange={updateInput} />
                            </div>   
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">精算To:</label> 
                                <input type="text" className="form-control" name="settlement_to"  value={(assignmentApproveList['settlement_to'] != null)?assignmentApproveList['settlement_to']: ""}  onChange={updateInput} />
                            </div>             
                        </div>
                   </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">精算備考:</label> 
                                <input type="text" className="form-control" name="settlement_biko"  value={(assignmentApproveList['settlement_biko'] != null)?assignmentApproveList['settlement_biko']: ""}  onChange={updateInput} />
                            </div>             
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">乙方支払サイト:</label> 
                                <select name="payment_site" onChange={onSelectAssignment} value={(assignmentApproveList["payment_site"] != null)?assignmentApproveList["payment_site"]: ""} style={{width:'100%'}}>
                                    {paymentSiteList.map(payment_site => {
                                            return(<option value={payment_site}> {payment_site}</option>)
                                        })
                                    }
                                </select>  
                            </div>         
                        </div>
                    </div>  
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">コスト:</label> 
                                <input type="number" className="form-control" name="person_cost"  value={(assignmentApproveList['person_cost'] != null)?assignmentApproveList['person_cost']: ""}  onChange={updateInput} />          
                            </div>        
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <span id='text'>乙方精算From</span>
                                <input type="number" className="form-control" name="o_adj_hours_low"  value={(assignmentApproveList['o_adj_hours_low'] != null)?assignmentApproveList['o_adj_hours_low']: ""}  onChange={updateInput} />          
                            </div>           
                        </div>
                    </div>    
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <span id='text'>乙方精算To</span>
                                <input type="number" className="form-control" name="o_adj_hours_high"  value={(assignmentApproveList['o_adj_hours_high'] != null)?assignmentApproveList['o_adj_hours_high']: ""}  onChange={updateInput} />          
                            </div>           
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <span id='text'>乙方精算備考</span>
                                <input type="text" className="form-control" name="o_adj_hours_biko"  value={(assignmentApproveList['o_adj_hours_biko'] != null)?assignmentApproveList['o_adj_hours_biko']: ""}  onChange={updateInput} />          
                            </div>           
                        </div>
                    </div> 
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">乙方支払サイト:</label> 
                                <select name="payment_site" onChange={onSelectAssignment} value={(assignmentApproveList["payment_site"] != null)?assignmentApproveList["payment_site"]: ""} style={{width:'100%'}}>
                                    {oPaymentSiteList.map(payment_site => {
                                            return(<option value={payment_site}> {payment_site}</option>)
                                        })
                                    }
                                </select>  
                            </div>         
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label>社内共有メモ</label>
                                <textarea className="form-control" name = "flb_shared_cmt" id= "flb_shared_cmt" aria-label="With textarea" 
                                value={(assignmentApproveList["flb_shared_cmt"] != null)?assignmentApproveList["flb_shared_cmt"] : ''}  onChange={updateTextArea}></textarea>
                                <span id='star' className='error'></span>
                            </div>           
                        </div>
                    </div>                 
                    <div className="row">
                        <div className="form-group mt-5 text-center">
                            <button type="submit" className="button submit-btn btn_standard">更新</button>
                        </div>
                    </div>
                </form>
        </div>
    )

    return(
        <React.Fragment>
            <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                    ml: sizeConfigs.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color
                }}
                >
                 <Toolbar>
                <Typography variant="h6">
                    <header className="top-baner">
                        <Row>
                            <Col xxl={10} xl={10} md={10} sm={10} xs={10}>
                                <div id="search-autocomplete" className="form-outline">
                                    <input type="text" name="search" id="search" placeholder = "検索(案件ID、案件名、要員ID、要員名を検索)"
                                        className="form-control"  onChange={onChange}/>
                                </div>
                            </Col>
                            <Col xxl={2} xl={2} md={2} sm={2} xs={2} className="mt-4">
                                <DropDownList />
                            </Col>
                        </Row>
                    </header>
                    </Typography>
                </Toolbar>
                </AppBar>
                <Container>
                        <PageTitle loading={loading} title="要員割り当て承認" />
                        <Row className="p-2">
                             <Col xxl={3} xl={3} md={3} sm={6} xs={12} className="check-list xs-hidden" >
                                <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                                <select name="sort" id="sort" className="filter" onChange={onSelect}>
                                    <option value="0" selected>新しい順</option>
                                    <option value="1">古い順</option>
                                </select>
                            </Col>
                            <Col xxl={9} xl={9} md={9} sm={6} xs={12} className="filter_select">
                                <div className="float-right xs-disable-float">
                                    <label htmlFor="sort" className="control-label">承認状況</label>
                                    <select className="filter" name="approve" aria-label=".form-select-sm example" onChange={onSelect}>
                                    {approveList.map(approve_status => {
                                            return(<option value={approve_status["0"]}> {approve_status["1"]}</option>)
                                        })
                                    }
                                    </select> 
                                    <select name="list_length" id="list_length" value={no_of_rows} className="filter xs-hidden list_length" onChange={onSelect}>
                                        <option value="10">10</option>
                                        <option value="20" >20</option>
                                        <option value="50" >50</option>
                                        <option value="100" >100</option>
                                        <option value="200" >200</option>
                                    </select>
                                    <label htmlFor="sort" className="control-label xs-hidden">件表示</label>
                                    {/* <label htmlFor="sort" className="control-label ml_20 ">すべての件数：{totalRows}件</label> */}
                                    <button type="button" className="btn-sm previous-page-icon xs-hidden" style={{border:"0px"}} onClick={() => {
                                                        previousPage();
                                                        }}>
                                                    <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                                    </button>
                                                    <button type="button" className=" btn-sm next-page-icon xs-hidden" style={{border:"0px"}} onClick={() => {
                                                        nextPage();
                                                        }}>
                                                        <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                                    </button>                                 
                                    <button type="button" className="btn_standard ml_20 btn-sm " onClick={() => {
                                        makeApprove();
                                        }}>一括承認
                                    </button>
                                </div>
                            </Col>
                        </Row>
                        <Row className="p-2 pc-hidden">
                            <Col xs={12}>
                                <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                                <select name="sort" id="sort" className="filter" onChange={onSelect}>
                                    <option value="0" selected>新しい順</option>
                                    <option value="1">古い順</option>
                                </select>
                                <select name="list_length" id="list_length" value={no_of_rows} className="filter list_length" onChange={onSelect}>
                                        <option value="10">10</option>
                                        <option value="20" >20</option>
                                        <option value="50" >50</option>
                                        <option value="100" >100</option>
                                        <option value="200" >200</option>
                                </select>
                                <label htmlFor="sort" className="control-label">件表示</label>
                                <button type="button" className="btn-sm previous-page-icon" style={{border:"0px"}} onClick={() => {
                                    previousPage();
                                    }}>
                                <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                </button>
                                <button type="button" className=" btn-sm next-page-icon" style={{border:"0px"}} onClick={() => {
                                    nextPage();
                                    }}>
                                    <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                                </button>                                 
                            </Col>           
                        </Row>
                        <div className='datatable assign-approve-freeze-header assign-approve-freeze-cell assign-approve-frozen-columns xs-hidden'>
                            <MUIDataTable
                                title={''}
                                data={assignment}
                                columns={defaultcolumns}
                                options = {options}
                            />
                        </div>
                        <div className='datatable mobile pc-hidden'>
                            <MUIDataTable
                                title={''}
                                data={assignment}
                                columns={defaultcolumns}
                                options = {options}
                            />
                        </div>
                </Container>

        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'none', sm: 'block' },
            }}
        >                                
            <Box sx={PCModalStyle}>
               {edit_moda_box}
            </Box>
        </Modal>

        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'block', sm: 'none' },
            }}
        >                                
            <Box sx={MobileModalStyle}>
               {edit_moda_box}
            </Box>
        </Modal>
        </React.Fragment>
    );
};
export default AssignmentApproveList;