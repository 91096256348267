import React, {useEffect, useState} from 'react';
import ProspectService from "../../services/ProspectService";
import AuthService from "../../services/AuthService";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Topbar from "../../components/common/Topbar";
import BulkMailModal from "../../components/common/BulkMailModal";
import CustomerMailModal from "../../components/common/CustomerMailModal";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import {Link} from "react-router-dom";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import $ from 'jquery';
import { CSVLink, CSVDownload } from "react-csv";
import moment from 'moment';
import {SendBulkMail} from "../../models/Person";
import {SendCustomerMail} from "../../models/Person";
import Loader from "../../components/common/Loader";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageTitleWithButton from "../../components/common/PageTitleWithButton";

interface IState{
    prospects : [];
    errorMessage : string;  
    perPage : number;
    page : number;
    column: string;
    order: string;
    totalRows : number;
    partnertype_list : [];
    prospect_staff_list : [];
    mail_flg_list : [];
    upgrade_flg_list : [];
    koukata_partner_id : any;
    otsukata_partner_id : any;
    loading : boolean;
}
interface IProps{}
let PersonList:React.FC<IProps> = () => {

    const row : string[] = [];
    const [checkedRows, setCheckedRows] = useState(row);
    const [partner_type, setPartnerType] = useState("-1");  
    const [mail_flg, setMailFlg] = useState("-1");  
    const [upgradeflg, setUpgradeFlg] = useState("0");  
    const [prospect_staff_id, setProspectStaffId] = useState("");  
    const [search, setSearch] = useState("");  
    const [operationColumnChecked, setOperationColumnChecked] = useState<boolean>(false);
    const [mail_flg_update, setMailFlgUpdate] = useState("-1");  
    const [showPrevColor, setShowPrevColor] = useState("grey");
    const [showNextColor, setShowNextColor] = useState("grey");

    const defaultColumns = [
        {
            name: "id",
            label: "編集",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<Link to={`/prospect/${tableMeta.rowData[0]}/edit`} className="btn_standard btn-sm" style={{textDecoration:"none"}}>編集</Link>);
                    },
                },
           
        },
        { 
            name :'partner_id', 
            label : "パートナID",
            class: "longText",
            options: {
                filter: true, 
                // setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                        );
                    },
            },
        },
        { 
            name :'partner_type', 
            label : "種別",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "50px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                            {value}
                        </div>
                        );
                    },
            }
        },
        { 
            name :'prospect_staff_id', 
            label : "担当者",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                        );
                    },
            },
        },
        { 
            name :'company', 
            label : "会社名",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "150px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                        );
                    },
            },
        },
        { 
            name :'familyname', 
            label : "姓",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "80px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                },
        },
        { 
            name :'contact_email', 
            label : "メール",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                        );
                    },
            },
        },
        { 
            name :'mobilenumber', 
            label : "携帯電話"
        },
        { 
            name :'mail_flg', 
            label : "メール送信フラグ"
        },
        { 
            name :'casemail', 
            label : "客先メールアドレス",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                        );
                    },
            },
        },
        { 
            name :'personmail', 
            label : "要員メールアドレス",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                        );
                    },
            },
        },
        { 
            name :'prospect_status', 
            label : "見込み客ステータス",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px",textAlign:"center"}}),
            },
        },
        { 
            name :'social_id_social_login', 
            label : "ソーシャルログイン"
        },
        { 
            name :'create_date', 
            label : "登録日",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                        );
                    },
            },
        }
      ];

      const allColumns = [
        {
            name: "id",
            label: "編集",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<Link to={`/prospect/${tableMeta.rowData[0]}/edit`} className="btn_standard btn-sm">編集</Link>);
                    },
                },
           
        },
        { 
            name :'partner_id', 
            label : "ID",
            class: "longText",
            options: {
                filter: true, 
                // setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                        );
                    },
            },
        },
        { 
            name :'partner_type', 
            label : "種別",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "50px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                            {value}
                        </div>
                        );
                    },
            }
        },
        { 
            name :'prospect_staff_id', 
            label : "担当者",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                        );
                    },
            },
        },
        { 
            name :'company', 
            label : "会社名",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                        );
                    },
            },
        },
        { 
            name :'department', 
            label : "部門名"
        },
        { 
            name :'familyname', 
            label : "姓",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "80px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                },
        },
        { 
            name :'firstname', 
            label : "名"
        },
        { 
            name :'position', 
            label : "職位"
        },
        { 
            name :'contact_email', 
            label : "メール",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                        );
                    },
            },
        },
        { 
            name :'contact_tel', 
            label : "電話番号"
        },
        { 
            name :'mobilenumber', 
            label : "携帯電話"
        },
        { 
            name :'fax', 
            label : "FAX"
        },
        { 
            name :'prospectdatasource', 
            label : "見込み客のデータ元"
        },
        { 
            name :'industry', 
            label : "業界"
        },
        { 
            name :'url', 
            label : "ホームページ",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                        );
                    },
            },
        },
        { 
            name :'employeenumb', 
            label : "従業員数"
        },
        { 
            name :'annual_sales', 
            label : "年間売上"
        },
        { 
            name :'information', 
            label : "詳細情報"
        },
        { 
            name :'mail_flg', 
            label : "メール送信フラグ"
        },
        { 
            name :'casemail', 
            label : "客先メールアドレス",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                        );
                    },
            },
        },
        { 
            name :'personmail', 
            label : "要員メールアドレス",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                        );
                    },
            },
        },
        { 
            name :'prospect_status', 
            label : "見込み客ステータス",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px",textAlign:"center"}}),
            },
        },
        { 
            name :'startday', 
            label : "接触開始日",
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(value != "" && value != null){
                        value = value.substring(0, value.indexOf(" "));
                    }
                    return(value);
                    },
                },
        },
        { 
            name :'evaluation', 
            label : "評価"
        },
        { 
            name :'upgradeflg', 
            label : "アップグレードフラグ",
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "50px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(value != "" && value != null){
                        value = (value !== null && value.length >= 10)? value.substring(0, 10)+"...": value;
                    }
                    return(value);
                    },
                },
        },
        { 
            name :'social_id_social_login', 
            label : "ソーシャルログイン"
        },
        { 
            name :'post', 
            label : "郵便番号"
        },
        { 
            name :'address', 
            label : "住所",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                        );
                    },
            },
        },
        { 
            name :'remarks', 
            label : "特記事項",
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "50px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(value != "" && value != null){
                        value = (value !== null && value.length >= 10)? value.substring(0, 10)+"...": value;
                    }
                    return(value);
                    },
                },
        },
        { 
            name :'create_date', 
            label : "登録日",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                        );
                    },
            },
        },
      ];
    

    const getMuiTheme = () =>
    createTheme({
        components: {
        MUIDataTable: {
            styleOverrides:{
                responsiveScroll: {
                    maxHeight: 'none',
                  },
            }
        },
        MuiPaper: {
            styleOverrides:{
                root: {
                    width: "100%",
                    marginTop: "5%",
                    // // marginLeft: "5%",
                    // // marginBottom: "20%"
                }
            }
        },  
        MUIDataTableHeadCell: {
                styleOverrides:{
                  root: {
                      backgroundColor: "rgb(193,193,193)",
                      padding: '0px'
                  }
                }
        },
        // MuiTableCell: {
        //     head: {
        //         backgroundColor: "red !important"
        //     }
        // }

        MuiTableCell: {
            styleOverrides:{
                head: {
                        color : 'white',
                        backgroundColor: "red !important"
                }
            }
        },
          MUIDataTableBodyCell: {
            styleOverrides:{
              root: {
                  backgroundColor: "#f1f1f1",
                  width: "100%",
                  padding: '0px'
              }
            }
          }
        }
      })

    let [state , setState] = useState<IState>({
        prospects : [],
        errorMessage : "",
        page : 0,
        perPage : 10,
        column : "",
        order : "",
        totalRows : 0,
        partnertype_list : [],
        prospect_staff_list : [],
        mail_flg_list : [],
        upgrade_flg_list : [],
        koukata_partner_id : "",
        otsukata_partner_id : "",
        loading: false
    });

   
    const [columnVisible, setColumnVisible] = React.useState(defaultColumns);

    const [sort, setSort] = useState("0");
    const [selectedRows, setSelectedRows] = useState<any[]>([]);

    const [open, setOpenModal] = React.useState(false);
    const handleClose = () => setOpenModal(false); 

    const login_user =   JSON.parse(localStorage.getItem("user") || '{}');
  
 
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height: 700,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

   
    const getData = async (partner_type:any, mail_flg:string, upgradeflg:string,prospect_staff_id:string, perPage:number, page:number, search:any, sort:any, column:string, order:string) => {
        setState({...state,loading :true,})
        ProspectService.list(partner_type, mail_flg, upgradeflg, prospect_staff_id, perPage,page,search, sort, column, order).then((response)=>{
            setState({
                ...state,
                prospects: response.data.data,
                page : response.data.current_page,
                perPage : response.data.per_page,
                totalRows : response.data.total,
                partnertype_list : response.data.partnertype_list,
                prospect_staff_list :  response.data.prospect_staff_list,
                mail_flg_list :  response.data.mail_flg_list,
                upgrade_flg_list :  response.data.upgrade_flg_list,
                koukata_partner_id : response.data.koukata_prospect.partner_id,
                otsukata_partner_id : response.data.otsukata_prospect.partner_id,
                loading:false,
            })
            if(Math.floor(response.data.total/response.data.per_page) >0){
                setShowNextColor("blue");
            }else{
                setShowNextColor("grey");
            }
            if(0 == response.data.current_page){
                setShowPrevColor("grey");
            }
            // if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
            //     setShowNextColor("grey");
            // }
        }).catch((error:any) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
            setState({
                ...state,
                errorMessage:error.message,
                loading:false,
            })
        })
    }

    let {prospects, perPage, page, column, order, totalRows, errorMessage,  partnertype_list, prospect_staff_list, mail_flg_list, upgrade_flg_list,koukata_partner_id,otsukata_partner_id, loading} = state;

   
      useEffect(()=>{
        setState({...state,
            loading: true
        });
        getData(partner_type, mail_flg, upgradeflg, prospect_staff_id,perPage,page,search, sort, column, order);
        }, [perPage]);

    let changePage = (page:any) => {
        setCurrentPage(page);
        getData(partner_type, mail_flg, upgradeflg, prospect_staff_id,perPage,page+1,search, sort, column, order);
    };
    let changeRowsPerPage = (perPage:any) => {
        getData(partner_type, mail_flg, upgradeflg, prospect_staff_id,perPage,page,search, sort, column, order);
    };
    let emailSend = (event : React.FormEvent<HTMLFormElement>):void => { // click event type declare in React
        event.preventDefault();
        // PersonService.bulkMailsend(send_mail_arr).then((response) => {
        //     // window.location.reload();
        // })
    };
    let customerEmailSend = (event : React.FormEvent<HTMLFormElement>):void => { // click event type declare in React
        event.preventDefault();
        // PersonService.customerMailsend(send_customer_mail_arr).then((response) => {
        //     alert("メール送信いたしました。ありがとうございます。")
        // })
    };

    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        switch (e.target.name) {
            case "partner_type":
                setPartnerType(e.target.value);
                getData(e.target.value, mail_flg, upgradeflg, prospect_staff_id,perPage,page,search, sort, column, order);
                break;
            case "prospect_staff_id":
                setProspectStaffId(e.target.value);
                getData(partner_type, mail_flg, upgradeflg, e.target.value,perPage,page,search, sort, column, order);
                break;
            case "mail_flg":
                setMailFlg(e.target.value);
                getData(partner_type, e.target.value, upgradeflg, prospect_staff_id,perPage,page,search, sort, column, order);
                break;
            case "upgradeflg":
                setUpgradeFlg(e.target.value);
                getData(partner_type, mail_flg, e.target.value, prospect_staff_id,perPage,page,search, sort, column, order);
                break;
            case "sort":
                setSort(e.target.value);
                getData(partner_type, mail_flg, upgradeflg, prospect_staff_id,perPage,page,search, e.target.value, column, order);
                break;
            case "list_length":
                changeRowsPerPage(e.target.value);
                setNoOfRows(parseInt(e.target.value));
                setState({
                    ...state,
                    perPage : parseInt(e.target.value)

                })
                break;
            case "mail_flg_update":
                setMailFlgUpdate(e.target.value);
                break;
            default:
                break;
        }
     };

     const handleChange  = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.target.name) {
            case "operationColumn":
                if(e.target.checked == true){
                    setOperationColumnChecked(e.target.checked)
                    setColumnVisible(allColumns);
                }else{
                    setOperationColumnChecked(e.target.checked)
                    setColumnVisible(defaultColumns);
                }
            break;
            default:
            break;
        }
    };
            

    

    let updateMailFlag = () => {
        if(selectedRows.length == 0){
            alert("更新したいデータを選んでください。");
            return false;
        }
        if(mail_flg_update == "-1"){
            alert("メール送信フラグ設定を選択してください。");
            return false;
        }
        let ids : any[] = [];
        prospects.forEach((element, index) => {            
            if (selectedRows.length > 0 && selectedRows.includes(index)) {
                ids.push(element["id"]);
            }
        });

        let data = {};
        data["ids"] = ids;
        data['mail_flg'] = mail_flg_update;
        ProspectService.updateMailFlag(data).then((response:any)=>{
                if(response.data.success == false){
                    alert(response.data.info.message);
                    window.location.reload();
                }else{
                    alert(response.data.data[0]);
                    window.location.reload();
                }
        }).catch((error:any) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
            setState({
                ...state,
                errorMessage:error.message
            })
        })

   }

   const deleteProspect = () => {
    if(selectedRows.length == 0){
        alert("削除したい見込み客をチェックしてください。");
        return false;
    }
    let ans = window.confirm("削除を実施します、大丈夫ですか？");
    if(ans){
        let ids : any[] = [];
        prospects.forEach((element, index) => {            
            if (selectedRows.length > 0 && selectedRows.includes(index)) {
                ids.push(element["id"]);
            }
        });

        let data = {};
        data["ids"] = ids;
        ProspectService.deleteList(data).then((data)=>{
            window.location.reload();
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
        });
    }
   
}


let nextPage = () => {
        
    // if(Math.floor(totalRows/perPage) >0){
    //     setShowNextColor("blue");
    // }

    if(Math.floor(totalRows/perPage) >0){
        changePage(current_page+1);
        setCurrentPage(current_page+1);
        setState({
            ...state,
            page : current_page+1
        })
        setShowNextColor("blue");
    }
    
    if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
        setShowNextColor("grey");
    }   
    if(0 < current_page+1){
        setShowPrevColor("blue");
    }     

}

let previousPage = () => {
    if(current_page > 0){
        setCurrentPage(current_page-1);
        changePage(current_page - 1);
        setState({
            ...state,
            page : current_page-1
        });
        setShowPrevColor("blue");
    }
    

    if(0 == current_page-1){
        setShowPrevColor("grey");
    } 

    if(Math.floor(totalRows/perPage) >0){
        setShowNextColor("blue");
    } 
    
}


    let handleSort = (column:any, order:any) => {
        getData(partner_type, mail_flg, upgradeflg, prospect_staff_id,perPage,page,search, sort, column, order);
      };


    
    const [no_of_rows, setNoOfRows] = useState(10);
    const [current_page, setCurrentPage] = useState(0);

    const options = {
        filterType: 'checkbox' as any,
        tableBodyHeight : "360px",
        tableBodyMaxHeight : "360px",
        responsive: "standard" as any,
        selectableRows: "multiple" as any,
        selectableRowsHeader: true as any, 
        rowsPerPage: perPage,
        page : page,
        rowsPerPageOptions:[10,20,50,100,200],
        // selectableRowsOnClick: true  as any,
        count: totalRows,
        serverSide: true,
        filter : false,
        print: false,
        viewColumns : false,
        sort: true,
        search : false,
        download :false,
        // resizableColumns: true,
        customToolbarSelect: (selectedRows:any, displayData:any, setSelectedRows:any) => {
         
            const handleClick = () => {
                
                
            };
                let rows : any[] = [];
                selectedRows.data.forEach((element:any, index:any) => {
                    rows.push(element.dataIndex);
                })
                let csvData : any[] = [];
                prospects.forEach((element, index) => {
                    if (rows.includes(index)) {
                        csvData.push(element);
                    }
                });

                const getFileName = () => {
                    let now = new Date().toLocaleDateString();
                    let year = now.slice(0,4);
                    let month = '0' + now.slice(5,6);
                    let day = now.slice(7);
                    let date = year+month+day;
                    return "見込み客一覧" +date;
                }

                let headers  : any[]= [];
                
                if(operationColumnChecked == false){
                    headers =  [
                        { 
                            key :'partner_id', 
                            label : "パートナーID"
                        },
                        { 
                            key :'partner_type', 
                            label : "パートナー種別"
                        },
                        { 
                            key :'prospect_staff_id', 
                            label : "見込客の担当者"
                        },
                        { 
                            key :'company', 
                            label : "会社名"
                        },
                        { 
                            key :'familyname', 
                            label : "姓"
                        },
                        { 
                            key :'contact_email', 
                            label : "メール"
                        },
                        { 
                            key :'mobilenumber', 
                            label : "携帯電話"
                        },
                        { 
                            key :'mail_flg', 
                            label : "メール送信フラグ"
                        },
                        { 
                            key :'casemail', 
                            label : "客先メールアドレス"
                        },
                        { 
                            key :'personmail', 
                            label : "要員メールアドレ"
                        },
                        { 
                            key :'prospect_status', 
                            label : "見込み客ステータス"
                        },
                        { 
                            key :'create_date', 
                            label : "登録日",
                        },
                    ];
                }else{
                    headers =  [
                        { 
                            key :'partner_id', 
                            label : "パートナーID"
                        },
                        { 
                            key :'partner_type', 
                            label : "パートナー種別"
                        },
                        { 
                            key :'prospect_staff_id', 
                            label : "見込客の担当者"
                        },
                        { 
                            key :'company', 
                            label : "会社名"
                        },
                        { 
                            key :'department', 
                            label : "部門名"
                        },
                        { 
                            key :'familyname', 
                            label : "姓"
                        },
                        { 
                            key :'firstname', 
                            label : "名"
                        },
                        { 
                            key :'position', 
                            label : "職位"
                        },
                        { 
                            key :'contact_email', 
                            label : "メール"
                        },
                        { 
                            key :'contact_tel', 
                            label : "電話番号"
                        },
                        { 
                            key :'mobilenumber', 
                            label : "携帯電話"
                        },
                        { 
                            key :'fax', 
                            label : "FAX"
                        },
                        { 
                            key :'prospectdatasource', 
                            label : "見込み客のデータ元"
                        },
                        { 
                            key :'industry', 
                            label : "業界"
                        },
                        { 
                            key :'url', 
                            label : "ホームページ"
                        },
                        { 
                            key :'employeenumb', 
                            label : "従業員数"
                        },
                        { 
                            key :'annual_sales', 
                            label : "年間売上"
                        },
                        { 
                            key :'information', 
                            label : "詳細情報"
                        },
                        { 
                            key :'mail_flg', 
                            label : "メール送信フラグ"
                        },
                        { 
                            key :'casemail', 
                            label : "客先メールアドレス"
                        },
                        { 
                            key :'personmail', 
                            label : "要員メールアドレス"
                        },
                        { 
                            key :'prospect_status', 
                            label : "見込み客ステータス"
                        },
                        { 
                            key :'startday', 
                            label : "接触開始日",
                        },
                        { 
                            key :'evaluation', 
                            label : "評価"
                        },
                        { 
                            key :'upgradeflg', 
                            label : "アップグレードフラグ",
                        },
                        { 
                            key :'social_id_social_login', 
                            label : "ソーシャルログイン"
                        },
                        { 
                            key :'post', 
                            label : "郵便番号"
                        },
                        { 
                            key :'address', 
                            label : "住所"
                        },
                        { 
                            key :'remarks', 
                            label : "特記事項",
                        },
                        { 
                            key :'create_date', 
                            label : "登録日",
                        },
                    ];
                }

            var now = new Date();
            return (
                <CSVLink  className="btn_standard text-decoration-none" filename={getFileName()} data={csvData}  headers={headers}>ダウンロード</CSVLink>
            );
          },
        textLabels: {
            body: {
              noMatch: "データはありません。",
              toolTip: "Sort"
            },
            pagination: {
              next: "Next Page",
              previous: "Previous Page",
              rowsPerPage: "件表示", 
              displayRows: "すべての件数：",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "行 を選択しました。",
                delete: "削除",
                deleteAria: "選択した行を削除しました。",
              },
          },
        rowsSelected: selectedRows,
        onRowSelectionChange: (rowsSelectedData:any, allRows:any, rowsSelected:any) => {
            setSelectedRows(rowsSelected);
        },
        onChangePage (currentPage:any) {
            changePage(currentPage);
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage (numberOfRows:any) {
            changeRowsPerPage(numberOfRows);
            setNoOfRows(numberOfRows);
        },
        // onRowClick: (rowData:any, rowState:any) => {
        //    goToDetail(rowData, rowState);
        // },
        onCellClick : (colData:any, cellMeta:any) =>{
            // goToDetail(colData, cellMeta);

        },
        // onRowsDelete(rowData :any, rowState:any) {
        //     handleDelete(rowData, rowState);
        // },
        onColumnSortChange: (changedColumn:any, direction:any) => {
            let order = 'desc';
            if (direction === 'asc') {
              order = 'asc';
            }
            handleSort(changedColumn, order);
          },
    };
 
    
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.target.name) {
            case "search":
                    setSearch(e.target.value);
                    getData(partner_type, mail_flg, upgradeflg, prospect_staff_id,perPage,page,e.target.value, sort, column, order);
                    break;
            default:
                break;
        } 
    };  

    return(
        <React.Fragment>
            <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                    ml: sizeConfigs.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color
                }}
                >
                 <Toolbar>
                <Typography variant="h6">
                    <header className="top-baner">
                        <Row>
                            <Col xxl={10} xl={10} md={10} sm={10} xs={10}>
                                <div id="search-autocomplete" className="form-outline">
                                    <input type="text" name="search" id="search" placeholder = "検索(パートナーID、会社名、部門名、姓、名、メールで検索できる)"
                                        className="form-control"  onChange={onChange}/>
                                </div>
                            </Col>
                            <Col xxl={2} xl={2} md={2} sm={2} xs={2} className="mt-4">
                                <DropDownList />
                            </Col>
                        </Row>
                    </header>
                    </Typography>
                </Toolbar>
                </AppBar>
                <Container>
                    <PageTitleWithButton loading={loading} title="見込客一覧"  btnText="見込客追加" btnAction={`/prospect/create`}/>
                    <Row className="p-2">
                        <Col xxl={12} xl={12} md={12} sm={12} xs={12} className="filter_select check-list">
                            <label htmlFor="sort" className="control-label">パートナー種別</label>
                            <select className="filter" name="partner_type" aria-label=".form-select-sm example" onChange={onSelect}>
                                <option value="-1">全て</option>
                                    {partnertype_list.map((value, index) => {
                                    if(login_user.role==3 || login_user == 4){
                                        if(index == 2){
                                                return(<option value={index}> {value}</option>)
                                            }
                                        }else{
                                                return(<option value={index}> {value}</option>)
                                        }  
                                    })
                                }
                            </select>
                            {
                                (() => {
                                    if(login_user.role == 9) {
                                    return(
                                        <React.Fragment>
                                             <label htmlFor="sort" className="control-label">見込み客の担当者ID</label>
                                                <select className="filter" name="prospect_staff_id" aria-label=".form-select-sm example" onChange={onSelect}>
                                                    <option value="">全て</option>
                                                        {prospect_staff_list.map(prospect_staff => {
                                                            return(<option value={prospect_staff["user_id"]}> {prospect_staff["name_jp"]}</option>)
                                                        })
                                                    }
                                                </select>
                                        </React.Fragment>
                                    )
                                    }
                                })()
                            }
                          
                            <label htmlFor="sort" className="control-label">メール送信</label>
                            <select className="filter" name="mail_flg" aria-label=".form-select-sm example" onChange={onSelect}>
                                <option value="-1">全て</option>
                                    {mail_flg_list.map((value, index) => {
                                        return(<option value={index}> {value}</option>)
                                    })
                                }
                            </select>
                            <label htmlFor="sort" className="control-label">アップグレード</label>
                            <select className="filter" name="upgradeflg" aria-label=".form-select-sm example" onChange={onSelect}>
                                    {upgrade_flg_list.map((value, index) => {
                                        return(<option value={index}> {value}</option>)
                                    })
                                }
                            </select>
                            <button type="button" className="btn_danger ml_20 btn-sm" onClick={deleteProspect}>削除
                            </button>
                        </Col>
                    </Row>
                    <Row className="p-2">
                        <Col  xxl={12} xl={12} md={12} sm={12} xs={12}>
                            <input className="" type="checkbox" id="operationColumn" name="operationColumn" checked={operationColumnChecked} onChange={handleChange} style={{position:"relative",top:"1px"}}/> 
                            <label htmlFor="operationColumn">すべて表示</label>
                            <label htmlFor="sort" className="latest_partner" style={{paddingLeft: "20px"}}>甲方最新パートナID: {koukata_partner_id}</label>
                            <label htmlFor="sort" className="latest_partner" style={{paddingLeft: "20px"}}>乙方最新パートナID: {otsukata_partner_id}</label>
                        </Col>
                    </Row>
                    <Row className="pt-2 pb-2">
                        <Col xxl={4} xl={4} md={4} sm={4} xs={4} className="xs-hidden">
                            <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                            <select name="sort" id="sort" className="filter" onChange={onSelect}>
                                <option value="0" selected>新しい順</option>
                                <option value="1">古い順</option>
                            </select>
                        </Col>
                        <Col  xxl={8} xl={8} md={8} sm={8} xs={12} className="filter_select">
                                <div className="float-right">
                                    <label htmlFor="sort" className="control-label">メール送信フラグ設定</label>
                                    <select className="filter" name="mail_flg_update" aria-label=".form-select-sm example" onChange={onSelect}>
                                        <option value="-1">選択してください</option>
                                            {mail_flg_list.map((value, index) => {
                                                return(<option value={index}> {value}</option>)
                                            })
                                        }
                                    </select>
                                    <button type="button" className="btn_danger  btn-sm" onClick={() => {
                                        updateMailFlag();
                                        }}>メールフラグ更新
                                    </button> 
                                    <select name="list_length" id="list_length" value={no_of_rows} className="filter xs-hidden list_length" onChange={onSelect}>
                                        <option value="10">10</option>
                                        <option value="20" >20</option>
                                        <option value="50" >50</option>
                                        <option value="100" >100</option>
                                        <option value="200" >200</option>
                                    </select>
                                    <label htmlFor="sort" className="control-label xs-hidden">件表示</label>
                                    <button type="button" className="btn-sm previous-page-icon xs-hidden" style={{border:"0px"}} onClick={() => {
                                                        previousPage();
                                                        }}>
                                    <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                    </button>
                                    <button type="button" className=" btn-sm next-page-icon xs-hidden" style={{border:"0px"}} onClick={() => {
                                        nextPage();
                                        }}>
                                        <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                                    </button>
                                   
                                </div>
                            </Col>
                    </Row>
                    <Row className="pt-2 pb-2 pc-hidden">
                        <Col xs={12}>
                            <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                            <select name="sort" id="sort" className="filter" onChange={onSelect}>
                                <option value="0" selected>新しい順</option>
                                <option value="1">古い順</option>
                            </select>
                            <select name="list_length" id="list_length" value={no_of_rows} className="filter list_length" onChange={onSelect}>
                                <option value="10">10</option>
                                <option value="20" >20</option>
                                <option value="50" >50</option>
                                <option value="100" >100</option>
                                <option value="200" >200</option>
                            </select>
                            <label htmlFor="sort" className="control-label ">件表示</label>
                            <button type="button" className="btn-sm previous-page-icon " style={{border:"0px"}} onClick={() => {
                                                previousPage();
                                                }}>
                            <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                            </button>
                            <button type="button" className=" btn-sm next-page-icon " style={{border:"0px"}} onClick={() => {
                                nextPage();
                                }}>
                                <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                            </button>
                        </Col>
                    </Row>
                    <div className='datatable prospect-freeze-header prospect-freeze-cell prospect-frozen-columns xs-hidden'>
                        {/* <ThemeProvider> */}
                            <MUIDataTable
                                title={''}
                                data={prospects}
                                columns={columnVisible}
                                options = {options}
                            />
                        {/* </ThemeProvider> */}
                    </div>
                    
                    <div className="datatable mobile pc-hidden">
                        <MUIDataTable
                            title={''}
                            data={prospects}
                            columns={columnVisible}
                            options = {options}
                        />
                    </div>
                    
                </Container>
        
       
        </React.Fragment>
    );
};
export default PersonList;