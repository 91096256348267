import React, {useEffect, useState} from "react";
import ResourceService from '../../services/ResourceService'
type Props = {
    src: string,
    alt?: string,
    className?: string,
    style?: any,
    width?: string,
    height?: string,
    remoteSrc?: string,
    defSrc?: string
};
export default function ResourceImg(props:Props) {
    let [fileName, setFileName] = useState("");
    let [fileSrc, setFileSrc] = useState("");
    // 401 Unauthorized
    // 403 Forbidden
    // 404 Not Found
    // 405 Method Not Allowed
    async function load(filePath: string){
        let result = await ResourceService.apiFileBase64(filePath).then((res)=>{
            return res;
        });
        if( 200 === result.status ){
            let tmpSrc = "";
            if (result?.data?.data?.mime) {
                tmpSrc = `data:${result.data.data.mime};base64,${result.data.data.fileBase64}`;
                setFileName(result.data.data.basename);
            } else if(props?.defSrc) {
                tmpSrc = props.defSrc;
            }
            setFileSrc(tmpSrc);
        }
    }

    useEffect( () => {
        if (props.remoteSrc && props.remoteSrc.length > 0) {
            load(props.remoteSrc);
        }else if (props.src && props.src.length > 0){
            setFileSrc(props.src);
        }

        return () => {
            // cleanup
        }
    }, [props.remoteSrc]);

    return (
        <>
            <img 
                src={fileSrc} 
                alt={fileName} 
                className={props?.className} 
                style={props?.style}
                width={props?.width}
                height={props?.height}
            ></img>
        </>
    )
}