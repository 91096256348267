import React, {useState , useEffect} from 'react';
// import {User} from "../../models/User";
import InterviewReminderService from "../../services/InterviewReminderService";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import { CSVLink, CSVDownload } from "react-csv";
import {Link} from "react-router-dom";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import Loader from "../../components/common/Loader";
import {useLocation} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface IState{
    loading :boolean;
    reminders  :  [];
    errorMessage : string;  
    perPage : number;
    page : number;
    column: string;
    order: string;
    totalRows : number;
    staff_list : [];
}
interface IProps{}

let ReminderList:React.FC<IProps> = () =>{
    
    let [state, setState] = useState<IState>({
        loading : false,
        reminders :  [],
        errorMessage : "",
        page : 0,
        perPage : 10,
        column : "",
        order : "",
        totalRows : 0,
        staff_list : []
    });
    const [search, setSearch] = useState("");  
    const [company_filter, setCompanyFilter] = useState("");  
    const [staff_filter, setStaffFilter] = useState("");
    const login_user =   JSON.parse(localStorage.getItem("user") || '{}');
    const [showPrevColor, setShowPrevColor] = useState("grey");
    const [showNextColor, setShowNextColor] = useState("grey");
    const defaultColumns = [
        {
            name: "id",
            label: "編集",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<Link to={`/interview/reminder/${tableMeta.rowData[0]}/edit`} className="btn_standard btn-sm" style={{textDecoration:"none"}}>
                         編集</Link>);
                    },
                },
           
        },
        {
            "name" : 'id', 
            "label" : "ID"
        },
        {
            "name" : 'staff_name',
            "label" : "担当者",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "80px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                },
        },
        {
            name: "person_encode",
            label: "Encode",
            "options":{
                "display":false,
             }
        },
        {
            "name" : 'person_name', 
            "label" : "要員名",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to="" onClick={() =>personDetail(tableMeta.rowData[3])} >
                         <div className='longText' title={value} style={{color:"blue"}}>
                            {value}
                        </div>
                        </Link>);
                    },
                },
        },
        {
            name: "case_encode",
            label: "Encode",
            "options":{
                "display":false,
             }
        },
        {
            "name" : 'case_name', 
            "label" : "案件の名",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "250px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to="" onClick={() =>caseDetail(tableMeta.rowData[5])} >
                        <div className='longText' title={value} style={{color:"blue"}}>
                            {value}
                        </div>
                        </Link>);
                    },
                },
        },
        {
            "name" : 'title', 
            "label" : "タイトル",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                       );
                    },
                },
        },
        {
            "name" : 'description', 
            "label" : "概要",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                       );
                    },
                },
        },//
        {
            "name" : 'phone', 
            "label" : "電話番号",
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                       );
                    },
                },
        },
        {
            "name" : 'start_dt', 
            "label" : "開始日時",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width : "100px"}}>
                            {value}
                        </div>
                       );
                    },
                },
        },//
        {
            "name" : 'notify_dt', 
            "label" : "SMS通知日時",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width : "100px"}}>
                            {value}
                        </div>
                       );
                    },
                },
        },//
        {
            "name" : 'sms_voice_flg', 
            "label" : "通知型"
        },
        {
            "name" : 'notified_flg', 
            "label" : "通知済"
        },
        
    ]
    const [calendar_data, setCalendarData] = useState("");  


    const getData = async (company_filter:string, perPage:number, page:number, search:any, sort:any, column:string, order:string) => {
        setState({...state,loading :true,})
        InterviewReminderService.list(company_filter, perPage, page, search, sort, column, order).then((response)=>{
            setState({
                ...state,
                loading:false,
                reminders:response.data.data,
                page : response.data.current_page,
                column : response.data.column,
                order : response.data.order,
                perPage : response.data.per_page,
                totalRows : response.data.total,
                staff_list : response.data.staff_list
            });
            setCalendarData(JSON.parse(response.data.calender_data));
            if(Math.floor(response.data.total/response.data.per_page) >0){
                setShowNextColor("blue");
            }else{
                setShowNextColor("grey");
            }
            if(0 == response.data.current_page-1){
                setShowPrevColor("grey");
            }
            if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
                setShowNextColor("grey");
            }
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
            setState({
                ...state,
                loading:false,
                errorMessage:error.message
            })
        })
    }   
        
    let {loading, reminders, perPage, page, column, order, totalRows, staff_list, errorMessage} = state;
    const [selected, setSelected] = useState('base');
    const [sort, setSort] = useState("0");
    const [no_of_rows, setNoOfRows] = useState(10);
    const [current_page, setCurrentPage] = useState(0);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
  
 
    useEffect(()=>{
        setState({...state, loading: true});
        getData(company_filter,perPage, page, search, sort, column, order);
    }, [perPage]);
    let changePage = (page:any) => {
        setCurrentPage(page);
        getData(company_filter,perPage, page+1, search, sort, column, order);
    };
    let changeRowsPerPage = (perPage:any) => {
        getData(company_filter,perPage, page, search, sort, column, order);
    };
    let handleSort = (column:any, order:any) => {
        let temp = {};
        temp["column"] = column;
        temp["order"] = order;
        getData(company_filter,perPage, page, search, sort, column, order);
    };

    
    const deleteReminder = () => {
        if(selectedRows.length == 0){
            alert("削除したい面談通知情報をチェックしてください。");
            return false;
        }
        let ans = window.confirm("削除を実施します、大丈夫ですか？");
        if(ans){
            let ids : any[] = [];
            reminders.forEach((element, index) => {            
                if (selectedRows.length > 0 && selectedRows.includes(index)) {
                    ids.push(element["id"]);
                }
            });
    
            let data = {};
            data["ids"] = ids;
            InterviewReminderService.delete(data).then((data)=>{
                if(data.data.success == false){
                    alert(data.data.info.message);
                }
                window.location.href = "/interview/reminders";
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
            });
        }
    }


    const options = {
        filterType: 'checkbox' as any,
        tableBodyHeight : "360px",
        tableBodyMaxHeight : "360px",
        responsive: "standard" as any,
        selectableRows: "multiple" as any,
        selectableRowsHeader: true as any, 
        rowsPerPage: perPage,
        page: page,
        rowsPerPageOptions:[10,20,50,100,200],
        // selectableRowsOnClick: true  as any,
        count: totalRows,
        serverSide: true,
        filter : false,
        print: false,
        viewColumns : false,
        sort: true,
        search : false,
        download :false,
        // resizableColumns: true,
        customToolbarSelect: (selectedRows:any, displayData:any, setSelectedRows:any) => {         
            //     let rows : any[] = [];
            //     selectedRows.data.forEach((element:any, index:any) => {
            //         rows.push(element.dataIndex);
            //     })
            //     let csvData : any[] = [];
            //     reminders.forEach((element, index) => {
            //             if (rows.includes(index)) {
            //                 csvData.push(element);
            //             }
            //     });
            // const getFileName = () => {
            //         let now = new Date().toLocaleDateString();
            //         let year = now.slice(0,4);
            //         let month = '0' + now.slice(5,6);
            //         let day = now.slice(7);
            //         let date = year+month+day;
            //         return "面談通知情報" +date;
            //     }
            // let headers = [
            //     {
            //         "key" : 'id', 
            //         "label" : "ID"
            //     },
            //     {
            //         "key" : 'staff_name',
            //         "label" : "担当者"
            //     },
            //     {
            //         "key" : 'person_name', 
            //         "label" : "要員名"
            //     },
            //     {
            //         "key" : 'case_name', 
            //         "label" : "案件の名",
            //     },
            //     {
            //         "key" : 'title', 
            //         "label" : "タイトル"
            //     },
            //     {
            //         "key" : 'description', 
            //         "label" : "概要"
            //     },
            //     {
            //         "key" : 'phone', 
            //         "label" : "電話番号"
            //     },
            //     {
            //         "key" : 'start_dt', 
            //         "label" : "開始日時"
            //     },
            //     {
            //         "key" : 'notify_dt', 
            //         "label" : "SMS通知日時"
            //     },
            //     {
            //         "key" : 'sms_voice_flg', 
            //         "label" : "通知型"
            //     },
            //     {
            //         "key" : 'notified_flg', 
            //         "label" : "通知済"
            //     },
            //   ];
            // var now = new Date();
            // return (
            //     <CSVLink  className="btn_standard text-decoration-none" filename={getFileName()} data={csvData}  headers={headers}>ダウンロード</CSVLink>
            // );
            return (<div></div>);
          },
        textLabels: {
            body: {
              noMatch: "データはありません。",
              toolTip: "Sort"
            },
            pagination: {
              next: "Next Page",
              previous: "Previous Page",
              rowsPerPage: "件表示", 
              displayRows: "すべての件数：",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "行 を選択しました。",
                delete: "削除",
                deleteAria: "選択した行を削除しました。",
              },
          },
        rowsSelected: selectedRows,
        onRowSelectionChange: (rowsSelectedData:any, allRows:any, rowsSelected:any) => {
            setSelectedRows(rowsSelected);
        },
        onChangePage (currentPage:any) {
            changePage(currentPage);
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage (numberOfRows:any) {
            changeRowsPerPage(numberOfRows);
            setNoOfRows(numberOfRows);
        },
        onColumnSortChange: (changedColumn:any, direction:any) => {
            let order = 'desc';
            if (direction === 'asc') {
              order = 'asc';
            }
            handleSort(changedColumn, order);
          },
    };
 
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => { 
        switch (e.target.name) {
            case "search":
                    setSearch(e.target.value);
                    getData(company_filter, state.perPage, state.page, e.target.value, sort, column, order);              
                break;
            default:
                break;
        }
      };
      const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        switch (e.target.name) {
            case "company_filter":
                setCompanyFilter(e.target.value);
                getData(e.target.value, state.perPage, state.page, search, sort, column, order);              
                break;
            case "sort":
                setSort(e.target.value);
                getData(company_filter, state.perPage, state.page, search, e.target.value, column, order);              
                break;
            case "list_length":
                changeRowsPerPage(e.target.value);
                setNoOfRows(parseInt(e.target.value));
                setState({
                    ...state,
                    perPage : parseInt(e.target.value)

                })
                break;
                
            default:
                break;
        }
     };

     let nextPage = () => {
        
        // if(Math.floor(totalRows/perPage) >0){
        //     setShowNextColor("blue");
        // }

        if(Math.floor(totalRows/perPage) >0){
            changePage(current_page+1);
            setCurrentPage(current_page+1);
            setState({
                ...state,
                page : current_page+1
            })
            setShowNextColor("blue");
        }
        
        if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
            setShowNextColor("grey");
        }   
        if(0 < current_page+1){
            setShowPrevColor("blue");
        }     

    }

    let previousPage = () => {
        if(current_page > 0){
            setCurrentPage(current_page-1);
            changePage(current_page - 1);
            setState({
                ...state,
                page : current_page-1
            });
            setShowPrevColor("blue");
        }
        

        if(0 == current_page-1){
            setShowPrevColor("grey");
        } 

        if(Math.floor(totalRows/perPage) >0){
            setShowNextColor("blue");
        } 
        
    }

     const getMuiTheme = () =>
     createTheme({
         components: {
         MUIDataTable: {
             styleOverrides:{
                 responsiveScroll: {
                     maxHeight: 'none',
                   },
             }
         },
         MuiPaper: {
             styleOverrides:{
                 root: {
                     width: "100%",
                    //  marginTop: "5%",
                    //  marginLeft: "5%",
                    //  marginBottom: "20%"
                 }
             }
         },  
         MUIDataTableHeadCell: {
                 styleOverrides:{
                   root: {
                       backgroundColor: "#444",
                       padding: '0px'
                   }
                 }
         },
         MuiTableCell: {
             styleOverrides:{
                 head: {
                         color : 'white'
                 }
             }
         },
           MUIDataTableBodyCell: {
             styleOverrides:{
               root: {
                   backgroundColor: "#f1f1f1",
                   width: "100%",
                   padding: '0px'
               }
             }
           }
         }
       })
     
    
    var d = new Date();
	var month = d.getMonth()+1;
	var day = d.getDate();
	
	var output = d.getFullYear() + '-' +
		(month<10 ? '0' : '') + month + '-' +
		(day<10 ? '0' : '') + day;


    let location = useLocation();
    const personDetail = function (encode:any) {
        localStorage.setItem('previousRoute', location.pathname);
        localStorage.setItem('rootRoute', location.pathname);
        window.location.href = `/persons/${encode}`;
    }
    const caseDetail = function (encode:any) {
        localStorage.setItem('rootRoute', location.pathname);
        localStorage.setItem('previousRoute', location.pathname);
        window.location.href = `/cases/${encode}`
    }
    return(
        
       <React.Fragment>
       <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                    ml: sizeConfigs.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color
                }}
                >
                 <Toolbar>
                <Typography variant="h6">
                    <header className="top-baner">
                        <Row>
                            <Col xxl={10} xl={10} md={10} sm={10} xs={10}>
                                <div id="search-autocomplete" className="form-outline">
                                    <input type="text" name="search" id="search" placeholder = ""
                                        className="form-control"  onChange={onChange}/>
                                </div>
                            </Col>
                            <Col xxl={2} xl={2} md={2} sm={2} xs={2} className="mt-4">
                                <DropDownList />
                            </Col>
                        </Row>
                    </header>
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container>
                <Row>
                    <Col xs={12} className="pc-hidden">
                        <div className="mt-3 text-center">
                            {( loading   == true) ? <Loader /> :<> </>}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                        <h3 className='header-left-design'>
                            面談通知情報一覧
                        </h3>
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={4} className="xs-hidden">
                        <div className="mt-3 text-center">
                            {( loading   == true) ? <Loader /> :<> </>}
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={6} className="float-end">
                        <div className="mt-4">
                            <button type="button" className="btn_danger float-right btn-sm ml_1"  onClick={deleteReminder}>削除</button>
                            <Link to={`/interview/reminders/create`} className="btn_add">
                            追加
                            </Link>
                        </div>
                    </Col>
                </Row> 
                <Row className="pt-2 pb-2">
                        <Col xxl={4} xl={4} md={4} sm={4} xs={6} className="xs-hidden">
                            <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                            <select name="sort" id="sort" className="filter" onChange={onSelect}>
                                <option value="0" selected>新しい順</option>
                                <option value="1">古い順</option>
                            </select>
                        </Col>
                        <Col xxl={8} xl={8} md={8} sm={8} xs={6} className="filter_select">
                                <div className="float-right">
                                {
                                    (() => {
                                        if(login_user.role!=8) {
                                        return(
                                            <React.Fragment>
                                                <label className="control-label">担当者</label>
                                                <select name="company_filter" className="filter" onChange={onSelect} aria-label=".form-select-sm example">
                                                    <option value=""> 未選択</option>
                                                    {staff_list.map(staff => {
                                                            if (staff !== null){
                                                                return(<option value={staff['user_id']}> {staff['name_jp']}</option>)
                                                            }
                                                        })
                                                    }
                                                </select>
                                            </React.Fragment>
                                        )
                                        }
                                    })()
                                }
                                <select name="list_length" id="list_length" value={no_of_rows} className="filter xs-hidden list_length" onChange={onSelect}>
                                    <option value="10">10</option>
                                    <option value="20" >20</option>
                                    <option value="50" >50</option>
                                    <option value="100" >100</option>
                                    <option value="200" >200</option>
                                </select>
                                <label htmlFor="sort" className="control-label xs-hidden">件表示</label>
                                <button type="button" className="btn-sm previous-page-icon xs-hidden" style={{border:"0px"}} onClick={() => {
                                        previousPage();
                                        }}>
                                    <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                    </button>
                                    <button type="button" className=" btn-sm next-page-icon xs-hidden" style={{border:"0px"}} onClick={() => {
                                        nextPage();
                                        }}>
                                        <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                                </button>
                            </div>
                        </Col>
                    </Row>
                    <Row className="pt-2 pb-2">
                        <Col xs={12} className="pc-hidden">
                            <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                            <select name="sort" id="sort" className="filter" onChange={onSelect}>
                                <option value="0" selected>新しい順</option>
                                <option value="1">古い順</option>
                            </select>                            
                            <select name="list_length" id="list_length" value={no_of_rows} className="filter list_length" onChange={onSelect}>
                                <option value="10">10</option>
                                <option value="20" >20</option>
                                <option value="50" >50</option>
                                <option value="100" >100</option>
                                <option value="200" >200</option>
                            </select>
                            <label htmlFor="sort" className="control-label">件表示</label>
                            <button type="button" className="btn-sm previous-page-icon" style={{border:"0px"}} onClick={() => {
                                    previousPage();
                                    }}>
                                <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                </button>
                                <button type="button" className=" btn-sm next-page-icon" style={{border:"0px"}} onClick={() => {
                                    nextPage();
                                    }}>
                                    <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                            </button>
                        </Col>
                    </Row>
                            
                    <div className='datatable freeze-reminder-header freeze-reminder-cell reminder-frozen-columns xs-hidden'>
                        <MUIDataTable
                            title = {""}
                            data = {reminders}
                            columns={defaultColumns}
                            options={options}
                        />
                    </div>
                    <div className='datatable mobile pc-hidden'>
                        <MUIDataTable
                            title = {""}
                            data = {reminders}
                            columns={defaultColumns}
                            options={options}
                        />
                    </div>
                <Row className="mt-3">
                    <div className="col-12">
                            <FullCalendar
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                            initialView="dayGridMonth"
                            weekends={true}
                            events= {
                                calendar_data
                            }
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
                            }}
                            initialDate  = {
                                output
                            }
                            navLinks = {true}
                            businessHours ={ true} 
                            editable = {false}
                            selectable = {true}	
                            eventTimeFormat = {{ // like '14:30:00'
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                hour12: false
                              }}
                            />
                    </div>
                </Row>
            </Container>
       
        </React.Fragment>
    )

}

export default ReminderList;