import React, {useState , useEffect} from 'react';
// import {User} from "../../models/User";
import InterviewReminderService from "../../services/InterviewReminderService";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import { CSVLink, CSVDownload } from "react-csv";
import {Link} from "react-router-dom";
import GroupRoomService from '../../services/GroupRoomService';
import Loader from "../../components/common/Loader";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface IState{
    loading :boolean;
    reminders  :  [];
    errorMessage : string;  
    perPage : number;
    page : number;
    column: string;
    order: string;
    totalRows : number;
}
interface IProps{}

let GroupRoomList:React.FC<IProps> = () =>{
    
    let [state, setState] = useState<IState>({
        loading : false,
        reminders :  [],
        errorMessage : "",
        page : 0,
        perPage : 10,
        column : "",
        order : "",
        totalRows : 0,
    });
    const [search, setSearch] = useState("");
    const defaultColumns = [
        {
            name: "room_id",
            label: "編集",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<Link to={`/interview/grouproom/${tableMeta.rowData[0]}/edit`} className="btn_standard btn-sm" style={{textDecoration:"none"}}>
                         編集</Link>);
                    },
                },
           
        },
        {
            name: "open",
            label: "開始",
            options: {
                filter: true, 
                sort : false,
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(tableMeta.rowData[4]!= 1){
                        return(<Link to={`grouprooms/open/${tableMeta.rowData[3]}`} className="btn_standard ml_20 btn-sm">
                         開始</Link>);
                    }
                    },
                }
        },
        {
            name: "room_id",
            label: "会議室ID",
            options: {
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
            }
        },
        {
            name: "room_type",
            label: "会議室形",
            options: {
                filter: true, 
                setCellProps: () => ({ style: {maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(tableMeta.rowData[4]=="go"){
                        return("1-1台会議")
                    }else{
                        return("グループ会議")
                    }
                    },
                }
        },
        {
            name: "room_status",
            label: "ステータス",
            options: {
                filter: true, 
                setCellProps: () => ({ style: {maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(tableMeta.rowData[5]== 1){
                        return("開始済み")
                    }else{
                        return("未開始")
                    }
                    },
                }
        },
    ]

    const getData = async (perPage:number,page:number,sort:any, column:string, order:string) => {
        setState({...state,loading :true,})
        GroupRoomService.list(perPage,page,sort, column, order).then((response)=>{
            setState({
                ...state,
                loading:false,
                reminders:response.data.data,
                page : response.data.current_page,
                column : response.data.column,
                order : response.data.order,
                perPage : response.data.per_page,
                totalRows : response.data.total,
            })
            if(Math.floor(response.data.total/response.data.per_page) >0){
                setShowNextColor("blue");
            }else{
                setShowNextColor("grey");
            }
            if(0 == response.data.current_page-1){
                setShowPrevColor("grey");
            }
            if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
                setShowNextColor("grey");
            }
        }).catch((error) => {
            setState({
                ...state,
                loading:false,
                errorMessage:error.message
            })
        })
    }   
        
    let {loading, reminders, perPage, page, column, order, totalRows, errorMessage} = state;
    const [selected, setSelected] = useState('base');
    const [sort, setSort] = useState("0");
    const [showPrevColor, setShowPrevColor] = useState("grey");
    const [showNextColor, setShowNextColor] = useState("grey");
    const [no_of_rows, setNoOfRows] = useState(10);
    const [current_page, setCurrentPage] = useState(0);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
  
 
    useEffect(()=>{
        setState({...state, loading: true});
        getData(perPage,page,sort,column,order);
    }, [perPage]);
    let changePage = (page:any) => {
        setCurrentPage(page);
        getData(perPage, page+1,sort, column, order);
    };
    let changeRowsPerPage = (perPage:any) => {
        getData(perPage, page, sort, column, order);
    };
    let handleSort = (column:any, order:any) => {
        let temp = {};
        temp["column"] = column;
        temp["order"] = order;
        getData(perPage, page, sort, column, order);
    };

    const deleteReminder = () => {
        if(selectedRows.length == 0){
            alert("削除したい面談通知情報をチェックしてください。");
            return false;
        }
        let ans = window.confirm("削除を実施します、大丈夫ですか？");
        if(ans){
            let ids : any[] = [];
            reminders.forEach((element, index) => {            
                if (selectedRows.length > 0 && selectedRows.includes(index)) {
                    ids.push(element["id"]);
                }
            });
    
            let data = {};
            data["ids"] = ids;
            GroupRoomService.delete(data).then((data)=>{
                if(data.data.success == false){
                    alert(data.data.info.message);
                }
                window.location.href = "/interview/grouproom";
            }).catch((error) => {
                console.log(error);
            });
        }
    }


    const options = {
        filterType: 'checkbox' as any,
        tableBodyHeight : "360px",
        tableBodyMaxHeight : "360px",
        responsive: "standard" as any,
        selectableRows: "multiple" as any,
        selectableRowsHeader: true as any, 
        rowsPerPage: perPage,
        page : page,
        rowsPerPageOptions:[10,20,50,100,200],
        // selectableRowsOnClick: true  as any,
        count: totalRows,
        serverSide: true,
        filter : false,
        print: false,
        viewColumns : false,
        sort: true,
        search : false,
        download :false,
        // resizableColumns : true,
        customToolbarSelect: (selectedRows:any, displayData:any, setSelectedRows:any) => {         
                let rows : any[] = [];
                selectedRows.data.forEach((element:any, index:any) => {
                    rows.push(element.dataIndex);
                })
                return ("");
          },
        textLabels: {
            body: {
              noMatch: "データはありません。",
              toolTip: "Sort"
            },
            pagination: {
              next: "Next Page",
              previous: "Previous Page",
              rowsPerPage: "件表示", 
              displayRows: "すべての件数：",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "行 を選択しました。",
                delete: "削除",
                deleteAria: "選択した行を削除しました。",
              },
          },
        rowsSelected: selectedRows,
        onRowSelectionChange: (rowsSelectedData:any, allRows:any, rowsSelected:any) => {
            setSelectedRows(rowsSelected);
        },
        onChangePage (currentPage:any) {
            changePage(currentPage);
            setCurrentPage(currentPage);
        },
        // onChangeRowsPerPage (numberOfRows:any) {
        //     changeRowsPerPage(numberOfRows);
        //     setNoOfRows(numberOfRows);
        // },
        onColumnSortChange: (changedColumn:any, direction:any) => {
            let order = 'desc';
            if (direction === 'asc') {
              order = 'asc';
            }
            handleSort(changedColumn, order);
          },
    };
 
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => { 
        switch (e.target.name) {
            case "search":
                    setSearch(e.target.value);
                    getData(perPage,page,sort,column,order);              
                break;
            default:
                break;
        }
      };
      const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        switch (e.target.name) {
            case "sort":
                setSort(e.target.value);
                getData(perPage,page,e.target.value,column,order);              
                break;
            case "list_length":
                changeRowsPerPage(e.target.value);
                setNoOfRows(parseInt(e.target.value));
                setState({
                    ...state,
                    perPage : parseInt(e.target.value)
                })
                break;
                
            default:
                break;
        }
     };

     let nextPage = () => {
        
        // if(Math.floor(totalRows/perPage) >0){
        //     setShowNextColor("blue");
        // }

        if(Math.floor(totalRows/perPage) >0){
            changePage(current_page+1);
            setCurrentPage(current_page+1);
            setState({
                ...state,
                page : current_page+1
            })
            setShowNextColor("blue");
        }
        
        if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
            setShowNextColor("grey");
        }   
        if(0 < current_page+1){
            setShowPrevColor("blue");
        }     

    }

    let previousPage = () => {
        if(current_page > 0){
            setCurrentPage(current_page-1);
            changePage(current_page - 1);
            setState({
                ...state,
                page : current_page-1
            });
            setShowPrevColor("blue");
        }
        

        if(0 == current_page-1){
            setShowPrevColor("grey");
        } 

        if(Math.floor(totalRows/perPage) >0){
            setShowNextColor("blue");
        } 
        
    }

     const getMuiTheme = () =>
     createTheme({
         components: {
         MUIDataTable: {
             styleOverrides:{
                 responsiveScroll: {
                     maxHeight: 'none',
                   },
             }
         },
         MuiPaper: {
             styleOverrides:{
                 root: {
                     width: "100%",
                    //  marginTop: "5%",
                    //  marginLeft: "5%",
                    //  marginBottom: "20%"
                 }
             }
         },  
         MUIDataTableHeadCell: {
                 styleOverrides:{
                   root: {
                       backgroundColor: "#444",
                       padding: '0px'
                   }
                 }
         },
         MuiTableCell: {
             styleOverrides:{
                 head: {
                         color : 'white'
                 }
             }
         },
           MUIDataTableBodyCell: {
             styleOverrides:{
               root: {
                   backgroundColor: "#f1f1f1",
                   width: "100%",
                   padding: '0px'
               }
             }
           }
         }
       })
     
    
    return(
        
       <React.Fragment>
       <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                    ml: sizeConfigs.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color
                }}
                >
                 <Toolbar>
                <Typography variant="h6">
                    <header className="top-baner">
                        <Row>
                            <Col xxl={10} xl={10} md={10} sm={10} xs={10}>
                                <div id="search-autocomplete" className="form-outline">
                                    <input type="text" name="search" id="search" placeholder = "検索(会社ID、会社名、社長、担当者、事務担当、特記事項を検索)"
                                        className="form-control"  onChange={onChange}/>
                                </div>
                            </Col>
                            <Col xxl={2} xl={2} md={2} sm={2} xs={2} className="mt-4">
                                <DropDownList />
                            </Col>
                        </Row>
                    </header>
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container>
                    <Row>
                        <Col xs={12} className="pc-hidden">
                            <div className="mt-3 text-center">
                                {( loading   == true) ? <Loader /> :<> </>}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                            <h3 className='header-left-design'>
                            会議室情報一覧
                            </h3>
                        </Col>
                        <Col xxl={4} xl={4} md={4} sm={4} xs={4} className="xs-hidden">
                            <div className="mt-3 text-center">
                                {( loading   == true) ? <Loader /> :<> </>}
                            </div>
                        </Col>
                        <Col xxl={4} xl={4} md={4} sm={4} xs={6} className="float-end">
                            <div className="mt-4">
                                <button type="button" className="btn_danger float-right btn-sm ml_1"  onClick={deleteReminder}>削除</button>
                                <Link to={`/interview/grouprooms/create`} className="btn_add">
                                    追加
                                </Link>
                            </div>
                        </Col>
                    </Row> 
                    <Row className="pt-2 pb-2">
                        <Col xxl={12} xl={12} md={12} sm={12} xs={12} >
                            <div style={{display:"inline"}}>
                                <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                                <select name="sort" id="sort" className="filter" onChange={onSelect}>
                                    <option value="0" selected>新しい順</option>
                                    <option value="1">古い順</option>
                                </select>
                            </div>
                            <div className="float-right">
                                <select name="list_length" id="list_length" value={no_of_rows} className="filter list_length"  onChange={onSelect}>
                                    <option value="10">10</option>
                                    <option value="20" >20</option>
                                    <option value="50" >50</option>
                                    <option value="100" >100</option>
                                    <option value="200" >200</option>
                                </select>
                                <label htmlFor="sort" className="control-label ">件表示</label>
                                <button type="button" className="btn-sm previous-page-icon " style={{border:"0px"}} onClick={() => {
                                    previousPage();
                                    }}>
                                <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                </button>
                                <button type="button" className=" btn-sm next-page-icon " style={{border:"0px"}} onClick={() => {
                                    nextPage();
                                    }}>
                                    <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                                </button>
                            </div>
                        </Col>
                    </Row>
                   
                    </Container>
                    <div className='datatable mobile'>
                {/* <ThemeProvider theme={getMuiTheme()}> */}
                    <MUIDataTable
                        title = {""}
                        data = {reminders}
                        columns={defaultColumns}
                        options={options}
                    />
                     {/* </ThemeProvider> */}
                </div>
       
        </React.Fragment>
    )

}

export default GroupRoomList;