import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
// const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;
const token = localStorage.getItem("token"); // retrieve stored user information from local storage
const header =  {
    headers :  {
        'Authorization': `Bearer ${token}` ,
        // "server_url" : `${SERVER_URL}`,
        "client-domain" :  `${CLIENT_DOMAIN}`,
       } 
};
class InterviewReminderService {
    list(staff_id:string, perPage:number, page:number,search_keywords:any, sort:any, column:string, order:string){
        let dataURL:string = API_URL + `/reminders/list?staff_id=${staff_id}&list_length=${perPage}&page=${page}&search_keywords=${search_keywords}&sort=${sort}&column=${column}&order=${order}`;
        return axios.get(dataURL, header);
    }

    create(){
        let dataURL:string = API_URL + `/reminders/create1`;
        return axios.get(dataURL, header);
    }

    store(data:any) {
        let dataURL:string = API_URL + "/reminders/store1";
        return axios.post(dataURL,data, { headers: { 'Authorization': `Bearer ${token}` } });
    }

    edit(id:any){
        let dataURL:string = API_URL + "/reminders/"+id+"/edit";
        return axios.get(dataURL, header);
    }

    update(data:any ,id:any) {
        let dataURL:string = API_URL + "/reminders/update1/"+id;
        return axios.post(dataURL,data, header);
    }

    delete(data:any) {
        let dataURL:string = API_URL + "/reminders/list/destroy1";
        return axios.post(dataURL,data, header);
    }

    
}

  export default new InterviewReminderService();