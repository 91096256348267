import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
// const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;
const token = localStorage.getItem("token"); // retrieve stored user information from local storage
const header =  {
    headers :  {
        'Authorization': `Bearer ${token}` ,
        // "server_url" : `${SERVER_URL}`,
        "client-domain" :  `${CLIENT_DOMAIN}`,
        "Access-Control-Allow-Origin": "*" ,
       } 
};
class WorkService {
    list(admission_status:any,visit_status:any,exit_status:any,expect_end_date:any,year_months:any,operation_flg:any, money_flg:any, 
        share_memo_filter:any, belongs_filter:any, supplier_filter:any, affiliation_company_filter:any, perPage:number, page:number,
        search_keywords:any, sort:any, column:string, order:string,business_flow:any){
        let dataURL:string = API_URL + `/works/list/follow?admission_status=${admission_status}&visit_status=${visit_status}`;
        dataURL += `&exit_status=${exit_status}&expect_end_date=${expect_end_date}&year_months=${year_months}&operation_flg=${operation_flg}`;
        dataURL += `&money_flg=${money_flg}&share_memo_filter=${share_memo_filter}&belongs_filter=${belongs_filter}&supplier_filter=${supplier_filter}`;
        dataURL += `&affiliation_company_filter=${affiliation_company_filter}&list_length=${perPage}&page=${page}&search_keywords=${search_keywords}&sort=${sort}&column=${column}&order=${order}&business_flow=${business_flow}`;
        return axios.get(dataURL, header);
    }
    chartData(monthflg:any, yearflg:any){
        let dataURL:string = API_URL + `/work/chart/data1?yearflg=${yearflg}&monthflg=${monthflg}`;
        return axios.get(dataURL, header);
    }

    create(){
        let dataURL:string = API_URL + `/works/create1`;
        return axios.get(dataURL, header);
    }

    store(data:any) {
        console.log("Store Data");
        console.log(data);
        let dataURL:string = API_URL + `/works/store1`;
        return axios.post(dataURL,data, header);
    }

    edit(id:any){
        let dataURL:string = API_URL + "/works/"+id+"/edit";
        return axios.get(dataURL, header);
    }

    update(data:any ,id:any) {
        console.log(data);
        let dataURL:string = API_URL + "/works/update1/"+id;
        return axios.post(dataURL,data, header);
    }

    deleteList(data:any) {
        console.log(data);
        let dataURL:string = API_URL + "/works/list/destroy1";
        return axios.post(dataURL,data, header);
    }

    calTax(data:any){
        let dataURL:string = API_URL + "/salerys/tax/pay1";
        return axios.post(dataURL,data, header);
    }

    getChartData(data:any){
        let dataURL:string = API_URL + "/work/chart/data1";
        return axios.post(dataURL,data, header);
    }
    
}

  export default new WorkService();